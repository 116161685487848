// Copyright (C) 2019 TANNER AG

type Options = {
    items: ScrollItem[];
    offset?: number;
    scrollToc?: boolean;
    rootId?: string;
    onActivate?(item: ScrollItem): void;
};

export type ScrollItem = {
    activator: string;
    elements: string[];
};

export enum DataAttribute {
    Target = "data-target"
}

export enum ClassName {
    Active = "active",
    Open = "open",
    NavLink = "tocListItem"
}

export class TocScroller {
    private scrollElement: HTMLElement;
    private options: Options;
    private offsets: number[] = [];
    private targets: string[] = [];
    private activeTarget: string | null = null;
    private scrollHeight: number = 0;
    private selector: string;

    private static readonly DEFAULT_OPTIONS: Options = {
        scrollToc: true,
        offset: 100,
        items: [],
        rootId: "documentToc"
    };

    public constructor(element: HTMLElement | Window, options: Options) {
        // @ts-ignore
        this.scrollElement = element;
        this.options = { ...TocScroller.DEFAULT_OPTIONS, ...options };
        this.selector = `#${this.options.rootId} .${ClassName.NavLink}`;

        this.scrollElement.addEventListener("scroll", () => this.process());

        this.refresh();
        this.process();
    }

    public update(items: ScrollItem[]) {
        this.options.items = items;
        this.refresh();
    }

    public forceProcess() {
        this.process(true);
    }

    private refresh() {
        const offsetBase = 0;

        this.offsets = [];
        this.targets = [];

        this.scrollHeight = this.getScrollHeight();

        const targets: [number, string][] = [];

        this.options.items
            .filter((item) => item.activator)
            .forEach((item) => {
                const targetSelector = `[id="${item.activator}"]`;
                const target: HTMLElement | null = document.querySelector(targetSelector);

                if (target) {
                    const targetBCR = target.getBoundingClientRect();

                    if (targetBCR.width || targetBCR.height) {
                        targets.push([(target.offsetTop ?? 0) + offsetBase, targetSelector]);
                    }
                }
            });

        targets
            .sort((a, b) => a[0] - b[0])
            .forEach((item) => {
                this.offsets.push(item[0]);
                this.targets.push(item[1]);
            });
    }

    private process(force?: boolean) {
        // @ts-ignore
        const scrollTop = this.getScrollTop() + this.options.offset;
        const scrollHeight = this.getScrollHeight();

        // @ts-ignore
        const maxScroll = this.options.offset + scrollHeight - TocScroller.getOffsetHeight();

        if (this.scrollHeight !== scrollHeight) {
            this.refresh();
        }

        if (scrollTop >= maxScroll) {
            const target = this.targets[this.targets.length - 1];

            if (this.activeTarget !== target) {
                this.activate(target);
            }

            return;
        }

        if (this.activeTarget && scrollTop < this.offsets[0] && this.offsets[0] > 0) {
            this.activeTarget = null;
            this.clear();
            return;
        }

        const offsetLength = this.offsets.length;

        for (let i = offsetLength; i--; ) {
            const isActiveTarget =
                (this.activeTarget !== this.targets[i] || force) &&
                scrollTop >= this.offsets[i] &&
                (typeof this.offsets[i + 1] === "undefined" || scrollTop < this.offsets[i + 1]);

            if (isActiveTarget) {
                this.activate(this.targets[i]);
            }
        }
    }

    private activate(target: string) {
        this.activeTarget = target;

        this.clear();

        const item = this.options.items.find((item) => `[id="${item.activator}"]` === target);

        if (item) {
            [].slice
                .call(
                    document.querySelectorAll(
                        item.elements
                            .map((element) => `${this.selector}[${DataAttribute.Target}="#${element}"]`)
                            .join(",")
                    )
                )
                .forEach((node: HTMLElement) => node.classList.add(ClassName.Active));

            // Scroll to active toc item - if option is enabled.
            if (this.options.scrollToc) {
                document
                    .querySelector(`${this.selector}[${DataAttribute.Target}="#${item.activator}"]`)
                    ?.scrollIntoView(false);
            }

            // Execute activation callback function - if option is enabled.
            if (this.options.onActivate) {
                this.options.onActivate(item);
            }
        }
    }

    private getScrollHeight() {
        return (
            this.scrollElement.scrollHeight ||
            Math.max(document.body.scrollHeight, document.documentElement.scrollHeight)
        );
    }

    private clear() {
        [].slice
            .call(document.querySelectorAll(this.selector))
            .filter((node: HTMLElement) => node.classList.contains(ClassName.Active))
            .forEach((node: HTMLElement) => node.classList.remove(ClassName.Active, ClassName.Open));
    }

    private getScrollTop() {
        // @ts-ignore
        return this.scrollElement.scrollTop || this.scrollElement.scrollY;
    }

    private static getOffsetHeight() {
        return window.innerHeight;
    }
}
