// Copyright (C) 2022 TANNER AG

import React from "react";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "../icons/MANIcons";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import clsx from "clsx";

type Props = {
    id: string;
    title?: string;
    no?: string | number;
    active?: boolean;
    to: string;
    onClick?(): void;
};

const ContentTopicLink: React.FC<Props> = ({ id, title, no, active, to, onClick }) => {
    if (!title) {
        return null;
    }

    return (
        <Stack
            direction="row"
            key={id}
            sx={{ alignItems: "center", "&.active": { bgcolor: "grey.50" } }}
            className={clsx({ active })}
        >
            <ChevronRightIcon color="secondary" sx={{ fontSize: 12, mr: 1 }} />
            <Box component={Link} to={to} sx={{ textDecoration: "none", color: "text.primary" }} onClick={onClick}>
                <Typography
                    component="span"
                    variant="subtitle2"
                    color="secondary"
                    sx={{ fontSize: 16, mr: 1, lineHeight: 1 }}
                >
                    {no}
                </Typography>
                <Typography component="span" variant="subtitle2" sx={{ fontSize: 16, mr: 0.5, lineHeight: 1 }}>
                    {title}
                </Typography>
            </Box>
        </Stack>
    );
};

export default ContentTopicLink;
