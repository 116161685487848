// Copyright (C) 2022 TANNER AG

import { useClient } from "urql";
import { DocumentNode } from "graphql";

const useOfflineQuery = () => {
    const client = useClient();

    return <Data = any, Variables extends object = {}>(query: DocumentNode, variables?: Variables) =>
        client.query<Data>(query, variables, { requestPolicy: "network-only" }).toPromise();
};

export default useOfflineQuery;
