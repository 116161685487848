// Copyright (C) 2022 TANNER AG

import React from "react";
import FormControl from "./FormControl";
import { Input } from "./FormInput";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CalendarIcon } from "../icons/MANIcons";

type Props = {
    id: string;
    label: string;
    helperText?: string;
    required?: boolean;
    value?: string;
    onChange?(value: string): void;
};

const FormDate: React.FC<Props> = ({ label, id, helperText, required, value: initValue, onChange }) => {
    const handleChange = (value: Date | null) => {
        try {
            onChange?.(value?.toISOString() ?? "");
        } catch (err) {
            // Ignore invalid dates
        }
    };

    return (
        <FormControl label={label} required={required} helperText={helperText}>
            <DatePicker
                value={initValue ? new Date(initValue) : null}
                onChange={handleChange}
                components={{ OpenPickerIcon: CalendarIcon }}
                InputAdornmentProps={{ position: "start" }}
                minDate={new Date()}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                    <>
                        {InputProps?.endAdornment}
                        <Input id={id} name={id} ref={inputRef} inputProps={inputProps} {...InputProps} />
                    </>
                )}
            />
        </FormControl>
    );
};

export default FormDate;
