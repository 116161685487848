// Copyright (C) 2022 TANNER AG

import {useEffect} from "react";

/**
 * Hash link scroll functionality for React Router.
 * Note that this is for React Router v4/5.
 * Hook that scrolling to #hash-fragment after page is loading.
 * @see https://github.com/rafrex/react-router-hash-link/tree/06822346273c0548d79dacdf7d73ae48910c6b6f
 */

let hashFragment: string = "";
let observer: MutationObserver | null = null;
let asyncTimerId: number | null = null;

const scrollFunction = (el: HTMLElement, offset: number) => {
    el.scrollIntoView(true);
    window.scrollBy(0, -offset);
};

// Reset current observe - clear timeout
const reset = () => {
    hashFragment = "";

    if (observer) {
        observer.disconnect();
    }

    if (asyncTimerId !== null) {
        window.clearTimeout(asyncTimerId);
        asyncTimerId = null;
    }
};

// Return true if target element exists and scroll are executed - otherwise false
const getElAndScroll = (offset: number, rootEl = ""): boolean => {
    const element = document.querySelector<HTMLElement>(`${rootEl} [id='${hashFragment}']`);
    const hashTitle = hashFragment.replace("chapter-", "");
    const h3TitleEl = document.querySelectorAll('h3');
    const h3TitleArray = Array.from(h3TitleEl);
    const headerTitle = h3TitleArray.filter((el) => el.innerText === hashTitle);
    if (element) {
        scrollFunction(element, offset);
        reset();
        return true;
    } else {
        if (headerTitle.length > 0) {
            scrollFunction(headerTitle[0], offset);
            reset();
            return true;
        }
    }

    return false;
};

const hashLinkScroll = (offset: number, rootEl = "") => {
    // Push onto callback queue so it runs after the DOM is updated
    window.setTimeout(() => {
        if (!getElAndScroll(offset, rootEl)) {
            if (!observer) {
                observer = new MutationObserver(() => getElAndScroll(offset, rootEl));
            }
            observer.observe(document, {
                attributes: true,
                childList: true,
                subtree: true
            });
            // if the element doesn't show up in 10 seconds, stop checking
            asyncTimerId = window.setTimeout(() => {
                reset();
            }, 10000);
        }
    }, 0);
};

export const useHashLink = (offset = 0) => {
    const hash = window.location.hash;

    // console.log("element", element2);
    useEffect(() => {
        hashFragment = hash.replace("#", "");

        hashLinkScroll(80);
    }, [hash]);
    return (hash?: string) => {
        if (!hash) {
            return;
        }

        hashFragment = hash.replace("#", "");
        hashLinkScroll(offset);
    };
};

type HasScrollParams = {
    anchor?: string;
    rootEl?: string;
    offset?: number;
};

const useHashScroll = ({anchor = "", offset = 0, rootEl}: HasScrollParams) => {
    // Link scroll is executed only once after mount. Then <HashLink> must be used.
    useEffect(() => {
        if (!anchor) {
            return;
        }

        hashFragment = anchor.replace("#", "");
        hashLinkScroll(offset, rootEl);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useHashScroll;
