// Copyright (C) 2022 TANNER AG

import useStore from "./usestore";
import shallow from "zustand/shallow";
import { useMemo } from "react";
import { TRANSLATION } from "../lib/translation";

const SUPPORTED_LOCALES: readonly string[] = ["en", "de"];
const DEFAULT_COUNTRY = "DE";

// const getDefaultLocale = () => {
//     const browserLanguage = window.navigator.language.split("-")[0];
//     const index = SUPPORTED_LOCALES.indexOf(browserLanguage);
//     if (index !== -1) {
//         return SUPPORTED_LOCALES[index];
//     }
//
//     return SUPPORTED_LOCALES[0];
// };

const useLocale = () => {
    const [locale, setLocale] = useStore((state) => [state.locale ?? 'de', state.setLocale], shallow);
    const [country, setCountry] = useStore((state) => [state.country, state.setCountry], shallow);
    const [options, regionNames, languageNames] = useMemo(() => {
        return [
            TRANSLATION[locale],
            new Intl.DisplayNames([locale], { type: "region" }),
            new Intl.DisplayNames([locale], { type: "language" })
        ];
    }, [locale]);

    return {
        locale,
        setLocale,
        country: country ?? DEFAULT_COUNTRY,
        setCountry,
        locales: SUPPORTED_LOCALES,
        regionNames,
        languageNames,
        ...options
    };
};

export default useLocale;
