// Copyright (C) 2022 TANNER AG

import React from "react";
import Dialog from "../../components/dialog/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { CloseIcon } from "../../components/icons/MANIcons";
import { CustomerProduct } from "./types";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Section from "../../components/Section";
import RichText from "../../components/content/RichText";
import useTranslation from "../../hooks/usetranslation";
import BulletList from "../../components/BulletList";

type Props = {
    product?: CustomerProduct;
    onClose(): void;
};

const CustomerProductDetails: React.FC<Props> = ({ product, onClose }) => {
    const { t } = useTranslation();
    return (
        <Dialog open={Boolean(product)} onClose={onClose} maxWidth="md" scroll="body">
            <DialogContent sx={{ p: 0 }}>
                <DialogContent>
                    <Stack direction="row" sx={{ alignItems: "flex-start" }}>
                        <Stack>
                            <Typography variant="h5" color="secondary" sx={{ textTransform: "uppercase" }}>
                                {product?.category?.name}
                            </Typography>
                            <Typography gutterBottom variant="h2">
                                {product?.name}
                            </Typography>
                        </Stack>
                        <IconButton onClick={onClose} sx={{ ml: "auto" }}>
                            <CloseIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                    </Stack>
                    <Section>
                        <RichText html={product?.description} />
                    </Section>
                    {Boolean(product?.details?.length) && (
                        <Section>
                            <BulletList title={t("details")} items={product?.details} />
                        </Section>
                    )}
                    {Boolean(product?.benefits?.length) && (
                        <Section>
                            <BulletList title={t("customer_benefits")} items={product?.benefits} />
                        </Section>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
                        {t("close_details")}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default CustomerProductDetails;
