// Copyright (C) 2022 TANNER AG

import { styled } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import homeCardDefaultImage from "./home_card_default_image.png";
import useAsset from "../../../hooks/useasset";

type Props = {
    title: string;
    src?: string;
    sx?: SxProps;
    url?: string;
};

const HomeCard: React.FC<Props> = ({ title, src, sx, url = "/" }) => {
    const navigate = useNavigate();
    const { createImageUrl } = useAsset();

    return (
        <HomeCardRoot onClick={() => navigate(url)} sx={sx}>
            <Box component="img" alt={title} src={src ? createImageUrl(src) : homeCardDefaultImage} />
            <Typography
                variant="h2"
                sx={{
                    textShadow: "2px 2px 4px #000000",
                    whiteSpace: "pre-wrap",
                    lineHeight: 1,
                    "@media (min-width:900px)": {
                        fontSize: 34
                    }
                }}
            >
                {title}
            </Typography>
        </HomeCardRoot>
    );
};

export const HomeCardRoot = styled(Paper)(({ theme }) => ({
    position: "relative",
    overflow: "hidden",
    height: 280,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    boxShadow: "0 0 4px 0 #d6d8db",
    borderRadius: 0,
    color: theme.palette.common.white,
    transform: "scale(1) translateZ(0)",
    cursor: "pointer",
    "&.disabled": {
        opacity: 0.5
    },
    "& > img": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
        transition: "transform 2s cubic-bezier(0.23, 1, 0.32, 1)",
        zIndex: -1
    },
    "&:hover > img": {
        transform: "scale(1.1) translateZ(0)"
    }
}));

export default HomeCard;
