// Copyright (C) 2022 TANNER AG

import React from "react";
import { useParams } from "react-router-dom";
import { ContentTypeGroup } from "../../../graphql";
import NavBar from "../../../components/navbar/NavBar";
import ContentIntro from "../../../components/content/ContentIntro";
import Box from "@mui/material/Box";
import Content from "../../../components/content/Content";
import { formatNo, routes } from "../../../lib/utils";
import Stack from "@mui/material/Stack";
import ContentTopicLink from "../../../components/content/ContentTopicLink";
import Divider from "@mui/material/Divider";
import ContentSectionToc from "../../../components/content/section/ContentSectionToc";
import useContentTopic from "../../../hooks/usecontenttopic";
import useContentGroup from "../../../hooks/usecontentgroup";
import Spinner from "../../../components/Spinner";
import { useHashLink } from "../../../hooks/usehashscroll";
import { OFFSET } from "../../../components/content/section/ContentSectionNav";

const TransportChapter = () => {
    const { topicKey, subTopicKey } = useParams();
    const { group } = useContentGroup(ContentTypeGroup.Transportsolutions);
    const { topic, fetching } = useContentTopic(ContentTypeGroup.Transportsolutions, [
        String(topicKey),
        String(subTopicKey)
    ]);
    const navigate = useHashLink(OFFSET - 80);

    if (fetching) {
        return <Spinner />;
    }

    return (
        <>
            <NavBar
                links={[
                    { label: group?.title, to: routes.transportSolutions },
                    { label: topic?.parent?.title, to: `${routes.transportSolutions}/${topic?.parent?.key}` },
                    { label: topic?.title }
                ]}
            />
            <ContentIntro
                title={topic?.title ?? ""}
                theme={topic?.parent?.title}
                content={topic?.description ?? ""}
                no={formatNo([topic?.parent?.no, topic?.no])}
                additional={
                    <Stack spacing={1} sx={{ mt: 3 }}>
                        {topic?.sections?.map((section) => (
                            <ContentTopicLink
                                key={section.id}
                                id={section.id}
                                to={`#${section.id}`}
                                title={section.title}
                                no={formatNo([topic?.parent?.no, topic?.no, section?.no])}
                                onClick={() => navigate(section.id)}
                            />
                        ))}
                    </Stack>
                }
            >
                <ContentSectionToc
                    title={group?.title}
                    topics={group?.topics?.filter((groupTopic) => groupTopic.id === topic?.parent?.id)}
                    activeTopicKey={topic?.parent?.key}
                    activeChapterId={topic?.id}
                    path={routes.transportSolutions}
                    group={ContentTypeGroup.Transportsolutions}
                />
            </ContentIntro>
            <Divider />
            <Box sx={{ pt: 4, bgcolor: "background.paper" }}>
                {topic?.sections?.map((section) => (
                    <Content
                        key={section.id}
                        id={section.id}
                        section={section}
                        no={formatNo([topic?.parent?.no, topic?.no, section?.no])}
                    />
                ))}
            </Box>
        </>
    );
};

export default TransportChapter;
