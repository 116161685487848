// Copyright (C) 2022 TANNER AG

import { useEffect, useState } from "react";

const useOnline = () => {
    const [online, setOnline] = useState(window.navigator.onLine);

    const checkOnlineStatus = () => {
        setOnline(window.navigator.onLine);
    };

    useEffect(() => {
        window.addEventListener("online", checkOnlineStatus);
        window.addEventListener("offline", checkOnlineStatus);

        return () => {
            window.removeEventListener("online", checkOnlineStatus);
            window.removeEventListener("offline", checkOnlineStatus);
        };
    }, []);

    return online;
};

export default useOnline;
