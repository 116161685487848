// Copyright (C) 2022 TANNER AG

import React from "react";
import { ContentSection } from "../../graphql";
import ContentHeading from "./ContentHeading";
import ContentRichText from "./ContentRichtText";
import Box from "@mui/material/Box";
import HotspotImage from "./hotspot/HotspotImage";
import Carousel from "./carousel/Carousel";
import Grid from "@mui/material/Grid";
import ContentWrapper from "./ContentWrapper";

type Props = {
    id: string;
    no?: string;
    section?: Partial<ContentSection>;
};

const Content: React.FC<Props> = ({ id, section, no }) => {
    return (
        <Box id={id} data-section-id={id} sx={{ pb: 2 }}>
            <ContentWrapper>
                <ContentHeading content={section?.title} no={no} />
                {section?.twoColumns ? (
                    <Grid container>
                        <Grid item xs={5}>
                            <HotspotImage src={section?.image} hotspots={section?.hotspots} />
                        </Grid>
                        <Grid item xs={7}>
                            <ContentRichText content={section?.content} />
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        <ContentRichText content={section?.content} />
                        <HotspotImage src={section?.image} hotspots={section?.hotspots} />
                        <Carousel images={section?.gallery} />
                    </>
                )}
            </ContentWrapper>
        </Box>
    );
};

export default Content;
