// Copyright (C) 2022 TANNER AG

import React from "react";
import useAnchorHandler from "../../../../hooks/useanchorhandler";
import { ChevronDownIcon } from "../../../../components/icons/MANIcons";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import MenuPopover from "../../../../components/MenuPopover";
import useProductCategories from "../../../../hooks/useproductcategories";
import MenuList from "@mui/material/MenuList";
import HeroIcon from "../../../../components/icons/HeroIcons";
import useProductCategory from "../../../../hooks/useproductcategory";
import ProductSidebarMobileItem from "./ProductSidebarMobileItem";
import useBreakpoint from "../../../../hooks/usebreakpoint";

const ProductSidebarMobile: React.FC = () => {
    const { onClick, onClose, anchorEl } = useAnchorHandler();
    const { categories } = useProductCategories();
    const { productCategory } = useProductCategory();
    const { down } = useBreakpoint("lg");

    if (!down) {
        return null;
    }

    return (
        <Stack sx={{ mb: 2 }}>
            <Button
                variant="outlined"
                fullWidth
                startIcon={<HeroIcon iconName={productCategory?.icon ?? ""} />}
                endIcon={<ChevronDownIcon />}
                sx={{
                    justifyContent: "flex-start",
                    "& > .MuiButton-endIcon": { marginLeft: "auto" }
                }}
                onClick={onClick}
            >
                <Typography component="span" noWrap variant="body2">
                    {productCategory?.name}
                </Typography>
            </Button>
            <MenuPopover
                anchorEl={anchorEl}
                onClose={onClose}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                maxWidth={350}
            >
                <MenuList>
                    {categories?.map((category) => {
                        const selected = category.id === productCategory?.id;
                        return (
                            <ProductSidebarMobileItem
                                key={category.id}
                                id={category.id}
                                name={category.name ?? ""}
                                icon={category.icon ?? ""}
                                selected={selected}
                                onClick={onClose}
                            />
                        );
                    })}
                </MenuList>
            </MenuPopover>
        </Stack>
    );
};

export default ProductSidebarMobile;
