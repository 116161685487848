// Copyright (C) 2022 TANNER AG

import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import GroupAttr from "../../../components/GroupAttr";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "../../../components/icons/MANIcons";
import useTranslation from "../../../hooks/usetranslation";
import ProjectStatus from "../../../components/ProjectStatus";
import useDatetime from "../../../hooks/usedatetime";
import { boldFontFamily } from "../../../theme";
import UserAvatar from "../../../components/UserAvatar";
import Skeleton from "@mui/material/Skeleton";
import ListItemRegularAction from "../../../components/listitem/regular/ListItemRegularAction";

type Props = {
    id: string;
    name: string;
    status?: string;
    created?: string;
    modified?: string;
    gpa?: string;
};

const ProjectItem: React.FC<Props> = ({ id, name, status, created, modified, gpa }) => {
    const { t } = useTranslation();
    const { fDate } = useDatetime();

    return (
        <Stack component={Paper} direction={{ xs: "column", sm: "row" }} sx={{ px: 3, py: 2, minHeight: 157 }}>
            <Stack sx={{ flexGrow: 1 }}>
                <ListItemRegularAction to={id}>
                    <Typography variant="h3" gutterBottom>
                        {name}
                    </Typography>
                </ListItemRegularAction>
                <Stack direction="row" alignItems="center" spacing={3} sx={{ mb: 2 }}>
                    <ProjectStatus status={status} />
                    <Typography color="textSecondary">
                        <Typography component="strong" color="inherit" sx={{ fontFamily: boldFontFamily }}>
                            {t("gpa_no")}
                        </Typography>{" "}
                        {gpa}
                    </Typography>
                </Stack>
                <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 0.5, md: 3 }} sx={{ mt: "auto" }}>
                    <GroupAttr label={t("last_updated")} value={fDate(modified)} minWidth="auto" />
                    <GroupAttr label={t("created")} value={fDate(created)} minWidth="auto" />
                    <GroupAttr label={t("created_by")} value={<UserAvatar />} minWidth="auto" />
                </Stack>
            </Stack>
            <Stack direction="row-reverse">
                <Button component={Link} to={id} startIcon={<ArrowRightIcon color="secondary" />}>
                    {t("show_details")}
                </Button>
            </Stack>
        </Stack>
    );
};

export const ProjectItemLoader: React.FC = () => {
    return (
        <Stack component={Paper} direction={{ xs: "column", sm: "row" }} sx={{ px: 3, py: 2, minHeight: 157 }}>
            <Stack sx={{ flexGrow: 1 }}>
                <Typography variant="h3" width="70%" gutterBottom>
                    <Skeleton />
                </Typography>
                <Skeleton variant="rectangular" width={140} height={20} sx={{ borderRadius: 1 }} />
                <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 0.5, md: 3 }} sx={{ mt: "auto" }}>
                    <Skeleton variant="rectangular" width={140} height={20} />
                    <Skeleton variant="rectangular" width={140} height={20} />
                    <Skeleton variant="rectangular" width={140} height={20} />
                </Stack>
            </Stack>
            <Stack direction="row-reverse">
                <Skeleton variant="rectangular" width={140} height={40} />
            </Stack>
        </Stack>
    );
};

export default ProjectItem;
