// Copyright (C) 2022 TANNER AG

import fnsFormat from "date-fns/format";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { Asset } from "../../../graphql";
import { boldFontFamily } from "../../../theme";
import { formatTag } from "../../../lib/utils";
import useTranslation from "../../../hooks/usetranslation";

enum ASSET_INFO_ROW_VALUE_TYPE {
    STRING,
    ARRAY,
    DATE,
    TAGS,
    FILE_NAME
}

const ASSET_INFO_TABLE_ROWS: {
    id: number;
    nameTranslationKey: string;
    valueKey: keyof Asset;
    valueType: ASSET_INFO_ROW_VALUE_TYPE;
}[] = [
    {
        id: 0,
        nameTranslationKey: "title",
        valueKey: "title",
        valueType: ASSET_INFO_ROW_VALUE_TYPE.STRING
    },
    {
        id: 1,
        nameTranslationKey: "fileName",
        valueKey: "fileName",
        valueType: ASSET_INFO_ROW_VALUE_TYPE.FILE_NAME
    },
    {
        id: 2,
        nameTranslationKey: "uploader",
        valueKey: "uploader",
        valueType: ASSET_INFO_ROW_VALUE_TYPE.STRING
    },
    {
        id: 3,
        nameTranslationKey: "expert",
        valueKey: "expert",
        valueType: ASSET_INFO_ROW_VALUE_TYPE.STRING
    },
    {
        id: 4,
        nameTranslationKey: "date",
        valueKey: "date",
        valueType: ASSET_INFO_ROW_VALUE_TYPE.DATE
    },
    {
        id: 5,
        nameTranslationKey: "series",
        valueKey: "series",
        valueType: ASSET_INFO_ROW_VALUE_TYPE.ARRAY
    },
    {
        id: 6,
        nameTranslationKey: "bus_type",
        valueKey: "busTypes",
        valueType: ASSET_INFO_ROW_VALUE_TYPE.ARRAY
    },
    {
        id: 7,
        nameTranslationKey: "type",
        valueKey: "type",
        valueType: ASSET_INFO_ROW_VALUE_TYPE.STRING
    },
    {
        id: 8,
        nameTranslationKey: "color_group",
        valueKey: "groupColor",
        valueType: ASSET_INFO_ROW_VALUE_TYPE.STRING
    },
    {
        id: 9,
        nameTranslationKey: "solutions",
        valueKey: "solutions",
        valueType: ASSET_INFO_ROW_VALUE_TYPE.ARRAY
    },
    {
        id: 10,
        nameTranslationKey: "languages",
        valueKey: "languageNames",
        valueType: ASSET_INFO_ROW_VALUE_TYPE.ARRAY
    },
    {
        id: 11,
        nameTranslationKey: "country",
        valueKey: "countries",
        valueType: ASSET_INFO_ROW_VALUE_TYPE.ARRAY
    },
    {
        id: 12,
        nameTranslationKey: "file_size",
        valueKey: "fileSize",
        valueType: ASSET_INFO_ROW_VALUE_TYPE.STRING
    },
    {
        id: 13,
        nameTranslationKey: "tags",
        valueKey: "tags",
        valueType: ASSET_INFO_ROW_VALUE_TYPE.TAGS
    }
];

const formatAssetInfoTableRowValue = (value: any, type: ASSET_INFO_ROW_VALUE_TYPE) => {
    switch (type) {
        case ASSET_INFO_ROW_VALUE_TYPE.STRING:
            return value;
        case ASSET_INFO_ROW_VALUE_TYPE.ARRAY:
            return value.join(", ");
        case ASSET_INFO_ROW_VALUE_TYPE.DATE:
            return fnsFormat(new Date(value), "dd.MM.yyyy");
        case ASSET_INFO_ROW_VALUE_TYPE.TAGS:
            return value.map(formatTag).join(", ");
        case ASSET_INFO_ROW_VALUE_TYPE.FILE_NAME:
            return value.split(".")[0];
    }
};

const isInfoTableRowVisible = (value: any, type: ASSET_INFO_ROW_VALUE_TYPE) => {
    switch (type) {
        case ASSET_INFO_ROW_VALUE_TYPE.STRING:
        case ASSET_INFO_ROW_VALUE_TYPE.DATE:
        case ASSET_INFO_ROW_VALUE_TYPE.FILE_NAME:
            return !!value;
        case ASSET_INFO_ROW_VALUE_TYPE.ARRAY:
        case ASSET_INFO_ROW_VALUE_TYPE.TAGS:
            return !!value.length;
    }
};

type Props = {
    asset: Asset;
};

const DownloadDetailsInfoTable: React.FC<Props> = ({ asset }) => {
    const { t } = useTranslation();

    return (
        <Table aria-labelledby="tableTitle" padding="none" sx={{ tableLayout: "fixed", marginY: 2 }}>
            <TableBody>
                {ASSET_INFO_TABLE_ROWS.filter((row) => isInfoTableRowVisible(asset[row.valueKey], row.valueType)).map(
                    (row) => {
                        const rowValue = formatAssetInfoTableRowValue(asset[row.valueKey], row.valueType);
                        return (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row" sx={{ border: 0, paddingBottom: 0.5 }}>
                                    <Typography fontFamily={boldFontFamily} color="text.secondary">
                                        {t(row.nameTranslationKey)}
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: 0, paddingBottom: 0.5 }}>
                                    <Typography
                                        color="text.secondary"
                                        textOverflow="ellipsis"
                                        overflow="hidden"
                                        title={rowValue}
                                    >
                                        {rowValue}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        );
                    }
                )}
            </TableBody>
        </Table>
    );
};

export default DownloadDetailsInfoTable;
