// Copyright (C) 2022 TANNER AG

import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";
import Section from "../../../../components/Section";
import Stack from "@mui/material/Stack";
import ListItemUser from "../../../../components/listitem/ListItemUser";
import { LocalExpert } from "../../../../graphql";
import useLocale from "../../../../hooks/uselocale";
import CountrySwitch from "../../../../components/apptoolbar/countryswitch/CountrySwitch";
import useTranslation from "../../../../hooks/usetranslation";
import ExpertsActions from "./ExpertsActions";

type Props = {
    productName?: string;
    experts: LocalExpert[];
};

const Experts: React.FC<Props> = ({ productName, experts }) => {
    const { country } = useLocale();
    const { t } = useTranslation();
    const localExperts = useMemo(() => {
        return experts.filter((expert) => !expert.countries?.length || expert.countries?.includes(country));
    }, [experts, country]);

    return (
        <Section>
            <Stack direction="row" sx={{ alignItems: "center" }}>
                <Stack sx={{ flexGrow: 1 }}>
                    <Typography variant="h3">{t("local_domain_experts")}</Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                        {t("question")}
                    </Typography>
                </Stack>
                <CountrySwitch />
            </Stack>
            <Stack>
                {localExperts.map((expert, index) => (
                    <ListItemUser
                        key={index}
                        name={`${expert?.name}`}
                        description={expert?.details}
                        phone={expert?.phone}
                        mail={expert?.email}
                        action={<ExpertsActions expert={expert} productName={productName} />}
                    />
                ))}
            </Stack>
        </Section>
    );
};

export default Experts;
