// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import useCustomerProject from "../../../../hooks/usecustomerproject";
import CustomerProjectDetails from "../../../customer/project/CustomerProjectDetails";
import Spinner from "../../../../components/Spinner";
import Typography from "@mui/material/Typography";
import CustomerProjectTcoDetails from "../../../customer/project/CustomerProjectTcoDetails";

type Props = {
    uuid: string;
    password: string;
};

const ProjectCustomerPreviewDetails: React.FC<Props> = ({ uuid, password }) => {
    const { project, fetching, error } = useCustomerProject(uuid, password);
    const [calcId, setCalcId] = useState("");

    if (!fetching && error) {
        return <Typography color="error">{error}</Typography>;
    }

    if (fetching || !project) {
        return <Spinner />;
    }

    return (
        <>
            {!calcId ? (
                <CustomerProjectDetails project={project} onCalcClick={(id) => setCalcId(id)} />
            ) : (
                <>
                    <CustomerProjectTcoDetails
                        calcId={calcId}
                        projectName={project?.name}
                        calculations={project?.calculations}
                        onBack={() => setCalcId("")}
                    />
                </>
            )}
        </>
    );
};

export default ProjectCustomerPreviewDetails;
