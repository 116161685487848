// Copyright (C) 2022 TANNER AG

import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";

import { routes } from "../../../lib/utils";
import { useVehicleTopicQuery } from "../../../graphql";
import Content from "../../../components/content/Content";
import ContentIntro from "../../../components/content/ContentIntro";
import ContentSectionNav from "../../../components/content/section/ContentSectionNav";
import NavBar from "../../../components/navbar/NavBar";
import Spinner from "../../../components/Spinner";
import useLocale from "../../../hooks/uselocale";
import usePage from "../../../hooks/usePage";
import useTranslation from "../../../hooks/usetranslation";

const PageTopic: React.FC = () => {
    const { t } = useTranslation();
    const { locale } = useLocale();
    const { topicKey, topicType } = useParams();
    const [{ data, fetching }] = useVehicleTopicQuery({
        variables: { key: topicKey ?? "", type: topicType ?? "", locale },
        pause: !topicKey || !topicType
    });
    const { page } = usePage();

    const topic = data?.vehicleTopic;

    if (fetching) {
        return <Spinner />;
    }

    return (
        <>
            <NavBar
                links={[page ? { label: page.title, to: `${routes.page}/${page.id}` } : {}, { label: topic?.title }]}
            />
            <ContentIntro
                title={topic?.title ?? ""}
                subTitle={topic?.subtitle ?? ""}
                theme={t(topic?.type ?? "")}
                content={topic?.description ?? ""}
            />
            <ContentSectionNav sections={topic?.sections} />
            <Box sx={{ pt: 4, bgcolor: "background.paper" }}>
                {topic?.sections?.map((section) => (
                    <Content key={section.id} id={section.id} section={section} />
                ))}
            </Box>
        </>
    );
};

export default PageTopic;
