// Copyright (C) 2022 TANNER AG

import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { boldFontFamily } from "../../../theme";
import { routes } from "../../../lib/utils";
import { useNewsCategoriesQuery } from "../../../graphql";
import RichText from "../../../components/content/RichText";
import useAsset from "../../../hooks/useasset";
import useDatetime from "../../../hooks/usedatetime";
import useFallbackImage from "../../../hooks/useFallbackImage";
import useLocale from "../../../hooks/uselocale";
import useTranslation from "../../../hooks/usetranslation";

type Props = {
    author: string;
    categories: string[];
    description?: string;
    isNew: boolean;
    publication?: string;
    slug: string;
    thumbnail?: string | null;
    title: string;
};

const NewMark: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                position: "absolute",
                top: 8,
                left: 8,
                borderRadius: 16,
                backgroundColor: "#E40045"
            }}
        >
            <Typography
                color="white"
                fontSize={12}
                fontFamily={boldFontFamily}
                textTransform="uppercase"
                lineHeight="20px"
                sx={{ paddingX: 1, paddingY: 0.25 }}
            >
                {t("new")}
            </Typography>
        </Box>
    );
};

const NewsListItem: React.FC<Props> = ({
    author,
    categories,
    description,
    isNew,
    publication,
    slug,
    thumbnail,
    title
}) => {
    const { locale } = useLocale();
    const { fDate } = useDatetime();
    const { createImageUrl } = useAsset();
    const { fallbackImage } = useFallbackImage();
    const navigate = useNavigate();
    const [{ data }] = useNewsCategoriesQuery({ variables: { locale } });

    const newsCategories = data?.newsCategories ?? [];
    const categoriesList = categories
        .map((catId) => ({ name: newsCategories.find((cat) => cat.id === catId)?.name, id: catId }))
        .filter((category) => category.name);

    return (
        <Stack
            sx={{ alignItems: "flex-start", cursor: "pointer" }}
            onClick={() => {
                navigate(`${routes.newsFlash}/${slug}`);
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Box position="relative">
                        <Box
                            component="img"
                            alt="News thumbnail"
                            src={thumbnail ? createImageUrl(thumbnail) : fallbackImage}
                            sx={{ width: "100%", maxWidth: "286px", maxHeight: "208px", objectFit: "cover" }}
                        />
                        {isNew && <NewMark />}
                    </Box>
                </Grid>
                <Grid item xs={12} md={8} sx={{ display: "flex", flexDirection: "column" }}>
                    <Box>
                        {categoriesList.map((category, index) => (
                            <Fragment key={category.id}>
                                <Typography display="inline" color="secondary" variant="subtitle2">
                                    {category.name}
                                </Typography>
                                {index !== categoriesList.length - 1 && (
                                    <Typography display="inline" color="#CBD3DC" sx={{ mx: 1 }}>
                                        •
                                    </Typography>
                                )}
                            </Fragment>
                        ))}
                    </Box>

                    <Typography variant="h3" color="#161616" sx={{ my: 0.5 }}>
                        {title}
                    </Typography>
                    <Typography
                        variant="body2"
                        component="div"
                        color="#676767"
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "3",
                            WebkitBoxOrient: "vertical"
                        }}
                    >
                        <RichText html={description} />
                    </Typography>
                    <Box sx={{ mt: "auto", mb: 1 }}>
                        <Typography display="inline" color="#4E6174" variant="subtitle2">
                            {author}
                        </Typography>
                        <Typography display="inline" color="#CBD3DC" sx={{ mx: 1 }}>
                            •
                        </Typography>
                        <Typography display="inline" color="#4E6174" fontSize={14}>
                            {fDate(publication)}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Stack>
    );
};

export default NewsListItem;
