// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { CloseIcon, MenuIcon } from "../../icons/MANIcons";
import { lightFontFamily } from "../../../theme";
import Toolbar from "@mui/material/Toolbar";
import AppLogo from "../AppLogo";
import Container from "@mui/material/Container";

type Props = {
    children?: React.ReactNode;
};

const AppMenuMobile: React.FC<Props> = ({ children }) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <Stack direction="row" spacing={1} sx={{ alignItems: "center", display: { md: "none" } }}>
                <IconButton onClick={() => setOpen(true)} edge="start">
                    <MenuIcon sx={{ color: "grey.800" }} />
                </IconButton>
                <Typography variant="body2" sx={{ color: "grey.300", fontFamily: lightFontFamily }}>
                    {process.env.REACT_APP_TITLE}
                </Typography>
            </Stack>
            <Drawer
                open={open}
                onClose={() => setOpen(false)}
                anchor="top"
                sx={{ "& .MuiDrawer-paper": { minHeight: 200 } }}
            >
                <MuiAppBar
                    position="static"
                    elevation={0}
                    sx={{
                        backgroundColor: "background.paper",
                        color: "text.secondary",
                        position: " relative",
                        borderBottom: "1px solid",
                        borderColor: "grey.50"
                    }}
                >
                    <Container sx={{ pb: 2 }}>
                        <Toolbar disableGutters sx={{ minHeight: { sm: 56, md: 76 } }}>
                            <Stack direction="row" spacing={1} sx={{ alignItems: "center", display: { md: "none" } }}>
                                <IconButton onClick={() => setOpen(false)} edge="start">
                                    <CloseIcon sx={{ color: "grey.800", fontSize: 20 }} />
                                </IconButton>
                                <Typography variant="body2" sx={{ color: "grey.300", fontFamily: lightFontFamily }}>
                                    {process.env.REACT_APP_TITLE}
                                </Typography>
                            </Stack>
                            <AppLogo />
                        </Toolbar>
                        {children}
                    </Container>
                </MuiAppBar>
            </Drawer>
        </>
    );
};

export default AppMenuMobile;
