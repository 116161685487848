// Copyright (C) 2022 TANNER AG

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ArrowRightIcon } from "../../../../components/icons/MANIcons";
import { Link } from "react-router-dom";
import ProjectSelect from "../../../../components/projectselect/ProjectSelect";
import Section from "../../../../components/Section";
import useActiveProject from "../../../../hooks/useactiveproject";
import ProjectSidebarProducts from "./ProductSidebarProducts";
import { routes } from "../../../../lib/utils";
import useTranslation from "../../../../hooks/usetranslation";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";

const ProjectSidebar: React.FC = () => {
    const { id } = useActiveProject();
    const { t } = useTranslation();
    return (
        <Box>
            <Section>
                <Stack direction="row" sx={{ alignItems: "center", mb: 2, height: 36 }}>
                    <Typography
                        component={Link}
                        to={`${routes.dsg.projects}/${id}`}
                        variant="h4"
                        sx={{ color: "inherit", textDecoration: "none", "&:hover": { color: "secondary.main" } }}
                    >
                        {t("my_projects")}
                    </Typography>
                    {id && (
                        <IconButton
                            sx={{ ml: "auto" }}
                            color="secondary"
                            component={Link}
                            to={`${routes.dsg.projects}/${id}`}
                            title={t("project_details")}
                            size="small"
                        >
                            <ArrowRightIcon />
                        </IconButton>
                    )}
                </Stack>
                <ProjectSelect />
            </Section>
            <Section>
                <ProjectSidebarProducts />
            </Section>
        </Box>
    );
};

export default ProjectSidebar;
