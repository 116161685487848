// Copyright (C) 2022 TANNER AG

import React from "react";
import { Route, Routes } from "react-router-dom";
import EMobilityOverview from "./overview/EMobilityOverview";
import EMobilityTopic from "./topic/EMobilityTopic";
import EMobilityChapter from "./chapter/EMobilityChapter";

const EMobilityView = () => {
    return (
        <Routes>
            <Route path="/" element={<EMobilityOverview />} />
            <Route path="/:topicKey" element={<EMobilityTopic />} />
            <Route path="/:topicKey/:subTopicKey" element={<EMobilityChapter />} />
        </Routes>
    );
};

export default EMobilityView;
