// Copyright (C) 2022 TANNER AG

import React, { useMemo } from "react";
import Stack from "@mui/material/Stack";
import useLocale from "../../../hooks/uselocale";
import ProductListItem from "../../../components/productlistitem/ProductListItem";
import ListItemRegularLoader from "../../../components/listitem/regular/ListItemRegularLoader";
import { Product } from "../../../graphql";
import isBefore from "date-fns/isBefore";

type Props = {
    fetching?: boolean;
    products?: Pick<
        Product,
        "id" | "name" | "image" | "intro" | "details" | "availableFrom" | "availableTo" | "regionalAvailability"
    >[];
};

const ProductGroupProducts: React.FC<Props> = ({ fetching, products }) => {
    const { country } = useLocale();
    const filteredProducts = useMemo(() => {
        return (
            products?.filter(
                (product) =>
                    (!product?.regionalAvailability?.length || product.regionalAvailability.includes(country)) &&
                    (!product.availableTo || isBefore(new Date(), new Date(product.availableTo)))
            ) ?? []
        );
    }, [country, products]);

    return (
        <Stack spacing={2}>
            {!fetching && filteredProducts.map((product) => <ProductListItem key={product.id} product={product} />)}
            {fetching && Array.from(Array(4).keys()).map((key) => <ListItemRegularLoader key={key} border />)}
        </Stack>
    );
};

export default ProductGroupProducts;
