// Copyright (C) 2022 TANNER AG

import {Link, useLocation} from "react-router-dom";
import React, {useState} from "react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/lab/LoadingButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import {accessToken,routes, runtimeConfig} from "../../lib/utils";
import FormInput from "../../components/inputs/FormInput";
import HTTPClient from "../../lib/HTTPClient";
import useLocalStorage from "../tco/hooks/uselocalstorage";
import useTranslation from "../../hooks/usetranslation";
import Dialog from "../../components/dialog/Dialog";
import {DialogContent, Typography} from "@mui/material";
import {ArrowLeftIcon} from "../../components/icons/MANIcons";
import LocaleDropdown from "../../components/apptoolbar/LocaleDropdown";

const UNDER_CONSTRUCTION_MESSAGE = "The system is currently under construction.\nLogin is disabled.";
const IS_UNDER_CONSTRUCTION_KEY = "REACT_APP_IS_UNDER_CONSTRUCTION";
const IS_UNDER_CONSTRUCTION = process.env[IS_UNDER_CONSTRUCTION_KEY] === "true";

const isBefore15thFebruary = new Date() < new Date("2023-02-15T00:00");

type Props = {
    onSuccess?(): void;
    onClickVerifyHandler: (email: string) => void;
};

const Login: React.FC<Props> = ({onSuccess, onClickVerifyHandler}) => {
    const [fetching, setFetching] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [normalLogin, setNormalLogin] = useState(false);
    const [emailInput, setEmailInput] = useState('');
    const [isError, setError] = useState(false);
    const {t} = useTranslation();
    const [isUnderConstruction] = useLocalStorage(IS_UNDER_CONSTRUCTION_KEY, IS_UNDER_CONSTRUCTION);
    const shouldResetPassword = isBefore15thFebruary;

    const { search, pathname } = useLocation();

    const onChangeHandler = (e: any) => {
        setEmailInput(e.target.value);
    }
    const popUpComponent = () => {
        return (
            <Dialog open={showPopup}>
                <DialogContent>
                    <Typography sx={{padding: "0 0 20px 0"}} variant="h3">
                        {t("popup_title")}
                    </Typography>
                    <Typography sx={{padding: "0 0 20px 0"}}>{t("popup_deactivated_message")}</Typography>
                    <Box sx={{display: 'flex', float: 'right', gap: '20px'}}>
                        <Button variant="contained" color="secondary" onClick={() => onClickVerifyHandler(emailInput)}>
                            {t("popup_form_button")}
                        </Button>
                        <Button variant="outlined" color="secondary" onClick={() => setShowPopup(false)}>
                            {t("popup_cancel")}
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const username = formData.get("username") as string;
        const password = formData.get("password") as string;

        setFetching(true);
        HTTPClient.POST("/user/auth", {username, password})
            .then((response) => {
                return response.text();
            }).then((token: string) => {
            if (!token) throw new Error("Login failed!");
            accessToken.set(token);
            onSuccess?.();
        })
            .catch((error) => {
                if (JSON.parse(error.message) === 403) {
                    setShowPopup(true);
                } else {
                    setError(true);
                }
            })
            .finally(() => {
                setFetching(false);
            });
    };
    let isRedirect = false;
    const handleSsoLoginRequest = () => {
        if (!isRedirect) {
            const queryParams = new URLSearchParams(search);
            const redirectUrl = pathname + '?' + queryParams.toString();
            localStorage.setItem('redirectUrl', redirectUrl);
            window.location.href = '/samlsso?redirectUrl=' + redirectUrl;
            isRedirect = true;
        }
    }

    const PreLogin = (
        <Box component={Paper} sx={{width: "100%", maxWidth: 500, p: 4, boxShadow: "0 0 4px 0 #d6d8db"}}>
            <Button onClick={handleSsoLoginRequest} sx={{minWidth: 140}} variant="contained" color="secondary"
                    fullWidth>{t("samlsso")}</Button>
            <Button
                type="submit"
                sx={{minWidth: 140, backgroundColor: "#e0e0e0", marginBottom:2}}
                fullWidth
                onClick={() => setNormalLogin(true)}
            >
                {t("sign_in_main")}
            </Button>
            <LocaleDropdown label={t("language")}/>
        </Box>
    );

    return (
        <Box sx={{display: "flex", height: "100vh"}}>
            <Box
                style={{backgroundImage: `url(${runtimeConfig.basePath}/login-banner.jpg)`}}
                sx={{
                    width: 750,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    display: {xs: "none", lg: "block"}
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    flexGrow: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    mx: 2
                }}
                onClick={() => setShowPopup(false)}
            >

                {showPopup && popUpComponent()}
                {isUnderConstruction ? (
                    <Typography variant="h3" whiteSpace="pre-line">
                        {UNDER_CONSTRUCTION_MESSAGE}
                    </Typography>
                ) : (
                    <>
                        {
                            normalLogin ? (
                                <Box component={Paper}
                                     sx={{width: "100%", maxWidth: 500, p: 4, boxShadow: "0 0 4px 0 #d6d8db"}}>
                                    <form onSubmit={handleSubmit}>
                                        <Button startIcon={<ArrowLeftIcon color="secondary"/>}
                                                onClick={() => setNormalLogin(false)} sx={{minWidth: 100}}>
                                            {t("back_to_main")}
                                        </Button>
                                        <Box sx={{mb: 3}}>
                                            <Typography variant="h3" gutterBottom>
                                                {t("sign_in")}
                                            </Typography>
                                            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                                {t("enter_credential")}
                                            </Typography>
                                            {shouldResetPassword && (
                                                <Alert severity="warning" sx={{mt: 2}}>
                                                    For your first login after 1st February 2023, please reset your
                                                    password{" "}
                                                    <Link to={routes.passwordForgotten}>here</Link>.
                                                </Alert>
                                            )}
                                        </Box>
                                        <Stack spacing={2}>
                                            <FormInput
                                                id="username"
                                                label={t("business_email")}
                                                error={isError}
                                                InputProps={{autoComplete: "username", onChange: onChangeHandler}}
                                            />
                                            <FormInput
                                                id="password"
                                                label={t("password")}
                                                error={isError}
                                                InputProps={{autoComplete: "current-password", type: "password"}}
                                            />
                                            <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                                component={Link}
                                                to={routes.passwordForgotten}
                                            >
                                                {t("password_forgotten")}
                                            </Typography>
                                        </Stack>
                                        {isError && (
                                            <Alert severity="error" sx={{mt: 1}}>
                                                {t("login_error")}
                                            </Alert>
                                        )}
                                        <Stack direction="row" sx={{mt: 4, textAlign: "left"}} spacing={1}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                                loading={fetching}
                                                sx={{minWidth: 140}}
                                                fullWidth
                                            >
                                                {t("signin")}
                                            </Button>
                                            <Button component={Link} to={routes.register}
                                                    sx={{minWidth: 140, backgroundColor: "#e0e0e0"}}
                                                    fullWidth>
                                                {t("signup")}
                                            </Button>
                                        </Stack>
                                    </form>
                                </Box>
                            ) : PreLogin
                        }
                    </>
                )}
                <Typography variant="caption" sx={{position: "absolute", bottom: 0, left: 0, my: 1, mx: 2}}>
                    © MAN {new Date().getFullYear()}
                </Typography>
            </Box>
            <Box
                component="img"
                sx={{position: "absolute", top: 0, left: 0, width: 80, my: 2, mx: 2}}
                src={`${runtimeConfig.basePath}/logo.svg`}
                alt="C42 Logo"
            />
        </Box>
    );
};

export default Login;
