// Copyright (C) 2022 TANNER AG

import React from "react";
import { AddCircleIcon, MinusCircleIcon } from "../../../components/icons/MANIcons";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import useTranslation from "../../../hooks/usetranslation";
import useActiveProject from "../../../hooks/useactiveproject";
import { UpdateMode, useUpdateProjectProductMutation } from "../../../graphql";

type Props = {
    productId: string;
};

const ProductDetailsAdd: React.FC<Props> = ({ productId }) => {
    const { t } = useTranslation();
    const { id, addedProducts } = useActiveProject();
    const [{ fetching }, updateProduct] = useUpdateProjectProductMutation();
    const added = addedProducts.includes(productId);

    const handleProductUpdate = () =>
        updateProduct({ where: { id }, data: { productId, mode: added ? UpdateMode.Remove : UpdateMode.Update } });

    if (!id) {
        return null;
    }

    return (
        <Stack direction="row" spacing={2} sx={{ mt: 4, alignItems: "center" }}>
            {added ? (
                <Button onClick={handleProductUpdate} startIcon={<MinusCircleIcon />} disabled={fetching}>
                    {t("remove_from_project")}
                </Button>
            ) : (
                <Button
                    onClick={handleProductUpdate}
                    startIcon={<AddCircleIcon color="secondary" />}
                    disabled={fetching}
                >
                    {t("add_to_project")}
                </Button>
            )}
        </Stack>
    );
};

export default ProductDetailsAdd;
