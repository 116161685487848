// Copyright (C) 2022 TANNER AG

import React from "react";
import Container from "@mui/material/Container";
import NavBar from "../../../components/navbar/NavBar";
import Typography from "@mui/material/Typography";
import RichText from "../../../components/content/RichText";
import Grid from "@mui/material/Grid";
import TopicCard from "../../../components/TopicCard";
import HeroBanner from "../../../components/HeroBanner";
import useContentGroup from "../../../hooks/usecontentgroup";
import { ContentTypeGroup } from "../../../graphql";
import ContentRichText from "../../../components/content/ContentRichtText";

const TransportOverview: React.FC = () => {
    const { group } = useContentGroup(ContentTypeGroup.Transportsolutions);

    return (
        <>
            <NavBar links={[{ label: group?.title ?? "" }]} />
            <Container sx={{ mt: 4 }}>
                <HeroBanner src={group?.image}>
                    <Typography variant="h1">{group?.title}</Typography>
                    <RichText html={group?.description} />
                </HeroBanner>
            </Container>
            <Container>
                <ContentRichText content={group?.content} />
            </Container>
            <Container sx={{ mb: 4 }}>
                <Grid container spacing={2}>
                    {group?.topics?.map((topic) => (
                        <Grid key={topic.id} xs={12} md={6} item>
                            <TopicCard
                                id={topic.id}
                                to={topic.key}
                                title={topic.title}
                                no={topic.no}
                                src={topic.image}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </>
    );
};

export default TransportOverview;
