// Copyright (C) 2022 TANNER AG

import React from "react";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import { ChevronDownIcon, ChevronRightIcon } from "../../icons/MANIcons";

type Props = {
    title: string;
    to: string;
    no?: string;
    children?: React.ReactNode;
};

const ContentSectionTocItem: React.FC<Props> = ({ title, to, no, children }) => {
    return (
        <Paper sx={{ border: "1px solid", borderColor: "grey.100" }}>
            <ButtonBase sx={{ textAlign: "left", width: 1, px: 2, py: 1.5 }} component={Link} to={to}>
                {no && (
                    <Typography variant="subtitle2" color="secondary" sx={{ fontSize: 16, minWidth: 30 }}>
                        {no}
                    </Typography>
                )}
                <Typography variant="subtitle2" sx={{ fontSize: 16 }}>
                    {title.toUpperCase()}
                </Typography>
                {Boolean(children) ? (
                    <ChevronDownIcon sx={{ ml: "auto", fontSize: 14 }} />
                ) : (
                    <ChevronRightIcon sx={{ ml: "auto", fontSize: 14 }} />
                )}
            </ButtonBase>
            {children}
        </Paper>
    );
};

export default ContentSectionTocItem;
