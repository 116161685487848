// Copyright (C) 2022-2023 TANNER AG

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import HomeCards from "./home-cards/HomeCards";
import HomeDownloads from "./home-downloads/HomeDownloads";
import HomeNews from "./home-news/HomeNews";

const HomeView = () => {
    return (
        <Container sx={{ my: 5 }}>
            <Grid container spacing={6}>
                <Grid item xs={12} md={8}>
                    <HomeCards />
                </Grid>
                <Grid item xs={12} md={4}>
                    <HomeNews />
                    <HomeDownloads />
                </Grid>
            </Grid>
        </Container>
    );
};

export default HomeView;
