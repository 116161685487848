// Copyright (C) 2022 TANNER AG

import React from "react";
import InputBase from "@mui/material/InputBase";
import { boldFontFamily } from "../../../theme";
import useTranslation from "../../../hooks/usetranslation";

type Props = {
    value: string;
    onChange(value: string): void;
};

const SearchInputBase: React.FC<Props> = ({ value, onChange }) => {
    const { t } = useTranslation();
    return (
        <InputBase
            placeholder={`${t("search")} ...`}
            value={value}
            onChange={(event) => onChange(event.currentTarget.value)}
            autoFocus
            sx={{
                flexGrow: 1,
                fontFamily: boldFontFamily,
                fontSize: 28,
                color: "inherit",
                "& .MuiInputBase-input": {
                    textTransform: "uppercase"
                }
            }}
            inputMode="search"
        />
    );
};

export default SearchInputBase;
