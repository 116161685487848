// Copyright (C) 2022 TANNER AG

import React, { lazy, Suspense, useMemo } from "react";
import Spinner from "../../../components/Spinner";
import { CustomerCalculation } from "../types";
import CustomerAppBar from "../CustomerAppBar";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { ArrowLeftIcon } from "../../../components/icons/MANIcons";
import Typography from "@mui/material/Typography";
const TcoView = lazy(() => import("../../tco/TcoView"));

type Props = {
    calcId: string;
    projectName?: string;
    calculations?: CustomerCalculation[];
    onBack?(): void;
};

const CustomerProjectTcoDetails: React.FC<Props> = ({ calcId, calculations, projectName, onBack }) => {
    const calculation = useMemo(() => {
        return calculations?.find((calc) => String(calc.id) === String(calcId));
    }, [calcId, calculations]);

    if (!calculation) {
        return null;
    }

    return (
        <>
            <CustomerAppBar>
                <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                    <IconButton onClick={onBack} edge="start" color="secondary">
                        <ArrowLeftIcon />
                    </IconButton>
                    <Stack>
                        <Typography
                            variant="subtitle2"
                            color="secondary"
                            textTransform="uppercase"
                            sx={{ lineHeight: 1 }}
                        >
                            {projectName}
                        </Typography>
                        <Typography variant="h3" color="textPrimary" textTransform="uppercase">
                            {calculation.name}
                        </Typography>
                    </Stack>
                </Stack>
            </CustomerAppBar>
            <Suspense fallback={<Spinner />}>
                <TcoView initData={calculation?.data} readonly />
            </Suspense>
        </>
    );
};

export default CustomerProjectTcoDetails;
