// Copyright (C) 2022 TANNER AG

import React from "react";
import ListItemRegular from "../listitem/regular/ListItemRegular";
import { Product } from "../../graphql";
import useActiveProject from "../../hooks/useactiveproject";
import ProductListItemUpdate from "./ProductListItemUpdate";
import useStore from "../../hooks/usestore";
import Availability from "../Availability";

type Props = {
    product: Pick<Product, "id" | "name" | "image" | "intro" | "details" | "availableFrom" | "availableTo">;
};

const ProductListItem: React.FC<Props> = ({ product }) => {
    const { addedProducts } = useActiveProject();
    const added = addedProducts.includes(product.id);
    const setProductId = useStore((state) => state.setProduct);

    return (
        <ListItemRegular
            key={product.id}
            title={product.name}
            image={product.image}
            description={product.intro}
            selected={added}
            onClick={() => setProductId(product.id)}
            action={<ProductListItemUpdate productId={product.id} added={added} />}
            border
            additional={<Availability availableFrom={product.availableFrom} availableTo={product.availableTo} />}
        />
    );
};

export default ProductListItem;
