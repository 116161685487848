// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import { RelatedContent as IRelatedContent } from "../../graphql";
import Dialog from "../dialog/Dialog";
import RelatedContentDetails from "./RelatedContentDetails";
import RelatedContentImage from "./RelatedContentImage";

type Props = {
    related: Pick<IRelatedContent, "id" | "name" | "intro" | "image">;
};

const RelatedContent: React.FC<Props> = ({ related }) => {
    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);

    return (
        <>
            <Card sx={{ height: 1 }}>
                <CardActionArea
                    onClick={() => setOpen(true)}
                    sx={{
                        height: 1,
                        p: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        "&:hover": { color: "secondary.main" }
                    }}
                >
                    <RelatedContentImage src={related.image} />
                    <CardContent sx={{ px: 0, pt: 2, pb: 1 }}>
                        <Typography variant="h4" sx={{ mb: 0.5 }}>
                            {related.name}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="div"
                            dangerouslySetInnerHTML={{ __html: related?.intro ?? "" }}
                        />
                    </CardContent>
                </CardActionArea>
            </Card>
            <Dialog open={open} onClose={handleClose} maxWidth="md" scroll="body">
                <RelatedContentDetails id={related.id} onClose={handleClose} />
            </Dialog>
        </>
    );
};

export default RelatedContent;
