// Copyright (C) 2022 TANNER AG

import React, { useEffect, useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import MuiAppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { ContentSection } from "../../../graphql";
import { TocScroller } from "../../../lib/tocscroller";
import { useHashLink } from "../../../hooks/usehashscroll";

type Props = {
    sections?: Pick<ContentSection, "id" | "title" | "slug">[];
};

export const OFFSET = 160;

const ContentSectionNav: React.FC<Props> = ({ sections }) => {
    const [value, setValue] = useState<string>(sections?.[0]?.id ?? "");
    const navigate = useHashLink(OFFSET - 20);

    useEffect(() => {
        if (!sections?.length) {
            return;
        }
        setValue(sections[0].id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sections?.length, setValue]);

    useEffect(() => {
        if (sections?.length) {
            new TocScroller(window, {
                offset: OFFSET,
                items: sections?.map((section) => ({ activator: section.id, elements: [section.id] })),
                onActivate: (item) => {
                    setValue(item.activator);
                }
            });
        }
    }, [sections]);

    return (
        <MuiAppBar
            position="sticky"
            elevation={0}
            sx={{
                top: 49,
                backgroundColor: "grey.50",
                color: "text.secondary"
            }}
        >
            <Container>
                <Toolbar disableGutters variant="dense">
                    <Tabs value={value} indicatorColor="secondary">
                        {sections?.map(({ id, title }) => (
                            <Tab key={id} label={title} value={id} onClick={() => navigate(id)} />
                        ))}
                    </Tabs>
                </Toolbar>
            </Container>
        </MuiAppBar>
    );
};

export default ContentSectionNav;
