// Copyright (C) 2022 TANNER AG

import React, { useEffect } from "react";
import useCustomerStore from "../hooks/usecustomerstore";
import shallow from "zustand/shallow";
import { Route, Routes, useNavigate } from "react-router-dom";
import CustomerProjectTco from "./CustomerProjectTco";
import CustomerProjectDetails from "./CustomerProjectDetails";
import Spinner from "../../../components/Spinner";
import useCustomerProject from "../../../hooks/usecustomerproject";

type Props = {
    id: string;
};

const CustomerProject: React.FC<Props> = ({ id }) => {
    const [password, setPassword] = useCustomerStore((state) => [state.password, state.setPassword], shallow);
    const { project, fetching, error } = useCustomerProject(id, password);
    const navigate = useNavigate();

    useEffect(() => {
        if (error) {
            setPassword("");
        }
    }, [error, setPassword]);

    if (fetching || !project) {
        return <Spinner />;
    }

    return (
        <Routes>
            <Route
                path="/"
                element={<CustomerProjectDetails project={project} onCalcClick={(id) => navigate(`tco/${id}`)} />}
            />
            <Route
                path="tco/:calcId"
                element={<CustomerProjectTco projectName={project?.name} calculations={project?.calculations} />}
            />
        </Routes>
    );
};

export default CustomerProject;
