// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { DeleteIcon } from "../../../../components/icons/MANIcons";
import Paper from "@mui/material/Paper";
import useActiveProject from "../../../../hooks/useactiveproject";
import { UpdateMode, useUpdateProjectProductMutation } from "../../../../graphql";
import useStore from "../../../../hooks/usestore";
import ListItemRegularImage from "../../../../components/listitem/regular/ListItemRegularImage";

type Props = {
    productId: string;
    name?: string;
    categoryName?: string;
    image?: string;
};

const ProjectSidebarProduct: React.FC<Props> = ({ productId, name, categoryName, image }) => {
    const { id } = useActiveProject();
    const showProduct = useStore((state) => state.setProduct);
    const [{ fetching }, updateProduct] = useUpdateProjectProductMutation();

    const handleProductDelete = () => updateProduct({ where: { id }, data: { productId, mode: UpdateMode.Remove } });

    return (
        <Stack
            key={id}
            component={Paper}
            direction="row"
            sx={{
                alignItems: "center",
                p: 0.5,
                borderRadius: 1,
                position: "relative",
                "&:hover": { borderColor: "secondary.main", "& .heading": { color: "secondary.main" } }
            }}
        >
            <ListItemRegularImage src={image} title={name} sx={{ width: 44, height: 44, mr: 1, borderRadius: 0 }} />
            <Stack>
                <Typography
                    component="a"
                    variant="subtitle2"
                    className="heading"
                    sx={{
                        lineHeight: 1.3,
                        "&::before": {
                            position: "absolute",
                            content: "''",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            cursor: "pointer"
                        }
                    }}
                    onClick={() => showProduct(productId)}
                >
                    {name}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ lineHeight: 1.3 }}>
                    {categoryName}
                </Typography>
            </Stack>
            <Box sx={{ ml: "auto" }}>
                <IconButton onClick={handleProductDelete} size="small" color="primary" disabled={fetching}>
                    <DeleteIcon sx={{ fontSize: 16 }} />
                </IconButton>
            </Box>
        </Stack>
    );
};

export default ProjectSidebarProduct;
