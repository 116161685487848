// Copyright (C) 2022 TANNER AG

import React from "react";
import { Link, To } from "react-router-dom";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/material/styles";

type Props = {
    to?: To;
    href?: string;
    onClick?(): void;
    children?: React.ReactNode;
};

const sx: SxProps = {
    color: "inherit",
    textDecoration: "none",
    transition: "color 0.1s ease-out",
    cursor: "pointer",
    "&:hover": {
        color: "secondary.main"
    }
};

const ListItemRegularAction: React.FC<Props> = ({ to, href, onClick, children }) => {
    if (to) {
        return (
            <Box component={Link} to={to} onClick={onClick} sx={sx}>
                {children}
            </Box>
        );
    }

    if (!href && !onClick) {
        return <>{children}</>;
    }

    return (
        <Box component="a" href={href} onClick={onClick} sx={sx}>
            {children}
        </Box>
    );
};

export default ListItemRegularAction;
