// Copyright (C) 2022 TANNER AG

import React from "react";
import { useParams } from "react-router-dom";
import { ContentTypeGroup } from "../../../graphql";
import NavBar from "../../../components/navbar/NavBar";
import ContentIntro from "../../../components/content/ContentIntro";
import Box from "@mui/material/Box";
import Content from "../../../components/content/Content";
import useAsset from "../../../hooks/useasset";
import Stack from "@mui/material/Stack";
import ContentTopicLink from "../../../components/content/ContentTopicLink";
import Divider from "@mui/material/Divider";
import { formatNo, routes } from "../../../lib/utils";
import useContentTopic from "../../../hooks/usecontenttopic";
import useContentGroup from "../../../hooks/usecontentgroup";
import Spinner from "../../../components/Spinner";

const TransportTopic = () => {
    const { createImageUrl } = useAsset();
    const { topicKey } = useParams();
    const { group } = useContentGroup(ContentTypeGroup.Transportsolutions);
    const { topic, fetching } = useContentTopic(ContentTypeGroup.Transportsolutions, [String(topicKey)]);

    if (fetching) {
        return <Spinner />;
    }

    return (
        <>
            <NavBar links={[{ label: group?.title, to: routes.transportSolutions }, { label: topic?.title }]} />
            <ContentIntro
                title={topic?.title ?? ""}
                no={topic?.no}
                theme={group?.title ?? ""}
                content={topic?.description ?? ""}
                additional={
                    <Stack spacing={1} sx={{ mt: 3 }}>
                        {topic?.topics?.map((subTopic) => (
                            <ContentTopicLink
                                key={subTopic.id}
                                id={subTopic.id}
                                to={subTopic.key}
                                title={subTopic.title}
                                no={formatNo([topic?.no, subTopic?.no])}
                            />
                        ))}
                    </Stack>
                }
            >
                {topic?.image && (
                    <Stack sx={{ alignItems: "flex-end" }}>
                        <Box
                            component="img"
                            src={createImageUrl(topic?.image)}
                            sx={{
                                width: 200,
                                height: 200,
                                objectFit: "cover",
                                borderRadius: 1
                            }}
                        />
                    </Stack>
                )}
            </ContentIntro>
            <Divider />
            <Box sx={{ pt: 4, bgcolor: "background.paper" }}>
                {topic?.sections?.map((section) => (
                    <Content key={section.id} id={section.id} section={section} />
                ))}
            </Box>
        </>
    );
};

export default TransportTopic;
