// Copyright (C) 2022 TANNER AG

import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { ChevronDownIcon, CloseIcon } from "../../icons/MANIcons";
import { NavItem } from "../../../graphql";
import AdditionalLinks from "./AdditionalLinks";
import AppMenuItemSection from "./AppMenuItemSection";
import useAsset from "../../../hooks/useasset";

type Props = {
    navItem: NavItem;
    anchorRef: React.RefObject<HTMLDivElement> | null;
};

const AppMenuItem: React.FC<Props> = ({ navItem, anchorRef }) => {
    const { title, url, children = [] } = navItem;

    if (children.length) return <AppMenuItemExpanded navItem={navItem} anchorRef={anchorRef} />;

    return (
        <LinkItem
            // @ts-ignore
            component={Link}
            to={url ?? "/"}
            variant="subtitle2"
            textTransform="uppercase"
        >
            {title}
        </LinkItem>
    );
};

const AppMenuItemExpanded: React.FC<Props> = ({ navItem, anchorRef }) => {
    const { title, image, children = [], additionalLinks = [] } = navItem;
    const { createImageUrl } = useAsset();
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    const handleClick = () => {
        setAnchorEl(anchorRef ? anchorRef.current : null);
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <LinkItem
                onClick={handleClick}
                variant="subtitle2"
                textTransform="uppercase"
                sx={{ color: anchorEl ? "#E40045" : "initial" }}
            >
                {title} <ChevronDownIcon sx={{ fontSize: 12 }} />
            </LinkItem>
            <Popover
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={onClose}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                transformOrigin={{ vertical: -12, horizontal: 4 }}
                sx={(theme) => ({ zIndex: theme.zIndex.appBar })}
                PaperProps={{
                    sx: {
                        width: 1200,
                        borderRadius: "0px 0px 4px 4px",
                        boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.24)"
                    }
                }}
            >
                <Stack direction="row">
                    <Box
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        marginTop={2}
                        marginBottom={3}
                        marginLeft={3}
                        marginRight={4}
                    >
                        <Box>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="h3">{title}</Typography>
                                <IconButton onClick={onClose}>
                                    <CloseIcon sx={{ color: "#5B7085", fontSize: 14 }} />
                                </IconButton>
                            </Stack>
                            <Stack flexWrap="wrap" sx={{ maxHeight: { xs: "100%", md: "320px" } }}>
                                {children.map((child) => (
                                    <AppMenuItemSection key={child.id} navItem={child} closePopover={onClose} />
                                ))}
                            </Stack>
                        </Box>
                        {!!additionalLinks.length && <AdditionalLinks additionalLinks={additionalLinks} />}
                    </Box>
                    {image && (
                        <Box
                            display={{ xs: "none", md: "block" }}
                            component="img"
                            src={createImageUrl(image)}
                            width="392px"
                            height="432px"
                            alt={title}
                            sx={{ objectFit: "cover" }}
                        />
                    )}
                </Stack>
            </Popover>
        </>
    );
};

export const LinkItem = styled(Typography)(({ theme }) => ({
    cursor: "pointer",
    fontSize: 14,
    textDecoration: "none",
    color: theme.palette.text.primary,
    "&:hover": { color: theme.palette.secondary.main },
    [theme.breakpoints.up("lg")]: {
        fontSize: 16
    }
}));

export default AppMenuItem;
