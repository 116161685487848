// Copyright (C) 2022 TANNER AG

import React from "react";
import BuscastOverview from "./overview/BuscastOverview";

const BuscastView = () => {
    return <BuscastOverview />;
};

export default BuscastView;
