// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import Box from "@mui/material/Box";
import SwipeableViews from "react-swipeable-views";
import MobileStepper from "@mui/material/MobileStepper";
import IconButton from "@mui/material/IconButton";
import { ArrowLeftIcon, ArrowRightIcon } from "../../icons/MANIcons";
import Typography from "@mui/material/Typography";
import { Image } from "../../../graphql";
import useAsset from "../../../hooks/useasset";

type Props = {
    images?: Pick<Image, "file" | "title" | "description">[];
};

const Carousel: React.FC<Props> = ({ images }) => {
    const [activeStep, setActiveStep] = useState(0);
    const { createImageUrl } = useAsset();

    if (!images?.length) {
        return null;
    }

    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    return (
        <Box sx={{ bgcolor: "background.paper" }}>
            <Box sx={{ py: 2 }}>
                {/* @ts-ignore */}
                <SwipeableViews index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
                    {images.map((image, index) => (
                        <div key={index}>
                            <Box
                                component="img"
                                sx={{
                                    height: "auto",
                                    display: "block",
                                    overflow: "hidden",
                                    width: 1,
                                    borderRadius: 1,
                                    boxShadow: "0 0 4px 0 #d6d8db",
                                    objectFit: "scale-down"
                                }}
                                src={createImageUrl(image.file)}
                                alt={image.title}
                            />
                            {image.description && <Typography sx={{ pt: 1 }}>{image.description}</Typography>}
                        </div>
                    ))}
                </SwipeableViews>
                {images.length > 1 && (
                    <MobileStepper
                        position="static"
                        activeStep={activeStep}
                        backButton={
                            <IconButton color="secondary" onClick={handleBack} disabled={activeStep === 0} edge="start">
                                <ArrowLeftIcon />
                            </IconButton>
                        }
                        nextButton={
                            <IconButton
                                color="secondary"
                                onClick={handleNext}
                                disabled={activeStep === maxSteps - 1}
                                edge="end"
                            >
                                <ArrowRightIcon />
                            </IconButton>
                        }
                        steps={maxSteps}
                    />
                )}
            </Box>
        </Box>
    );
};

export default Carousel;
