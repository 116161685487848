// Copyright (C) 2022 TANNER AG

import React from "react";
import { Route, Routes } from "react-router-dom";
import ProjectOverview from "./overview/ProjectOverview";
import ProjectDetails from "./project/ProjectDetails";
import ProductViewer from "../products/product/ProductViewer";

const ProjectsView = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<ProjectOverview />} />
                <Route path="/:id/*" element={<ProjectDetails />} />
            </Routes>
            <ProductViewer />
        </>
    );
};

export default ProjectsView;
