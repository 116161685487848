// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import Dialog from "../dialog/Dialog";
import ProjectForm from "./ProjectForm";
import Button from "@mui/lab/LoadingButton";
import { CheckIcon, EditIcon } from "../icons/MANIcons";
import DialogHead from "../dialog/DialogHead";
import { ProjectDataInput, useProjectQuery, useUpdateProjectMutation } from "../../graphql";
import useLocale from "../../hooks/uselocale";
import useTranslation from "../../hooks/usetranslation";

type Props = {
    projectId: string;
    label: string;
};

const ProjectEdit: React.FC<Props> = ({ projectId, label }) => {
    const [open, setOpen] = useState(false);
    const [{ fetching }, updateProject] = useUpdateProjectMutation();
    const { locale } = useLocale();
    const [{ data }] = useProjectQuery({ variables: { id: projectId, locale }, pause: !projectId });

    const handleClose = () => setOpen(false);

    const handleSubmit = async (data: ProjectDataInput) => {
        const result = await updateProject({ data, where: { id: projectId } });
        if (result.error || !result.data?.updateProject) {
            return;
        }

        setOpen(false);
    };
    const { t } = useTranslation();
    return (
        <>
            <Button onClick={() => setOpen(true)} startIcon={<EditIcon />} variant="outlined" sx={{ mt: 2 }}>
                {label}
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <ProjectForm
                    onSubmit={handleSubmit}
                    head={<DialogHead title={t("project_settings")} onClose={handleClose} />}
                    initialValues={data?.project}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        loadingPosition="start"
                        loading={fetching}
                        startIcon={<CheckIcon />}
                        type="submit"
                    >
                        {t("save_changes")}
                    </Button>
                    <Button onClick={handleClose}>{t("cancel")}</Button>
                </ProjectForm>
            </Dialog>
        </>
    );
};

export default ProjectEdit;
