// Copyright (C) 2022 TANNER AG

import React, { FormEvent, useState } from "react";
import Dialog from "../../components/dialog/Dialog";
import DialogHead from "../../components/dialog/DialogHead";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { CheckCircleIcon } from "../../components/icons/MANIcons";
import FormInput from "../../components/inputs/FormInput";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import useCustomerStore from "./hooks/usecustomerstore";
import useTranslation from "../../hooks/usetranslation";
import CustomerAppBar from "./CustomerAppBar";

const CustomerLogin: React.FC = () => {
    const setPassword = useCustomerStore((state) => state.setPassword);
    const [value, setValue] = useState("");

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        setPassword(value);
    };
    const { t } = useTranslation();
    return (
        <>
            <CustomerAppBar />
            <Dialog open hideBackdrop>
                <Stack component="form" onSubmit={handleSubmit} sx={{ overflow: "hidden" }}>
                    <DialogContent sx={{ pb: 0 }}>
                        <DialogHead title={t("dsg_title")} subTitle={t("password_protected")} />
                        <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
                            {t("password_protected_desc")}
                        </Typography>
                        <FormInput
                            id="password"
                            label={t("password")}
                            InputProps={{
                                type: "password",
                                value,
                                onChange: (event) => setValue(event.currentTarget.value)
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button startIcon={<CheckCircleIcon />} variant="contained" color="secondary" type="submit">
                            {t("submit")}
                        </Button>
                    </DialogActions>
                </Stack>
            </Dialog>
        </>
    );
};

export default CustomerLogin;
