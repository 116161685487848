// Copyright (C) 2022 TANNER AG

import React from "react";
import User from "./user/User";
import Stack from "@mui/material/Stack";
import SearchToolbarItem from "../../views/search/SearchToolbarItem";
import LocaleDropdown from "./LocaleDropdown";

type Props = {
    isUserAuthenticated?: boolean;
}

const AppToolbar: React.FC<Props> = ({isUserAuthenticated = true}) => {
    return (
        <Stack
            spacing={1}
            direction="row"
            sx={{
                ml: "auto",
                mr: { sm: "100px", md: "150px" },
                pr: 2,
                pb: 1,
                alignItems: "center",
                alignSelf: "flex-end",
                display: { xs: "none", sm: "flex" }
            }}
        >
            {isUserAuthenticated &&
                (
                    <>
                    <LocaleDropdown isUserAuthenticated={isUserAuthenticated} />
                    <SearchToolbarItem />
                    <User />
                    </>
            )
        }
        </Stack>
    );
};

export default AppToolbar;
