// Copyright (C) 2023 TANNER AG

import { useNavigate } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";

import { routes } from "../../../lib/utils";
import { UserIcon } from "../../icons/MANIcons";
import useTranslation from "../../../hooks/usetranslation";

const ProfileEdit: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <MenuItem onClick={() => navigate(routes.profile)}>
            <ListItemIcon>
                <UserIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: "body2" }}>{t("my_profile")}</ListItemText>
        </MenuItem>
    );
};

export default ProfileEdit;
