// Copyright (C) 2022 TANNER AG

import React from "react";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { lightFontFamily } from "../../theme";
import clsx from "clsx";
import AppMenuMobile from "./menu/AppMenuMobile";
import AppLogo from "./AppLogo";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import useLocale from "../../hooks/uselocale";
import {languageOptions} from "../../lib/utils";

type Props = {
    toolbar?: React.ReactNode;
    children?: React.ReactNode;
    disableBorder?: boolean;
    disableTitle?: boolean;
};

const BaseAppBar: React.FC<Props> = ({ disableBorder, disableTitle, toolbar, children }) => {
    const { locale } = useLocale();
    const getLabelFromLocale = (locale: string) => {
        const option = languageOptions.find(option => option.value === locale);
        return option ? option.message : '';
    };
    return (
        <MuiAppBar
            position="static"
            elevation={0}
            className={clsx({ disableBorder })}
            sx={{
                backgroundColor: "background.paper",
                color: "text.secondary",
                position: " relative",
                borderBottom: "1px solid",
                borderColor: "grey.50",
                "&.disableBorder": {
                    borderBottom: "none"
                }
            }}
        >
            <Container>
                <Toolbar disableGutters sx={{ minHeight: { sm: 56, md: 76 } }}>
                    {disableTitle !== true ? (
                        <>
                            <Stack sx={{ display: { xs: "none", md: "flex" } }}>
                                <Typography
                                    component={Link}
                                    to="/"
                                    variant="body2"
                                    sx={{ color: "grey.300", fontFamily: lightFontFamily, textDecoration: "none" }}
                                >
                                    {process.env.REACT_APP_TITLE}
                                </Typography>
                                {children}
                            </Stack>
                            <AppMenuMobile>{children}</AppMenuMobile>
                        </>
                    ) : (
                        children
                    )}
                    {toolbar}
                    <AppLogo />
                </Toolbar>
            </Container>
            {Boolean(locale!=="en" && locale!=="de") &&(
            <Box width={'100%'} sx={{backgroundColor:'rgb(255,210,0)', display:'flex', color:'black',justifyContent:"center",alignItems:"center", padding:"8px 32px"}}>
            <Typography>{getLabelFromLocale(locale)}</Typography>
            </Box>
            )}
        </MuiAppBar>
    );
};

export default BaseAppBar;
