// Copyright (C) 2022 TANNER AG

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import React from "react";
import Typography from "@mui/material/Typography";

type Props = {
    title?: string;
    items?: (string | null | undefined)[];
};

const BulletList: React.FC<Props> = ({ title, items }) => {
    if (!items?.length) {
        return null;
    }

    return (
        <>
            {title && <Typography variant="h4">{title}</Typography>}
            <List disablePadding>
                {items
                    ?.filter((item) => Boolean(item))
                    ?.map((item, idx) => (
                        <ListItem
                            key={idx}
                            sx={{ borderLeft: "4px solid", borderColor: "secondary.main", my: 1, p: 1, py: 0 }}
                        >
                            {item}
                        </ListItem>
                    ))}
            </List>
        </>
    );
};

export default BulletList;
