// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import Section from "../../../../components/Section";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { AddCircleIcon } from "../../../../components/icons/MANIcons";
import { Project } from "../../../../graphql";
import Link from "@mui/material/Link";
import ProjectIntroEdit from "./ProjectIntroEdit";
import useTranslation from "../../../../hooks/usetranslation";

type Props = {
    project?: Pick<Project, "id" | "customerIntro">;
};

const ProjectIntro: React.FC<Props> = ({ project }) => {
    const [edit, setEdit] = useState(false);

    const handleOpenEdit = () => setEdit(true);
    const handleCloseEdit = () => setEdit(false);
    const { t } = useTranslation();
    return (
        <Section sx={{ mb: 4 }}>
            <Typography variant="subtitle2" color="textSecondary" sx={{ pb: 1 }}>
                {t("introduction")}
            </Typography>
            {edit ? (
                <ProjectIntroEdit project={project} onClose={handleCloseEdit} />
            ) : (
                <>
                    {project?.customerIntro ? (
                        <Typography>
                            <Typography
                                sx={{ display: "inline" }}
                                dangerouslySetInnerHTML={{
                                    __html: project?.customerIntro?.replaceAll("\n", "<br/>") ?? ""
                                }}
                            />
                            <Link sx={{ pl: 1 }} onClick={handleOpenEdit}>
                                {t("edit")}
                            </Link>
                        </Typography>
                    ) : (
                        <Button onClick={handleOpenEdit} startIcon={<AddCircleIcon color="secondary" />}>
                            {t("personal_info")}
                        </Button>
                    )}
                </>
            )}
        </Section>
    );
};

export default ProjectIntro;
