// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { ArrowLeftIcon, ArrowRightIcon } from "../icons/MANIcons";
import { useNavigate } from "react-router-dom";

const NavBarControls: React.FC = () => {
    const navigate = useNavigate();
    return (
        <Stack direction="row" spacing={0.5}>
            <IconButton edge="start" color="inherit" onClick={() => navigate(-1)}>
                <ArrowLeftIcon sx={{ fontSize: 20 }} />
            </IconButton>
            <IconButton color="inherit" onClick={() => navigate(1)}>
                <ArrowRightIcon sx={{ fontSize: 20 }} />
            </IconButton>
        </Stack>
    );
};

export default NavBarControls;
