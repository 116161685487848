// Copyright (C) 2022 TANNER AG

import React from "react";
import Typography from "@mui/material/Typography";
import { CustomerCalculation } from "../types";
import Stack from "@mui/material/Stack";
import Section from "../../../components/Section";
import useTranslation from "../../../hooks/usetranslation";
import { ArrowRightIcon, ChartOutlineIcon } from "../../../components/icons/MANIcons";
import Button from "@mui/material/Button";
import ListItemBase from "../../../components/listitem/ListItemBase";
import GroupAttr from "../../../components/GroupAttr";

type Props = {
    calculations?: CustomerCalculation[];
    onCalcClick(id: string): void;
};

const CustomerProjectCalculations: React.FC<Props> = ({ calculations, onCalcClick }) => {
    const { t } = useTranslation();

    if (!calculations?.length) {
        return null;
    }

    return (
        <Section sx={{ mb: 4 }}>
            <Typography variant="h3" sx={{ mb: 2 }}>
                {t("tco_title")}
            </Typography>
            <Stack spacing={2}>
                {calculations?.map((calculation) => (
                    <ListItemBase
                        key={calculation.id}
                        id={calculation.id}
                        name={calculation.name}
                        border
                        icon={<ChartOutlineIcon color="secondary" sx={{ fontSize: { xs: 34, sm: 40 } }} />}
                        action={
                            <>
                                <Button
                                    onClick={() => onCalcClick(calculation.id)}
                                    startIcon={<ArrowRightIcon color="secondary" />}
                                >
                                    {t("show_details")}
                                </Button>
                            </>
                        }
                    >
                        <Stack direction="row" spacing={4}>
                            <GroupAttr label="Type" value={t("tco_title")} minWidth="auto" />
                        </Stack>
                    </ListItemBase>
                ))}
            </Stack>
        </Section>
    );
};

export default CustomerProjectCalculations;
