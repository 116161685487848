// Copyright (C) 2022 TANNER AG

import { Route, Routes } from "react-router-dom";

import NewsListView from "./NewsListView";
import NewsView from "./NewsView";

const NewsRouter: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<NewsListView />} />
            <Route path="/:slug" element={<NewsView />} />
        </Routes>
    );
};

export default NewsRouter;
