// Copyright (C) 2023 TANNER AG

import { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { allFields } from "./fields";
import { FormFieldsObj } from "../register/RegisterView";
import { isValidPassword } from "../../lib/validations";
import { User, useUpdateProfileMutation, useUserQuery } from "../../graphql";
import ProfileFirstStep from "./ProfileFirstStep";
import ProfileSecondStep from "./ProfileSecondStep";
import Spinner from "../../components/Spinner";
import useTranslation from "../../hooks/usetranslation";
import {useLocation} from "react-router-dom";
import Typography from "@mui/material/Typography";

const ProfileEditView: React.FC = () => {
    const { t } = useTranslation();
    const [formFields, setFormFields] = useState<FormFieldsObj>({});
    const [{ data }] = useUserQuery();
    const [{ fetching, error }, updateProfile] = useUpdateProfileMutation();
    const user = data?.user;
    const location = useLocation();
    const {pathname} = location;
    const editAllFields = allFields.map((field) => (field.name === "password" || field.name === "confirmPassword")? {...field, required: false}: field);
    const updateFormField = (name: string, value: any) => {
        setFormFields((prevForm) => ({ ...prevForm, [name]: { value, error: undefined } }));
    };

    const setFormFieldError = (name: string, error: string) => {
        setFormFields((prevForm) => ({ ...prevForm, [name]: { ...prevForm[name], error } }));
    };

    useEffect(() => {
        if (user) {
            editAllFields.forEach((field) => {
                updateFormField(field.name, user[field.name as keyof User] ?? field.defaultValue);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const handleSave = () => {
        const allValues: { [key: string]: string } = {};
        let isError = false;
        editAllFields.forEach((field) => {
            allValues[field.name] = formFields[field.name]?.value ?? field.defaultValue;
            if (field.required && !allValues[field.name]) {
                isError = true;
                setFormFieldError(field.name, t("required"));
            }
        });

        if (!isValidPassword(allValues.password, allValues.confirmPassword) && pathname ==="/register") {
            isError = true;
            setFormFieldError("password", t("invalid_password"));
            setFormFieldError("confirmPassword", t("invalid_password"));
        }
        if (allValues.comment.length > 500) {
            isError = true;
            setFormFieldError("comment", t("invalid_comment"));
        }

        if (isError) {
            return window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }

        updateProfile({
            data: {
                comment: formFields.comment.value,
                city: formFields.city.value,
                company: formFields.company.value,
                country: formFields.country.value,
                firstname: formFields.firstname.value,
                infoletter: formFields.infoletter.value,
                language: formFields.language.value,
                lastname: formFields.lastname.value,
                newsflash: formFields.newsflash.value,
                password: formFields.password.value,
                position: formFields.position.value,
                salutation: formFields.salutation.value,
                street: formFields.street.value,
                zipcode: formFields.zipcode.value
            }
        });
    };

    if (fetching) return <Spinner />;

    return (
        <Container sx={{ marginY: 4 }}>
            <Grid container spacing={2} padding={2} sx={{ backgroundColor: "white" }}>
                <ProfileFirstStep isUpdate={true} formFields={formFields} updateFormField={updateFormField} />
                <ProfileSecondStep isUpdate={true} formFields={formFields} updateFormField={updateFormField} />
                
                <Grid item xs={12}>
                    <Typography variant="subtitle2" marginTop={2}>
                        {t("information_on_copyright")}
                    </Typography>
                    <Typography fontSize={14}>{t("information_on_copyright_content")}</Typography>
                    <Typography padding={2} fontSize={14} marginTop={2}>
                        {t("password_hint")}
                        <br/>
                        {t("news_flash_description")}
                    </Typography>
                    <Typography variant="subtitle2" marginTop={2}>
                        {t("note")}
                    </Typography>
                    <Typography fontSize={14}>{t("note_content_1")}</Typography>
                    <Typography fontSize={14} marginTop={1}>
                        {t("note_content_2")}
                    </Typography>
                    <Typography fontSize={14} marginTop={2}>
                        © {new Date().getFullYear()} MAN Truck & Bus SE, Munich
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" fullWidth onClick={handleSave}>
                        {t("save")}
                    </Button>
                </Grid>
                {!!error && (
                    <Grid item xs={12}>
                        <Alert severity="error">{t("error")}</Alert>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default ProfileEditView;
