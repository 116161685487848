// Copyright (C) 2022 TANNER AG

import React, { useMemo } from "react";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useTranslation from "../../../hooks/usetranslation";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../../../lib/utils";

const SubAppBar = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const value = useMemo(() => {
        return (
            [routes.dsg.products, routes.dsg.projects, routes.dsg.tools].find(
                (route) => pathname === route || pathname.startsWith(`${route}/`)
            ) ?? routes.dsg.products
        );
    }, [pathname]);

    return (
        <MuiAppBar
            position="sticky"
            elevation={0}
            sx={{ backgroundColor: "background.paper", color: "text.secondary" }}
        >
            <Container>
                <Toolbar disableGutters variant="dense">
                    <Tabs value={value} indicatorColor="secondary" variant={"scrollable"}>
                        {[
                            { label: t("products_services"), to: routes.dsg.products },
                            { label: t("my_projects"), to: routes.dsg.projects },
                            { label: t("my_tools"), to: routes.dsg.tools }
                        ].map(({ label, to }, index) => (
                            <Tab
                                key={index}
                                label={label}
                                value={to}
                                component={Link}
                                to={to}
                                sx={{ marginRight: 1.2 }}
                            />
                        ))}
                    </Tabs>
                </Toolbar>
            </Container>
        </MuiAppBar>
    );
};

export default SubAppBar;
