// Copyright (C) 2022 TANNER AG

import React from "react";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useProjectQuery } from "../../../graphql";
import ProjectProductList from "./productlist/ProjectProductList";
import useLocale from "../../../hooks/uselocale";
import ProjectToolbar from "./ProjectToolbar";
import ProjectActions from "./ProjectActions";
import ProjectEmpty from "./ProjectEmpty";
import Stack from "@mui/material/Stack";
import ProjectHeader from "./ProjectHeader";
import ProjectDownloadList from "./downloadlist/ProjectDownloadList";
import ProjectIntro from "./intro/ProjectIntro";
import ProjectNote from "./note/ProjectNote";
import ProjectCalculation from "./calculation/ProjectCalculation";
import Spinner from "../../../components/Spinner";

const ProjectDetails = () => {
    const { id } = useParams();
    const { locale } = useLocale();
    const [{ data, fetching }] = useProjectQuery({ variables: { id: id ?? "", locale }, pause: !id });
    const project = data?.project;

    if (fetching) {
        return <Spinner />;
    }

    return (
        <Container sx={{ py: 4 }}>
            <Typography variant="h2">{project?.name}</Typography>
            <Stack sx={{ borderBottom: "1px solid", borderColor: "grey.100", mb: 4 }}>
                <ProjectToolbar project={project} />
                <ProjectHeader project={project} />
            </Stack>
            <ProjectIntro project={project} />
            <ProjectNote project={project} />
            <ProjectProductList projectId={data?.project?.id} products={project?.products ?? []} />
            <ProjectEmpty project={project} show={Boolean(!fetching && !project?.products?.length)} />
            <ProjectDownloadList projectId={data?.project?.id} downloads={data?.project?.downloads ?? []} />
            <ProjectCalculation projectId={data?.project?.id} calculations={data?.project?.calculations} />
            <ProjectActions project={project} />
        </Container>
    );
};

export default ProjectDetails;
