// Copyright (C) 2022 TANNER AG

import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { boldFontFamily } from "../../theme";
import DownloadsView from "../downloads/DownloadsView";
import NavBar from "../../components/navbar/NavBar";
import PageFeatures from "./features/PageFeatures";
import PageTabs from "./PageTabs";
import PageToolbarPdf from "./pdf/PageToolbarPdf";
import Spinner from "../../components/Spinner";
import SubPage from "./subpage/SubPage";
import usePage from "../../hooks/usePage";

const PageView = () => {
    const navigate = useNavigate();
    const { tabId } = useParams();
    const { page, fetching } = usePage();
    const [isPDFMode, setPDFMode] = useState(false);

    useEffect(() => {
        if (!tabId && !fetching && page) {
            const firstTabId = page.children[0].id;
            navigate(`./${firstTabId}`);
        }
    }, [tabId, fetching, page, navigate]);

    const activeTab = (page?.children ?? []).find((child) => child.id === tabId);

    const tabs = (page?.children || []).map((child) => ({
        id: child.id,
        title: "name" in child ? child.name : child.title
    }));

    const showVehicleToolbarPdf = activeTab?.__typename === "Vehicle";

    const renderTab = () => {
        switch (activeTab?.__typename) {
            case "CmsSubPage":
                return <SubPage contentBlocks={activeTab.contentBlocks} />;
            case "Vehicle":
                return <PageFeatures topics={activeTab.topics} isPDFMode={isPDFMode} />;
            case "DownloadPreset":
                return <DownloadsView isTab={true} activeFilters={activeTab.filter} />;
        }
    };

    return (
        <>
            <NavBar links={[{ label: page?.title }]}>
                {showVehicleToolbarPdf && (
                    <PageToolbarPdf
                        enabled={isPDFMode}
                        enable={() => setPDFMode(true)}
                        disable={() => setPDFMode(false)}
                    />
                )}
            </NavBar>

            <Box sx={{ backgroundColor: "#303C49" }}>
                <Container sx={{ paddingTop: 4, paddingBottom: 6 }}>
                    <Typography fontFamily={boldFontFamily} fontSize={60} color="white" textTransform="uppercase">
                        {page?.title}
                    </Typography>
                    <Typography color="#CBD3DC" width={{ sx: "100%", md: "70%" }}>
                        {page?.description}
                    </Typography>
                </Container>
            </Box>
            <PageTabs tabs={tabs} activeTabId={tabId} />
            <Container disableGutters>{fetching ? <Spinner /> : renderTab()}</Container>
        </>
    );
};

export default PageView;
