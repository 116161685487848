// Copyright (C) 2022 TANNER AG

import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import CustomerProjectProducts from "./CustomerProjectProducts";
import CustomerProjectDownloads from "./CustomerProjectDownloads";
import CustomerProjectContact from "./CustomerProjectContact";
import { CustomerProject } from "../types";
import Divider from "@mui/material/Divider";
import CustomerProjectCalculations from "./CustomerProjectCalculations";
import CustomerAppBar from "../CustomerAppBar";

type Props = {
    project: CustomerProject;
    onCalcClick(id: string): void;
};

const CustomerProjectDetails: React.FC<Props> = ({ project, onCalcClick }) => {
    return (
        <>
            <CustomerAppBar />
            <Container sx={{ mb: 8 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <Typography variant="h1" sx={{ mt: 6, mb: 3 }}>
                            {project?.name}
                        </Typography>
                        {project?.intro && (
                            <>
                                <Typography
                                    dangerouslySetInnerHTML={{
                                        __html: project?.intro?.replaceAll("\n", "<br/>") ?? ""
                                    }}
                                />
                                <Divider sx={{ mb: 4, mt: 3 }} />
                            </>
                        )}
                        <CustomerProjectProducts products={project?.products} />
                        <CustomerProjectCalculations calculations={project?.calculations} onCalcClick={onCalcClick} />
                        <CustomerProjectDownloads downloads={project?.downloads} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <CustomerProjectContact contact={project?.contact} subject={project?.name} />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default CustomerProjectDetails;
