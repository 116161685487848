// Copyright (C) 2022 TANNER AG

import {
    VehicleDocument,
    VehicleQuery,
    VehiclesDocument,
    VehiclesQuery,
    VehicleTopic,
    VehicleTopicDocument
} from "../../graphql";
import useLocale from "../uselocale";
import useOfflineQuery from "./useofflinequery";

const useOfflineVehicles = () => {
    const { locale } = useLocale();
    const query = useOfflineQuery();

    const queryVehicleTopics = (topics: Pick<VehicleTopic, "key" | "type">[] = []) =>
        topics.map((topic) => query<VehicleTopic>(VehicleTopicDocument, { key: topic.key, type: topic.type, locale }));

    const queryVehicle = async (id: string) => {
        const { data } = await query<VehicleQuery>(VehicleDocument, { id, locale });

        await Promise.all(queryVehicleTopics(data?.vehicle?.topics?.topics));
        await Promise.all(queryVehicleTopics(data?.vehicle?.topics?.components));
        await Promise.all(queryVehicleTopics(data?.vehicle?.topics?.benefits));
    };

    return async () => {
        const { data } = await query<VehiclesQuery>(VehiclesDocument, { locale });
        await Promise.all(data?.vehicles?.map((vehicle) => queryVehicle(vehicle.id)) ?? []);
    };
};

export default useOfflineVehicles;
