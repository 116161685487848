// Copyright (C) 2023 TANNER AG

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import LocaleSwitch from "../../components/apptoolbar/LocaleSwitch";
import useTranslation from "../../hooks/usetranslation";

type Props = {
    title: string;
    subtitle?: string;
    buttonLabel?: string;
    children?: JSX.Element;
    onSubmit: React.FormEventHandler<HTMLFormElement>;
    successMessage: string;
    errorMessage: string;
};

const FormLayout: React.FC<Props> = ({
    title,
    subtitle,
    buttonLabel,
    children,
    onSubmit,
    successMessage,
    errorMessage
}) => {
    const { t } = useTranslation();

    const isSubmitButtonDisabled = !!errorMessage;

    return (
        <Box
            sx={{
                display: "flex",
                flexGrow: 1,
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Box position="absolute" top={16} right={16}>
                <LocaleSwitch />
            </Box>
            <Box component={Paper} sx={{ width: "100%", maxWidth: 500, p: 4, boxShadow: "0 0 4px 0 #d6d8db" }}>
                <form onSubmit={onSubmit}>
                    <Box sx={{ mb: 3 }}>
                        <Typography variant="h3" gutterBottom>
                            {title}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                            {subtitle}
                        </Typography>
                    </Box>
                    {children}
                    {!!successMessage && (
                        <Box mt={1}>
                            <Alert severity="info">{successMessage}</Alert>
                        </Box>
                    )}
                    {!!errorMessage && (
                        <Box mt={1}>
                            <Alert severity="error">{errorMessage}</Alert>
                        </Box>
                    )}
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        sx={{ mt: 2, minWidth: 140 }}
                        disabled={isSubmitButtonDisabled}
                        fullWidth
                    >
                        {buttonLabel ?? t("submit")}
                    </Button>
                </form>
            </Box>
        </Box>
    );
};

export default FormLayout;
