// Copyright (C) 2022 TANNER AG

import React from "react";
import { Link, useMatch, useParams } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import HeroIcon from "../../../../components/icons/HeroIcons";
import { routes } from "../../../../lib/utils";
import { boldFontFamily } from "../../../../theme";

type Props = {
    name?: string;
    icon?: string;
    slug: string;
    disabled?: boolean;
};

const ProductSidebarItem: React.FC<Props> = ({ name, slug, icon = "", disabled }) => {
    const { categoryId } = useParams();
    const match = useMatch(`${routes.dsg.products}/*`);

    return (
        <ListItemButton
            selected={slug === categoryId}
            component={Link}
            to={`${match?.pathnameBase}/${slug}`}
            disabled={disabled}
            sx={{
                borderRadius: 1,
                px: 1,
                py: 0,
                mb: 0.25,
                transition: "all .3s",
                "&.Mui-selected": {
                    backgroundColor: "grey.900",
                    color: "common.white",
                    "& .MuiSvgIcon-root": { color: "common.white" }
                },
                "&:hover": { backgroundColor: "background.paper", color: "secondary.main" }
            }}
        >
            <ListItemIcon sx={{ minWidth: 40 }}>
                <HeroIcon iconName={icon} color="inherit" sx={{ fontSize: 32, color: "grey.800" }} />
            </ListItemIcon>
            <ListItemText
                primary={name}
                primaryTypographyProps={{ variant: "subtitle1", fontSize: 16, fontFamily: boldFontFamily }}
            />
        </ListItemButton>
    );
};

export default ProductSidebarItem;
