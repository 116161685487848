// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useUserQuery } from "../graphql";
import { UserIcon } from "./icons/MANIcons";
import useAsset from "../hooks/useasset";

// TODO temporary use signed user because currently the user get only own projectsä

const UserAvatar: React.FC = () => {
    const { createImageUrl } = useAsset();
    const [{ data }] = useUserQuery();

    return (
        <Stack direction="row" sx={{ alignItems: "center" }} spacing={0.5}>
            <Avatar sx={{ width: 24, height: 24 }} src={createImageUrl(data?.user?.avatar)}>
                <UserIcon />
            </Avatar>
            <Typography variant="body2" color="textSecondary">
                {data?.user?.firstname} {data?.user?.lastname}
            </Typography>
        </Stack>
    );
};

export default UserAvatar;
