// Copyright (C) 2022 TANNER AG

import DialogContent from "@mui/material/DialogContent";
import React, { useState } from "react";
import Typography from "@mui/material/Typography";

import Dialog from "./dialog/Dialog";
import DialogHead from "./dialog/DialogHead";
import useTranslation from "../hooks/usetranslation";

const Disclaimer: React.FC = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    return (
        <>
            <Typography sx={{ cursor: "pointer" }} onClick={() => setOpen(true)} variant="body2">
                {t("disclaimer")}
            </Typography>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent>
                    <DialogHead title={t("disclaimer")} onClose={() => setOpen(false)} />
                    <Typography>{t("disclaimer_content")}</Typography>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Disclaimer;
