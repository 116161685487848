// Copyright (C) 2022 TANNER AG

import React from "react";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/system";

type Props = {
    sx?: SxProps;
    children?: React.ReactNode;
};

const Section: React.FC<Props> = ({ sx, children }) => {
    return (
        <Box component="section" sx={{ mb: 3, ...sx }}>
            {children}
        </Box>
    );
};

export default Section;
