// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

type Props = {
    tags: string[];
    activeTags: string[];
    toggleTag(tag: string): void;
};

const TagList: React.FC<Props> = ({ tags, activeTags, toggleTag }) => {
    return (
        <Stack direction="row" sx={{ mb: 2, flexWrap: "wrap", "& > .MuiChip-root": { mr: 1, mb: 1 } }}>
            {tags.map((tag) => (
                <Chip
                    key={tag}
                    label={tag}
                    color={activeTags.includes(tag) ? "secondary" : "primary"}
                    sx={{ textTransform: "none" }}
                    onClick={() => toggleTag(tag)}
                />
            ))}
        </Stack>
    );
};

export default TagList;
