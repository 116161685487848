// Copyright (C) 2022 TANNER AG

import React from "react";
import Grid from "@mui/material/Grid";

import RichText from "./RichText";

type Props = {
    content?: string;
};

const ContentRichText: React.FC<Props> = ({ content }) => {
    if (!content) {
        return null;
    }

    return (
        <Grid container sx={{ mb: 2 }}>
            <Grid item xs={12} md={12}>
                <RichText html={content} />
            </Grid>
        </Grid>
    );
};

export default ContentRichText;
