// Copyright (C) 2022 TANNER AG

import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import CategoryListItem from "../../../components/categorylistitem/CategoryListItem";
import { Project } from "../../../graphql";
import useStore from "../../../hooks/usestore";
import useProductCategories from "../../../hooks/useproductcategories";
import useTranslation from "../../../hooks/usetranslation";

type Props = {
    project?: Pick<Project, "id" | "name" | "status" | "created" | "modified">;
    show?: boolean;
};

const ProjectEmpty: React.FC<Props> = ({ project, show }) => {
    const { silos } = useProductCategories();
    const setProduct = useStore((state) => state.setProject);
    const { t } = useTranslation();
    const handleCategoryClick = () => {
        if (!project) {
            return;
        }

        setProduct({ id: project.id, name: project.name });
    };

    if (!show) {
        return null;
    }

    return (
        <Paper sx={{ bgcolor: "grey.100", p: 2, mb: 4 }}>
            <Typography color="textSecondary" sx={{ mb: 2 }}>
                {t("product_has_no_cat")}
            </Typography>
            <Grid container spacing={3}>
                {silos.map(({ name, categories }, index) => (
                    <Grid key={index} xs={12} md={4} item>
                        <Typography variant="h5" textTransform="uppercase" color="textSecondary" sx={{ mb: 2 }}>
                            {name}
                        </Typography>
                        <List disablePadding>
                            {categories.map(({ id, name, slug, icon }) => (
                                <CategoryListItem
                                    key={id}
                                    name={name}
                                    slug={slug || id}
                                    icon={icon}
                                    onClick={handleCategoryClick}
                                />
                            ))}
                        </List>
                    </Grid>
                ))}
            </Grid>
        </Paper>
    );
};

export default ProjectEmpty;
