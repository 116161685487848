// Copyright (C) 2022 TANNER AG

import React, {useState} from "react";
import Typography from "@mui/material/Typography";

import useTranslation from "../hooks/usetranslation";
import DataProtectionDialog from "../views/register/DataProtectionDialog";


const DataProtectionRefactor: React.FC = () => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    return (
        <>
            <Typography sx={{cursor: "pointer"}} onClick={() => setOpen(true)} variant="body2">
                {t("data_protection_title")}
            </Typography>
            <DataProtectionDialog isOpen={open} onClose={() => setOpen(false)}/>
        </>
    );
};

export default DataProtectionRefactor;
