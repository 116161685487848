import { useEffect, useState } from "react";
import { useUserQuery } from "../graphql";
import { User } from "../graphql";

export const useChatService = () => {
    const [{ data }] = useUserQuery();
    const BASE_URL = "https://llama2-man.westeurope.cloudapp.azure.com";
    const [messageHistoryContent, setMessageHistory] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    if (data) {
        const { id, groups, country, groupColor } = data.user as User;
        
        const getMessageHistory = async () => {
            try {
                const bodyOption = {
                    userid: id,
                    context: 'mybus',
                }
                const response = await fetch(`${BASE_URL}/chat/history`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(bodyOption)
                });
                if (response.ok) {
                    const messageHistory = await response.json();
                    const result = messageHistory || [];
                    const isComplete = messageHistory.every((item: any) => item.status === 'completed');
                    
                    if (isComplete) {
                        setIsLoading(false)
                    } else {
                        setIsLoading(true)
                    }
                    setMessageHistory(result);
                }
            } catch (e) {
                console.log(e);
            }
        }
        const sendChatmessage = async (message: string) => {
            const bodyOption = {
                userid: id,
                context: 'mybus',
                message: message,
                groupColor: groupColor,
                country: country,
                groups: groups
            }
            try{
                fetch(`${BASE_URL}/chat/messages`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(bodyOption)
                });
            }
            catch(e){
                console.log(e);
            }
        }

        const deleteChatHistory = async () => {
            const bodyOption = {
                userid: id,
                context: 'mybus',
            }
            try {
                setIsLoading(true);
                fetch(`${BASE_URL}/chat/history`, {
                    method: "DELETE",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(bodyOption)
                })
            }
            catch (e) {
                console.log(e);
            } finally {
                setIsLoading(false);
            }

        }

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            getMessageHistory();
            const interval =setInterval(()=>{
                getMessageHistory();
            }, 3000);
            return ()=>{
                clearInterval(interval);
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isLoading===true])
        return {
            sendChatmessage,
            messageHistoryContent,
            deleteChatHistory,
            isLoading,
        }
    }
}