// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import { Provider } from "urql";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { CloudDownloadIcon, DevicesIcon } from "../icons/MANIcons";
import ListItemText from "@mui/material/ListItemText";
import useTranslation from "../../hooks/usetranslation";
import OfflineHandler from "./OfflineHandler";
import OfflineDialog from "./OfflineDialog";
import { offlineClient } from "../../lib/urql";

type Props = {
    onClose(): void;
};

const Offline: React.FC<Props> = ({ onClose }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleInstallClick = () => {
        window.deferredPrompt?.prompt();
        onClose();
    };

    return (
        <>
            {Boolean(window.deferredPrompt) && (
                <MenuItem onClick={handleInstallClick}>
                    <ListItemIcon>
                        <DevicesIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ variant: "body2" }}>{t("install_app")}</ListItemText>
                </MenuItem>
            )}
            <MenuItem onClick={() => setOpen(true)}>
                <ListItemIcon>
                    <CloudDownloadIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: "body2" }}>{t("make_offline")}</ListItemText>
            </MenuItem>
            <Provider value={offlineClient}>
                <OfflineDialog open={open} onClose={() => setOpen(false)}>
                    <OfflineHandler onClose={() => setOpen(false)} />
                </OfflineDialog>
            </Provider>
        </>
    );
};

export default Offline;
