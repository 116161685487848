// Copyright (C) 2022 TANNER AG

import { ProductCategory, useProductCategoriesQuery } from "../graphql";
import { useMemo } from "react";
import useLocale from "./uselocale";

type SiloCategory = Pick<ProductCategory, "id" | "name" | "slug" | "icon" | "hasGroups">;

const useProductCategories = () => {
    const { locale } = useLocale();
    const [{ data, fetching, error }] = useProductCategoriesQuery({ variables: { locale } });
    const categories = data?.productCategories ?? [];
    const silos = useMemo(() => {
        const silos: { name: string; categories: SiloCategory[] }[] = [];
        data?.productCategories?.forEach((category) => {
            const index = silos.findIndex((silo) => silo.name === (category.silo ?? ""));
            if (index === -1) {
                silos.push({ name: category.silo ?? "", categories: [category] });
            } else {
                silos[index].categories.push(category);
            }
        });

        return silos;
    }, [data?.productCategories]);

    return { silos, categories, fetching, error };
};

export default useProductCategories;
