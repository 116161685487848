// Copyright (C) 2022 TANNER AG

import React from "react";
import IconButton from "@mui/material/IconButton";
import MenuPopover from "../MenuPopover";
import ListItemText from "@mui/material/ListItemText";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { WorldIcon } from "../icons/MANIcons";
import useAnchorHandler from "../../hooks/useanchorhandler";
import useLocale from "../../hooks/uselocale";
import useTranslation from "../../hooks/usetranslation";

const LocaleSwitch = () => {
    const { t } = useTranslation();
    const { anchorEl, onClick, onClose } = useAnchorHandler();
    const { locales, locale: activeLocale, setLocale, languageNames } = useLocale();

    const handleLocaleClick = (locale: string) => () => {
        setLocale(locale);
        onClose();
    };

    return (
        <>
            <IconButton onClick={onClick} size="small" color="primary" title={t("language")}>
                <WorldIcon />
            </IconButton>
            <MenuPopover anchorEl={anchorEl} onClose={onClose} maxWidth={160}>
                <MenuList component="div">
                    {locales.map((locale) => (
                        <MenuItem key={locale} selected={activeLocale === locale}>
                            <ListItemText
                                primaryTypographyProps={{ variant: "body2" }}
                                onClick={handleLocaleClick(locale)}
                            >
                                {languageNames.of(locale) ?? locale}
                            </ListItemText>
                        </MenuItem>
                    ))}
                </MenuList>
            </MenuPopover>
        </>
    );
};

export default LocaleSwitch;
