// Copyright (C) 2022 TANNER AG

import React from "react";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import { Project, useProjectsQuery } from "../../graphql";
import useLocale from "../../hooks/uselocale";
import useStore from "../../hooks/usestore";
import shallow from "zustand/shallow";
import { CheckIcon } from "../icons/MANIcons";

type Props = {
    open?: boolean;
    onSelect?(): void;
};

const ProjectSelectList: React.FC<Props> = ({ open, onSelect }) => {
    const [activeProject, setProject] = useStore((state) => [state.activeProject, state.setProject], shallow);
    const { locale } = useLocale();
    const [{ data }] = useProjectsQuery({ variables: { locale }, pause: !open });
    const projects = data?.projects?.projects ?? [];

    const handleSelect = (project: Pick<Project, "id" | "name">, selected?: boolean) => () => {
        setProject(selected ? undefined : project);
        onSelect?.();
    };

    return (
        <MenuList>
            {projects?.map((project) => {
                const selected = activeProject?.id === project.id;
                return (
                    <MenuItem key={project.id} selected={selected} onClick={handleSelect(project, selected)}>
                        <ListItemText primaryTypographyProps={{ variant: "body2" }}>{project.name}</ListItemText>
                        {selected && <CheckIcon fontSize="small" />}
                    </MenuItem>
                );
            })}
        </MenuList>
    );
};

export default ProjectSelectList;
