// Copyright (C) 2022 TANNER AG

import React from "react";
import DialogContent from "@mui/material/DialogContent";
import ProductDetails from "./ProductDetails";
import ProductAdditional from "./ProductAdditional";
import useStore from "../../../hooks/usestore";
import shallow from "zustand/shallow";
import Dialog from "../../../components/dialog/Dialog";

type Props = {
    enableProjectUpdate?: boolean;
};

const ProductViewer: React.FC<Props> = ({ enableProjectUpdate }) => {
    const [productId, setProductId] = useStore((state) => [state.openProduct, state.setProduct], shallow);

    const handleClose = () => setProductId();

    return (
        <Dialog open={Boolean(productId)} onClose={handleClose} maxWidth="md" scroll="body">
            <DialogContent sx={{ p: 0 }}>
                <ProductDetails productId={productId} onClose={handleClose} enableProjectUpdate={enableProjectUpdate} />
                <ProductAdditional productId={productId} onClose={handleClose} />
            </DialogContent>
        </Dialog>
    );
};

export default ProductViewer;
