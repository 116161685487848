// Copyright (C) 2022 TANNER AG

import React from "react";
import { styled } from "@mui/material/styles";
import MuiSwitch, { SwitchProps } from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

type Props = SwitchProps & {
    id: string;
    label?: string;
};

const FormSwitch: React.FC<Props> = ({ id, label, ...switchProps }) => {
    return <FormControlLabel id={id} name={id} label={label} control={<Switch {...switchProps} />} sx={{ m: 0 }} />;
};

export const Switch = styled((props: SwitchProps) => (
    <MuiSwitch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 44,
    height: 24,
    padding: 0,
    "& + .MuiFormControlLabel-label": {
        marginLeft: theme.spacing(1)
    },
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(20px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: "#4b96d2",
                opacity: 1,
                border: 0
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5
            }
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#4b96d2",
            border: "6px solid #fff"
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600]
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3
        }
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 20,
        height: 20
    },
    "& .MuiSwitch-track": {
        borderRadius: 24 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500
        })
    },
    "&.MuiSwitch-sizeSmall": {
        width: 28,
        height: 16,
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(12px)"
        },
        "& .MuiSwitch-thumb": {
            width: 12,
            height: 12
        },
        "& .MuiSwitch-track": {
            borderRadius: 16 / 2
        }
    }
}));

export default FormSwitch;
