// Copyright (C) 2023 TANNER AG

import {Typography} from "@mui/material";
import BaseAppBar from "../../components/appbar/BaseAppBar";
import AppToolbar from "../../components/apptoolbar/AppToolbar";
import Container from "@mui/material/Container";
import {useEffect, useState} from "react";
import HTTPClient from "../../lib/HTTPClient";
import Spinner from "../../components/Spinner";
import useTranslation from "../../hooks/usetranslation";
import {useSearchParams} from "react-router-dom";
import Alert from "@mui/material/Alert";
import {accessToken} from "../../lib/utils";

const SsoLoginView: React.FC = () => {
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const token = searchParams.get("token");
    useEffect(() => {
        setLoading(true);
        setTimeout(
            () =>
                HTTPClient.POST("/user/samlsso", {token})
                    .then((response) => {
                        return response.text();
                    }).then((token: string) => {
                    if (!token) throw new Error("Login failed!");
                    accessToken.set(token);
                    const redirectUrl = localStorage.getItem('redirectUrl')
                        ? localStorage.getItem('redirectUrl')
                        : '/';
                    window.location.href = '' + redirectUrl;
                })
                    .catch((error) => {
                        setError(true);
                    })
                    .finally(() => {
                        setLoading(false);
                    }),
            10000
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) return <Spinner/>;
    return (
        <>
            <BaseAppBar toolbar={<AppToolbar isUserAuthenticated={false}/>}/>
            {isError ? (
                <Alert severity="error" sx={{width: {sx: "100%", md: "50%"}, mx: "auto", mt: 4}}>
                    {t("error")}
                </Alert>
            ) : (
                <Container>
                    <Typography variant="h2" textAlign="center" marginTop={16}>
                        {t("verification_of_auth_sso")}
                    </Typography>
                </Container>
            )}
        </>
    );
};

export default SsoLoginView;
