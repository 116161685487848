// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import { Project } from "../../../../graphql";
import { ShareIcon } from "../../../../components/icons/MANIcons";
import Button from "@mui/material/Button";
import Dialog from "../../../../components/dialog/Dialog";
import ProjectCustomerSiteForm from "./ProjectCustomerSiteForm";
import useBreakpoint from "../../../../hooks/usebreakpoint";
import IconButton from "@mui/material/IconButton";
import useTranslation from "../../../../hooks/usetranslation";

type Props = {
    project?: Pick<Project, "id" | "name" | "uuid" | "customerPassword">;
};

const ProjectCustomerSite: React.FC<Props> = ({ project }) => {
    const { down } = useBreakpoint("md");
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            {down ? (
                <IconButton onClick={handleOpen}>
                    <ShareIcon color="secondary" />
                </IconButton>
            ) : (
                <Button onClick={handleOpen} startIcon={<ShareIcon />} variant="contained" color="secondary">
                    {t("sharing_options")}
                </Button>
            )}
            <Dialog open={open} onClose={handleClose}>
                <ProjectCustomerSiteForm onClose={handleClose} project={project} />
            </Dialog>
        </>
    );
};

export default ProjectCustomerSite;
