// Copyright (C) 2022 TANNER AG

import React from "react";
import Box from "@mui/material/Box";
import useAsset from "../../../hooks/useasset";
import { ImageIcon } from "../../icons/MANIcons";
import { SxProps } from "@mui/system";
import clsx from "clsx";

type Props = {
    title?: string;
    src?: string;
    sx?: SxProps;
    inactive?: boolean;
};

const ListItemRegularImage: React.FC<Props> = ({ title, src, sx, inactive }) => {
    const { createImageUrl } = useAsset();

    if (!src) {
        return (
            <Box sx={{ borderRadius: 2, overflow: "hidden" }}>
                <ImageIcon
                    sx={{
                        width: { xs: 64, sm: 120 },
                        height: { xs: 64, sm: 120 },
                        color: "grey.50",
                        ...sx
                    }}
                />
            </Box>
        );
    }

    return (
        <Box
            component="img"
            src={createImageUrl(src)}
            alt={title}
            className={clsx({ inactive })}
            sx={{
                width: { xs: 64, sm: 120 },
                height: { xs: 64, sm: 120 },
                objectFit: "cover",
                borderRadius: 1,
                "&.inactive": { opacity: 0.5 },
                ...sx
            }}
        />
    );
};

export default ListItemRegularImage;
