// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import Dialog from "../dialog/Dialog";
import ProjectForm from "./ProjectForm";
import Button from "@mui/lab/LoadingButton";
import { AddCircleIcon, CheckIcon } from "../icons/MANIcons";
import DialogHead from "../dialog/DialogHead";
import { ProjectDataInput, useCreateProjectMutation } from "../../graphql";
import { useNavigate } from "react-router-dom";
import { routes } from "../../lib/utils";
import useTranslation from "../../hooks/usetranslation";
import GqlError from "../error/GqlError";

type Props = {
    label: string;
};

const ProjectCreation: React.FC<Props> = ({ label }) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [{ fetching, error }, createProject] = useCreateProjectMutation();

    const handleClose = () => setOpen(false);

    const handleSubmit = async (data: ProjectDataInput) => {
        const result = await createProject({ data });
        if (result.error || !result.data?.createProject) {
            return;
        }

        navigate(`${routes.dsg.projects}/${result.data.createProject.id}`);
    };
    const { t } = useTranslation();
    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                variant="contained"
                color="secondary"
                startIcon={<AddCircleIcon />}
                sx={{ ml: "auto" }}
            >
                {label}
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <ProjectForm
                    onSubmit={handleSubmit}
                    head={<DialogHead title={t("create_a_project")} onClose={handleClose} />}
                    error={<GqlError error={error} />}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        loadingPosition="start"
                        loading={fetching}
                        startIcon={<CheckIcon />}
                        type="submit"
                    >
                        {t("create_project")}
                    </Button>
                    <Button onClick={handleClose}>{t("cancel")}</Button>
                </ProjectForm>
            </Dialog>
        </>
    );
};

export default ProjectCreation;
