// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useTranslation from "../hooks/usetranslation";
import { PackageCarryIcon, WarningCircleIcon } from "./icons/MANIcons";
import useDatetime from "../hooks/usedatetime";

type Props = {
    availableFrom?: string;
    availableTo?: string;
};

const Availability: React.FC<Props> = ({ availableFrom, availableTo }) => {
    const { t } = useTranslation();
    const { fDate } = useDatetime();

    if (!availableFrom && !availableTo) {
        return null;
    }

    return (
        <Stack direction="row" spacing={1}>
            {availableFrom && (
                <>
                    <PackageCarryIcon fontSize="small" sx={{ color: "#4b96d2" }} />
                    <Typography variant="body2" color="textSecondary">
                        {t("available_from", { date: fDate(availableFrom, "MMMM yyyy") })}
                    </Typography>
                </>
            )}
            {availableTo && (
                <>
                    <WarningCircleIcon fontSize="small" sx={{ color: "#ff6d00" }} />
                    <Typography variant="body2" color="textSecondary">
                        {t("available_to", { date: fDate(availableTo, "MMMM yyyy") })}
                    </Typography>
                </>
            )}
        </Stack>
    );
};

export default Availability;
