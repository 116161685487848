// Copyright (C) 2022 TANNER AG

import React from "react";
import useLocale from "../../../hooks/uselocale";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { boldFontFamily } from "../../../theme";
import ListItemText from "@mui/material/ListItemText";
import { ArrowRightIcon } from "../../icons/MANIcons";

type Props = {
    focusCountries?: string[];
    selectedCountry?: string;
    onClick?(country: string): void;
};

const FocusCountries: React.FC<Props> = ({ focusCountries = [], selectedCountry, onClick }) => {
    const { regionNames } = useLocale();

    if (!focusCountries?.length) {
        return null;
    }

    return (
        <List dense sx={{ mb: 2 }}>
            {focusCountries.map((country) => (
                <ListItemButton
                    key={country}
                    sx={{ mb: 1, border: "1px solid", borderColor: "grey.100", borderRadius: 1 }}
                    selected={country === selectedCountry}
                    onClick={() => onClick?.(country)}
                >
                    <ListItemText
                        primary={regionNames.of(country) ?? country}
                        primaryTypographyProps={{ variant: "subtitle1", fontSize: 16, fontFamily: boldFontFamily }}
                    />
                    <ArrowRightIcon color="secondary" />
                </ListItemButton>
            ))}
        </List>
    );
};

export default FocusCountries;
