// Copyright (C) 2022 TANNER AG

import { Fragment } from "react";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { NavItem } from "../../../graphql";
import { boldFontFamily } from "../../../theme";
import useTranslation from "../../../hooks/usetranslation";

type Props = {
    additionalLinks: NavItem[];
};

const AdditionalLinks: React.FC<Props> = ({ additionalLinks }) => {
    const { t } = useTranslation();

    return (
        <Stack direction="row" alignItems="center" marginTop={2} paddingY={1} sx={{ backgroundColor: "#F8F8F8" }}>
            <Typography fontFamily={boldFontFamily} marginLeft={1.5} marginRight={1}>
                {t("find_interesting")}:
            </Typography>
            {additionalLinks.map((link, index) => (
                <Fragment key={link.id}>
                    <Link to={link.url ?? "/"} style={{ textDecoration: "none", color: "#E40045" }}>
                        {link.title}
                    </Link>
                    {index !== additionalLinks.length - 1 && (
                        <Typography display="inline" color="#CBD3DC" sx={{ mx: 1 }}>
                            •
                        </Typography>
                    )}
                </Fragment>
            ))}
        </Stack>
    );
};

export default AdditionalLinks;
