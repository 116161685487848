// Copyright (C) 2022 TANNER AG

import React from "react";
import CardMedia from "@mui/material/CardMedia";
import useAsset from "../../hooks/useasset";
import { ImageIcon } from "../icons/MANIcons";
import Box from "@mui/material/Box";

type Props = {
    src?: string;
};

const RelatedContentImage: React.FC<Props> = ({ src }) => {
    const { createImageUrl } = useAsset();

    if (!src) {
        return (
            <Box sx={{ borderRadius: 2, overflow: "hidden", display: "flex" }}>
                <ImageIcon
                    sx={{
                        width: { xs: 64, sm: 150 },
                        height: { xs: 64, sm: 150 },
                        color: "grey.50"
                    }}
                />
            </Box>
        );
    }

    return <CardMedia component="img" src={createImageUrl(src)} sx={{ height: 150 }} />;
};

export default RelatedContentImage;
