// Copyright (C) 2022 TANNER AG

import React, { useState, FormEvent } from "react";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { CloseIcon } from "../../../components/icons/MANIcons";
import SearchInputBase from "./SearchInputBase";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../lib/utils";

type Props = {
    onClose(): void;
};

const SearchInputFloating: React.FC<Props> = ({ onClose }) => {
    const [value, setValue] = useState("");
    const navigate = useNavigate();

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        navigate(`${routes.search}?q=${value}`);
        onClose();
    };

    return (
        <Container>
            <Stack
                direction="row"
                sx={{ alignItems: "center", bgcolor: "secondary.main", color: "common.white", py: 2 }}
                component="form"
                onSubmit={handleSubmit}
            >
                <SearchInputBase value={value} onChange={setValue} />
                <IconButton onClick={onClose} size="large" edge="end" color="inherit">
                    <CloseIcon />
                </IconButton>
            </Stack>
        </Container>
    );
};

export default SearchInputFloating;
