// Copyright (C) 2022 TANNER AG

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { boldFontFamily } from "../../../../theme";
import List from "@mui/material/List";
import ProductSidebarItem from "./ProductSidebarItem";
import useProductCategories from "../../../../hooks/useproductcategories";
import Stack from "@mui/material/Stack";

const ProductSidebar: React.FC = () => {
    const { silos } = useProductCategories();
    return (
        <Stack>
            {silos.map(({ name, categories }, index) => (
                <Box key={index} sx={{ mb: 3 }}>
                    <Typography
                        sx={{
                            fontFamily: boldFontFamily,
                            fontSize: 16,
                            color: "grey.300",
                            px: 1,
                            textTransform: "uppercase"
                        }}
                    >
                        {name}
                    </Typography>
                    <List dense>
                        {categories.map(({ id, name, slug, icon, hasGroups }) => (
                            <ProductSidebarItem
                                key={id}
                                name={name}
                                slug={slug || id}
                                icon={icon}
                                disabled={!hasGroups}
                            />
                        ))}
                    </List>
                </Box>
            ))}
        </Stack>
    );
};

export default ProductSidebar;
