// Copyright (C) 2022 TANNER AG

import React from "react";
import {Link as RouterLink, useMatch} from "react-router-dom";
import Link from "@mui/material/Link";
import {routes} from "../../lib/utils";

type Props = {
    href?: string;
    children?: React.ReactNode;
};

const ContentLink: React.FC<Props> = ({href = "/", children, ...others}) => {
    const vehiclePath = useMatch(`${routes.vehicles}/:vehicleId/*`);

    if (href?.startsWith("http") || href?.startsWith("mailto")) {
        return (
            <Link {...others} href={href}>
                {children}
            </Link>
        );
    }

    return (
        <Link {...others} component={RouterLink} to={vehiclePath ? `${vehiclePath.pathnameBase}${href}` : href}>
            {children}
        </Link>
    );
};

export default ContentLink;
