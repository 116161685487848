// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";

type Props = {
    message: string;
};

const ErrorAlertMore: React.FC<Props> = ({ message = "" }) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <Typography variant="caption" onClick={() => setOpen(!open)}>
                Show {open ? "less" : "more"}
            </Typography>
            <Collapse in={open}>{message}</Collapse>
        </>
    );
};

export default ErrorAlertMore;
