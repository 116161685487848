// Copyright (C) 2022 TANNER AG

import create from "zustand";
import { persist } from "zustand/middleware";
import { CustomerProject } from "../types";

type CustomerStore = {
    password: string;
    project?: CustomerProject;
    setPassword(password: string): void;
};

const useCustomerStore = create<CustomerStore>()(
    persist(
        (set) => ({
            password: "",
            setPassword: (password) => set(() => ({ password }))
        }),
        {
            name: "dsg-customer-project",
            partialize: (state) => ({ password: state.password }),
            getStorage: () => sessionStorage
        }
    )
);

export default useCustomerStore;
