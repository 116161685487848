// Copyright (C) 2022 TANNER AG

import React, { FormEvent, useState } from "react";
import Stack from "@mui/material/Stack";
import DialogContent from "@mui/material/DialogContent";
import DialogHead from "../../../../components/dialog/DialogHead";
import FormInput from "../../../../components/inputs/FormInput";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/lab/LoadingButton";
import { CheckCircleIcon } from "../../../../components/icons/MANIcons";
import { useCreateProjectCalcMutation } from "../../../../graphql";

type Props = {
    projectId: string;
    onClose(): void;
};

const ProjectAddCalcForm: React.FC<Props> = ({ projectId, onClose }) => {
    const [{ fetching }, createCalc] = useCreateProjectCalcMutation();
    const [name, setName] = useState("");

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        if (!name) {
            return;
        }

        const result = await createCalc({ where: { id: projectId }, data: { name } });
        if (!result?.data?.createProjectCalculation) {
            return;
        }

        onClose();
    };

    return (
        <Stack component="form" onSubmit={handleSubmit} sx={{ overflow: "hidden" }}>
            <DialogContent>
                <DialogHead title="Create Calculation" onClose={onClose} />
                <FormInput
                    id="name"
                    label="Name"
                    required
                    helperText="Name of the TCO calculation"
                    InputProps={{ value: name, onChange: (event) => setName(event.currentTarget.value) }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    startIcon={<CheckCircleIcon />}
                    loading={fetching}
                    variant="contained"
                    color="secondary"
                >
                    Create calculation
                </Button>
                <Button disabled={fetching} onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Stack>
    );
};

export default ProjectAddCalcForm;
