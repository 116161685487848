// Copyright (C) 2022 TANNER AG

import { useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import { Filter } from "../../../graphql";
import ActiveFilter from "./ActiveFilter";
import MultiSelectFilter from "./MultiSelectFilter";
import useTranslation from "../../../hooks/usetranslation";

const getActiveFiltersInitialValue = (filters: Filter[]) =>
    filters.reduce((acc, cur) => ({ ...acc, [cur.key]: cur.values.map((filterValue) => filterValue.value) }), {});

export type FilterObj = {
    [key: string]: string[];
};

type Props = {
    activeFilters: Filter[];
    filters: Filter[];
    submitFilters: (filters: FilterObj) => void;
};

const Filters: React.FC<Props> = ({ activeFilters: selectedFilters, filters, submitFilters }) => {
    const { t } = useTranslation();
    const [activeFilters, setActiveFilters] = useState<FilterObj>(getActiveFiltersInitialValue(selectedFilters));

    const handleSetActiveFilters = (filterKey: string) => (filters: string[]) => {
        setActiveFilters((prevState) => ({ ...prevState, [filterKey]: filters }));
    };

    const handleClearFilter = (filterKey: string, filterName: string) => {
        const newState = {
            ...activeFilters,
            [filterKey]: activeFilters[filterKey].filter((filter) => filter !== filterName)
        };
        setActiveFilters(newState);
        submitFilters(newState);
    };

    const handleClearAllFilters = () => {
        setActiveFilters({});
        submitFilters({});
    };

    return (
        <>
            <Stack direction="row" alignItems="center" spacing={0.5}>
                {filters.map(({ key, values }) => (
                    <MultiSelectFilter
                        key={key}
                        id={key}
                        name={t(`filter_${key}`)}
                        options={values}
                        activeFilters={activeFilters[key] || []}
                        setActiveFilters={handleSetActiveFilters(key)}
                        submitFilters={() => submitFilters(activeFilters)}
                    />
                ))}
            </Stack>
            {!!Object.values(activeFilters).flat().length && (
                <Stack direction="row" alignItems="center" flexWrap="wrap" marginTop={3}>
                    {Object.keys(activeFilters).map((filterKey) => {
                        const filter = filters.find((filter) => filter.key === filterKey);
                        return activeFilters[filterKey].map((filterValue, index) => {
                            const name =
                                (filter ? filter.values : []).find((filter) => filter.value === filterValue)?.name ??
                                filterValue;

                            return (
                                <ActiveFilter
                                    key={index}
                                    name={name}
                                    clearFilter={() => handleClearFilter(filterKey, filterValue)}
                                />
                            );
                        });
                    })}
                    <Button color="secondary" onClick={handleClearAllFilters} sx={{ mt: 0.5 }}>
                        {t("clear_all")}
                    </Button>
                </Stack>
            )}
        </>
    );
};

export default Filters;
