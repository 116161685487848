// Copyright (C) 2022 TANNER AG

import React from "react";
import useCustomerStore from "./hooks/usecustomerstore";
import CustomerLogin from "./CustomerLogin";

type Props = {
    children?: React.ReactNode;
};

const CustomerLobby: React.FC<Props> = ({ children }) => {
    const password = useCustomerStore((state) => state.password);

    if (!password) {
        return <CustomerLogin />;
    }

    return <>{children}</>;
};

export default CustomerLobby;
