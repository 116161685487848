// Copyright (C) 2022 TANNER AG

import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

type Props = {
    name: string;
    description?: string;
    phone?: string;
    mail?: string;
    action?: React.ReactNode;
};

const ListItemUser: React.FC<Props> = ({ name, description, phone, mail, action }) => {
    return (
        <Stack direction="row" sx={{ alignItems: "center", borderBottom: "1px solid", borderColor: "grey.100", py: 2 }}>
            <Stack>
                <Typography variant="h4" sx={{ fontSize: 18, mb: 0.5 }}>
                    {name}
                </Typography>
                {description && (
                    <Typography variant="body2" color="textSecondary" textTransform="uppercase">
                        {description}
                    </Typography>
                )}
                {mail && (
                    <Typography variant="body2" color="textSecondary">
                        {mail}
                    </Typography>
                )}
                {phone && (
                    <Typography variant="body2" color="textSecondary">
                        Tel. {phone}
                    </Typography>
                )}
            </Stack>
            <Box sx={{ ml: "auto" }}>{action}</Box>
        </Stack>
    );
};

export default ListItemUser;
