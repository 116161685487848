// Copyright (C) 2022 TANNER AG

import DialogContent from "@mui/material/DialogContent";
import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import {createStyles, makeStyles} from "@mui/styles";

import Dialog from "./dialog/Dialog";
import DialogHead from "./dialog/DialogHead";
import useTranslation from "../hooks/usetranslation";
import {Theme} from "@mui/material/styles";
import {Link} from "@mui/material";
import Divider from "@mui/material/Divider";

const useStyles = makeStyles((theme:Theme) =>
    createStyles({
        subTitle: {
            fontSize:21,
        },
        divider:{
            marginTop:theme.spacing(2),
            marginBottom:theme.spacing(2),
        }
    })
);

const Imprint: React.FC = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    return (
        <>
            <Typography sx={{ cursor: "pointer" }} onClick={() => setOpen(true)} variant="body2">
                {t("imprint_title")}
            </Typography>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent>
                    <DialogHead title={t("imprint_title")} onClose={() => setOpen(false)} />
                    <Typography className={classes.subTitle} variant={"h3"} gutterBottom>{t("imprint_publisher_title")}</Typography>
                    <Typography>{"MAN Truck & Bus SE"}</Typography>
                    <Typography>{"Dachauer Straße 667"}</Typography>
                    <Typography gutterBottom>{t("imprint_address")}</Typography>
                    <Typography gutterBottom>{"+49 (0) 89 1580 0"}</Typography>
                    <Typography gutterBottom>{"Fax +49 (0) 89 15039 1212"}</Typography>
                    <Typography gutterBottom><Link>{"imprint@man.eu"}</Link></Typography>
                    <Divider className={classes.divider}/>
                    <Typography className={classes.subTitle} variant={"h3"} gutterBottom>{t("imprint_subheader")}</Typography>
                    <Typography>{`Alexander Vlaskamp ${t("imprint_content_role")}`}</Typography>
                    <Typography>Murat Aksel</Typography>
                    <Typography>Friedrich Baumann</Typography>
                    <Typography>Michael Kobriger</Typography>
                    <Typography>Inka Koljonen</Typography>
                    <Typography>Arne Puls</Typography>
                    <Typography>Dr. Frederik Zohm</Typography>
                    <Divider className={classes.divider}/>
                    <Typography className={classes.subTitle} variant={"h3"} gutterBottom>{t("imprint_registry_title")}</Typography>
                    <Typography>{t("imprint_registry_first_content")}</Typography>
                    <Typography>{t("imprint_registry_second_content")}</Typography>
                    <Divider className={classes.divider}/>
                    <Typography className={classes.subTitle} variant={"h3"} gutterBottom>{t("imprint_note_title")}</Typography>
                    <Typography gutterBottom>{t("imprint_note_first_content")}</Typography>
                    <Typography gutterBottom>{t("imprint_note_second_content")}</Typography>
                    <Divider className={classes.divider}/>
                    <Typography gutterBottom>{t("imprint_note_third_content")}</Typography>
                    <Typography gutterBottom>{t("imprint_note_fourth_content")}</Typography>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Imprint;
