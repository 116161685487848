// Copyright (C) 2023 TANNER AG

import {Fragment, useMemo} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import {addressFields, Field, personalFields, userFields, userFieldsEditView} from "./fields";
import { FormFieldsObj } from "../register/RegisterView";
import countries from "../register/countries.json";
import FormInput from "../../components/inputs/FormInput";
import FormSelect from "../../components/inputs/FormSelect";
import useTranslation from "../../hooks/usetranslation";
import {useLocation} from "react-router-dom";

type Props = {
    isUpdate?: boolean;
    formFields: FormFieldsObj;
    updateFormField: (name: string, value: any) => void;
};

const RegisterFirstStep: React.FC<Props> = ({ isUpdate = false, formFields, updateFormField }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const {pathname} = location;
    


    const countryOptions = [{ value: "", label: t("please_select_country") }, ...countries];

    const renderField = ({ id, labelTranslationKey, required, takeFullRow, InputProps }: Field) => (
        <FormInput
            id={id}
            label={t(labelTranslationKey)}
            error={!!formFields[id]?.error}
            helperText={formFields[id]?.error}
            required={required}
            InputProps={{
                value: formFields[id]?.value ?? "",
                onChange: (event) => updateFormField(id, event.target.value),
                disabled: isUpdate && id === "email",
                ...InputProps
            }}
        />
    );
    const renderUserFields = useMemo(() => {
        return pathname === "/register" ? userFields : userFieldsEditView
    }, [pathname])

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h4">{t("user_data")}</Typography>
            </Grid>
            {renderUserFields.map((field) => (
                <Fragment key={field.id}>
                    <Grid item xs={12} md={6}>
                        {renderField(field)}
                    </Grid>
                    {field.takeFullRow && <Grid item xs={0} md={6} />}
                </Fragment>
            ))}

            <Grid item xs={12} marginTop={2}>
                <Typography variant="h4">{t("personal_data")}</Typography>
            </Grid>
            {personalFields.map((field) => (
                <Fragment key={field.id}>
                    <Grid item xs={12} md={6}>
                        {renderField(field)}
                    </Grid>
                    {field.takeFullRow && <Grid item xs={0} md={6} />}
                </Fragment>
            ))}

            <Grid item xs={12}>
                <Typography variant="h4" marginTop={2}>
                    {t("address_data")}
                </Typography>
            </Grid>
            {addressFields.map((field) => (
                <Fragment key={field.id}>
                    <Grid item xs={12} md={6}>
                        {renderField(field)}
                    </Grid>
                    {field.takeFullRow && <Grid item xs={0} md={6} />}
                </Fragment>
            ))}
            <Grid item xs={12} md={6}>
                <FormSelect
                    id="country"
                    label={t("country")}
                    options={countryOptions}
                    error={!!formFields.country?.error}
                    helperText={formFields.country?.error}
                    required
                    SelectProps={{
                        value: formFields.country?.value ?? "",
                        onChange: (event) => updateFormField("country", event.target.value)
                    }}
                />
            </Grid>
        </>
    );
};

export default RegisterFirstStep;
