// Copyright (C) 2022 TANNER AG

import { useSearchParams } from "react-router-dom";
import { useMemo } from "react";

const useParam = (
    name: string,
    defaultValue = ""
): [string, (value?: string, override?: boolean, replace?: boolean) => void] => {
    const [searchParams, setSearchParams] = useSearchParams();
    const value = useMemo(() => {
        return searchParams.get(name) ?? defaultValue;
    }, [name, defaultValue, searchParams]);

    const setValue = (value = "", override = false, replace?: boolean) => {
        const newSearchParams = new URLSearchParams(override ? undefined : searchParams);
        if (value) {
            newSearchParams.set(name, value);
        } else {
            newSearchParams.delete(name);
        }
        setSearchParams(newSearchParams, { replace });
    };

    return [value, setValue];
};

export default useParam;
