// Copyright (C) 2022 TANNER AG

import React from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { CloseIcon, ArrowRightIcon, DownloadIcon } from "../../../components/icons/MANIcons";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import useTranslation from "../../../hooks/usetranslation";
import ListItemSmall from "../../../components/listitem/ListItemSmall";
import { useProductQuery } from "../../../graphql";
import BulletList from "../../../components/BulletList";
import Section from "../../../components/Section";
import DialogContent from "@mui/material/DialogContent";
import useLocale from "../../../hooks/uselocale";
import ProductDetailsAdd from "./ProductDetailsAdd";
import RichText from "../../../components/content/RichText";
import List from "@mui/material/List";
import ListItemLink from "../../../components/listitem/ListItemLink";
import ListItemDownload from "../../../components/listitem/ListItemDownload";
import useAsset from "../../../hooks/useasset";
import ChangeRequest from "../../../components/ChangeRequest";
import Availability from "../../../components/Availability";

type Props = {
    productId: string;
    enableProjectUpdate?: boolean;
    onClose(): void;
};

const ProductDetails: React.FC<Props> = ({ productId, onClose, enableProjectUpdate }) => {
    const { createDownloadUrl } = useAsset();
    const { t } = useTranslation();
    const { locale } = useLocale();
    const [{ data }] = useProductQuery({ variables: { key: productId, locale }, pause: !productId });

    return (
        <DialogContent>
            <Stack direction="row" sx={{ alignItems: "flex-start" }}>
                <Stack>
                    <Typography variant="h5" color="secondary" sx={{ textTransform: "uppercase" }}>
                        {data?.product?.group?.name}
                    </Typography>
                    <Typography gutterBottom variant="h2" sx={{ mt: 1 }}>
                        {data?.product?.name}
                    </Typography>
                </Stack>
                <Stack direction="row" sx={{ ml: "auto", position: "absolute", right: "2em" }}>
                    <ChangeRequest sx={{ mr: 0.5 }} subject={data?.product?.name} />
                    <IconButton onClick={onClose}>
                        <CloseIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                </Stack>
            </Stack>
            {Boolean(data?.product?.availableFrom || data?.product?.availableTo) && (
                <Stack sx={{ mb: 2 }}>
                    <Availability
                        availableFrom={data?.product?.availableFrom}
                        availableTo={data?.product?.availableTo}
                    />
                </Stack>
            )}
            <Section>
                <RichText html={data?.product?.descriptionInternal} />
            </Section>
            {data?.product?.details && (
                <Section>
                    <BulletList title={t("details")} items={data?.product?.details} />
                </Section>
            )}
            {Boolean(data?.product?.benefits?.length) && (
                <Section>
                    <BulletList title={t("customer_benefits")} items={data?.product?.benefits} />
                </Section>
            )}
            {Boolean(data?.product?.downloads?.length) && (
                <Section>
                    <Typography variant="h4" textTransform="uppercase" gutterBottom>
                        {t("downloads")}
                    </Typography>
                    <Stack>
                        {data?.product?.downloads.map((download) => (
                            <ListItemDownload
                                key={download.id}
                                id={download.id}
                                name={download.name}
                                mime={download.mime}
                                size={download.size}
                                border
                                action={
                                    <Button
                                        startIcon={<DownloadIcon color="secondary" />}
                                        component="a"
                                        href={createDownloadUrl(download.id)}
                                        download
                                    >
                                        {t("download_file")}
                                    </Button>
                                }
                            />
                        ))}
                    </Stack>
                </Section>
            )}
            {Boolean(data?.product?.information?.length) && (
                <Section>
                    <Typography variant="h4" textTransform="uppercase">
                        {t("further_information")}
                    </Typography>
                    <List disablePadding>
                        {data?.product?.information.map((info, idx) => (
                            <ListItemLink key={idx} href={info.href}>
                                {info.title}
                            </ListItemLink>
                        ))}
                    </List>
                </Section>
            )}
            <Section sx={{ display: "none" }}>
                <Typography variant="h4">{t("only_in_comb")}</Typography>
                <Stack>
                    {[1, 2].map((_, index) => (
                        <ListItemSmall
                            key={index}
                            action={
                                <Button startIcon={<ArrowRightIcon color="secondary" />}>{t("show_details")}</Button>
                            }
                        />
                    ))}
                </Stack>
            </Section>
            {enableProjectUpdate && <ProductDetailsAdd productId={productId} />}
        </DialogContent>
    );
};

export default ProductDetails;
