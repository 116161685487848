// Copyright (C) 2022 TANNER AG

import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { LinkExternIcon } from "../icons/MANIcons";

type Props = {
    href?: string;
    children?: React.ReactNode;
};

const ListItemLink: React.FC<Props> = ({ href, children }) => {
    return (
        <ListItem disablePadding>
            <ListItemButton disableGutters component="a" target="_blank" href={href}>
                <ListItemIcon sx={{ minWidth: 30 }}>
                    <LinkExternIcon sx={{ color: "grey.800", fontSize: 16 }} />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ color: "secondary" }}>{children}</ListItemText>
            </ListItemButton>
        </ListItem>
    );
};

export default ListItemLink;
