// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DataIcon } from "../../components/icons/MANIcons";
import useTranslation from "../../hooks/usetranslation";

type Props = {
    tags?: string[];
    availableTo?: string;
};

const ToolsItemsTags: React.FC<Props> = ({ tags }) => {
    const { t } = useTranslation();

    if (!tags?.length) {
        return null;
    }

    return (
        <Stack direction="row" spacing={1}>
            <DataIcon fontSize="small" sx={{ color: "secondary.main" }} titleAccess={t("tags")} />
            <Typography variant="body2" color="textSecondary">
                {tags.join(", ")}
            </Typography>
        </Stack>
    );
};

export default ToolsItemsTags;
