// Copyright (C) 2023 TANNER AG

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useFeaturedNavQuery } from "../../../graphql";
import HomeCardItem from "./HomeCardItem";
import Spinner from "../../../components/Spinner";
import useLocale from "../../../hooks/uselocale";
import useTranslation from "../../../hooks/usetranslation";

const HomeCards: React.FC = () => {
    const { t } = useTranslation();
    const { locale: storeLocale } = useLocale();

    let locale = storeLocale;

    if (storeLocale === "de") {
        locale = "de_DE";
    }

    const [{ data, fetching }] = useFeaturedNavQuery({ variables: { locale } });

    const navItems = data?.featuredNav ?? [];

    return (
        <>
            <Typography variant="h4" color="black" textTransform="uppercase">
                {t("featured")}
            </Typography>
            {fetching ? (
                <Spinner />
            ) : (
                <Grid container spacing={3} paddingTop={3}>
                    {navItems.map((navItem) => (
                        <Grid key={navItem.id} item xs={12} md={6}>
                            <HomeCardItem title={navItem.title} url={navItem.url} src={navItem.image} />
                        </Grid>
                    ))}
                </Grid>
            )}
        </>
    );
};

export default HomeCards;
