// Copyright (C) 2023 TANNER AG

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import AppToolbar from "../../components/apptoolbar/AppToolbar";
import BaseAppBar from "../../components/appbar/BaseAppBar";
import HTTPClient from "../../lib/HTTPClient";
import Spinner from "../../components/Spinner";
import useTranslation from "../../hooks/usetranslation";

const ThanksForConfirmView: React.FC = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const token = searchParams.get("token");
    const username = searchParams.get("username");

    useEffect(() => {
        setLoading(true);
        setTimeout(
            () =>
                HTTPClient.POST("/user/confirm", { token, username })
                    .catch(() => {
                        setError(true);
                    })
                    .finally(() => {
                        setLoading(false);
                    }),
            5 * 1000
        );
    }, [token, username]);

    if (isLoading) return <Spinner />;

    return (
        <>
            <BaseAppBar toolbar={<AppToolbar isUserAuthenticated={false} />} />
            {isError ? (
                <Alert severity="error" sx={{ width: { sx: "100%", md: "50%" }, mx: "auto", mt: 4 }}>
                    {t("error")}
                </Alert>
            ) : (
                <Container>
                    <Typography variant="h2" textAlign="center" marginTop={16}>
                        {t("thank_you_register")}
                    </Typography>
                    <Typography variant="h3" textAlign="center" marginTop={2}>
                        {t("verification_of_data")}
                    </Typography>
                    <Typography variant="h3" textAlign="center" whiteSpace="pre-line" marginTop={2}>
                        {t("best_regards")}
                    </Typography>
                </Container>
            )}
        </>
    );
};

export default ThanksForConfirmView;
