// Copyright (C) 2022 TANNER AG

import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import useAsset from "../hooks/useasset";
import CardActionArea from "@mui/material/CardActionArea";
import { Link } from "react-router-dom";
import { zeroPad } from "../lib/utils";

type Props = {
    id: string;
    to: string;
    title: string;
    no?: number;
    src?: string;
};

const TopicCard: React.FC<Props> = ({ id, to, no, title, src }) => {
    const { createImageUrl } = useAsset();
    return (
        <CardRoot data-id={id} style={{ backgroundImage: `url(${createImageUrl(src)})` }}>
            <CardActionArea
                component={Link}
                to={to}
                sx={{
                    height: 1,
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "flex-start"
                }}
            >
                {no && (
                    <Typography
                        variant="h2"
                        color="secondary"
                        sx={{
                            lineHeight: 1,
                            "@media (min-width:900px)": {
                                fontSize: 72
                            }
                        }}
                    >
                        {zeroPad(no)}
                    </Typography>
                )}
                <Typography
                    variant="h2"
                    sx={{
                        lineHeight: "32px",
                        "@media (min-width:900px)": {
                            fontSize: 34
                        }
                    }}
                >
                    {title}
                </Typography>
            </CardActionArea>
        </CardRoot>
    );
};

export const CardRoot = styled(Paper)(({ theme }) => ({
    height: 230,
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 0 4px 0 #d6d8db",
    borderRadius: 0,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    color: theme.palette.common.white,
    "&:hover": {
        boxShadow: "none"
    }
}));

export default TopicCard;
