// Copyright (C) 2021-2022 TANNER AG

import { createClient, cacheExchange, dedupExchange, fetchExchange, ClientOptions } from "urql";
import { multipartFetchExchange } from "@urql/exchange-multipart-fetch";
import { offlineExchange } from "@urql/exchange-graphcache";
import { makeDefaultStorage } from "@urql/exchange-graphcache/default-storage";
import { accessToken } from "./utils";

const opts: ClientOptions = {
    url: `${process.env.REACT_APP_SERVICE_URL ?? ""}/graphql`,
    fetchOptions: () => {
        const token = accessToken.get();
        return {
            headers: {
                Authorization: token ? `Bearer ${token}` : ""
            }
        };
    }
};

const storage = makeDefaultStorage({ idbName: "graphcache-v3" });
const offlineCache = offlineExchange({
    storage
});

export const offlineClient = createClient({
    ...opts,
    exchanges: [dedupExchange, offlineCache, fetchExchange]
});

export const defaultClient = createClient({
    ...opts,
    exchanges: [dedupExchange, cacheExchange, multipartFetchExchange]
});
