// Copyright (C) 2023 TANNER AG

import Grid from "@mui/material/Grid";

import { FormFieldsObj } from "../register/RegisterView";
import FormCheckbox from "../../components/inputs/FormCheckbox";
import FormInput from "../../components/inputs/FormInput";
import FormSelect from "../../components/inputs/FormSelect";
import useTranslation from "../../hooks/usetranslation";

type Props = {
    isUpdate?: boolean;
    formFields: FormFieldsObj;
    updateFormField: (name: string, value: any) => void;
};

const RegisterSecondStep: React.FC<Props> = ({ isUpdate = false, formFields, updateFormField }) => {
    const { t } = useTranslation();

    const languageOptions = [
        { value: "en", label: t("en") },
        { value: "de_DE", label: t("de_DE") },
        { value: "fr_FR", label: t("fr") },
        { value: "es_ES", label: t("es") },
        { value: "it_IT", label: t("it") }
    ];

    return (
        <>
            {!isUpdate && (
                <Grid item xs={12} md={6}>
                    <FormInput
                        id="email"
                        label={t("email")}
                        InputProps={{ type: "email", value: formFields.email?.value, disabled: true }}
                    />
                </Grid>
            )}
            <Grid item xs={12} md={6}>
                <FormSelect
                    id="language"
                    label={t("preferred_language")}
                    options={languageOptions}
                    required
                    SelectProps={{
                        value: formFields.language?.value ?? "",
                        onChange: (event) => updateFormField("language", event.target.value)
                    }}
                />
            </Grid>

            {!isUpdate && (
                <Grid item xs={12} md={6}>
                    <FormCheckbox
                        id="salesDepartment"
                        label={t("work_sales_department")}
                        checked={formFields.salesDepartment?.value ?? false}
                        onChange={(_, checked) => updateFormField("salesDepartment", checked)}
                    />
                    <FormCheckbox
                        id="truckBus"
                        label={t("work_truck_bus")}
                        checked={formFields.truckBus?.value ?? false}
                        onChange={(_, checked) => updateFormField("truckBus", checked)}
                    />
                    <FormCheckbox
                        id="bodyBuilder"
                        label={t("work_bodybuilder")}
                        checked={formFields.bodyBuilder?.value ?? false}
                        onChange={(_, checked) => updateFormField("bodyBuilder", checked)}
                    />
                </Grid>
            )}

            <Grid item xs={12} md={6}>
                <FormCheckbox
                    id="newsflash"
                    label={t("news_flash")}
                    checked={formFields.newsflash?.value ?? false}
                    onChange={(_, checked) => updateFormField("newsflash", checked)}
                />
            </Grid>
        </>
    );
};

export default RegisterSecondStep;
