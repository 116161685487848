// Copyright (C) 2022 TANNER AG

import { VehicleTopic } from "../graphql";
import { useMemo, useState } from "react";

type PdfTopic = Pick<VehicleTopic, "id" | "key" | "type">;

const useVehiclePdf = () => {
    const [items, setItems] = useState<PdfTopic[]>([]);
    const itemIds = useMemo(() => {
        return items.map((item) => item.id);
    }, [items]);

    const toggleItem = (item: PdfTopic) => {
        const newItems = [...items];
        const index = newItems.findIndex((cItem) => cItem.id === item.id);
        if (index === -1) {
            newItems.push(item);
        } else {
            newItems.splice(index, 1);
        }

        setItems(newItems);
    };

    return { items, itemIds, toggleItem };
};

export default useVehiclePdf;
