// Copyright (C) 2022 TANNER AG

import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { boldFontFamily } from "../../../theme";
import { useNewsCategoriesQuery } from "../../../graphql";
import NewsCategoriesListItem from "./NewsCategoriesListItem";
import Spinner from "../../../components/Spinner";
import useLocale from "../../../hooks/uselocale";
import useTranslation from "../../../hooks/usetranslation";

type Props = {
    selectedCategoryIds: string[];
    setSelectedCategoryIds: React.Dispatch<React.SetStateAction<string[]>>;
    searchParam: string | null;
};

const NewsCategoriesList: React.FC<Props> = ({ selectedCategoryIds, setSelectedCategoryIds, searchParam }) => {
    const { t } = useTranslation();
    const { locale } = useLocale();
    const [{ data, fetching }] = useNewsCategoriesQuery({ variables: { locale } });

    if (fetching) return <Spinner />;

    const newsCategories = data?.newsCategories ?? [];

    const handleChangeCategoryCheckbox = (checked: boolean, categoryId: string) => {
        if (checked) setSelectedCategoryIds((prevState) => [...prevState, categoryId]);
        else setSelectedCategoryIds((prevState) => prevState.filter((catId) => catId !== categoryId));
    };

    return (
        <Stack spacing={1}>
            <Typography color="textSecondary" sx={{ fontFamily: boldFontFamily }}>
                {t("category")}
            </Typography>
            <List dense={true}>
                {newsCategories.map((category) => (
                    <NewsCategoriesListItem
                        key={category.id}
                        categoryId={category.id}
                        categoryName={category.name}
                        selected={selectedCategoryIds.includes(category.id)}
                        onChange={handleChangeCategoryCheckbox}
                    />
                ))}
            </List>
        </Stack>
    );
};

export default NewsCategoriesList;
