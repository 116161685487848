// Copyright (C) 2022 TANNER AG

import React, { createContext, useContext, useState } from "react";
import Login from "../views/login/Login";
import { useUserQuery } from "../graphql";
import Spinner from "../components/Spinner";
import { accessToken } from "../lib/utils";
import useOnline from "./useonline";
import { Button, Typography, Box, Paper } from "@mui/material";
import { CloseIcon } from "../components/icons/MANIcons";
import HTTPClient from "../lib/HTTPClient";
import useTranslation from "./usetranslation";

type AuthContextProps = {
    refetch: () => void;
    logout(): void;
};

const AuthContext = createContext<AuthContextProps>({
    logout: () => null,
    refetch: () => null
});

export const useAuth = () => useContext(AuthContext);

type Props = {
    children?: React.ReactNode;
};

export const AuthProvider: React.FC<Props> = ({ children }) => {
    const isOnline = useOnline();
    const { t } = useTranslation();
    const [showPopup, setShowPopup] = useState(true);
    const [{ data, fetching }, refetch] = useUserQuery({
        requestPolicy: isOnline ? "network-only" : "cache-only"
    });
    const onClickVerifyHandler = (email: string) => {
        HTTPClient.GET(`/user/send-verification/${email}`)
            .then((response) => response.text());
    };
    const renderChildren = (userData: any) => {
        if (userData?.popupType) {
            const { daysRemain, popupType, email } = userData;
            return popupType === "countdown" ? (
                <Box
                    component={Paper}
                    sx={{
                        width: "100%",
                        height: "100px",
                        display: "flex",
                        position: "relative",
                        border: "1px solid #e40045"
                    }}
                >
                    <Typography
                        variant="h3"
                        sx={{
                            alignItems: "center",
                            width: "10%",
                            backgroundColor: "#e40045",
                            display: "flex",
                            justifyContent: "center",
                            color: "whitesmoke"
                        }}
                    >
                        {t("popup_title")}
                    </Typography>
                    <Box
                        sx={{
                            width: "75%",
                            margin: "auto 0 auto 20px",
                            fontSize:"13px",
                        }}
                    >
                        <Typography sx={{ fontWeight: "600" }}>{t("popup_dialog_description")}</Typography>
                        <Typography sx={{ fontWeight: "600" }}>{t("popup_dialog_description_2")}</Typography>
                        <Typography>{`${t("popup_days_remain")} ${daysRemain}`}</Typography>
                        <Typography>{`${t("popup_registered_email")} ${email}`}</Typography>
                    </Box>
                    <Button
                        variant="outlined"
                        sx={{
                            width: "10%",
                            borderRadius: "10px",
                            right: "0",
                            top: "25px"
                        }}
                        onClick={() => onClickVerifyHandler(email)}
                    >
                        {t("popup_button")}
                    </Button>
                    <CloseIcon
                    sx={{
                        width: "2%",
                        height: "15px",
                        borderRadius: "10px",
                        position: "absolute",
                        right: "0",
                        top: "5px",
                        cursor: "pointer"
                    }}
                    onClick={()=>setShowPopup(false)}/>
                </Box>
            ) : (
                <Box
                    component={Paper}
                    sx={{
                        width: "100%",
                        height: "100px",
                        display: "flex",
                        position: "relative",
                        border: "1px solid #e40045"
                    }}
                >
                    <Typography
                        variant="h3"
                        sx={{
                            alignItems: "center",
                            width: "10%",
                            backgroundColor: "#e40045",
                            display: "flex",
                            justifyContent: "center",
                            color: "whitesmoke"
                        }}
                    >
                        {t("popup_title")}
                    </Typography>
                    <Box
                        sx={{
                            width: "75%",
                            margin: "auto 0 auto 20px",
                            fontSize:"13px",
                        }}
                    >
                        <Typography sx={{ fontWeight: "600" }}>{t("popup_dialog_description")}</Typography>
                        <Typography sx={{ fontWeight: "600" }}>{t("popup_dialog_description_2")}</Typography>
                        <Typography>{`${t("popup_days_remain")} 21`}</Typography>
                        <Typography>{`${t("popup_registered_email")} ${email}`}</Typography>
                    </Box>
                    <Button
                        variant="outlined"
                        sx={{
                            width: "10%",
                            borderRadius: "10px",
                            right: "0",
                            top: "25px"
                        }}
                        onClick={() => onClickVerifyHandler(email)}
                    >
                        {t("popup_button")}
                    </Button>
                    <CloseIcon
                    sx={{
                        width: "2%",
                        height: "15px",
                        borderRadius: "10px",
                        position: "absolute",
                        right: "0",
                        top: "5px",
                        cursor: "pointer"
                    }}
                    onClick={()=>setShowPopup(false)}/>
                </Box>
            );
        }
        return null;
    };

    const handleSuccessLogin = () => {
        refetch();
    };

    const logout = () => {
        accessToken.clear();
        refetch();
    };

    if (fetching) {
        return <Spinner />;
    }

    return (
        <AuthContext.Provider value={{ refetch, logout }}>
            {showPopup && data?.user?.popupType !== "" && renderChildren(data?.user)}
            {data?.user ? (
                children
            ) : (
                <Login onSuccess={handleSuccessLogin} onClickVerifyHandler={onClickVerifyHandler} />
            )}
        </AuthContext.Provider>
    );
};

export default useAuth;
