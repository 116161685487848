// Copyright (C) 2023 TANNER AG

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import AppToolbar from "../../components/apptoolbar/AppToolbar";
import BaseAppBar from "../../components/appbar/BaseAppBar";
import HTTPClient from "../../lib/HTTPClient";
import Spinner from "../../components/Spinner";
import useDocMeta from "../../hooks/usedocmeta";
import useTranslation from "../../hooks/usetranslation";

const RegisterApproveStatusView: React.FC = () => {
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const username = searchParams.get("username");
    const token = searchParams.get("token");
    const firstName = searchParams.get("firstname") ?? "";
    const lastName = searchParams.get("lastname") ?? "";
    const access = searchParams.get("access") === "true";

    useDocMeta(t(access ? "rights_enabled_title" : "rights_disabled_title"));

    useEffect(() => {
        setLoading(true);
        setTimeout(
            () =>
                HTTPClient.POST("/user/approve", { username, token, access }).finally(() => {
                    setLoading(false);
                }),
            5 * 1000
        );
    }, [username, token, access]);

    if (isLoading) return <Spinner />;

    return (
        <>
            <BaseAppBar toolbar={<AppToolbar isUserAuthenticated={false} />} />
            <Container>
                <Typography variant="h3" textAlign="center" marginTop={16}>
                    {t(access ? "access_rights_enabled" : "access_rights_disabled", {
                        firstName,
                        lastName
                    })}
                </Typography>
            </Container>
        </>
    );
};

export default RegisterApproveStatusView;
