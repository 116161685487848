// Copyright (C) 2023 TANNER AG

import { useParams } from "react-router-dom";

import { usePageQuery } from "../graphql";
import useLocale from "./uselocale";

const usePage = () => {
    const { locale: storeLocale } = useLocale();
    let locale = storeLocale === "de" ? "de_DE" : storeLocale;

    const { pageId } = useParams();
    const [{ data, fetching }] = usePageQuery({ variables: { id: Number(pageId), locale } });
    const page = data?.page;

    return { page, fetching };
};

export default usePage;
