const useFallbackImage = () => {
    const fallbackImage = "/fallback_image.png";

    const displayFallbackImage: React.ReactEventHandler<HTMLImageElement> = (event) => {
        (event.target as HTMLImageElement).src = fallbackImage;
    };

    return { fallbackImage, displayFallbackImage };
};

export default useFallbackImage;
