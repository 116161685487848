// Copyright (C) 2022 TANNER AG

import React from "react";
import Avatar, { AvatarProps } from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import { User } from "../../../graphql";
import { UserIcon } from "../../icons/MANIcons";
import useAsset from "../../../hooks/useasset";

type Props = {
    user?: User;
    size?: "large";
};

const UserAvatar: React.FC<Props> = ({ user, size }) => {
    const { createImageUrl } = useAsset();
    return (
        <StyledAvatar size={size} src={createImageUrl(user?.avatar)}>
            {user?.lastname ? (
                <>
                    {user?.firstname?.toUpperCase()?.charAt(0)}
                    {user?.lastname?.toUpperCase()?.charAt(0)}
                </>
            ) : (
                <UserIcon color="inherit" />
            )}
        </StyledAvatar>
    );
};

export const StyledAvatar = styled(Avatar, {
    shouldForwardProp: (prop) => prop !== "size"
})<AvatarProps & { size?: "large" }>(({ theme, size }) => ({
    width: 40,
    height: 40,
    fontSize: "1rem",
    fontWeight: 600,
    background: theme.palette.mode === "light" ? "#d3d3d3" : "#303030",
    color: theme.palette.mode === "light" ? "#303030" : "#d3d3d3",
    ...(size === "large" && {
        width: 35,
        height: 35,
        fontSize: "1rem"
    })
}));

export default UserAvatar;
