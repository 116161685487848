// Copyright (C) 2022 TANNER AG

import React from "react";
import Box from "@mui/material/Box";
import ProductSidebar from "./productsidebar/ProductSidebar";
import ProjectSidebar from "./project/ProjectSidebar";
import Divider from "@mui/material/Divider";
import useBreakpoint from "../../../hooks/usebreakpoint";

const Sidebar: React.FC = () => {
    const { down } = useBreakpoint("lg");

    if (down) {
        return null;
    }

    return (
        <Box sx={{ width: 288, flexShrink: 0 }}>
            <ProductSidebar />
            <Divider sx={{ mb: 4 }} />
            <ProjectSidebar />
        </Box>
    );
};

export default Sidebar;
