// Copyright (C) 2022 TANNER AG

import React from "react";
import Button from "@mui/material/Button";
import { ChatIcon, MailIcon, PhoneIcon } from "../../../../components/icons/MANIcons";
import { LocalExpert } from "../../../../graphql";
import useTranslation from "../../../../hooks/usetranslation";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import MenuPopover from "../../../../components/MenuPopover";
import useAnchorHandler from "../../../../hooks/useanchorhandler";
import ListItemIcon from "@mui/material/ListItemIcon";

type Props = {
    productName?: string;
    expert: LocalExpert;
};

const ExpertsActions: React.FC<Props> = ({ productName, expert }) => {
    const { anchorEl, onClose, onClick } = useAnchorHandler();
    const { t } = useTranslation();
    const msg = `${t("dsg_title")}: Questions about '${productName}'`;

    return (
        <>
            <Button startIcon={<ChatIcon color="secondary" />} onClick={onClick}>
                {t("ask_question")}
            </Button>
            <MenuPopover anchorEl={anchorEl} onClose={onClose} maxWidth={160}>
                <MenuList component="div">
                    {expert?.email?.endsWith("man.eu") && (
                        <MenuItem
                            component="a"
                            target="_blank"
                            href={`https://teams.microsoft.com/l/chat/0/0?users=${expert.email}&message=${msg}`}
                        >
                            <ListItemIcon>
                                <ChatIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={{ variant: "body2" }}>{t("teams")}</ListItemText>
                        </MenuItem>
                    )}
                    {expert?.email && (
                        <MenuItem component="a" href={`mailto:${expert.email}?subject=${msg}`}>
                            <ListItemIcon>
                                <MailIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={{ variant: "body2" }}>{t("email")}</ListItemText>
                        </MenuItem>
                    )}
                    {expert?.phone && (
                        <MenuItem component="a" href={`tel:${expert.phone}`}>
                            <ListItemIcon>
                                <PhoneIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={{ variant: "body2" }}>{t("phone")}</ListItemText>
                        </MenuItem>
                    )}
                </MenuList>
            </MenuPopover>
        </>
    );
};

export default ExpertsActions;
