// Copyright (C) 2022 TANNER AG

import { useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { UserIcon, LogoutIcon } from "../../icons/MANIcons";
import { useUserQuery } from "../../../graphql";
import MenuPopover from "../../MenuPopover";
import Offline from "../../offline/Offline";
import useAuth from "../../../hooks/useauth";
import UserAvatar from "./UserAvatar";
import useTranslation from "../../../hooks/usetranslation";
import ProfileEdit from "./ProfileEdit";

const User: React.FC = () => {
    const { logout } = useAuth();
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [{ data }] = useUserQuery();
    const user = data?.user;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton size="small" onClick={handleClick} color="primary" title={t("user")}>
                <UserIcon />
            </IconButton>
            <MenuPopover anchorEl={anchorEl} onClose={handleClose}>
                <Stack sx={{ px: 2, py: 2, textAlign: "left", alignItems: "center" }} spacing={1} direction="row">
                    <UserAvatar user={user} />
                    <Box>
                        <Typography component="div" variant="subtitle2" color="textPrimary" sx={{ fontSize: 16 }}>
                            {user?.firstname} {user?.lastname}
                        </Typography>
                        <Typography component="div" variant="body2" color="textPrimary" sx={{ lineHeight: 1.1 }}>
                            {user?.email}
                        </Typography>
                    </Box>
                </Stack>
                <Divider />
                <MenuList component="div">
                    <ProfileEdit />
                    <Offline onClose={handleClose} />
                    <Divider />
                    <MenuItem onClick={logout}>
                        <ListItemIcon>
                            <LogoutIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{ variant: "body2" }}>{t("logout")}</ListItemText>
                    </MenuItem>
                </MenuList>
            </MenuPopover>
        </>
    );
};

export default User;
