// Copyright (C) 2022 TANNER AG

import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { useMemo } from "react";
import CssBaseline from "@mui/material/CssBaseline";

import AppRouter from "./components/AppRouter";
import createTheme from "./theme";

function App() {
    const theme = useMemo(() => createTheme("light"), []);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <AppRouter />
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
