// Copyright (C) 2022 TANNER AG

import React from "react";
import Dialog from "../dialog/Dialog";
import DialogContent from "@mui/material/DialogContent";

type Props = {
    open: boolean;
    children?: React.ReactNode;
    onClose(): void;
};

const OfflineDialog: React.FC<Props> = ({ open, onClose, children }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>{children}</DialogContent>
        </Dialog>
    );
};

export default OfflineDialog;
