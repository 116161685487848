// Copyright (C) 2022 TANNER AG

import React from "react";
import { Project } from "../../../graphql";
import useDatetime from "../../../hooks/usedatetime";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useStore from "../../../hooks/usestore";
import Box from "@mui/material/Box";
import ProjectEdit from "../../../components/projectform/ProjectEdit";
import useTranslation from "../../../hooks/usetranslation";
import useLocale from "../../../hooks/uselocale";

type Props = {
    project?: Pick<
        Project,
        | "id"
        | "name"
        | "gpa"
        | "tenderCountry"
        | "status"
        | "vehicleAmount"
        | "deliveryYear"
        | "lub"
        | "orderValue"
        | "proposal"
        | "deadline"
        | "publication"
    >;
};

const ProjectHeader: React.FC<Props> = ({ project }) => {
    const show = useStore((state) => state.projectDetails);
    const { fDate } = useDatetime();
    const { t } = useTranslation();
    const { regionNames } = useLocale();
    if (!project) {
        return null;
    }

    return (
        <Collapse in={show}>
            <Box sx={{ pt: 1, pb: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Typography
                            variant="subtitle2"
                            textTransform="uppercase"
                            gutterBottom
                            sx={{ color: "grey.300" }}
                        >
                            {t("project_details")}
                        </Typography>
                        {[
                            { label: t("gpa_number"), value: project.gpa },
                            {
                                label: t("country"),
                                value: project.tenderCountry ? regionNames.of(project.tenderCountry) : ""
                            }
                        ].map(({ label, value }, index) => (
                            <ProjectHeaderRow key={index} label={label} value={value} />
                        ))}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography
                            variant="subtitle2"
                            textTransform="uppercase"
                            gutterBottom
                            sx={{ color: "grey.300" }}
                        >
                            {t("order_details")}
                        </Typography>
                        {[
                            { label: t("num_of_vehicles"), value: String(project.vehicleAmount) },
                            { label: t("years_of_delivery"), value: String(project.deliveryYear) },
                            { label: t("lub_numbers"), value: project.lub },
                            { label: t("oder_val"), value: String(project.orderValue) }
                        ].map(({ label, value }, index) => (
                            <ProjectHeaderRow key={index} label={label} value={value} />
                        ))}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography
                            variant="subtitle2"
                            textTransform="uppercase"
                            gutterBottom
                            sx={{ color: "grey.300" }}
                        >
                            {t("call_for_proposals")}
                        </Typography>
                        {[
                            { label: "Expect. publication", value: fDate(project.publication) },
                            { label: "Deadline", value: fDate(project.deadline) }
                        ].map(({ label, value }, index) => (
                            <ProjectHeaderRow key={index} label={label} value={value} />
                        ))}
                    </Grid>
                </Grid>
                <ProjectEdit projectId={project.id} label="Edit project settings" />
            </Box>
        </Collapse>
    );
};

const ProjectHeaderRow: React.FC<{ label: string; value?: string }> = (props) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <Typography variant="subtitle2" color="textSecondary">
                    {props.label}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                    {props.value || "n.a."}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default ProjectHeader;
