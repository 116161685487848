// Copyright (C) 2022 TANNER AG

import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";

type Props = CheckboxProps & {
    id: string;
    label: string;
    checked?: boolean;
};

const FormCheckbox: React.FC<Props> = ({ id, checked, label, ...otherProps }) => {
    return (
        <FormControlLabel
            checked={checked}
            label={label}
            control={<Checkbox id={id} name={id} {...otherProps} />}
            sx={{ mr: 4 }}
        />
    );
};

export default FormCheckbox;
