// Copyright (C) 2022 TANNER AG

import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { boldFontFamily } from "../../../theme";
import useBreakpoint from "../../../hooks/usebreakpoint";
import useTranslation from "../../../hooks/usetranslation";

const HeroSection: React.FC = () => {
    const { down } = useBreakpoint("lg");
    const { t } = useTranslation();
    if (down) {
        return (
            <Container>
                <Lettering sx={{ fontSize: "3.7em" }}>{t("lions_city_e")}</Lettering>
            </Container>
        );
    }

    return (
        <Box sx={{ background: "linear-gradient(180deg, #ABB6C3 0%, #FFFFFF 100%)", height: 550 }}>
            <Container>
                <Lettering>{t("lions_city_e")}</Lettering>
            </Container>
            <Box
                sx={{
                    position: "absolute",
                    left: "48%",
                    transform: "translateX(-50%)",
                    top: 300,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <Box component="img" src="/overview_hero.png" sx={{ width: 1100 }} />
            </Box>
        </Box>
    );
};

export const Lettering = styled(Typography)(({ theme }) => ({
    fontFamily: boldFontFamily,
    fontWeight: 700,
    fontSize: "4rem",
    color: "#fff",
    opacity: 0.8,
    [theme.breakpoints.up("lg")]: {
        fontSize: "13.35em",
        opacity: 0.3
    }
}));

export default HeroSection;
