// Copyright (C) 2021 TANNER AG

import parse, { domToReact, HTMLReactParserOptions, Element, attributesToProps } from "html-react-parser";
import Typography from "@mui/material/Typography";
import { Variant } from "@mui/material/styles/createTypography";

import ContentInfo from "../components/content/ContentInfo";
import ContentLink from "../components/content/ContentLink";
import useAsset from "./useasset";

const BASE64_IMAGE_REGEX = /^(data:image\/[^;]+;base64[^"]+)/;

const useHTMLParser = () => {
    const { createImageUrl } = useAsset();

    const parseHTML = (html: string | null | undefined): string | JSX.Element | JSX.Element[] => {
        if (!html) {
            return "";
        }

        const options: HTMLReactParserOptions = {
            trim: true,
            replace: (domNode) => {
                if (domNode.type === "tag") {
                    const element = domNode as Element;
                    if (element.name === "img" && element?.attribs?.src) {
                        const src = element?.attribs?.src;
                        const srcSet = element?.attribs?.srcset;
                        const isSrcBase64Format = BASE64_IMAGE_REGEX.test(src);
                        const isSrcSetBase64Format = BASE64_IMAGE_REGEX.test(srcSet);

                        return (
                            <>
                                <img
                                    {...attributesToProps(element?.attribs)}
                                    alt={element?.attribs?.alt ?? ""}
                                    src={isSrcBase64Format ? src : createImageUrl(src)}
                                    srcSet={isSrcSetBase64Format ? srcSet : createImageUrl(srcSet)}
                                />
                            </>
                        );
                    }

                    if (element.name === "a" && element?.attribs?.href) {
                        return (
                            <ContentLink href={element?.attribs["href"]} {...attributesToProps(element?.attribs)}>
                                {domToReact(element.children, options)}
                            </ContentLink>
                        );
                    }

                    if (["h1", "h2", "h3", "h4", "h5", "p"].includes(element.name)) {
                        return (
                            <Typography
                                {...attributesToProps(element?.attribs)}
                                component={element.name as "p"}
                                variant={element.name as Variant}
                                gutterBottom
                            >
                                {domToReact(element.children, options)}
                            </Typography>
                        );
                    }

                    if (element.name === "div" && element?.attribs?.class === "man-info") {
                        return <ContentInfo>{domToReact(element.children, options)}</ContentInfo>;
                    }

                    return false;
                }
                return false;
            }
        };

        return parse(html, options);
    };
    return { parseHTML };
};

export default useHTMLParser;
