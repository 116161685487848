// Copyright (C) 2022 TANNER AG

import Box from "@mui/material/Box";
import React from "react";

import { useVehicleTopicQuery, VehicleTopic } from "../../../graphql";
import Content from "../../../components/content/Content";
import ContentIntro from "../../../components/content/ContentIntro";
import useLocale from "../../../hooks/uselocale";

type Props = {
    item: Pick<VehicleTopic, "id" | "key" | "type">;
};

const PageTopicPdf: React.FC<Props> = ({ item: { key, type } }) => {
    const { locale } = useLocale();
    const [{ data }] = useVehicleTopicQuery({ variables: { key, type, locale }, pause: !key || !type });
    const topic = data?.vehicleTopic;

    return (
        <div className="print-section">
            <ContentIntro
                title={topic?.title ?? ""}
                subTitle={topic?.subtitle ?? ""}
                theme={topic?.type ?? ""}
                content={topic?.description ?? ""}
            />
            <Box sx={{ pt: 4, bgcolor: "background.paper" }}>
                {topic?.sections?.map((section) => (
                    <Content key={section.id} id={section.id} section={section} />
                ))}
            </Box>
        </div>
    );
};

export default PageTopicPdf;
