// Copyright (C) 2023 TANNER AG

import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import Dialog from "../../components/dialog/Dialog";
import DialogHead from "../../components/dialog/DialogHead";
import useTranslation from "../../hooks/usetranslation";

type Props = {
    onClose: () => void;
    isOpen: boolean;
};

const TermsOfUseDialog: React.FC<Props> = ({ onClose, isOpen }) => {
    const { t } = useTranslation();

    return (
        <Dialog onClose={onClose} open={isOpen}>
            <DialogTitle>
                <DialogHead title={t("terms_of_use")} onClose={onClose} />
            </DialogTitle>
            <DialogContent>
                <Typography variant="h5">{t("information_on_copyright")}</Typography>
                <Typography>{t("information_on_copyright_content")}</Typography>
            </DialogContent>
        </Dialog>
    );
};

export default TermsOfUseDialog;
