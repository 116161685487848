// Copyright (C) 2022 TANNER AG

import { ButtonBase } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import clsx from "clsx";
import Paper from "@mui/material/Paper";
import React from "react";

import { arialFontFamily } from "../../../theme";
import { ChevronRightIcon, FilterIcon } from "../../../components/icons/MANIcons";

type Props = {
    id: string;
    to: string;
    name: string;
    filter?: boolean;
    active?: boolean;
    pdf?: boolean;
    onFilterClick?(): void;
};

const PageThemeItem: React.FC<Props> = ({ id, to, name, filter, pdf, active, onFilterClick }) => {
    return (
        <Root data-id={id} className={clsx({ active })}>
            <ButtonBase
                sx={{
                    flexGrow: 1,
                    justifyContent: "space-between",
                    px: 1.5,
                    fontSize: 14,
                    fontFamily: arialFontFamily,
                    borderRight: "3px solid #eaedf3",
                    textAlign: "left",
                    lineHeight: 1.2
                }}
                component={Link}
                to={to}
            >
                <span>{name}</span>
                <ChevronRightIcon sx={{ fontSize: 16, color: "grey.100" }} />
            </ButtonBase>
            <ButtonBase sx={{ width: 46, flexShrink: 0 }} onClick={onFilterClick}>
                {pdf ? (
                    <Checkbox
                        size="small"
                        disableRipple
                        checked={active || false}
                        sx={{ "&.Mui-checked": { color: "#fff" } }}
                    />
                ) : (
                    <FilterIcon sx={{ fontSize: 16, color: filter ? "secondary.main" : "grey.100" }} />
                )}
            </ButtonBase>
        </Root>
    );
};

const Root = styled(Paper)(({ theme }) => ({
    display: "flex",
    height: 45,
    "&.active": {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.common.white
    }
}));

export default PageThemeItem;
