// Copyright (C) 2022 TANNER AG

import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { runtimeConfig } from "../../lib/utils";
import Typography from "@mui/material/Typography";
import useTranslation from "../../hooks/usetranslation";

type Props = {
    action?: React.ReactNode;
};

const ListItemSmall: React.FC<Props> = ({ action }) => {
    const { t } = useTranslation();
    return (
        <Stack direction="row" sx={{ alignItems: "center", borderBottom: "1px solid", borderColor: "grey.100", py: 1 }}>
            <Box
                component="img"
                src={`${runtimeConfig.basePath}/dummy.png`}
                sx={{ width: 56, height: 56, objectFit: "cover", mr: 2, borderRadius: 0.5 }}
            />
            <Stack>
                <Typography variant="h4" sx={{ fontSize: 16 }} gutterBottom>
                    {t("retail_ticket_biz")}
                </Typography>
            </Stack>
            <Box sx={{ ml: "auto" }}>{action}</Box>
        </Stack>
    );
};

export default ListItemSmall;
