// Copyright (C) 2022 TANNER AG

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { CheckIcon, ChevronDownIcon } from "../../components/icons/MANIcons";
import { SortMethod } from "../../graphql";
import useTranslation from "../../hooks/usetranslation";

type Props = {
    sortParam: SortMethod;
    setSortParam: (sort: SortMethod) => void;
};

const SortSelect: React.FC<Props> = ({ sortParam, setSortParam }) => {
    const { t } = useTranslation();

    return (
        <Select
            id="sort"
            value={sortParam}
            onChange={(event) => setSortParam(event.target.value as SortMethod)}
            renderValue={(selected) => `${t("sorting")}: ${t(`sort_${selected}`)}`}
            IconComponent={ChevronDownIcon}
            sx={{
                width: 208,
                height: 40,
                backgroundColor: "white",
                borderRadius: 0,
                color: "#303C49",
                ".MuiSelect-icon": { fontSize: 16, color: "#303C49", mr: 1 }
            }}
        >
            {Object.values(SortMethod)
                .filter(String)
                .map((option) => (
                    <MenuItem key={option} value={option} sx={{ justifyContent: "space-between" }}>
                        {t(`sort_${option}`)}
                        {sortParam === option && <CheckIcon />}
                    </MenuItem>
                ))}
        </Select>
    );
};

export default SortSelect;
