// Copyright (C) 2022 TANNER AG

import Container from "@mui/material/Container";
import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import BuscastListItem from "./BuscastListItem";
import useLocale from "../../../hooks/uselocale";
import { usePodcastsQuery } from "../../../graphql";
import ListItemRegularLoader from "../../../components/listitem/regular/ListItemRegularLoader";
import NavBar from "../../../components/navbar/NavBar";
import BuscastDetails from "../details/BuscastDetails";
import useTranslation from "../../../hooks/usetranslation";
import useTags from "../../../hooks/usetags";
import TagList from "../../../components/TagList";
import useParam from "../../../hooks/useparam";

const BuscastOverview = () => {
    const [id, setId] = useParam("id");
    const { locale } = useLocale();
    const [{ data, fetching }] = usePodcastsQuery({ variables: { locale } });
    const { t } = useTranslation();
    const { tags, activeTags, toggleTag } = useTags(data?.podcasts);

    return (
        <>
            <NavBar links={[{ label: t("bus_cast") }]} />
            <Container sx={{ my: 4 }}>
                <Typography variant="h2" sx={{ mb: 4 }}>
                    {t("bus_casts")}
                </Typography>
                <TagList tags={tags} activeTags={activeTags} toggleTag={toggleTag} />
                <Stack spacing={2}>
                    {!fetching &&
                        data?.podcasts
                            ?.filter(
                                (podcast) =>
                                    !activeTags.length || activeTags.every((tag) => podcast?.keywords?.includes(tag))
                            )
                            ?.map((podcast) => (
                                <BuscastListItem
                                    key={podcast.id}
                                    id={podcast.id}
                                    title={podcast.title}
                                    type={podcast.mediaType}
                                    description={podcast.description}
                                    thumbnail={podcast.thumbnail}
                                    publication={podcast.publication}
                                    keywords={podcast.keywords ?? []}
                                    onClick={() => setId(podcast.id)}
                                />
                            ))}
                    {fetching && Array.from(Array(4).keys()).map((key) => <ListItemRegularLoader key={key} />)}
                </Stack>
            </Container>
            <BuscastDetails activeId={id} onClose={() => setId()} />
        </>
    );
};

export default BuscastOverview;
