// Copyright (C) 2022 TANNER AG

import React from "react";
import { Product, UpdateMode, useUpdateProjectProductMutation } from "../../../../graphql";
import IconButton from "@mui/material/IconButton";
import { DeleteIcon, HideIcon, ShowIcon } from "../../../../components/icons/MANIcons";
import ListItemRegular from "../../../../components/listitem/regular/ListItemRegular";
import useTranslation from "../../../../hooks/usetranslation";
import Tooltip from "@mui/material/Tooltip";
import useStore from "../../../../hooks/usestore";
import Availability from "../../../../components/Availability";

type Props = {
    projectId: string;
    product: Pick<Product, "id" | "name" | "image" | "intro" | "details" | "availableFrom" | "availableTo">;
    visible?: boolean;
};

const ProjectProductListItem: React.FC<Props> = ({ projectId, product, visible }) => {
    const [{ fetching }, updateProduct] = useUpdateProjectProductMutation();
    const setProductId = useStore((state) => state.setProduct);
    const { t } = useTranslation();

    const handleProductVisibility = () =>
        updateProduct({
            where: { id: projectId },
            data: { productId: product.id, visible: !visible, mode: UpdateMode.Update }
        });

    const handleProductDelete = () =>
        updateProduct({ where: { id: projectId }, data: { productId: product.id, mode: UpdateMode.Remove } });

    return (
        <ListItemRegular
            key={product.id}
            title={product.name}
            image={product.image}
            description={product.intro}
            inactive={!visible}
            onClick={() => setProductId(product.id)}
            toolbar={
                <>
                    <Tooltip title={t(visible ? "item_enabled_info" : "item_disabled_info")}>
                        <IconButton size="small" onClick={handleProductVisibility} disabled={fetching}>
                            {visible ? (
                                <ShowIcon fontSize="small" color="secondary" />
                            ) : (
                                <HideIcon fontSize="small" color="primary" />
                            )}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t("item_remove_info")}>
                        <IconButton size="small" color="primary" onClick={handleProductDelete} disabled={fetching}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </>
            }
            additional={<Availability availableFrom={product.availableFrom} availableTo={product.availableTo} />}
        />
    );
};

export default ProjectProductListItem;
