// Copyright (C) 2022 TANNER AG

import React from "react";
import { Routes, Route } from "react-router-dom";
import ProductCategory from "./category/ProductCategory";
import ProductViewer from "./product/ProductViewer";
import ProductsRedirect from "./ProductsRedirect";

const ProductsView = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<ProductsRedirect />} />
                <Route path="/:categoryId/*" element={<ProductCategory />} />
            </Routes>
            <ProductViewer enableProjectUpdate />
        </>
    );
};

export default ProductsView;
