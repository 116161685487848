// Copyright (C) 2022 TANNER AG

import React from "react";
import FormInput from "../../../components/inputs/FormInput";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { SearchIcon, CloseIcon } from "../../../components/icons/MANIcons";
import useParam from "../../../hooks/useparam";
import useTranslation from "../../../hooks/usetranslation";

const ProjectFilterInput: React.FC = () => {
    const { t } = useTranslation();
    const [query, setQuery] = useParam("q");

    const onClear = () => setQuery("");

    return (
        <FormInput
            id="search"
            label={t("filter_projects")}
            placeholder={t("project_title_gpa")}
            InputProps={{
                value: query,
                onChange: (event) => setQuery(event.currentTarget.value),
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        {Boolean(query.length) && (
                            <IconButton onClick={onClear}>
                                <CloseIcon sx={{ fontSize: 14 }} />
                            </IconButton>
                        )}
                    </InputAdornment>
                )
            }}
        />
    );
};

export default ProjectFilterInput;
