// Copyright (C) 2022 TANNER AG

import React from "react";
import { useRelatedContentQuery } from "../../graphql";
import useTranslation from "../../hooks/usetranslation";
import DialogHead from "../dialog/DialogHead";
import DialogContent from "@mui/material/DialogContent";
import Section from "../Section";
import RichText from "../content/RichText";
import BulletList from "../BulletList";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemLink from "../listitem/ListItemLink";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import useLocale from "../../hooks/uselocale";
import Spinner from "../Spinner";
import Experts from "../../views/products/product/experts/Experts";
import ListItemDownload from "../listitem/ListItemDownload";
import useAsset from "../../hooks/useasset";
import { DownloadIcon } from "../icons/MANIcons";

type Props = {
    id: string;
    onClose?(): void;
};

const RelatedContentDetails: React.FC<Props> = ({ id, onClose }) => {
    const { locale } = useLocale();
    const { t } = useTranslation();
    const { createDownloadUrl } = useAsset();
    const [{ data, fetching }] = useRelatedContentQuery({ variables: { id, locale } });
    const related = data?.relatedContent;
    console.log(related);

    if (fetching) {
        return <Spinner />;
    }

    return (
        <>
            <DialogContent>
                <DialogHead title={related?.name ?? ""} subTitle={t("related_content")} onClose={onClose} />
                <Section>
                    <RichText html={related?.description} />
                </Section>
                {related?.details && (
                    <Section>
                        <BulletList title={t("details")} items={related?.details} />
                    </Section>
                )}
                {Boolean(related?.information?.length) && (
                    <Section>
                        <Typography variant="h4" textTransform="uppercase">
                            {t("further_information")}
                        </Typography>
                        <List disablePadding>
                            {related?.information.map((info, idx) => (
                                <ListItemLink key={idx} href={info.href}>
                                    {info.title}
                                </ListItemLink>
                            ))}
                        </List>
                    </Section>
                )}
            {Boolean(related?.downloads?.length) && (
                <Section>
                    <Typography variant="h4" textTransform="uppercase" gutterBottom>
                        {t("downloads")}
                    </Typography>
                    <Stack>
                        {related?.downloads.map((download) => (
                            <ListItemDownload
                                key={download.id}
                                id={download.id}
                                name={download.name}
                                mime={download.mime}
                                size={download.size}
                                border
                                action={
                                    <Button
                                        startIcon={<DownloadIcon color="secondary" />}
                                        component="a"
                                        href={createDownloadUrl(download.id)}
                                        download
                                    >
                                        {t("download_file")}
                                    </Button>
                                }
                            />
                        ))}
                    </Stack>
                </Section>
            )}
            </DialogContent>
            {Boolean(related?.experts?.length) && (
                <DialogContent sx={{ bgcolor: "grey.50" }}>
                    <Experts productName={related?.name} experts={related?.experts ?? []} />
                    <Stack direction="row" spacing={1} sx={{ mt: 3 }}>
                        <Button onClick={onClose} variant="outlined">
                            {t("back_to_overview")}
                        </Button>
                    </Stack>
                </DialogContent>
            )}
        </>
    );
};

export default RelatedContentDetails;
