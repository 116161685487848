// Copyright (C) 2022 TANNER AG

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

import { boldFontFamily } from "../../../theme";

type Props = {
    categoryId: string;
    categoryName: string;
    onChange: (checked: boolean, categoryId: string) => void;
    selected: boolean;
};

const NewsCategoriesList: React.FC<Props> = ({ categoryId, categoryName, onChange, selected }) => {
    return (
        <FormGroup>
            <FormControlLabel
                control={<Checkbox disableRipple />}
                label={categoryName}
                componentsProps={{
                    typography: {
                        sx: {
                            fontFamily: boldFontFamily,
                            fontSize: 18
                        }
                    }
                }}
                checked={selected}
                onChange={(_: unknown, checked: boolean) => onChange(checked, categoryId)}
                sx={{
                    backgroundColor: selected ? "white" : "inherit",
                    color: "grey.800",
                    "&:hover": { color: "secondary.main" }
                }}
            />
        </FormGroup>
    );
};

export default NewsCategoriesList;
