// Copyright (C) 2022 TANNER AG

import React from "react";
import BaseAppBar from "../BaseAppBar";
import SubAppBar from "./SubAppBar";
import AppToolbar from "../../apptoolbar/AppToolbar";
import AppMenuItem from "../menu/AppMenuItem";
import { routes } from "../../../lib/utils";
import useTranslation from "../../../hooks/usetranslation";
import useDocMeta from "../../../hooks/usedocmeta";

const AppBar = () => {
    const { t } = useTranslation();
    useDocMeta(t("dsg_title"));

    return (
        <>
            <BaseAppBar toolbar={<AppToolbar />}>
                <AppMenuItem anchorRef={null} navItem={{ id: "dsg", title: t("dsg_title"), url: routes.dsg.root }} />
            </BaseAppBar>
            <SubAppBar />
        </>
    );
};

export default AppBar;
