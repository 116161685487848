// Copyright (C) 2022 TANNER AG

import React from "react";
import { CustomerCalculation } from "../types";
import { useNavigate, useParams } from "react-router-dom";
import CustomerProjectTcoDetails from "./CustomerProjectTcoDetails";

type Props = {
    projectName?: string;
    calculations?: CustomerCalculation[];
};

const CustomerProjectTco: React.FC<Props> = ({ calculations, projectName }) => {
    const navigate = useNavigate();
    const { calcId } = useParams();
    const id = String(calcId);

    return (
        <CustomerProjectTcoDetails
            calcId={id}
            projectName={projectName}
            calculations={calculations}
            onBack={() => navigate(-1)}
        />
    );
};

export default CustomerProjectTco;
