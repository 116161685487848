// Copyright (C) 2022 TANNER AG

import { useEffect } from "react";

const useDocMeta = (title: string) => {
    useEffect(() => {
        const prevTitle = document.title;
        document.title = title;
        return () => {
            document.title = prevTitle;
        };
    }, [title]);
};

export default useDocMeta;
