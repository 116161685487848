// Copyright (C) 2022 TANNER AG

import React from "react";
import Container from "@mui/material/Container";
import { SxProps } from "@mui/system";

type Props = {
    children?: React.ReactNode;
    sx?: SxProps;
};

const ContentWrapper: React.FC<Props> = ({ sx, children }) => {
    return <Container sx={{ pb: 2, ...sx }}>{children}</Container>;
};

export default ContentWrapper;
