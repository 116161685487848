// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import useLocale from "../../hooks/uselocale";
import DialogHead from "../dialog/DialogHead";
import Spinner from "../Spinner";
import useOfflineVehicles from "../../hooks/offline/useofflinevehicles";
import { ContentTypeGroup, useOfflineQuery } from "../../graphql";
import useOfflineContentTopic from "../../hooks/offline/useofflinecontenttopic";
import useTranslation from "../../hooks/usetranslation";
import Button from "@mui/material/Button";
import { DownloadIcon } from "../icons/MANIcons";
import Box from "@mui/material/Box";

type Props = {
    onClose(): void;
};

const OfflineHandler: React.FC<Props> = ({ onClose }) => {
    const { t } = useTranslation();
    const { locale } = useLocale();
    const [fetching, setFetching] = useState(false);
    useOfflineQuery({ requestPolicy: "network-only", variables: { locale } });
    const fetchVehicles = useOfflineVehicles();
    const fetchEmobility = useOfflineContentTopic(ContentTypeGroup.Emobility);
    const fetchTransportSolutions = useOfflineContentTopic(ContentTypeGroup.Transportsolutions);

    const startDownload = async () => {
        setFetching(true);
        await fetchVehicles();
        await fetchEmobility();
        await fetchTransportSolutions();
        setFetching(false);
        onClose();
    };

    return (
        <>
            <DialogHead title={t("make_offline")} subTitle={t("download_data")} onClose={onClose} />
            {fetching ? (
                <Spinner />
            ) : (
                <Box sx={{ display: "flex", alignItems: "center", mt: 4 }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<DownloadIcon />}
                        onClick={() => startDownload()}
                    >
                        {t("start_download")}
                    </Button>
                    <Button onClick={onClose}>{t("cancel")}</Button>
                </Box>
            )}
        </>
    );
};

export default OfflineHandler;
