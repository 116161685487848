// Copyright (C) 2022 TANNER AG

import { Route, Routes } from "react-router-dom";

import PageView from "./PageView";
import PageTopic from "./topic/PageTopic";

const PageRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<PageView />} />
            <Route path="/:tabId" element={<PageView />} />
            <Route path="/:topicType/:topicKey" element={<PageTopic />} />
        </Routes>
    );
};

export default PageRouter;
