// Copyright (C) 2022 TANNER AG

import React from "react";

import { VehicleTopic } from "../../../graphql";
import PageTopicPdf from "./PageTopicPdf";

type Props = {
    items: Pick<VehicleTopic, "id" | "key" | "type">[];
};

const PagePdf: React.FC<Props> = ({ items }) => {
    return (
        <div id="print-container">
            {items.map((item) => (
                <PageTopicPdf key={item.id} item={item} />
            ))}
        </div>
    );
};

export default PagePdf;
