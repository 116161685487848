// Copyright (C) 2022 TANNER AG

import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

type Props = {
    children?: React.ReactNode;
};

const ContentInfo: React.FC<Props> = ({ children }) => {
    return (
        <Box sx={{ display: "flex", my: 2.5, justifyContent: "space-between" }}>
            <Bracket />
            <Box sx={{ flexGrow: 1, py: 3 }}>
                <Typography variant="h3" textTransform="uppercase" sx={{ mb: 2 }}>
                    MAN Info
                </Typography>
                {children}
            </Box>
            <Bracket />
        </Box>
    );
};

export const Bracket = styled("div")(({ theme }) => ({
    width: 24,
    border: "2px solid",
    borderColor: theme.palette.grey[200],
    "&:first-of-type": {
        borderRight: "none",
        marginRight: theme.spacing(1)
    },
    "&:last-of-type": {
        borderLeft: "none",
        marginLeft: theme.spacing(1)
    }
}));

export default ContentInfo;
