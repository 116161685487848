// Copyright (C) 2022 TANNER AG

import React from "react";
import { Link } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import HeroIcon from "../icons/HeroIcons";
import { routes } from "../../lib/utils";

type Props = {
    name?: string;
    icon?: string;
    slug: string;
    disabled?: boolean;
    onClick?(): void;
};

const CategoryListItem: React.FC<Props> = ({ name, slug, icon = "", onClick, disabled }) => {
    return (
        <ListItemButton
            component={Link}
            onClick={onClick}
            to={`${routes.dsg.products}/${slug}`}
            disabled={disabled}
            sx={{
                backgroundColor: "background.paper",
                borderRadius: 1,
                minHeight: 64,
                mb: 1,
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.24)",
                px: 1,
                transition: "all .3s",
                "&:hover": {
                    color: "secondary.main",
                    backgroundColor: "background.paper",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.24)"
                }
            }}
        >
            <ListItemIcon sx={{ minWidth: 56, color: "inherit" }}>
                <HeroIcon iconName={icon} color="inherit" sx={{ fontSize: 48 }} />
            </ListItemIcon>
            <ListItemText
                primary={name}
                primaryTypographyProps={{
                    variant: "h5"
                }}
            />
        </ListItemButton>
    );
};

export default CategoryListItem;
