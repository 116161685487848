// Copyright (C) 2022 TANNER AG

import React from "react";
import Chip from "@mui/material/Chip";

type Props = {
    status?: string;
};

const ProjectStatus: React.FC<Props> = ({ status }) => {
    if (!status) {
        return null;
    }

    return (
        <Chip
            label={status}
            className={status}
            size="small"
            sx={{
                color: "common.white",
                "&.open": { bgcolor: "grey.500" },
                "&.progress": { bgcolor: "secondary.main" },
                "&.done": { bgcolor: "success.main" }
            }}
        />
    );
};

export default ProjectStatus;
