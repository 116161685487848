// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Link from "@mui/material/Link";
import useTranslation from "../../../hooks/usetranslation";
import ListItemRegularAction from "./ListItemRegularAction";
import { To } from "react-router-dom";

type Props = {
    title?: string;
    description?: string;
    action?: React.ReactNode;
    additional?: React.ReactNode;
    children?: React.ReactNode;
    to?: To;
    href?: string;
    onClick?(): void;
};

const ListItemRegularMobile: React.FC<Props> = ({
    title,
    description,
    action,
    additional,
    children,
    to,
    href,
    onClick
}) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    return (
        <Stack>
            <ListItemRegularAction onClick={onClick} href={href} to={to}>
                <Typography
                    variant="h4"
                    sx={{ lineHeight: "24px", fontSize: 18, color: "inherit" }}
                    className="heading"
                >
                    {title}
                </Typography>
            </ListItemRegularAction>
            <Stack direction="row" sx={{ alignItems: "center" }}>
                <Link onClick={() => setOpen((open) => !open)} variant="body2" sx={{ mr: "auto" }}>
                    {open ? t("hide_details") : t("show_details")}
                </Link>
                {action}
            </Stack>
            <Collapse in={open}>
                <Stack sx={{ mt: 1 }}>
                    <Typography
                        variant="body2"
                        sx={{ pr: 2 }}
                        dangerouslySetInnerHTML={{ __html: description ?? "" }}
                        color="textSecondary"
                        component="div"
                        gutterBottom
                    />
                    {children}
                </Stack>
                <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center", mt: 0.5 }}>
                    <div>{additional}</div>
                </Stack>
            </Collapse>
        </Stack>
    );
};

export default ListItemRegularMobile;
