// Copyright (C) 2023 TANNER AG

import gql from "graphql-tag";
import * as Urql from "urql";

export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    JSON: any;
    Locale: string;
    Upload: any;
};

export type Asset = {
    __typename?: "Asset";
    busTypes?: Maybe<Array<Scalars["String"]>>;
    countries?: Maybe<Array<Scalars["String"]>>;
    date?: Maybe<Scalars["String"]>;
    expert?: Maybe<Scalars["String"]>;
    fileName?: Maybe<Scalars["String"]>;
    fileSize?: Maybe<Scalars["String"]>;
    groupColor?: Maybe<Scalars["String"]>;
    groups?: Maybe<Array<Scalars["String"]>>;
    id: Scalars["ID"];
    languageNames?: Maybe<Array<Maybe<Scalars["String"]>>>;
    languages?: Maybe<Array<Scalars["String"]>>;
    series?: Maybe<Array<Scalars["String"]>>;
    solutions?: Maybe<Array<Scalars["String"]>>;
    tags: Array<Scalars["String"]>;
    title?: Maybe<Scalars["String"]>;
    type?: Maybe<Scalars["String"]>;
    uploader?: Maybe<Scalars["String"]>;
};

export type AssetList = {
    __typename?: "AssetList";
    assets: Array<Asset>;
    filters: Array<Filter>;
    total: Scalars["Int"];
};

export type Calculation = {
    __typename?: "Calculation";
    data?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    modified?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    visible?: Maybe<Scalars["Boolean"]>;
};

export type CmsChild = CmsSubPage | DownloadPreset | Vehicle;

export type CmsPage = {
    __typename?: "CmsPage";
    children: Array<CmsChild>;
    description?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    title?: Maybe<Scalars["String"]>;
};

export type CmsSubPage = {
    __typename?: "CmsSubPage";
    author?: Maybe<Scalars["String"]>;
    contentBlocks: Maybe<Array<NewsContentBlock>>;
    groupColor?: Maybe<Scalars["String"]>;
    groups?: Maybe<Array<Scalars["String"]>>;
    id: Scalars["ID"];
    imagesMetadata?: Maybe<Array<Maybe<MetaData>>>;
    publication?: Maybe<Scalars["String"]>;
    slug: Scalars["String"];
    tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
    title?: Maybe<Scalars["String"]>;
};

export type ContentGroup = {
    __typename?: "ContentGroup";
    content?: Maybe<Scalars["String"]>;
    description?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    image?: Maybe<Scalars["String"]>;
    title: Scalars["String"];
    topics: Array<ContentTopic>;
};

export type ContentSection = {
    __typename?: "ContentSection";
    content?: Maybe<Scalars["String"]>;
    gallery: Array<Image>;
    hotspots: Array<Hotspot>;
    id: Scalars["ID"];
    image?: Maybe<Scalars["String"]>;
    no?: Maybe<Scalars["Int"]>;
    slug: Scalars["String"];
    title?: Maybe<Scalars["String"]>;
    twoColumns?: Maybe<Scalars["Boolean"]>;
    type: Scalars["String"];
};

export type ContentTopic = {
    __typename?: "ContentTopic";
    description?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    image?: Maybe<Scalars["String"]>;
    key: Scalars["String"];
    no?: Maybe<Scalars["Int"]>;
    parent?: Maybe<ContentTopic>;
    sections: Array<ContentSection>;
    title: Scalars["String"];
    topics: Array<ContentTopic>;
    type?: Maybe<Scalars["String"]>;
};

export enum ContentTypeGroup {
    Emobility = "emobility",
    Transportsolutions = "transportsolutions"
}

export type Countries = {
    __typename?: "Countries";
    countries?: Maybe<Array<Option>>;
    focusCountries?: Maybe<Array<Scalars["String"]>>;
};

export type Download = {
    __typename?: "Download";
    id: Scalars["ID"];
    mime?: Maybe<Scalars["String"]>;
    modified?: Maybe<Scalars["String"]>;
    name?: Maybe<Scalars["String"]>;
    size?: Maybe<Scalars["Float"]>;
};

export type DownloadPreset = {
    __typename?: "DownloadPreset";
    filter: Array<Filter>;
    id: Scalars["ID"];
    query?: Maybe<Scalars["String"]>;
    title?: Maybe<Scalars["String"]>;
};

export type Filter = {
    __typename?: "Filter";
    key: Scalars["String"];
    values: Array<FilterValue>;
};

export type FilterInput = {
    key: Scalars["String"];
    values: Array<Scalars["String"]>;
};

export type FilterValue = {
    __typename?: "FilterValue";
    active: Scalars["Boolean"];
    name?: Maybe<Scalars["String"]>;
    value: Scalars["String"];
};

export type Health = {
    __typename?: "Health";
    health?: Maybe<Scalars["Boolean"]>;
    userAgent?: Maybe<Scalars["String"]>;
};

export type Hotspot = {
    __typename?: "Hotspot";
    content?: Maybe<Scalars["String"]>;
    posX?: Maybe<Scalars["Int"]>;
    posY?: Maybe<Scalars["Int"]>;
    title?: Maybe<Scalars["String"]>;
};

export type Image = {
    __typename?: "Image";
    description?: Maybe<Scalars["String"]>;
    file: Scalars["String"];
    id: Scalars["ID"];
    title?: Maybe<Scalars["String"]>;
};

export type KeyValue = {
    __typename?: "KeyValue";
    name?: Maybe<Scalars["String"]>;
    value?: Maybe<Scalars["String"]>;
};

export type Link = {
    __typename?: "Link";
    href?: Maybe<Scalars["String"]>;
    title?: Maybe<Scalars["String"]>;
};

export type LocalExpert = {
    __typename?: "LocalExpert";
    countries: Array<Scalars["String"]>;
    details?: Maybe<Scalars["String"]>;
    email?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    name?: Maybe<Scalars["String"]>;
    phone?: Maybe<Scalars["String"]>;
};

export type LubStatus = {
    __typename?: "LubStatus";
    data: Array<Array<Scalars["String"]>>;
    headers: Array<Scalars["String"]>;
};

export type MetaData = {
    __typename?: "MetaData";
    id: Scalars["ID"];
    metadata?: Maybe<Array<KeyValue>>;
};

export type Mutation = {
    __typename?: "Mutation";
    createProject?: Maybe<Project>;
    createProjectCalculation?: Maybe<Project>;
    deleteProject?: Maybe<Project>;
    updateProfile?: Maybe<User>;
    updateProject?: Maybe<Project>;
    updateProjectCalculation?: Maybe<Project>;
    updateProjectFile?: Maybe<Project>;
    updateProjectProduct?: Maybe<Project>;
    uploadProjectFile?: Maybe<Project>;
};

export type MutationCreateProjectArgs = {
    data: ProjectDataInput;
};

export type MutationCreateProjectCalculationArgs = {
    data: ProjectCalcDataInput;
    where: ProjectWhereInput;
};

export type MutationDeleteProjectArgs = {
    where: ProjectWhereInput;
};

export type MutationUpdateProfileArgs = {
    data?: InputMaybe<ProfileUpdate>;
};

export type MutationUpdateProjectArgs = {
    data: ProjectDataInput;
    where: ProjectWhereInput;
};

export type MutationUpdateProjectCalculationArgs = {
    data: ProjectCalcDataInput;
    where: ProjectCalcWhereInput;
};

export type MutationUpdateProjectFileArgs = {
    data: ProjectFileDataInput;
    where: ProjectWhereInput;
};

export type MutationUpdateProjectProductArgs = {
    data: ProjectProductDataInput;
    where: ProjectWhereInput;
};

export type MutationUploadProjectFileArgs = {
    file: Scalars["Upload"];
    where: ProjectWhereInput;
};

export type NavItem = {
    __typename?: "NavItem";
    additionalLinks?: Maybe<Array<NavItem>>;
    children?: Maybe<Array<NavItem>>;
    enabled?: Maybe<Scalars["Boolean"]>;
    id: Scalars["ID"];
    image?: Maybe<Scalars["String"]>;
    offline?: Maybe<Scalars["Boolean"]>;
    title: Scalars["String"];
    url?: Maybe<Scalars["String"]>;
};

export type NavLink = {
    __typename?: "NavLink";
    label?: Maybe<Scalars["String"]>;
    url?: Maybe<Scalars["String"]>;
};

export enum NavType {
    Home = "home",
    Menu = "menu"
}

export type News = {
    __typename?: "News";
    author: Scalars["String"];
    categories: Array<Scalars["String"]>;
    contentBlocks: Array<NewsContentBlock>;
    description?: Maybe<Scalars["String"]>;
    groupColor?: Maybe<Scalars["String"]>;
    groups?: Maybe<Array<Scalars["String"]>>;
    id: Scalars["ID"];
    imagesMetadata?: Maybe<Array<Maybe<MetaData>>>;
    publication?: Maybe<Scalars["String"]>;
    slug: Scalars["String"];
    tags?: Maybe<Array<Scalars["String"]>>;
    thumbnail?: Maybe<Scalars["String"]>;
    title: Scalars["String"];
};

export type NewsCategory = {
    __typename?: "NewsCategory";
    id: Scalars["String"];
    name: Scalars["String"];
};

export type NewsContentBlock = {
    __typename?: "NewsContentBlock";
    assets: Array<Asset>;
    assetsTitle?: Maybe<Scalars["String"]>;
    content?: Maybe<Scalars["String"]>;
    hotspots: Array<Hotspot>;
    image?: Maybe<Scalars["String"]>;
    lubStatusHtml?: Maybe<LubStatus>;
    title?: Maybe<Scalars["String"]>;
};

export type NewsList = {
    __typename?: "NewsList";
    news: Array<News>;
    total: Scalars["Int"];
};

export type Option = {
    __typename?: "Option";
    label: Scalars["String"];
    value: Scalars["String"];
};

export type Podcast = {
    __typename?: "Podcast";
    description?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    keywords?: Maybe<Array<Scalars["String"]>>;
    longDescription?: Maybe<Scalars["String"]>;
    mediaType?: Maybe<Scalars["String"]>;
    mediaUrl?: Maybe<Scalars["String"]>;
    publication?: Maybe<Scalars["String"]>;
    thumbnail?: Maybe<Scalars["String"]>;
    title: Scalars["String"];
};

export type Product = {
    __typename?: "Product";
    availableFrom?: Maybe<Scalars["String"]>;
    availableTo?: Maybe<Scalars["String"]>;
    benefits: Array<Scalars["String"]>;
    category?: Maybe<ProductCategory>;
    descriptionExternal?: Maybe<Scalars["String"]>;
    descriptionInternal?: Maybe<Scalars["String"]>;
    details: Array<Scalars["String"]>;
    downloads: Array<Download>;
    group?: Maybe<ProductGroup>;
    id: Scalars["ID"];
    image?: Maybe<Scalars["String"]>;
    information: Array<Link>;
    intro?: Maybe<Scalars["String"]>;
    name?: Maybe<Scalars["String"]>;
    readiness?: Maybe<Scalars["Int"]>;
    regionalAvailability?: Maybe<Array<Scalars["String"]>>;
    slug?: Maybe<Scalars["String"]>;
};

export type ProductCategory = {
    __typename?: "ProductCategory";
    groups?: Maybe<Array<ProductGroup>>;
    hasGroups?: Maybe<Scalars["Boolean"]>;
    icon?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    name?: Maybe<Scalars["String"]>;
    silo?: Maybe<Scalars["String"]>;
    slug?: Maybe<Scalars["String"]>;
};

export type ProductGroup = {
    __typename?: "ProductGroup";
    descriptionExternal?: Maybe<Scalars["String"]>;
    descriptionInternal?: Maybe<Scalars["String"]>;
    downloads: Array<Download>;
    id: Scalars["ID"];
    image?: Maybe<Scalars["String"]>;
    information: Array<Link>;
    name?: Maybe<Scalars["String"]>;
    products: Array<Product>;
    readiness?: Maybe<Scalars["Int"]>;
    related: Array<RelatedContent>;
    slug?: Maybe<Scalars["String"]>;
    wowBullets: Array<Scalars["String"]>;
};

export type ProfileUpdate = {
    city: Scalars["String"];
    comment?: InputMaybe<Scalars["String"]>;
    company: Scalars["String"];
    country: Scalars["String"];
    firstname: Scalars["String"];
    infoletter?: InputMaybe<Scalars["Boolean"]>;
    language: Scalars["String"];
    lastname: Scalars["String"];
    newsflash?: InputMaybe<Scalars["Boolean"]>;
    password: Scalars["String"];
    position: Scalars["String"];
    salutation?: InputMaybe<Scalars["String"]>;
    street: Scalars["String"];
    zipcode: Scalars["String"];
};

export type Project = {
    __typename?: "Project";
    calculations: Array<Calculation>;
    created?: Maybe<Scalars["String"]>;
    customerIntro?: Maybe<Scalars["String"]>;
    notes?: Maybe<Scalars["String"]>;
    customerPassword?: Maybe<Scalars["String"]>;
    deadline?: Maybe<Scalars["String"]>;
    deliveryYear?: Maybe<Scalars["Int"]>;
    downloads: Array<ProjectDownloads>;
    gpa?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    lub?: Maybe<Scalars["String"]>;
    modified?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    orderValue?: Maybe<Scalars["Float"]>;
    products: Array<ProjectProducts>;
    proposal?: Maybe<Scalars["Boolean"]>;
    publication?: Maybe<Scalars["String"]>;
    status?: Maybe<Scalars["String"]>;
    tenderCountry?: Maybe<Scalars["String"]>;
    uuid?: Maybe<Scalars["String"]>;
    vehicleAmount?: Maybe<Scalars["Int"]>;
};

export type ProjectCalcDataInput = {
    data?: InputMaybe<Scalars["String"]>;
    mode?: InputMaybe<UpdateMode>;
    name?: InputMaybe<Scalars["String"]>;
    visible?: InputMaybe<Scalars["Boolean"]>;
};

export type ProjectCalcWhereInput = {
    calcId: Scalars["String"];
    projectId: Scalars["String"];
};

export type ProjectDataInput = {
    customerIntro?: InputMaybe<Scalars["String"]>;
    notes?: InputMaybe<Scalars["String"]>;
    customerPassword?: InputMaybe<Scalars["String"]>;
    deadline?: InputMaybe<Scalars["String"]>;
    deliveryYear?: InputMaybe<Scalars["Int"]>;
    gpa?: InputMaybe<Scalars["String"]>;
    lub?: InputMaybe<Scalars["String"]>;
    name?: InputMaybe<Scalars["String"]>;
    orderValue?: InputMaybe<Scalars["Float"]>;
    proposal?: InputMaybe<Scalars["Boolean"]>;
    publication?: InputMaybe<Scalars["String"]>;
    status?: InputMaybe<Scalars["String"]>;
    tenderCountry?: InputMaybe<Scalars["String"]>;
    vehicleAmount?: InputMaybe<Scalars["Int"]>;
};

export type ProjectDownloads = {
    __typename?: "ProjectDownloads";
    item: Download;
    visible: Scalars["Boolean"];
};

export type ProjectFileDataInput = {
    fileId: Scalars["String"];
    mode?: InputMaybe<UpdateMode>;
    visible?: InputMaybe<Scalars["Boolean"]>;
};

export type ProjectList = {
    __typename?: "ProjectList";
    projects: Array<Project>;
    total?: Maybe<Scalars["Int"]>;
};

export type ProjectProductDataInput = {
    mode?: InputMaybe<UpdateMode>;
    productId: Scalars["String"];
    visible?: InputMaybe<Scalars["Boolean"]>;
};

export type ProjectProducts = {
    __typename?: "ProjectProducts";
    item: Product;
    visible: Scalars["Boolean"];
};

export type ProjectWhereInput = {
    id: Scalars["String"];
};

export type ProjectsWhereInput = {
    query?: InputMaybe<Scalars["String"]>;
    status?: InputMaybe<Scalars["String"]>;
};

export type Query = {
    __typename?: "Query";
    asset?: Maybe<Asset>;
    assets?: Maybe<AssetList>;
    contentGroup?: Maybe<ContentGroup>;
    contentTopic?: Maybe<ContentTopic>;
    countries?: Maybe<Countries>;
    featuredNav: Array<NavItem>;
    health?: Maybe<Health>;
    nav: Array<NavItem>;
    news?: Maybe<NewsList>;
    newsCategories: Array<NewsCategory>;
    newsItem?: Maybe<News>;
    page?: Maybe<CmsPage>;
    podcast?: Maybe<Podcast>;
    podcasts: Array<Podcast>;
    product?: Maybe<Product>;
    productCategories: Array<ProductCategory>;
    productCategory?: Maybe<ProductCategory>;
    productExperts: Array<LocalExpert>;
    productGroup?: Maybe<ProductGroup>;
    project?: Maybe<Project>;
    projectCalculation?: Maybe<Calculation>;
    projects?: Maybe<ProjectList>;
    relatedContent?: Maybe<RelatedContent>;
    search?: Maybe<Search>;
    status?: Maybe<Array<Option>>;
    tool?: Maybe<Tool>;
    tools: Array<Tool>;
    user?: Maybe<User>;
    vehicle?: Maybe<Vehicle>;
    vehicleTopic?: Maybe<VehicleTopic>;
    vehicleTopics?: Maybe<VehicleTopics>;
    vehicleTopicsAll: Array<VehicleTopic>;
    vehicles: Array<Vehicle>;
};

export type QueryAssetArgs = {
    id: Scalars["String"];
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type QueryAssetsArgs = {
    filters?: InputMaybe<Array<FilterInput>>;
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["Locale"]>;
    page?: InputMaybe<Scalars["Int"]>;
    search?: InputMaybe<Scalars["String"]>;
    sort?: InputMaybe<SortMethod>;
};

export type QueryContentGroupArgs = {
    group: ContentTypeGroup;
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type QueryContentTopicArgs = {
    group: ContentTypeGroup;
    keys: Array<Scalars["String"]>;
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type QueryCountriesArgs = {
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type QueryFeaturedNavArgs = {
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type QueryNavArgs = {
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type QueryNewsArgs = {
    categories?: InputMaybe<Array<Scalars["String"]>>;
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["Locale"]>;
    page?: InputMaybe<Scalars["Int"]>;
    query?: InputMaybe<Scalars["String"]>;
};

export type QueryNewsCategoriesArgs = {
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type QueryNewsItemArgs = {
    locale?: InputMaybe<Scalars["Locale"]>;
    slug?: InputMaybe<Scalars["String"]>;
};

export type QueryPageArgs = {
    id: Scalars["Int"];
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type QueryPodcastArgs = {
    id: Scalars["String"];
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type QueryPodcastsArgs = {
    limit?: InputMaybe<Scalars["Int"]>;
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type QueryProductArgs = {
    key: Scalars["String"];
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type QueryProductCategoriesArgs = {
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type QueryProductCategoryArgs = {
    key: Scalars["String"];
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type QueryProductExpertsArgs = {
    key: Scalars["String"];
};

export type QueryProductGroupArgs = {
    key: Scalars["String"];
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type QueryProjectArgs = {
    id: Scalars["String"];
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type QueryProjectCalculationArgs = {
    where: ProjectCalcWhereInput;
};

export type QueryProjectsArgs = {
    locale?: InputMaybe<Scalars["Locale"]>;
    where?: InputMaybe<ProjectsWhereInput>;
};

export type QueryRelatedContentArgs = {
    id: Scalars["String"];
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type QuerySearchArgs = {
    locale?: InputMaybe<Scalars["Locale"]>;
    where: SearchWhereInput;
};

export type QueryStatusArgs = {
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type QueryToolArgs = {
    id: Scalars["String"];
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type QueryToolsArgs = {
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type QueryVehicleArgs = {
    id: Scalars["String"];
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type QueryVehicleTopicArgs = {
    key: Scalars["String"];
    locale?: InputMaybe<Scalars["Locale"]>;
    type: Scalars["String"];
};

export type QueryVehicleTopicsArgs = {
    id: Scalars["String"];
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type QueryVehicleTopicsAllArgs = {
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type QueryVehiclesArgs = {
    locale?: InputMaybe<Scalars["Locale"]>;
};

export type RegisterData = {
    bodyBuilder?: InputMaybe<Scalars["Boolean"]>;
    city: Scalars["String"];
    comment?: InputMaybe<Scalars["String"]>;
    company: Scalars["String"];
    country: Scalars["String"];
    email: Scalars["String"];
    firstname: Scalars["String"];
    infoletter?: InputMaybe<Scalars["Boolean"]>;
    language: Scalars["String"];
    lastname: Scalars["String"];
    newsflash?: InputMaybe<Scalars["Boolean"]>;
    password: Scalars["String"];
    position: Scalars["String"];
    salesDepartment?: InputMaybe<Scalars["Boolean"]>;
    salutation?: InputMaybe<Scalars["String"]>;
    street: Scalars["String"];
    truckBus?: InputMaybe<Scalars["Boolean"]>;
    zipcode: Scalars["String"];
};

export type RelatedContent = {
    __typename?: "RelatedContent";
    description?: Maybe<Scalars["String"]>;
    details: Array<Scalars["String"]>;
    experts: Array<LocalExpert>;
    id: Scalars["ID"];
    image?: Maybe<Scalars["String"]>;
    information: Array<Link>;
    intro?: Maybe<Scalars["String"]>;
    name?: Maybe<Scalars["String"]>;
    downloads: Array<Download>;
};

export type Search = {
    __typename?: "Search";
    hits: Array<SearchHit>;
    total: Scalars["Int"];
};

export type SearchBreadcrumb = {
    __typename?: "SearchBreadcrumb";
    id: Scalars["ID"];
    key: Scalars["String"];
    title?: Maybe<Scalars["String"]>;
};

export type SearchHit = {
    __typename?: "SearchHit";
    breadcrumbs: Array<SearchBreadcrumb>;
    description?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    key: Scalars["String"];
    title: Scalars["String"];
    type: Scalars["String"];
};

export type SearchWhereInput = {
    limit?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    q: Scalars["String"];
};

export enum SortMethod {
    Relevance = "relevance",
    Latest = "latest",
    TitleAsc = "titleAsc",
    TitleDesc = "titleDesc"
}

export type Tool = {
    __typename?: "Tool";
    description?: Maybe<Scalars["String"]>;
    details: Array<Scalars["String"]>;
    downloads: Array<Download>;
    experts: Array<LocalExpert>;
    id: Scalars["ID"];
    image?: Maybe<Scalars["String"]>;
    information: Array<Link>;
    intro?: Maybe<Scalars["String"]>;
    keywords: Array<Scalars["String"]>;
    name: Scalars["String"];
    url?: Maybe<Scalars["String"]>;
};

export enum UpdateMode {
    Remove = "remove",
    Update = "update"
}

export type User = {
    __typename?: "User";
    avatar?: Maybe<Scalars["String"]>;
    city: Scalars["String"];
    comment?: Maybe<Scalars["String"]>;
    company: Scalars["String"];
    country: Scalars["String"];
    email?: Maybe<Scalars["String"]>;
    firstname?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    infoletter?: Maybe<Scalars["Boolean"]>;
    language: Scalars["String"];
    lastname?: Maybe<Scalars["String"]>;
    newsflash?: Maybe<Scalars["Boolean"]>;
    position: Scalars["String"];
    salutation?: Maybe<Scalars["String"]>;
    street: Scalars["String"];
    username: Scalars["String"];
    zipcode: Scalars["String"];
    groups:Array<Scalars["String"]>;
    groupColor: Scalars["String"];
    popupType?: Scalars["String"];
    daysRemain?: Scalars["String"];
};

export type Vehicle = {
    __typename?: "Vehicle";
    id: Scalars["ID"];
    name: Scalars["String"];
    topics?: Maybe<VehicleTopics>;
};

export type VehicleTopic = {
    __typename?: "VehicleTopic";
    description?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    key: Scalars["String"];
    relatedTopics?: Maybe<Array<Scalars["Int"]>>;
    sections: Array<ContentSection>;
    subtitle?: Maybe<Scalars["String"]>;
    title: Scalars["String"];
    totalUsage?: Maybe<Scalars["Int"]>;
    type: Scalars["String"];
};

export type VehicleTopics = {
    __typename?: "VehicleTopics";
    benefits: Array<VehicleTopic>;
    components: Array<VehicleTopic>;
    topics: Array<VehicleTopic>;
};

export type CreateProjectMutationVariables = Exact<{
    data: ProjectDataInput;
}>;

export type CreateProjectMutation = {
    __typename?: "Mutation";
    createProject?:
        | {
              __typename?: "Project";
              id: string;
              name: string;
              gpa?: string | undefined;
              tenderCountry?: string | undefined;
              status?: string | undefined;
              created?: string | undefined;
              modified?: string | undefined;
              vehicleAmount?: number | undefined;
              deliveryYear?: number | undefined;
              lub?: string | undefined;
              orderValue?: number | undefined;
              proposal?: boolean | undefined;
              publication?: string | undefined;
              deadline?: string | undefined;
              downloads: Array<{
                  __typename?: "ProjectDownloads";
                  visible: boolean;
                  item: { __typename?: "Download"; id: string; name?: string | undefined };
              }>;
              products: Array<{
                  __typename?: "ProjectProducts";
                  visible: boolean;
                  item: {
                      __typename?: "Product";
                      id: string;
                      name?: string | undefined;
                      image?: string | undefined;
                      intro?: string | undefined;
                      details: Array<string>;
                      availableFrom?: string | undefined;
                      availableTo?: string | undefined;
                  };
              }>;
          }
        | undefined;
};

export type CreateProjectCalcMutationVariables = Exact<{
    where: ProjectWhereInput;
    data: ProjectCalcDataInput;
}>;

export type CreateProjectCalcMutation = {
    __typename?: "Mutation";
    createProjectCalculation?:
        | {
              __typename?: "Project";
              id: string;
              name: string;
              gpa?: string | undefined;
              tenderCountry?: string | undefined;
              status?: string | undefined;
              created?: string | undefined;
              modified?: string | undefined;
              vehicleAmount?: number | undefined;
              deliveryYear?: number | undefined;
              lub?: string | undefined;
              orderValue?: number | undefined;
              proposal?: boolean | undefined;
              publication?: string | undefined;
              deadline?: string | undefined;
          }
        | undefined;
};

export type DeleteProjectMutationVariables = Exact<{
    where: ProjectWhereInput;
}>;

export type DeleteProjectMutation = {
    __typename?: "Mutation";
    deleteProject?:
        | {
              __typename?: "Project";
              id: string;
              name: string;
              gpa?: string | undefined;
              tenderCountry?: string | undefined;
              status?: string | undefined;
              created?: string | undefined;
              modified?: string | undefined;
              vehicleAmount?: number | undefined;
              deliveryYear?: number | undefined;
              lub?: string | undefined;
              orderValue?: number | undefined;
              proposal?: boolean | undefined;
              publication?: string | undefined;
              deadline?: string | undefined;
              downloads: Array<{
                  __typename?: "ProjectDownloads";
                  visible: boolean;
                  item: { __typename?: "Download"; id: string; name?: string | undefined };
              }>;
              products: Array<{
                  __typename?: "ProjectProducts";
                  visible: boolean;
                  item: {
                      __typename?: "Product";
                      id: string;
                      name?: string | undefined;
                      image?: string | undefined;
                      intro?: string | undefined;
                      details: Array<string>;
                  };
              }>;
          }
        | undefined;
};

export type NewsItemQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["Locale"]>;
    slug?: InputMaybe<Scalars["String"]>;
}>;

export type NewsItemQuery = {
    __typename?: "Query";
    newsItem?: InputMaybe<News> | undefined;
};

export type UpdateProfileMutationVariables = Exact<{
    data: ProfileUpdate;
}>;

export type UpdateProfileMutation = {
    __typename?: "Mutation";
    updateProfile?: { __typename?: "User"; id: string } | undefined;
};

export type UpdatePreferredLanguageMutationVariables = Exact<{
    preferredLanguage: Scalars["Locale"];
}>;

export type UpdatePreferredLanguageMutation = {
    __typename?: "Mutation";
    updatepreferredLanguage?: any;
};

export type UpdateProjectMutationVariables = Exact<{
    where: ProjectWhereInput;
    data: ProjectDataInput;
}>;

export type UpdateProjectMutation = {
    __typename?: "Mutation";
    updateProject?:
        | {
              __typename?: "Project";
              id: string;
              name: string;
              gpa?: string | undefined;
              tenderCountry?: string | undefined;
              status?: string | undefined;
              created?: string | undefined;
              modified?: string | undefined;
              vehicleAmount?: number | undefined;
              deliveryYear?: number | undefined;
              lub?: string | undefined;
              orderValue?: number | undefined;
              proposal?: boolean | undefined;
              publication?: string | undefined;
              deadline?: string | undefined;
              downloads: Array<{
                  __typename?: "ProjectDownloads";
                  visible: boolean;
                  item: { __typename?: "Download"; id: string; name?: string | undefined };
              }>;
              products: Array<{
                  __typename?: "ProjectProducts";
                  visible: boolean;
                  item: {
                      __typename?: "Product";
                      id: string;
                      name?: string | undefined;
                      image?: string | undefined;
                      intro?: string | undefined;
                      details: Array<string>;
                      availableFrom?: string | undefined;
                      availableTo?: string | undefined;
                  };
              }>;
          }
        | undefined;
};

export type UpdateProjectCalcMutationVariables = Exact<{
    where: ProjectCalcWhereInput;
    data: ProjectCalcDataInput;
}>;

export type UpdateProjectCalcMutation = {
    __typename?: "Mutation";
    updateProjectCalculation?:
        | {
              __typename?: "Project";
              id: string;
              name: string;
              gpa?: string | undefined;
              tenderCountry?: string | undefined;
              status?: string | undefined;
              created?: string | undefined;
              modified?: string | undefined;
              vehicleAmount?: number | undefined;
              deliveryYear?: number | undefined;
              lub?: string | undefined;
              orderValue?: number | undefined;
              proposal?: boolean | undefined;
              publication?: string | undefined;
              deadline?: string | undefined;
          }
        | undefined;
};

export type UpdateProjectFileMutationVariables = Exact<{
    where: ProjectWhereInput;
    data: ProjectFileDataInput;
}>;

export type UpdateProjectFileMutation = {
    __typename?: "Mutation";
    updateProjectFile?:
        | {
              __typename?: "Project";
              id: string;
              name: string;
              gpa?: string | undefined;
              tenderCountry?: string | undefined;
              status?: string | undefined;
              created?: string | undefined;
              modified?: string | undefined;
              vehicleAmount?: number | undefined;
              deliveryYear?: number | undefined;
              lub?: string | undefined;
              orderValue?: number | undefined;
              proposal?: boolean | undefined;
              publication?: string | undefined;
              deadline?: string | undefined;
              downloads: Array<{
                  __typename?: "ProjectDownloads";
                  visible: boolean;
                  item: { __typename?: "Download"; id: string; name?: string | undefined };
              }>;
              products: Array<{
                  __typename?: "ProjectProducts";
                  visible: boolean;
                  item: {
                      __typename?: "Product";
                      id: string;
                      name?: string | undefined;
                      image?: string | undefined;
                      intro?: string | undefined;
                      details: Array<string>;
                      availableTo?: string | undefined;
                      availableFrom?: string | undefined;
                  };
              }>;
          }
        | undefined;
};

export type UpdateProjectProductMutationVariables = Exact<{
    where: ProjectWhereInput;
    data: ProjectProductDataInput;
}>;

export type UpdateProjectProductMutation = {
    __typename?: "Mutation";
    updateProjectProduct?:
        | {
              __typename?: "Project";
              id: string;
              name: string;
              gpa?: string | undefined;
              tenderCountry?: string | undefined;
              status?: string | undefined;
              created?: string | undefined;
              modified?: string | undefined;
              vehicleAmount?: number | undefined;
              deliveryYear?: number | undefined;
              lub?: string | undefined;
              orderValue?: number | undefined;
              proposal?: boolean | undefined;
              publication?: string | undefined;
              deadline?: string | undefined;
              downloads: Array<{
                  __typename?: "ProjectDownloads";
                  visible: boolean;
                  item: { __typename?: "Download"; id: string; name?: string | undefined };
              }>;
              products: Array<{
                  __typename?: "ProjectProducts";
                  visible: boolean;
                  item: {
                      __typename?: "Product";
                      id: string;
                      name?: string | undefined;
                      image?: string | undefined;
                      intro?: string | undefined;
                      details: Array<string>;
                      availableFrom?: string | undefined;
                      availableTo?: string | undefined;
                  };
              }>;
          }
        | undefined;
};

export type UploadProjectFileMutationVariables = Exact<{
    where: ProjectWhereInput;
    file: Scalars["Upload"];
}>;

export type UploadProjectFileMutation = {
    __typename?: "Mutation";
    uploadProjectFile?:
        | {
              __typename?: "Project";
              id: string;
              name: string;
              gpa?: string | undefined;
              tenderCountry?: string | undefined;
              status?: string | undefined;
              created?: string | undefined;
              modified?: string | undefined;
              vehicleAmount?: number | undefined;
              deliveryYear?: number | undefined;
              lub?: string | undefined;
              orderValue?: number | undefined;
              proposal?: boolean | undefined;
              publication?: string | undefined;
              deadline?: string | undefined;
              downloads: Array<{
                  __typename?: "ProjectDownloads";
                  visible: boolean;
                  item: { __typename?: "Download"; id: string; name?: string | undefined };
              }>;
              products: Array<{
                  __typename?: "ProjectProducts";
                  visible: boolean;
                  item: {
                      __typename?: "Product";
                      id: string;
                      name?: string | undefined;
                      image?: string | undefined;
                      intro?: string | undefined;
                      details: Array<string>;
                      availableTo?: string | undefined;
                      availableFrom?: string | undefined;
                  };
              }>;
          }
        | undefined;
};

export type AssetQueryVariables = Exact<{
    id: Scalars["String"];
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type AssetQuery = {
    __typename?: "Query";
    asset?:
        | {
              __typename?: "Asset";
              busTypes?: Array<string> | undefined;
              countries?: Array<string> | undefined;
              date?: string | undefined;
              expert?: string | undefined;
              fileName?: string | undefined;
              fileSize?: string | undefined;
              groupColor?: string | undefined;
              id: string;
              languageNames?: Array<string | undefined> | undefined;
              languages?: Array<string> | undefined;
              series?: Array<string> | undefined;
              solutions?: Array<string> | undefined;
              tags: Array<string>;
              title?: string | undefined;
              type?: string | undefined;
              uploader?: string | undefined;
          }
        | undefined;
};

export type AssetsQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["Locale"]>;
    limit?: InputMaybe<Scalars["Int"]>;
    page?: InputMaybe<Scalars["Int"]>;
    filters?: InputMaybe<Array<FilterInput> | FilterInput>;
    search?: InputMaybe<Scalars["String"]>;
    sort?: InputMaybe<SortMethod>;
}>;

export type AssetsQuery = {
    __typename?: "Query";
    assets: AssetList;
};

export type ContentGroupQueryVariables = Exact<{
    group: ContentTypeGroup;
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type ContentGroupQuery = {
    __typename?: "Query";
    contentGroup?: InputMaybe<ContentGroup> | undefined;
};

export type ContentTopicQueryVariables = Exact<{
    group: ContentTypeGroup;
    keys: Array<Scalars["String"]> | Scalars["String"];
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type ContentTopicQuery = {
    __typename?: "Query";
    contentTopic?: InputMaybe<ContentTopic> | undefined;
};

export type FeaturedNavQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type FeaturedNavQuery = {
    __typename?: "Query";
    featuredNav: Array<NavItem>;
};

export type HealthQueryVariables = Exact<{ [key: string]: never }>;

export type HealthQuery = {
    __typename?: "Query";
    health?: { __typename?: "Health"; health?: boolean | undefined; userAgent?: string | undefined } | undefined;
};

export type NavQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type NavQuery = {
    __typename?: "Query";
    nav: Array<NavItem>;
};

export type NewsQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["Locale"]>;
    categories?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
    limit?: InputMaybe<Scalars["Int"]>;
    page?: InputMaybe<Scalars["Int"]>;
    query?: InputMaybe<Scalars["String"]>;
}>;

export type NewsQuery = {
    __typename?: "Query";
    news?: InputMaybe<NewsList> | undefined;
};

export type NewsCategoriesQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type NewsCategoriesQuery = {
    __typename?: "Query";
    newsCategories: Array<{ __typename?: "NewsCategory"; id: string; name: string }>;
};

export type NewsInfoQueryVariables = Exact<{ [key: string]: never }>;

export type NewsInfoQuery = { __typename?: "Query"; news?: { __typename?: "NewsList"; total: number } | undefined };

export type OfflineQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type OfflineQuery = {
    __typename?: "Query";
    home: Array<{
        __typename?: "NavItem";
        id: string;
        title: string;
        image?: string | undefined;
        enabled?: boolean | undefined;
        offline?: boolean | undefined;
        additionalLinks?: Array<{ __typename?: "NavItem"; title: string; url?: string | undefined }> | undefined;
        children?:
            | Array<{
                  __typename?: "NavItem";
                  id: string;
                  title: string;
                  image?: string | undefined;
                  enabled?: boolean | undefined;
                  offline?: boolean | undefined;
                  additionalLinks?:
                      | Array<{ __typename?: "NavItem"; title: string; url?: string | undefined }>
                      | undefined;
              }>
            | undefined;
    }>;
    user?:
        | {
              __typename?: "User";
              id: string;
              username: string;
              email?: string | undefined;
              firstname?: string | undefined;
              lastname?: string | undefined;
              avatar?: string | undefined;
              country: string;
          }
        | undefined;
};

export type PageQueryVariables = Exact<{
    id: Scalars["Int"];
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type PageQuery = {
    __typename?: "Query";
    page?: InputMaybe<CmsPage> | undefined;
};

export type PodcastQueryVariables = Exact<{
    id: Scalars["String"];
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type PodcastQuery = {
    __typename?: "Query";
    podcast?:
        | {
              __typename?: "Podcast";
              id: string;
              title: string;
              mediaType?: string | undefined;
              mediaUrl?: string | undefined;
              thumbnail?: string | undefined;
              description?: string | undefined;
              longDescription?: string | undefined;
              keywords?: Array<string> | undefined;
              publication?: string | undefined;
          }
        | undefined;
};

export type PodcastsQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["Locale"]>;
    limit?: InputMaybe<Scalars["Int"]>;
}>;

export type PodcastsQuery = {
    __typename?: "Query";
    podcasts: Array<{
        __typename?: "Podcast";
        id: string;
        title: string;
        mediaType?: string | undefined;
        thumbnail?: string | undefined;
        description?: string | undefined;
        keywords?: Array<string> | undefined;
        publication?: string | undefined;
    }>;
};

export type ProductQueryVariables = Exact<{
    key: Scalars["String"];
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type ProductQuery = {
    __typename?: "Query";
    product?: InputMaybe<Product> | undefined;
};

export type ProductCategoriesQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type ProductCategoriesQuery = {
    __typename?: "Query";
    productCategories: Array<ProductCategory>;
};

export type ProductCategoryQueryVariables = Exact<{
    key: Scalars["String"];
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type ProductCategoryQuery = {
    __typename?: "Query";
    productCategory?: InputMaybe<ProductCategory> | undefined;
};

export type ProductExpertsQueryVariables = Exact<{
    key: Scalars["String"];
}>;

export type ProductExpertsQuery = {
    __typename?: "Query";
    productExperts: Array<{
        __typename?: "LocalExpert";
        id: string;
        name?: string | undefined;
        email?: string | undefined;
        phone?: string | undefined;
        details?: string | undefined;
        countries: Array<string>;
    }>;
};

export type ProductGroupQueryVariables = Exact<{
    key: Scalars["String"];
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type ProductGroupQuery = {
    __typename?: "Query";
    productGroup?: InputMaybe<ProductGroup> | undefined;
};

export type ProjectQueryVariables = Exact<{
    id: Scalars["String"];
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type ProjectQuery = {
    __typename?: "Query";
    project?: InputMaybe<Project> | undefined;
};

export type ProjectCalcQueryVariables = Exact<{
    where: ProjectCalcWhereInput;
}>;

export type ProjectCalcQuery = {
    __typename?: "Query";
    projectCalculation?: InputMaybe<Calculation> | undefined;
};

export type ProjectOptionsQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type ProjectOptionsQuery = {
    __typename?: "Query";
    countries?: InputMaybe<Countries> | undefined;
    status?: Array<Option> | undefined;
};

export type ProjectsQueryVariables = Exact<{
    where?: InputMaybe<ProjectsWhereInput>;
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type ProjectsQuery = {
    __typename?: "Query";
    projects?:
        | {
              __typename?: "ProjectList";
              total?: number | undefined;
              projects: Array<{
                  __typename?: "Project";
                  id: string;
                  name: string;
                  gpa?: string | undefined;
                  tenderCountry?: string | undefined;
                  status?: string | undefined;
                  created?: string | undefined;
                  modified?: string | undefined;
              }>;
          }
        | undefined;
};

export type RelatedContentQueryVariables = Exact<{
    id: Scalars["String"];
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type RelatedContentQuery = {
    __typename?: "Query";
    relatedContent?:
        | {
              __typename?: "RelatedContent";
              id: string;
              name?: string | undefined;
              image?: string | undefined;
              intro?: string | undefined;
              description?: string | undefined;
              details: Array<string>;
              information: Array<{ __typename?: "Link"; title?: string | undefined; href?: string | undefined }>;
              downloads: Array<Download>;
              experts: Array<{
                  __typename?: "LocalExpert";
                  id: string;
                  name?: string | undefined;
                  email?: string | undefined;
                  phone?: string | undefined;
                  details?: string | undefined;
                  countries: Array<string>;
              }>;
          }
        | undefined;
};

export type SearchQueryVariables = Exact<{
    where: SearchWhereInput;
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type SearchQuery = {
    __typename?: "Query";
    search?:
        | {
              __typename?: "Search";
              total: number;
              hits: Array<{
                  __typename?: "SearchHit";
                  id: string;
                  key: string;
                  title: string;
                  type: string;
                  description?: string | undefined;
                  breadcrumbs: Array<{
                      __typename?: "SearchBreadcrumb";
                      id: string;
                      key: string;
                      title?: string | undefined;
                  }>;
              }>;
          }
        | undefined;
};

export type StatusQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type StatusQuery = {
    __typename?: "Query";
    status?: Array<{ __typename?: "Option"; label: string; value: string }> | undefined;
};

export type ToolQueryVariables = Exact<{
    id: Scalars["String"];
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type ToolQuery = {
    __typename?: "Query";
    tool?:
        | {
              __typename?: "Tool";
              id: string;
              name: string;
              intro?: string | undefined;
              description?: string | undefined;
              url?: string | undefined;
              image?: string | undefined;
              keywords: Array<string>;
              details: Array<string>;
              information: Array<{ __typename?: "Link"; title?: string | undefined; href?: string | undefined }>;
              downloads: Array<{
                  __typename?: "Download";
                  id: string;
                  name?: string | undefined;
                  modified?: string | undefined;
                  size?: number | undefined;
                  mime?: string | undefined;
              }>;
              experts: Array<{
                  __typename?: "LocalExpert";
                  id: string;
                  name?: string | undefined;
                  email?: string | undefined;
                  phone?: string | undefined;
                  details?: string | undefined;
                  countries: Array<string>;
              }>;
          }
        | undefined;
};

export type ToolsQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type ToolsQuery = {
    __typename?: "Query";
    tools: Array<{
        __typename?: "Tool";
        id: string;
        name: string;
        intro?: string | undefined;
        description?: string | undefined;
        url?: string | undefined;
        image?: string | undefined;
        keywords: Array<string>;
    }>;
};

export type LinkResolverResponse = {
    __typename?: "LinkResolver";
    success:Scalars["Boolean"];
    msg:Scalars["String"];
    url?:Scalars["String"];
    title?:Scalars["String"];
}

export type LinkResolverQuery ={
    __typename?: "Query";
    resolvedLink:Maybe<LinkResolverResponse>;
}

export type LinkResolverQueryVariables = Exact<{
    documentId:Scalars["String"];
    user:Scalars["String"];
}>;

export type UserQueryVariables = Exact<{ [key: string]: never }>;

export type UserQuery = {
    __typename?: "Query";
    user?: InputMaybe<User> | undefined;
};

export type VehicleQueryVariables = Exact<{
    id: Scalars["String"];
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type VehicleQuery = {
    __typename?: "Query";
    vehicle?:
        | {
              __typename?: "Vehicle";
              id: string;
              name: string;
              topics?:
                  | {
                        __typename?: "VehicleTopics";
                        topics: Array<{
                            __typename?: "VehicleTopic";
                            id: string;
                            key: string;
                            title: string;
                            type: string;
                            totalUsage?: number | undefined;
                            relatedTopics?: Array<number> | undefined;
                        }>;
                        components: Array<{
                            __typename?: "VehicleTopic";
                            id: string;
                            key: string;
                            title: string;
                            type: string;
                            totalUsage?: number | undefined;
                            relatedTopics?: Array<number> | undefined;
                        }>;
                        benefits: Array<{
                            __typename?: "VehicleTopic";
                            id: string;
                            key: string;
                            title: string;
                            type: string;
                            totalUsage?: number | undefined;
                            relatedTopics?: Array<number> | undefined;
                        }>;
                    }
                  | undefined;
          }
        | undefined;
};

export type VehiclesQueryVariables = Exact<{
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type VehiclesQuery = {
    __typename?: "Query";
    vehicles: Array<{ __typename?: "Vehicle"; id: string; name: string }>;
};

export type VehicleTopicQueryVariables = Exact<{
    key: Scalars["String"];
    type: Scalars["String"];
    locale?: InputMaybe<Scalars["Locale"]>;
}>;

export type VehicleTopicQuery = {
    __typename?: "Query";
    vehicleTopic?: InputMaybe<VehicleTopic> | undefined;
};

export const CreateProjectDocument = gql`
    mutation CreateProject($data: ProjectDataInput!) {
        createProject(data: $data) {
            id
            name
            gpa
            tenderCountry
            status
            created
            modified
            vehicleAmount
            deliveryYear
            lub
            orderValue
            proposal
            publication
            deadline
            downloads {
                visible
                item {
                    id
                    name
                }
            }
            products {
                visible
                item {
                    id
                    name
                    image
                    intro
                    details
                    availableFrom
                    availableTo
                }
            }
        }
    }
`;

export function useCreateProjectMutation() {
    return Urql.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument);
}
export const CreateProjectCalcDocument = gql`
    mutation CreateProjectCalc($where: ProjectWhereInput!, $data: ProjectCalcDataInput!) {
        createProjectCalculation(where: $where, data: $data) {
            id
            name
            gpa
            tenderCountry
            status
            created
            modified
            vehicleAmount
            deliveryYear
            lub
            orderValue
            proposal
            publication
            deadline
        }
    }
`;

export function useCreateProjectCalcMutation() {
    return Urql.useMutation<CreateProjectCalcMutation, CreateProjectCalcMutationVariables>(CreateProjectCalcDocument);
}
export const DeleteProjectDocument = gql`
    mutation DeleteProject($where: ProjectWhereInput!) {
        deleteProject(where: $where) {
            id
            name
            gpa
            tenderCountry
            status
            created
            modified
            vehicleAmount
            deliveryYear
            lub
            orderValue
            proposal
            publication
            deadline
            downloads {
                visible
                item {
                    id
                    name
                }
            }
            products {
                visible
                item {
                    id
                    name
                    image
                    intro
                    details
                }
            }
        }
    }
`;

export function useDeleteProjectMutation() {
    return Urql.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument);
}

export const UpdateProfileDocument = gql`
    mutation UpdateProfile($data: ProfileUpdate!) {
        updateProfile(data: $data) {
            id
        }
    }
`;

export function useUpdateProfileMutation() {
    return Urql.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument);
}
export const UpdateProjectDocument = gql`
    mutation UpdateProject($where: ProjectWhereInput!, $data: ProjectDataInput!) {
        updateProject(where: $where, data: $data) {
            id
            name
            gpa
            tenderCountry
            status
            created
            modified
            vehicleAmount
            deliveryYear
            lub
            orderValue
            proposal
            publication
            deadline
            downloads {
                visible
                item {
                    id
                    name
                }
            }
            products {
                visible
                item {
                    id
                    name
                    image
                    intro
                    details
                    availableFrom
                    availableTo
                }
            }
        }
    }
`;

export function useUpdateProjectMutation() {
    return Urql.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument);
}
export const UpdateProjectCalcDocument = gql`
    mutation UpdateProjectCalc($where: ProjectCalcWhereInput!, $data: ProjectCalcDataInput!) {
        updateProjectCalculation(where: $where, data: $data) {
            id
            name
            gpa
            tenderCountry
            status
            created
            modified
            vehicleAmount
            deliveryYear
            lub
            orderValue
            proposal
            publication
            deadline
        }
    }
`;

export function useUpdateProjectCalcMutation() {
    return Urql.useMutation<UpdateProjectCalcMutation, UpdateProjectCalcMutationVariables>(UpdateProjectCalcDocument);
}
export const UpdateProjectFileDocument = gql`
    mutation UpdateProjectFile($where: ProjectWhereInput!, $data: ProjectFileDataInput!) {
        updateProjectFile(where: $where, data: $data) {
            id
            name
            gpa
            tenderCountry
            status
            created
            modified
            vehicleAmount
            deliveryYear
            lub
            orderValue
            proposal
            publication
            deadline
            downloads {
                visible
                item {
                    id
                    name
                }
            }
            products {
                visible
                item {
                    id
                    name
                    image
                    intro
                    details
                    availableTo
                    availableFrom
                }
            }
        }
    }
`;

export function useUpdateProjectFileMutation() {
    return Urql.useMutation<UpdateProjectFileMutation, UpdateProjectFileMutationVariables>(UpdateProjectFileDocument);
}
export const UpdateProjectProductDocument = gql`
    mutation UpdateProjectProduct($where: ProjectWhereInput!, $data: ProjectProductDataInput!) {
        updateProjectProduct(where: $where, data: $data) {
            id
            name
            gpa
            tenderCountry
            status
            created
            modified
            vehicleAmount
            deliveryYear
            lub
            orderValue
            proposal
            publication
            deadline
            downloads {
                visible
                item {
                    id
                    name
                }
            }
            products {
                visible
                item {
                    id
                    name
                    image
                    intro
                    details
                    availableFrom
                    availableTo
                }
            }
        }
    }
`;

export function useUpdateProjectProductMutation() {
    return Urql.useMutation<UpdateProjectProductMutation, UpdateProjectProductMutationVariables>(
        UpdateProjectProductDocument
    );
}
export const UpdatePreferredLanguageDocument = gql`
    mutation UpdatepreferredLanguage($preferredLanguage: String!) {
        updatepreferredLanguage(preferredLanguage: $preferredLanguage) {
            success
        }
    }
`;



export function useUpdatePreferredLanguageMutation() {
    return Urql.useMutation<UpdatePreferredLanguageMutation, UpdatePreferredLanguageMutationVariables>(
        UpdatePreferredLanguageDocument
    );
}

export const UploadProjectFileDocument = gql`
    mutation UploadProjectFile($where: ProjectWhereInput!, $file: Upload!) {
        uploadProjectFile(where: $where, file: $file) {
            id
            name
            gpa
            tenderCountry
            status
            created
            modified
            vehicleAmount
            deliveryYear
            lub
            orderValue
            proposal
            publication
            deadline
            downloads {
                visible
                item {
                    id
                    name
                }
            }
            products {
                visible
                item {
                    id
                    name
                    image
                    intro
                    details
                    availableTo
                    availableFrom
                }
            }
        }
    }
`;

export function useUploadProjectFileMutation() {
    return Urql.useMutation<UploadProjectFileMutation, UploadProjectFileMutationVariables>(UploadProjectFileDocument);
}
export const AssetDocument = gql`
    query Asset($id: String!, $locale: Locale) {
        asset(id: $id, locale: $locale) {
            busTypes
            countries
            date
            expert
            fileName
            fileSize
            groupColor
            id
            languageNames
            languages
            series
            solutions
            tags
            title
            type
            uploader
        }
    }
`;

export function useAssetQuery(options: Omit<Urql.UseQueryArgs<AssetQueryVariables>, "query">) {
    return Urql.useQuery<AssetQuery, AssetQueryVariables>({ query: AssetDocument, ...options });
}
export const AssetsDocument = gql`
    query Assets(
        $locale: Locale
        $limit: Int
        $page: Int
        $filters: [FilterInput!]
        $search: String
        $sort: SortMethod
    ) {
        assets(locale: $locale, limit: $limit, page: $page, filters: $filters, search: $search, sort: $sort) {
            total
            filters {
                key
                values {
                    active
                    name
                    value
                }
            }
            assets {
                id
                title
                type
            }
        }
    }
`;

export function useAssetsQuery(options?: Omit<Urql.UseQueryArgs<AssetsQueryVariables>, "query">) {
    return Urql.useQuery<AssetsQuery, AssetsQueryVariables>({ query: AssetsDocument, ...options });
}
export const ContentGroupDocument = gql`
    query ContentGroup($group: ContentTypeGroup!, $locale: Locale) {
        contentGroup(group: $group, locale: $locale) {
            id
            title
            image
            description
            content
            topics {
                id
                key
                no
                title
                image
            }
        }
    }
`;

export function useContentGroupQuery(options: Omit<Urql.UseQueryArgs<ContentGroupQueryVariables>, "query">) {
    return Urql.useQuery<ContentGroupQuery, ContentGroupQueryVariables>({ query: ContentGroupDocument, ...options });
}
export const ContentTopicDocument = gql`
    query ContentTopic($group: ContentTypeGroup!, $keys: [String!]!, $locale: Locale) {
        contentTopic(group: $group, keys: $keys, locale: $locale) {
            id
            no
            title
            image
            description
            topics {
                id
                key
                no
                title
                description
                image
            }
            sections {
                id
                no
                slug
                title
                content
                type
                image
                twoColumns
                hotspots {
                    title
                    content
                    posY
                    posX
                }
            }
            parent {
                id
                key
                no
                title
                type
            }
        }
    }
`;

export function useContentTopicQuery(options: Omit<Urql.UseQueryArgs<ContentTopicQueryVariables>, "query">) {
    return Urql.useQuery<ContentTopicQuery, ContentTopicQueryVariables>({ query: ContentTopicDocument, ...options });
}
export const FeaturedNavDocument = gql`
    query FeaturedNav($locale: Locale) {
        featuredNav(locale: $locale) {
            id
            title
            image
            enabled
            url
        }
    }
`;

export function useFeaturedNavQuery(options?: Omit<Urql.UseQueryArgs<FeaturedNavQueryVariables>, "query">) {
    return Urql.useQuery<FeaturedNavQuery, FeaturedNavQueryVariables>({ query: FeaturedNavDocument, ...options });
}
export const HealthDocument = gql`
    query Health {
        health {
            health
            userAgent
        }
    }
`;

export function useHealthQuery(options?: Omit<Urql.UseQueryArgs<HealthQueryVariables>, "query">) {
    return Urql.useQuery<HealthQuery, HealthQueryVariables>({ query: HealthDocument, ...options });
}
export const NavDocument = gql`
    query Nav($locale: Locale) {
        nav(locale: $locale) {
            id
            title
            image
            enabled
            offline
            additionalLinks {
                id
                title
                url
                enabled
                offline
            }
            children {
                id
                title
                image
                enabled
                offline
                additionalLinks {
                    title
                    url
                }
                children {
                    id
                    title
                    url
                    enabled
                    offline
                }
            }
        }
    }
`;

export function useNavQuery(options?: Omit<Urql.UseQueryArgs<NavQueryVariables>, "query">) {
    return Urql.useQuery<NavQuery, NavQueryVariables>({ query: NavDocument, ...options });
}
export const NewsDocument = gql`
    query News($locale: Locale, $categories: [String!], $limit: Int, $page: Int, $query: String) {
        news(locale: $locale, categories: $categories, limit: $limit, page: $page, query: $query) {
            total
            news {
                author
                categories
                description
                id
                publication
                slug
                thumbnail
                title
            }
        }
    }
`;

export function useNewsQuery(options?: Omit<Urql.UseQueryArgs<NewsQueryVariables>, "query">) {
    return Urql.useQuery<NewsQuery, NewsQueryVariables>({ query: NewsDocument, ...options });
}
export const NewsCategoriesDocument = gql`
    query NewsCategories($locale: Locale) {
        newsCategories(locale: $locale) {
            id
            name
        }
    }
`;

export const NewsItemDocument = gql`
    query NewsItem($locale: Locale, $slug: String) {
        newsItem(locale: $locale, slug: $slug) {
            id
            title
            description
            publication
            slug
            author
            categories
            author
            tags
            contentBlocks {
                title
                content
                image
                hotspots {
                    content
                    posX
                    posY
                    title
                }
                assets {
                    id
                    title
                    type
                }
            }
        }
    }
`;

export function useNewsItemQuery(options?: Omit<Urql.UseQueryArgs<NewsItemQueryVariables>, "query">) {
    return Urql.useQuery<NewsItemQuery, NewsItemQueryVariables>({ query: NewsItemDocument, ...options });
}

export function useNewsCategoriesQuery(options?: Omit<Urql.UseQueryArgs<NewsCategoriesQueryVariables>, "query">) {
    return Urql.useQuery<NewsCategoriesQuery, NewsCategoriesQueryVariables>({
        query: NewsCategoriesDocument,
        ...options
    });
}
export const NewsInfoDocument = gql`
    query NewsInfo {
        news {
            total
        }
    }
`;

export function useNewsInfoQuery(options?: Omit<Urql.UseQueryArgs<NewsInfoQueryVariables>, "query">) {
    return Urql.useQuery<NewsInfoQuery, NewsInfoQueryVariables>({ query: NewsInfoDocument, ...options });
}
export const OfflineDocument = gql`
    query Offline($locale: Locale) {
        home: nav(locale: $locale) {
            id
            title
            image
            enabled
            offline
            additionalLinks {
                title
                url
            }
            children {
                id
                title
                image
                enabled
                offline
                additionalLinks {
                    title
                    url
                }
            }
        }
        user {
            id
            username
            email
            firstname
            lastname
            avatar
            country
        }
    }
`;

export function useOfflineQuery(options?: Omit<Urql.UseQueryArgs<OfflineQueryVariables>, "query">) {
    return Urql.useQuery<OfflineQuery, OfflineQueryVariables>({ query: OfflineDocument, ...options });
}
export const PageDocument = gql`
    query Page($id: Int!, $locale: Locale) {
        page(id: $id, locale: $locale) {
            id
            title
            description
            children {
                ... on CmsSubPage {
                    id
                    slug
                    title
                    contentBlocks {
                        title
                        content
                        assets {
                            id
                            title
                            type
                        }
                        assetsTitle
                        image
                        hotspots {
                            title
                            content
                            posY
                            posX
                        }
                        lubStatusHtml {
                            headers
                            data
                        }
                    }
                }
                ... on Vehicle {
                    id
                    name
                    topics {
                        topics {
                            id
                            key
                            title
                            type
                            totalUsage
                            relatedTopics
                        }
                        components {
                            id
                            key
                            title
                            type
                            totalUsage
                            relatedTopics
                        }
                        benefits {
                            id
                            key
                            title
                            type
                            totalUsage
                            relatedTopics
                        }
                    }
                }
                ... on DownloadPreset {
                    id
                    title
                    filter {
                        key
                        values {
                            value
                        }
                    }
                }
            }
        }
    }
`;

export function usePageQuery(options: Omit<Urql.UseQueryArgs<PageQueryVariables>, "query">) {
    return Urql.useQuery<PageQuery, PageQueryVariables>({ query: PageDocument, ...options });
}
export const PodcastDocument = gql`
    query Podcast($id: String!, $locale: Locale) {
        podcast(id: $id, locale: $locale) {
            id
            title
            mediaType
            mediaUrl
            thumbnail
            description
            longDescription
            keywords
            publication
        }
    }
`;

export function usePodcastQuery(options: Omit<Urql.UseQueryArgs<PodcastQueryVariables>, "query">) {
    return Urql.useQuery<PodcastQuery, PodcastQueryVariables>({ query: PodcastDocument, ...options });
}
export const PodcastsDocument = gql`
    query Podcasts($locale: Locale, $limit: Int) {
        podcasts(locale: $locale, limit: $limit) {
            id
            title
            mediaType
            thumbnail
            description
            keywords
            publication
        }
    }
`;

export function usePodcastsQuery(options?: Omit<Urql.UseQueryArgs<PodcastsQueryVariables>, "query">) {
    return Urql.useQuery<PodcastsQuery, PodcastsQueryVariables>({ query: PodcastsDocument, ...options });
}
export const ProductDocument = gql`
    query Product($key: String!, $locale: Locale) {
        product(key: $key, locale: $locale) {
            id
            slug
            name
            descriptionInternal
            details
            benefits
            availableFrom
            availableTo
            regionalAvailability
            group {
                id
                slug
                name
            }
            information {
                title
                href
            }
            downloads {
                id
                name
                mime
                size
                modified
            }
        }
    }
`;

export function useProductQuery(options: Omit<Urql.UseQueryArgs<ProductQueryVariables>, "query">) {
    return Urql.useQuery<ProductQuery, ProductQueryVariables>({ query: ProductDocument, ...options });
}
export const ProductCategoriesDocument = gql`
    query ProductCategories($locale: Locale) {
        productCategories(locale: $locale) {
            id
            slug
            name
            icon
            silo
            hasGroups
            groups {
                id
                slug
                name
            }
        }
    }
`;

export function useProductCategoriesQuery(options?: Omit<Urql.UseQueryArgs<ProductCategoriesQueryVariables>, "query">) {
    return Urql.useQuery<ProductCategoriesQuery, ProductCategoriesQueryVariables>({
        query: ProductCategoriesDocument,
        ...options
    });
}
export const ProductCategoryDocument = gql`
    query ProductCategory($key: String!, $locale: Locale) {
        productCategory(key: $key, locale: $locale) {
            id
            slug
            name
            icon
            silo
            hasGroups
            groups {
                id
                slug
                name
                image
                descriptionInternal
                readiness
            }
        }
    }
`;

export function useProductCategoryQuery(options: Omit<Urql.UseQueryArgs<ProductCategoryQueryVariables>, "query">) {
    return Urql.useQuery<ProductCategoryQuery, ProductCategoryQueryVariables>({
        query: ProductCategoryDocument,
        ...options
    });
}
export const ProductExpertsDocument = gql`
    query ProductExperts($key: String!) {
        productExperts(key: $key) {
            id
            name
            email
            phone
            details
            countries
        }
    }
`;

export function useProductExpertsQuery(options: Omit<Urql.UseQueryArgs<ProductExpertsQueryVariables>, "query">) {
    return Urql.useQuery<ProductExpertsQuery, ProductExpertsQueryVariables>({
        query: ProductExpertsDocument,
        ...options
    });
}
export const ProductGroupDocument = gql`
    query ProductGroup($key: String!, $locale: Locale) {
        productGroup(key: $key, locale: $locale) {
            id
            slug
            name
            descriptionInternal
            image
            readiness
            wowBullets
            downloads {
                id
                name
                mime
                size
                modified
            }
            information {
                title
                href
            }
            related {
                id
                name
                image
                intro
            }
            products {
                id
                slug
                name
                image
                intro
                readiness
                availableFrom
                availableTo
                details
                regionalAvailability
            }
        }
    }
`;

export function useProductGroupQuery(options: Omit<Urql.UseQueryArgs<ProductGroupQueryVariables>, "query">) {
    return Urql.useQuery<ProductGroupQuery, ProductGroupQueryVariables>({ query: ProductGroupDocument, ...options });
}
export const ProjectDocument = gql`
    query Project($id: String!, $locale: Locale) {
        project(id: $id, locale: $locale) {
            id
            name
            gpa
            tenderCountry
            status
            created
            modified
            vehicleAmount
            deliveryYear
            lub
            orderValue
            proposal
            publication
            deadline
            uuid
            customerPassword
            customerIntro
            notes
            downloads {
                visible
                item {
                    id
                    name
                    mime
                    size
                    modified
                }
            }
            products {
                visible
                item {
                    id
                    name
                    image
                    intro
                    details
                    availableTo
                    availableFrom
                    category {
                        id
                        name
                    }
                }
            }
            calculations {
                id
                name
                visible
                data
                modified
            }
        }
    }
`;

export function useProjectQuery(options: Omit<Urql.UseQueryArgs<ProjectQueryVariables>, "query">) {
    return Urql.useQuery<ProjectQuery, ProjectQueryVariables>({ query: ProjectDocument, ...options });
}
export const ProjectCalcDocument = gql`
    query ProjectCalc($where: ProjectCalcWhereInput!) {
        projectCalculation(where: $where) {
            id
            name
            data
            visible
            modified
        }
    }
`;

export function useProjectCalcQuery(options: Omit<Urql.UseQueryArgs<ProjectCalcQueryVariables>, "query">) {
    return Urql.useQuery<ProjectCalcQuery, ProjectCalcQueryVariables>({ query: ProjectCalcDocument, ...options });
}
export const ProjectOptionsDocument = gql`
    query ProjectOptions($locale: Locale) {
        countries(locale: $locale) {
            focusCountries
            countries {
                label
                value
            }
        }
        status(locale: $locale) {
            label
            value
        }
    }
`;

export function useProjectOptionsQuery(options?: Omit<Urql.UseQueryArgs<ProjectOptionsQueryVariables>, "query">) {
    return Urql.useQuery<ProjectOptionsQuery, ProjectOptionsQueryVariables>({
        query: ProjectOptionsDocument,
        ...options
    });
}
export const ProjectsDocument = gql`
    query Projects($where: ProjectsWhereInput, $locale: Locale) {
        projects(where: $where, locale: $locale) {
            total
            projects {
                id
                name
                gpa
                tenderCountry
                status
                created
                modified
            }
        }
    }
`;

export function useProjectsQuery(options?: Omit<Urql.UseQueryArgs<ProjectsQueryVariables>, "query">) {
    return Urql.useQuery<ProjectsQuery, ProjectsQueryVariables>({ query: ProjectsDocument, ...options });
}
export const RelatedContentDocument = gql`
    query RelatedContent($id: String!, $locale: Locale) {
        relatedContent(id: $id, locale: $locale) {
            id
            name
            image
            intro
            description
            details
            information {
                title
                href
            }
            downloads {
                id
                name
                modified
                size
                mime
            }
            experts {
                id
                name
                email
                phone
                details
                countries
            }
        }
    }
`;

export function useRelatedContentQuery(options: Omit<Urql.UseQueryArgs<RelatedContentQueryVariables>, "query">) {
    return Urql.useQuery<RelatedContentQuery, RelatedContentQueryVariables>({
        query: RelatedContentDocument,
        ...options
    });
}
export const SearchDocument = gql`
    query Search($where: SearchWhereInput!, $locale: Locale) {
        search(where: $where, locale: $locale) {
            total
            hits {
                id
                key
                title
                type
                description
                breadcrumbs {
                    id
                    key
                    title
                }
            }
        }
    }
`;

export function useSearchQuery(options: Omit<Urql.UseQueryArgs<SearchQueryVariables>, "query">) {
    return Urql.useQuery<SearchQuery, SearchQueryVariables>({ query: SearchDocument, ...options });
}
export const StatusDocument = gql`
    query Status($locale: Locale) {
        status(locale: $locale) {
            label
            value
        }
    }
`;

export function useStatusQuery(options?: Omit<Urql.UseQueryArgs<StatusQueryVariables>, "query">) {
    return Urql.useQuery<StatusQuery, StatusQueryVariables>({ query: StatusDocument, ...options });
}
export const ToolDocument = gql`
    query Tool($id: String!, $locale: Locale) {
        tool(id: $id, locale: $locale) {
            id
            name
            intro
            description
            url
            image
            keywords
            details
            information {
                title
                href
            }
            downloads {
                id
                name
                modified
                size
                mime
            }
            experts {
                id
                name
                email
                phone
                details
                countries
            }
        }
    }
`;

export function useToolQuery(options: Omit<Urql.UseQueryArgs<ToolQueryVariables>, "query">) {
    return Urql.useQuery<ToolQuery, ToolQueryVariables>({ query: ToolDocument, ...options });
}
export const ToolsDocument = gql`
    query Tools($locale: Locale) {
        tools(locale: $locale) {
            id
            name
            intro
            description
            url
            image
            keywords
        }
    }
`;

export function useToolsQuery(options?: Omit<Urql.UseQueryArgs<ToolsQueryVariables>, "query">) {
    return Urql.useQuery<ToolsQuery, ToolsQueryVariables>({ query: ToolsDocument, ...options });
}

export const LinkResolverContent = gql`
query LinkResolver($documentId: String!, $user: String!) {
    resolvedLink(id: $documentId, user: $user) {
      success
      msg
      url
      title
    }
  }
`;

export const useLinkResolverQuery =(options?: Omit<Urql.UseQueryArgs<LinkResolverQueryVariables>, "query">)=>{
    return  Urql.useQuery<LinkResolverQuery, LinkResolverQueryVariables>({query: LinkResolverContent, ...options});
}

export const UserDocument = gql`
    query User {
        user {
            id
            username
            email
            salutation
            firstname
            lastname
            avatar
            country
            company
            position
            language
            comment
            street
            zipcode
            city
            infoletter
            newsflash
            groups
            groupColor
            daysRemain
            popupType
        }
    }
`;

export function useUserQuery(options?: any) {
    return Urql.useQuery<UserQuery, UserQueryVariables>({ query: UserDocument, ...options });
}
export const VehicleDocument = gql`
    query Vehicle($id: String!, $locale: Locale) {
        vehicle(id: $id, locale: $locale) {
            id
            name
            topics {
                topics {
                    id
                    key
                    title
                    type
                    totalUsage
                    relatedTopics
                }
                components {
                    id
                    key
                    title
                    type
                    totalUsage
                    relatedTopics
                }
                benefits {
                    id
                    key
                    title
                    type
                    totalUsage
                    relatedTopics
                }
            }
        }
    }
`;

export function useVehicleQuery(options: Omit<Urql.UseQueryArgs<VehicleQueryVariables>, "query">) {
    return Urql.useQuery<VehicleQuery, VehicleQueryVariables>({ query: VehicleDocument, ...options });
}
export const VehiclesDocument = gql`
    query Vehicles($locale: Locale) {
        vehicles(locale: $locale) {
            id
            name
        }
    }
`;

export function useVehiclesQuery(options?: Omit<Urql.UseQueryArgs<VehiclesQueryVariables>, "query">) {
    return Urql.useQuery<VehiclesQuery, VehiclesQueryVariables>({ query: VehiclesDocument, ...options });
}
export const VehicleTopicDocument = gql`
    query VehicleTopic($key: String!, $type: String!, $locale: Locale) {
        vehicleTopic(key: $key, type: $type, locale: $locale) {
            id
            key
            title
            type
            subtitle
            description
            sections {
                id
                slug
                title
                type
                image
                content
                hotspots {
                    title
                    content
                    posX
                    posY
                }
                gallery {
                    id
                    file
                    title
                    description
                }
            }
        }
    }
`;

export function useVehicleTopicQuery(options: Omit<Urql.UseQueryArgs<VehicleTopicQueryVariables>, "query">) {
    return Urql.useQuery<VehicleTopicQuery, VehicleTopicQueryVariables>({ query: VehicleTopicDocument, ...options });
}
