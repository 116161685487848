// Copyright (C) 2023 TANNER AG

import { useNewsQuery } from "../../../graphql";
import NewsWidget from "../../../components/newswidget/NewsWidget";
import useLocale from "../../../hooks/uselocale";
import useParam from "../../../hooks/useparam";

const NEWS_LIMIT = 3;

const HomeNews: React.FC = () => {
    const { locale: storeLocale } = useLocale();
    let locale ;
    if (storeLocale === "de") {
        locale = "de_DE";
    } else{
        locale = "en";
    }
    const [searchParam] = useParam("q", "");
    const [{ data, fetching }] = useNewsQuery({ variables: { locale, limit: NEWS_LIMIT, query: searchParam } });
    const news = data?.news?.news || [];
    const total = data?.news?.total || 0;

    return <NewsWidget searchQuery={searchParam} news={news} total={total} loading={fetching} />;
};

export default HomeNews;
