// Copyright (C) 2022 TANNER AG

import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { boldFontFamily } from "../../theme";
import Paper from "@mui/material/Paper";
import clsx from "clsx";

type Props = {
    id: string;
    name?: string;
    children?: React.ReactNode;
    action?: React.ReactNode;
    icon?: React.ReactNode;
    border?: boolean;
    inactive?: boolean;
};

const ListItemBase: React.FC<Props> = ({ id, name, inactive, icon, children, action, border }) => {
    return (
        <Stack
            data-id={id}
            direction="row"
            component={Paper}
            className={clsx({ border })}
            sx={{
                alignItems: { xs: "flex-star", sm: "center" },
                py: 1,
                px: 1.5,
                "&.border": { borderBottom: "1px solid", borderColor: "grey.100", borderRadius: 0, px: 0 }
            }}
        >
            <Box
                sx={{
                    width: { xs: 32, sm: 56 },
                    height: { xs: "auto", sm: 56 },
                    display: "flex",
                    alignItems: { xs: "flex-star", sm: "center" },
                    justifyContent: "center",
                    mr: { xs: 1.5, sm: 2 },
                    mt: { xs: 0.75, sm: 0 },
                    "&.inactive": { opacity: 0.5 }
                }}
                className={clsx({ inactive })}
            >
                {icon}
            </Box>
            <Stack sx={{ flexGrow: 1, flexDirection: { xs: "column", md: "row" } }}>
                <Stack spacing={0.25}>
                    <Typography variant="body1" sx={{ fontFamily: boldFontFamily }}>
                        {name}
                    </Typography>
                    {children}
                </Stack>
                <Stack spacing={1} direction="row" sx={{ ml: "auto", alignItems: "center" }}>
                    {action}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default ListItemBase;
