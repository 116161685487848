// Copyright (C) 2022 TANNER AG

import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import React from "react";
import Typography from "@mui/material/Typography";

import { boldFontFamily } from "../../theme";
import { News, useNewsCategoriesQuery } from "../../graphql";
import { routes } from "../../lib/utils";
import useAsset from "../../hooks/useasset";
import useDatetime from "../../hooks/usedatetime";
import useFallbackImage from "../../hooks/useFallbackImage";
import useLocale from "../../hooks/uselocale";

type Props = {
    news: News;
};

const NewsItem: React.FC<Props> = ({ news }) => {
    const navigate = useNavigate();
    const { locale } = useLocale();
    const { fDate } = useDatetime();
    const { createImageUrl } = useAsset();
    const { fallbackImage } = useFallbackImage();
    const [{ data }] = useNewsCategoriesQuery({ variables: { locale } });

    const categoriesList = React.useMemo(() => {
        const newsCategories = data?.newsCategories ?? [];
        return news.categories
            .map((catId) => ({ name: newsCategories.find((cat) => cat.id === catId)?.name, id: catId }))
            .filter((category) => category.name);
    }, [news.categories, data?.newsCategories]);

    return (
        <Box
            onClick={() => {
                navigate(`${routes.newsFlash}/${news.slug}`);
            }}
            sx={{ cursor: "pointer" }}
        >
            <img
                alt={`${news.title} News Thumbnail`}
                src={news.thumbnail ? createImageUrl(news.thumbnail) : fallbackImage}
                style={{ width: "100%", height: 208, objectFit: "cover" }}
            />
            {categoriesList.map((category, index) => (
                <React.Fragment key={category.id}>
                    <Typography display="inline" color="secondary" variant="subtitle2">
                        {category.name}
                    </Typography>
                    {index !== categoriesList.length - 1 && (
                        <Typography display="inline" color="#CBD3DC" sx={{ mx: 1 }}>
                            •
                        </Typography>
                    )}
                </React.Fragment>
            ))}
            <Typography variant="h3" color="#161616" sx={{ mb: 1.5 }}>
                {news.title}
            </Typography>
            <Typography color="#303C49" fontSize={16} fontFamily={boldFontFamily}>
                {news.author}
            </Typography>
            <Typography color="#4E6174" fontSize={14}>
                {fDate(news.publication)}
            </Typography>
        </Box>
    );
};

export default NewsItem;
