// Copyright (C) 2023 TANNER AG

import { useSearchParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import Stack from "@mui/material/Stack";

import { isValidPassword } from "../../lib/validations";
import FormInput from "../../components/inputs/FormInput";
import FormLayout from "./FormLayout";
import HTTPClient from "../../lib/HTTPClient";
import Spinner from "../../components/Spinner";
import useTranslation from "../../hooks/usetranslation";

const ResetPassword: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setLoading] = useState(false);

    const resetErrorMessage = () => setErrorMessage("");

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const password = formData.get("password") as string;
        const confirmPassword = formData.get("confirmPassword") as string;

        if (!isValidPassword(password, confirmPassword)) return setErrorMessage(t("password_invalid"));

        const token = searchParams.get("token");
        const username = searchParams.get("username");

        setLoading(true);
        setTimeout(
            () =>
                HTTPClient.POST("/user/pwreset", { username, password, token })
                    .then((response) => {
                        if (!response.ok) throw new Error();
                        setSuccessMessage(t("password_reset_success"));
                        setTimeout(() => navigate("/"), 3000);
                    })
                    .catch(() => {
                        setErrorMessage(t("error"));
                    })
                    .finally(() => {
                        setLoading(false);
                    }),
            5 * 1000
        );
    };

    if (isLoading) return <Spinner />;

    return (
        <FormLayout
            title={t("reset_password")}
            subtitle={t("password_requirements")}
            onSubmit={handleSubmit}
            successMessage={successMessage}
            errorMessage={errorMessage}
        >
            <Stack spacing={2}>
                <FormInput
                    id="password"
                    label={t("password")}
                    error={!!errorMessage}
                    required
                    InputProps={{ type: "password", onChange: resetErrorMessage }}
                />
                <FormInput
                    id="confirmPassword"
                    label={t("confirm_password")}
                    error={!!errorMessage}
                    required
                    InputProps={{ type: "password", onChange: resetErrorMessage }}
                />
            </Stack>
        </FormLayout>
    );
};

export default ResetPassword;
