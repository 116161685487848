// Copyright (C) 2022 TANNER AG

import React from "react";
import ListItemRegular from "../../../components/listitem/regular/ListItemRegular";
import { SoundIcon, VideoIcon } from "../../../components/icons/MANIcons";
import GroupAttr from "../../../components/GroupAttr";
import IconButton from "@mui/material/IconButton";
import useTranslation from "../../../hooks/usetranslation";
import useDatetime from "../../../hooks/usedatetime";
import Stack from "@mui/material/Stack";

type Props = {
    id: string;
    title: string;
    type?: string;
    description?: string;
    thumbnail?: string;
    publication?: string;
    keywords: string[];
    onClick(): void;
};

const BuscastListItem: React.FC<Props> = ({
    id,
    title,
    type,
    keywords,
    description,
    thumbnail,
    publication,
    onClick
}) => {
    const { t } = useTranslation();
    const { fDate } = useDatetime();

    return (
        <ListItemRegular
            title={title}
            description={description}
            onClick={onClick}
            image={thumbnail}
            toolbar={
                <IconButton size="small">
                    {type === "audio" ? <SoundIcon color="primary" /> : <VideoIcon color="primary" />}
                </IconButton>
            }
        >
            <Stack direction="row" spacing={3}>
                <GroupAttr label={t("release_date")} value={fDate(publication)} minWidth="auto" />
                <GroupAttr label={t("tags")} value={keywords.join(", ")} minWidth="auto" />
            </Stack>
        </ListItemRegular>
    );
};

export default BuscastListItem;
