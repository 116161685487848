// Copyright (C) 2022 TANNER AG

import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { boldFontFamily } from "../../../theme";
import Banner from "../../../components/Banner";
import Box from "@mui/material/Box";
import useTranslation from "../../../hooks/usetranslation";
import HeroSection from "./HeroSection";
import CategoryListItem from "../../../components/categorylistitem/CategoryListItem";
import useProductCategories from "../../../hooks/useproductcategories";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../../lib/utils";

const ProductOverview: React.FC = () => {
    const { t } = useTranslation();
    const { silos } = useProductCategories();
    const navigate = useNavigate();
    const { search } = useLocation();

    useEffect(() => {
        const firstCategory = silos?.[0]?.categories?.[0];
        if (!search?.includes("redirect") || !firstCategory) {
            return;
        }
        navigate(`${routes.dsg.products}/${firstCategory?.slug || firstCategory?.id}`, { replace: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, silos]);

    return (
        <>
            <Banner>
                <Typography variant="h1" sx={{ mb: 3 }}>
                    {t("dsg_title")}
                </Typography>
                <Typography variant="subtitle1" sx={{ lineHeight: 1.5 }}>
                    {t("products_services_desc")}
                </Typography>
            </Banner>
            <HeroSection />
            <Box sx={{ position: "relative", height: { xs: 24, lg: 100 } }}>
                <Box
                    sx={{
                        backgroundImage:
                            "linear-gradient(0deg, rgba(153, 201, 232, 0.45), rgba(153, 201, 232, 0.45)), url(/overview_background.png)",
                        minHeight: 700,
                        width: 1,
                        position: "absolute",
                        zIndex: -1,
                        opacity: 0.3,
                        display: { xs: "none", lg: "block" }
                    }}
                />
            </Box>
            <Container sx={{ mb: 8 }}>
                <Grid container spacing={3}>
                    {silos.map(({ name, categories }, index) => (
                        <Grid key={index} xs={12} md={4} item>
                            <Typography
                                sx={{ fontFamily: boldFontFamily, fontSize: 36, textTransform: "uppercase" }}
                                gutterBottom
                            >
                                {name}
                            </Typography>
                            <List>
                                {categories.map(({ id, name, slug, icon, hasGroups }) => (
                                    <CategoryListItem
                                        key={id}
                                        name={name}
                                        slug={slug || id}
                                        icon={icon}
                                        disabled={!hasGroups}
                                    />
                                ))}
                            </List>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </>
    );
};

export default ProductOverview;
