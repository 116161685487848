// Copyright (C) 2022 TANNER AG

import React from "react";
import { styled, alpha } from "@mui/material/styles";
import { Hotspot as GqlHotspot } from "../../../graphql";
import clsx from "clsx";

type Props = {
    hotspot: GqlHotspot;
    active?: boolean;
    imageSize: { width: number; height: number };
    onClick?(): void;
};

const HOTSPOT_SIZE = 36;

const calcCssPos = (value: number) => `calc(${value}% - ${(value / 100) * HOTSPOT_SIZE}px)`;

const Hotspot: React.FC<Props> = ({ hotspot, active, onClick, imageSize }) => {
    if (!hotspot.posX || !hotspot.posY) {
        return null;
    }

    const relX = (hotspot.posX / imageSize.width) * 100;
    const relY = (hotspot.posY / imageSize.height) * 100;

    return (
        <Root
            className={clsx({ active })}
            style={{ top: calcCssPos(relY), left: calcCssPos(relX) }}
            onClick={onClick}
        />
    );
};

export const Root = styled("div")(({ theme }) => ({
    width: HOTSPOT_SIZE,
    height: HOTSPOT_SIZE,
    position: "absolute",
    borderRadius: "50%",
    background: alpha(theme.palette.background.paper, 0.9),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&::before": {
        content: "''",
        background: "transparent",
        border: "3px solid",
        borderColor: theme.palette.grey["800"],
        width: 12,
        height: 12,
        display: "block",
        borderRadius: "50%",
        transition: theme.transitions.create("all")
    },
    "&.active::before": {
        width: 16,
        height: 16,
        background: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main
    }
}));

export default Hotspot;
