// Copyright (C) 2022 TANNER AG

import React, { useEffect, useLayoutEffect } from "react";
import useLocale from "../hooks/uselocale";
import { useLocation } from "react-router-dom";

const Bootstrap: React.FC = () => {
    const { locale } = useLocale();
    const { pathname } = useLocation();

    useLayoutEffect(() => {
        document.documentElement.lang = locale;
    }, [locale]);

    useEffect(() => {
        // TODO currently only pathname without params and hash are tracked
        window.et_eC_Wrapper?.({ et_et: process.env.REACT_APP_TRACKING_CODE });
    }, [pathname]);

    return <div />;
};

export default Bootstrap;
