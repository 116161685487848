// import { useContext } from 'react';

import NavBar from '../navbar/NavBar';
import { ChatWindow } from './ChatWindow';
// import { ChatboxContext } from './useChatBox';
import useTranslation from '../../hooks/usetranslation';
import { Container, Grid, Typography } from '@mui/material';


export const ChatBox = () => {
  const { t } = useTranslation();

  return (
    <>
      <NavBar links={[{ label: t("aiAssistant") }]} />
      <Container sx={{ my: 4 }}>
        <Grid container>
          <Typography variant="h2">{t("aiAssistant")}</Typography>
        </Grid>
        <Grid container>
          <ChatWindow />
        </Grid>
      </Container>
    </>
  );
}