// Copyright (C) 2022 TANNER AG

import React from "react";
import ListItemRegular from "../../../components/listitem/regular/ListItemRegular";
import Button from "@mui/material/Button";
import { LinkExternIcon } from "../../../components/icons/MANIcons";
import useTranslation from "../../../hooks/usetranslation";
import ToolsItemsTags from "../ToolsItemTags";

type Props = {
    id: string;
    name: string;
    intro?: string;
    thumbnail?: string;
    keywords: string[];
    url?: string;
    onClick(): void;
};

const ToolsListItem: React.FC<Props> = ({ id, name, intro, thumbnail, onClick, keywords, url }) => {
    const { t } = useTranslation();
    return (
        <ListItemRegular
            title={name}
            description={intro}
            onClick={onClick}
            action={
                url ? (
                    <Button startIcon={<LinkExternIcon color="secondary" />} component="a" href={url} target="_blank">
                        {t("open_tool")}
                    </Button>
                ) : undefined
            }
            image={thumbnail}
            additional={<ToolsItemsTags tags={keywords} />}
        />
    );
};

export default ToolsListItem;
