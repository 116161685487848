// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import { CheckIcon, UploadFileIcon } from "../icons/MANIcons";
import Button from "@mui/lab/LoadingButton";
import Dialog from "../dialog/Dialog";
import ProjectUploadForm from "./ProjectUploadForm";
import DialogHead from "../dialog/DialogHead";
import { useUploadProjectFileMutation } from "../../graphql";
import useTranslation from "../../hooks/usetranslation";

type Props = {
    projectId: string;
};

const ProjectUpload: React.FC<Props> = ({ projectId }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [{ fetching }, uploadFile] = useUploadProjectFileMutation();

    const handleClose = () => setOpen(false);

    const handleSubmit = async (file: File) => {
        const result = await uploadFile({ where: { id: projectId }, file });
        if (result.error || !result.data?.uploadProjectFile) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <Button onClick={() => setOpen(true)} startIcon={<UploadFileIcon color="secondary" />}>
                {t("upload_file")}
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <ProjectUploadForm
                    onSubmit={handleSubmit}
                    head={<DialogHead title={t("project_file_upload")} onClose={handleClose} />}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        loadingPosition="start"
                        startIcon={<CheckIcon />}
                        loading={fetching}
                        type="submit"
                    >
                        {t("upload_file")}
                    </Button>
                    <Button onClick={handleClose}>{t("cancel")}</Button>
                </ProjectUploadForm>
            </Dialog>
        </>
    );
};

export default ProjectUpload;
