// Copyright (C) 2022 TANNER AG

import { useEffect, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { useAssetQuery } from "../../../graphql";
import Dialog from "../../dialog/Dialog";
import DialogHead from "../../dialog/DialogHead";
import DownloadDetailsContent from "./DownloadDetailsContent";
import GqlError from "../../error/GqlError";
import Spinner from "../../Spinner";
import useLocale from "../../../hooks/uselocale";

type Props = {
    id: string;
    isOpen: boolean;
    onClose: () => void;
    selectedFilter?: string[];
};

const DownloadDetailsDialog: React.FC<Props> = ({ id, isOpen, onClose, selectedFilter = [] }) => {
    const { locale: globalLocale } = useLocale();
    const [isFirstFetch, setFirstFetch] = useState(true);
    const [locale, setLocale] = useState(globalLocale === "de" ? "de_DE" : globalLocale);
    const [{ data, fetching, error }] = useAssetQuery({ variables: { id, locale } });

    const asset = data?.asset;

    useEffect(() => {
        if (isFirstFetch && !fetching && !!asset) {
            setFirstFetch(false);
            if (selectedFilter.length) return setLocale(selectedFilter.sort()[0]);
            const assetLanguage = (asset.languages ?? []).find((language) => language.includes(globalLocale));
            if (assetLanguage) setLocale(assetLanguage);
            else {
                const firstLanguage = (asset.languages ?? []).sort()[0];
                setLocale(firstLanguage);
            }
        }
    }, [isFirstFetch, asset, fetching, globalLocale, selectedFilter]);

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="lg">
            <DialogTitle>
                <DialogHead title={asset?.title} onClose={onClose} />
            </DialogTitle>
            <DialogContent sx={{ marginBottom: 3 }}>
                {error ? (
                    <GqlError error={error} />
                ) : fetching || isFirstFetch ? (
                    <Spinner />
                ) : asset ? (
                    <DownloadDetailsContent asset={asset} locale={locale} setLocale={setLocale} />
                ) : null}
            </DialogContent>
        </Dialog>
    );
};

export default DownloadDetailsDialog;
