// Copyright (C) 2022 TANNER AG

import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { NavItem } from "../../../graphql";

type Props = {
    navItem: NavItem;
    closePopover: () => void;
};

const AppMenuItemSection: React.FC<Props> = ({ navItem, closePopover }) => {
    return (
        <Box marginTop={2} marginRight={2}>
            <Typography variant="h5" textTransform="uppercase">
                {navItem.title}
            </Typography>
            {(navItem.children || []).map((child) => (
                <Box key={child.id} marginTop={1}>
                    <Link
                        to={child.url ?? "/"}
                        onClick={closePopover}
                        style={{ textDecoration: "none", color: "#E40045" }}
                    >
                        {child.title}
                    </Link>
                </Box>
            ))}
        </Box>
    );
};

export default AppMenuItemSection;
