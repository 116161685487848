// Copyright (C) 2022 TANNER AG

import React from "react";
import Dialog from "../../../components/dialog/Dialog";
import ToolDetailsContent from "./ToolDetailsContent";

type Props = {
    activeId?: string;
    onClose(): void;
};

const ToolDetails: React.FC<Props> = ({ activeId, onClose }) => {
    return (
        <Dialog open={Boolean(activeId)} onClose={onClose} maxWidth="md" scroll="body">
            <ToolDetailsContent id={activeId ?? ""} onClose={onClose} />
        </Dialog>
    );
};

export default ToolDetails;
