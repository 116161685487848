// Copyright (C) 2022 TANNER AG

import React from "react";
import Container from "@mui/material/Container";
import useTranslation from "../../../hooks/usetranslation";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ProjectItem, { ProjectItemLoader } from "./ProjectItem";
import ProjectCreation from "../../../components/projectform/ProjectCreation";
import ProjectToolbar from "./ProjectToolbar";
import useProjects from "../../../hooks/useprojects";

const ProjectOverview: React.FC = () => {
    const { t } = useTranslation();
    const { projects, fetching } = useProjects();

    return (
        <Container sx={{ py: 4 }}>
            <Stack direction="row" sx={{ alignItems: "center", mb: 4 }}>
                <Typography variant="h2">{t("my_projects")}</Typography>
                <ProjectCreation label={t("create_project")} />
            </Stack>
            <ProjectToolbar />
            <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 2 }}>
                {projects.length === 1 ? `1 ${t("project")}` : t("total_projects", { total: projects.length })}
            </Typography>
            <Stack spacing={2}>
                {!fetching &&
                    projects?.map((project) => (
                        <ProjectItem
                            key={project.id}
                            id={project.id}
                            name={project.name}
                            gpa={project.gpa}
                            status={project.status}
                            created={project.created}
                            modified={project.modified}
                        />
                    ))}
                {fetching && Array.from(Array(4).keys()).map((key) => <ProjectItemLoader key={key} />)}
            </Stack>
        </Container>
    );
};

export default ProjectOverview;
