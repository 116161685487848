// Copyright (C) 2022 TANNER AG

import React from "react";
import BaseAppBar from "./BaseAppBar";

import AppMenu from "./menu/AppMenu";
import AppToolbar from "../apptoolbar/AppToolbar";

const AppBar: React.FC = () => {
    return (
        <BaseAppBar toolbar={<AppToolbar />}>
            <AppMenu />
        </BaseAppBar>
    );
};

export default AppBar;
