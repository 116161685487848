// Copyright (C) 2022 TANNER AG

import { useSearchParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useState } from "react";
import Typography from "@mui/material/Typography";

import { SearchIcon } from "../../components/icons/MANIcons";
import FormInput from "../../components/inputs/FormInput";
import NavBar from "../../components/navbar/NavBar";
import NewsCategoriesList from "./categories/NewsCategoriesList";
import NewsList from "./list/NewsList";
import useTranslation from "../../hooks/usetranslation";

const SEARCH_PARAM_NAME = "q";

const NewsListView: React.FC = () => {
    const { t } = useTranslation();
    const [selectedCategoryIds, setSelectedCategoryIds] = useState<string[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchText, setSearchText] = useState(searchParams.get(SEARCH_PARAM_NAME) ?? "");
    const [searchParam, setSearchParam] = useState(searchText);

    const handleSubmitSearchText = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSearchParam(searchText);
        if (searchText) {
            const searchParams = new URLSearchParams([[SEARCH_PARAM_NAME, searchText]]);
            setSearchParams(searchParams);
        } else {
            setSearchParams(new URLSearchParams());
        }
    };

    const handleChangeSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    return (
        <>
            <NavBar links={[{ label: t("news") }]} />
            <Container sx={{ my: 4 }}>
                <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 4 }}>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h2">{t("news")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ mt: { xs: 4, md: 0 } }}>
                        <form onSubmit={handleSubmitSearchText}>
                            <FormInput
                                id="searchText"
                                placeholder={t("search_posts")}
                                InputProps={{
                                    value: searchText,
                                    onChange: handleChangeSearchText,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </form>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <NewsCategoriesList
                            selectedCategoryIds={selectedCategoryIds}
                            setSelectedCategoryIds={setSelectedCategoryIds}
                            searchParam={searchParam}
                        />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <NewsList selectedCategoryIds={selectedCategoryIds} searchParam={searchParam} />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default NewsListView;
