// Copyright (C) 2022-2023 TANNER AG

import { FC, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { boldFontFamily } from "../../theme";
import { DownloadIcon } from "../icons/MANIcons";
import audioAssetThumbnail from "./audio_asset_thumbnail.png";
import DownloadDetailsDialog from "./details/DownloadDetailsDialog";
import useAsset from "../../hooks/useasset";
import useFallbackImage from "../../hooks/useFallbackImage";

export const ASSET_TYPE_AUDIO = "Audio";
export const ASSET_TYPE_VIDEO = "Video";

const ASSET_HEIGHT = 140;

const AssetTitleOverlay: FC<{ title?: string }> = ({ title }) => (
    <Box
        sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            backgroundColor: "rgba(30, 40, 50, 0.3)"
        }}
    >
        <Typography color="white" fontSize={14} fontFamily={boldFontFamily} lineHeight="20px" padding={1}>
            {title}
        </Typography>
    </Box>
);

const AssetTypeOverlay: FC<{ type?: string }> = ({ type }) => (
    <Box
        sx={{
            display: { xs: "block", md: "none" },
            position: "absolute",
            top: 8,
            left: 8,
            height: 24,
            borderRadius: 16,
            backgroundColor: "#5B7085"
        }}
    >
        <Typography
            color="white"
            fontSize={14}
            fontFamily={boldFontFamily}
            textTransform="uppercase"
            lineHeight="20px"
            paddingX={1}
            paddingY={0.25}
        >
            {type}
        </Typography>
    </Box>
);

const AssetDownloadIconOverlay: FC<{ url: string }> = ({ url }) => (
    <Box sx={{ display: { xs: "block", md: "none" }, position: "absolute", top: 8, right: 8 }}>
        <IconButton
            LinkComponent="a"
            href={url}
            download
            sx={{
                backgroundColor: "white",
                border: "1px solid black",
                borderRadius: 0,
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.5)" }
            }}
        >
            <DownloadIcon />
        </IconButton>
    </Box>
);

type Props = {
    id: string;
    title?: string;
    type?: string;
    assetHeight?: number;
    selectedFilter?: string[];
    showTitle?: boolean;
    showAdditionalIcons?: boolean;
};

const AssetItem: FC<Props> = ({
    id,
    title,
    type,
    assetHeight = ASSET_HEIGHT,
    selectedFilter = [],
    showTitle = true,
    showAdditionalIcons = true
}) => {
    const [isDetailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const { createAssetDownloadUrl, createAssetPreviewUrl } = useAsset();
    const { displayFallbackImage } = useFallbackImage();

    return (
        <>
            <Box
                sx={{
                    position: "relative",
                    height: assetHeight,
                    marginRight: 0.5,
                    marginBottom: 1,
                    cursor: "pointer",
                    "&:hover > *": { display: "block" }
                }}
                onClick={() => setDetailsDialogOpen(true)}
            >
                <Box
                    component="img"
                    src={type === ASSET_TYPE_AUDIO ? audioAssetThumbnail : createAssetPreviewUrl(id, assetHeight)}
                    onError={displayFallbackImage}
                    alt={`Asset ${title}`}
                    sx={{
                        maxWidth: "100%",
                        height: assetHeight,
                        backgroundColor: "white"
                    }}
                />
                {showTitle && <AssetTitleOverlay title={title} />}
                {showAdditionalIcons && (
                    <>
                        <AssetTypeOverlay type={type} />
                        <AssetDownloadIconOverlay url={createAssetDownloadUrl(id)} />
                    </>
                )}
            </Box>
            {isDetailsDialogOpen && (
                <DownloadDetailsDialog
                    id={id}
                    isOpen={isDetailsDialogOpen}
                    onClose={() => setDetailsDialogOpen(false)}
                    selectedFilter={selectedFilter}
                />
            )}
        </>
    );
};

export default AssetItem;
