// Copyright (C) 2023 TANNER AG

import {InputBaseProps} from "@mui/material";

export type Field = {
    id: string;
    labelTranslationKey: string;
    required: boolean;
    takeFullRow: boolean;
    InputProps?: InputBaseProps;
};

export const userFields: Field[] = [
    {
        id: "email",
        labelTranslationKey: "business_email",
        required: true,
        takeFullRow: true,
        InputProps: {
            type: "email",
            autoComplete: "email"
        }
    },
    {
        id: "password",
        labelTranslationKey: "password",
        required: true,
        takeFullRow: false,
        InputProps: {
            type: "password",
            autoComplete: "new-password"
        }
    },
    {
        id: "confirmPassword",
        labelTranslationKey: "confirm_password",
        required: true,
        takeFullRow: false,
        InputProps: {
            type: "password",
            autoComplete: "new-password"
        }
    }
];
export const userFieldsEditView: Field[] = [
    {
        id: "email",
        labelTranslationKey: "business_email",
        required: true,
        takeFullRow: true,
        InputProps: {
            type: "email",
            autoComplete: "email"
        }
    },
    {
        id: "password",
        labelTranslationKey: "password",
        required: false,
        takeFullRow: false,
        InputProps: {
            type: "password",
            autoComplete: "new-password"
        }
    },
    {
        id: "confirmPassword",
        labelTranslationKey: "confirm_password",
        required: false,
        takeFullRow: false,
        InputProps: {
            type: "password",
            autoComplete: "new-password"
        }
    }
];

export const personalFields: Field[] = [
    {id: "salutation", labelTranslationKey: "salutation", required: false, takeFullRow: true},
    {id: "lastname", labelTranslationKey: "surname", required: true, takeFullRow: false},
    {id: "firstname", labelTranslationKey: "first_name", required: true, takeFullRow: false},
    {id: "company", labelTranslationKey: "company", required: true, takeFullRow: false},
    {id: "position", labelTranslationKey: "position", required: false, takeFullRow: false},
    {
        id: "comment",
        labelTranslationKey: "comment",
        required: false,
        takeFullRow: true,
        InputProps: {
            type: "textarea",
            multiline: true,
            minRows: 2
        }
    }
];

export const addressFields: Field[] = [
    {id: "street", labelTranslationKey: "street", required: true, takeFullRow: false},
    {id: "zipcode", labelTranslationKey: "zip_code", required: true, takeFullRow: false},
    {id: "city", labelTranslationKey: "city", required: true, takeFullRow: false}
];

export const allFields = [
    {name: "email", required: true, defaultValue: ""},
    {name: "password", required: true, defaultValue: ""},
    {name: "confirmPassword", required: true, defaultValue: ""},
    {name: "salutation", required: false, defaultValue: ""},
    {name: "lastname", required: true, defaultValue: ""},
    {name: "firstname", required: true, defaultValue: ""},
    {name: "company", required: true, defaultValue: ""},
    {name: "position", required: false, defaultValue: ""},
    {name: "comment", required: false, defaultValue: ""},
    {name: "street", required: true, defaultValue: ""},
    {name: "zipcode", required: true, defaultValue: ""},
    {name: "city", required: true, defaultValue: ""},
    {name: "country", required: true, defaultValue: ""},
    {name: "language", required: false, defaultValue: "en"},
    {name: "salesDepartment", required: false, defaultValue: false},
    {name: "bodyBuilder", required: false, defaultValue: false},
    {name: "truckBus", required: false, defaultValue: false},
    {name: "infoletter", required: false, defaultValue: false},
    {name: "newsflash", required: false, defaultValue: false}
];
