// Copyright (C) 2022 TANNER AG

import React from "react";
import { Link } from "react-router-dom";
import { runtimeConfig } from "../../lib/utils";
import Box from "@mui/material/Box";

const AppLogo: React.FC = () => {
    return (
        <Box
            sx={(theme) => ({
                position: "absolute",
                top: { xs: 2, md: 4 },
                width: { xs: 100, md: 145 },
                right: 0,
                zIndex: theme.zIndex.appBar + 1
            })}
        >
            <Link to="/">
                <Box
                    component="img"
                    alt="Logo"
                    src={`${runtimeConfig.basePath}/logo.svg`}
                    sx={{ width: "100%", height: "auto" }}
                />
            </Link>
        </Box>
    );
};

export default AppLogo;
