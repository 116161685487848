// Copyright (C) 2023 TANNER AG

import { Fragment, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import GlobalStyles from "@mui/material/GlobalStyles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { ArrowLeftIcon } from "../../components/icons/MANIcons";
import { boldFontFamily } from "../../theme";
import { formatTag, routes } from "../../lib/utils";
import { useNewsCategoriesQuery, useNewsItemQuery } from "../../graphql";
import ContentRichText from "../../components/content/ContentRichtText";
import DownloadsSection from "./downloads/DownloadsSection";
import NavBar from "../../components/navbar/NavBar";
import Spinner from "../../components/Spinner";
import HotspotImage from "../../components/content/hotspot/HotspotImage";
import useDatetime from "../../hooks/usedatetime";
import useLocale from "../../hooks/uselocale";
import useStore from "../../hooks/usestore";
import useTranslation from "../../hooks/usetranslation";

const Divider: React.FC = () => (
    <Typography display="inline" color="#CBD3DC" sx={{ mx: 1 }}>
        •
    </Typography>
);

const NewsFlashView: React.FC = () => {
    const { t } = useTranslation();
    const { fDate } = useDatetime();
    const { locale: storeLocale } = useLocale();
    const navigate = useNavigate();

    let locale = storeLocale;

    if (storeLocale === "de") {
        locale = "de_DE";
    }

    const { slug } = useParams();
    const [{ data, fetching }] = useNewsItemQuery({ variables: { locale, slug } });
    const [{ data: newsCategoriesResponse }] = useNewsCategoriesQuery({ variables: { locale } });
    const [setSeenNews] = useStore((state) => [state.setSeenNews]);

    const news = data?.newsItem;

    useEffect(() => {
        if (news?.id) setSeenNews(news?.id);
    }, [news?.id, setSeenNews]);

    if (fetching) {
        return <Spinner />;
    }

    const categories = news?.categories
        .map((catId) => (newsCategoriesResponse?.newsCategories || []).find((cat) => cat.id === catId)?.name)
        .filter(String)
        .join(", ");

    const tags = (news?.tags || []).map(formatTag).join(" ");

    return (
        <>
            <GlobalStyles styles={{ body: { backgroundColor: "#fff" } }} />
            <NavBar links={[{ label: t("news"), to: routes.newsFlash }, { label: news?.title }]} />
            <Container sx={{ my: 4 }} disableGutters={true}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={8}>
                        <Typography variant="subtitle2" color="secondary" sx={{ lineHeight: 1, mt: 1 }}>
                            {fDate(news?.publication)}
                        </Typography>
                        <Typography variant="h2" color="#161616" sx={{ my: 1.5 }}>
                            {news?.title}
                        </Typography>
                        <Box>
                            <Typography display="inline" color="#4E6174" sx={{ fontFamily: boldFontFamily }}>
                                {news?.author}
                            </Typography>
                            <Divider />
                            <Typography display="inline" color="textSecondary">
                                {t("category")}
                            </Typography>
                            <Typography display="inline" color="secondary" sx={{ ml: 1, fontFamily: boldFontFamily }}>
                                {categories}
                            </Typography>
                            {tags && (
                                <>
                                    <Divider />
                                    <Typography display="inline" color="textSecondary">
                                        {t("tags")}
                                    </Typography>
                                    <Typography
                                        display="inline"
                                        color="secondary"
                                        sx={{ ml: 1, fontFamily: boldFontFamily }}
                                    >
                                        {tags}
                                    </Typography>
                                </>
                            )}
                        </Box>
                    </Grid>
                    {news?.contentBlocks.map((contentBlock, index) => (
                        <Fragment key={index}>
                            {contentBlock.content && (
                                <Grid item xs={12} md={8} paddingTop={4} sx={{ color: "#161616" }}>
                                    <ContentRichText content={contentBlock.content} />
                                </Grid>
                            )}
                            {!!contentBlock.hotspots?.length && (
                                <Grid item xs={12} md={8} marginTop={4} marginBottom={2} sx={{ color: "#161616" }}>
                                    <HotspotImage src={contentBlock?.image} hotspots={contentBlock?.hotspots} />
                                </Grid>
                            )}
                            {!!contentBlock.assets.length && (
                                <Grid
                                    item
                                    xs={12}
                                    md={10}
                                    paddingY={4}
                                    marginBottom={2}
                                    sx={{ backgroundColor: "grey.50" }}
                                >
                                    <DownloadsSection
                                        title={contentBlock.title}
                                        assets={contentBlock.assets}
                                        assetHeight={140}
                                    />
                                </Grid>
                            )}
                        </Fragment>
                    ))}
                    <Grid item xs={12} md={10} marginTop={9}>
                        <Button
                            color="primary"
                            startIcon={<ArrowLeftIcon color="secondary" />}
                            onClick={() => {
                                navigate(routes.newsFlash);
                            }}
                        >
                            {t("to_overview")}
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default NewsFlashView;
