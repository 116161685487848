// Copyright (C) 2022 TANNER AG

import React from "react";
import Typography from "@mui/material/Typography";
import Section from "../../../../components/Section";
import { Calculation } from "../../../../graphql";
import Stack from "@mui/material/Stack";
import ProjectCalculationItem from "./ProjectCalculationItem";

type Props = {
    projectId?: string;
    calculations?: Pick<Calculation, "id" | "name" | "data" | "visible" | "modified">[];
};

const ProjectCalculation: React.FC<Props> = ({ projectId, calculations = [] }) => {
    if (!calculations.length) {
        return null;
    }

    return (
        <Section sx={{ mb: 5 }}>
            <Typography variant="h3">Total Cost of Ownership</Typography>
            <Stack spacing={2} sx={{ mt: 2 }}>
                {calculations?.map((calculation) => (
                    <ProjectCalculationItem key={calculation.id} projectId={projectId} calculation={calculation} />
                ))}
            </Stack>
        </Section>
    );
};

export default ProjectCalculation;
