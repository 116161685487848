// Copyright (C) 2022 TANNER AG

import React from "react";
import { Calculation, UpdateMode, useUpdateProjectCalcMutation } from "../../../../graphql";
import Stack from "@mui/material/Stack";
import ListItemBase from "../../../../components/listitem/ListItemBase";
import GroupAttr from "../../../../components/GroupAttr";
import Button from "@mui/material/Button";
import { ChartOutlineIcon, DeleteIcon, EditIcon, HideIcon, ShowIcon } from "../../../../components/icons/MANIcons";
import IconButton from "@mui/material/IconButton";
import useDatetime from "../../../../hooks/usedatetime";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../lib/utils";
import Tooltip from "@mui/material/Tooltip";
import useTranslation from "../../../../hooks/usetranslation";

type Props = {
    projectId?: string;
    calculation: Pick<Calculation, "id" | "name" | "data" | "visible" | "modified">;
};

const ProjectCalculationItem: React.FC<Props> = ({ projectId = "", calculation }) => {
    const navigate = useNavigate();
    const { fDate } = useDatetime();
    const [{ fetching }, updateCalc] = useUpdateProjectCalcMutation();
    const { t } = useTranslation();

    const handleVisibility = () =>
        updateCalc({
            where: { projectId, calcId: calculation.id },
            data: { visible: !calculation.visible, mode: UpdateMode.Update }
        });

    const handleDelete = () =>
        updateCalc({ where: { projectId, calcId: calculation.id }, data: { mode: UpdateMode.Remove } });

    const handleEdit = () => {
        if (!projectId) {
            return;
        }

        const params = new URLSearchParams();
        params.set("project", projectId);
        params.set("calc", calculation.id);

        navigate({ pathname: routes.tco, search: params.toString() });
    };

    return (
        <ListItemBase
            key={calculation.id}
            id={calculation.id}
            name={calculation.name}
            icon={<ChartOutlineIcon color="secondary" sx={{ fontSize: { xs: 34, sm: 40 } }} />}
            inactive={!calculation.visible}
            action={
                <>
                    <Button onClick={handleEdit} startIcon={<EditIcon color="secondary" />}>
                        Edit calculation
                    </Button>
                    <Tooltip title={t(calculation.visible ? "item_enabled_info" : "item_disabled_info")}>
                        <IconButton size="small" color="primary" onClick={handleVisibility} disabled={fetching}>
                            {calculation.visible ? (
                                <ShowIcon fontSize="small" color="secondary" />
                            ) : (
                                <HideIcon fontSize="small" color="primary" />
                            )}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t("item_remove_info")}>
                        <IconButton size="small" color="primary" onClick={handleDelete} disabled={fetching}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </>
            }
        >
            <Stack direction="row" spacing={4}>
                <GroupAttr label="Last updated" value={fDate(calculation.modified)} minWidth="auto" />
            </Stack>
        </ListItemBase>
    );
};

export default ProjectCalculationItem;
