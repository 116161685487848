// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { CloseIcon } from "../icons/MANIcons";

type Props = {
    title?: string;
    subTitle?: string;
    onClose?(): void;
};

const DialogHead: React.FC<Props> = ({ title, subTitle, onClose }) => {
    return (
        <Stack direction="row" sx={{ alignItems: "flex-start" }}>
            <Stack>
                {subTitle && (
                    <Typography variant="h5" color="secondary" textTransform="uppercase">
                        {subTitle}
                    </Typography>
                )}
                {title && (
                    <Typography gutterBottom variant="h4" textTransform="uppercase" sx={{ fontSize: 36 }}>
                        {title}
                    </Typography>
                )}
            </Stack>
            {onClose && (
                <IconButton onClick={onClose} sx={{ ml: "auto" }}>
                    <CloseIcon sx={{ fontSize: 16 }} />
                </IconButton>
            )}
        </Stack>
    );
};

export default DialogHead;
