// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ListItemWrapper } from "./ListItemRegular";
import Skeleton from "@mui/material/Skeleton";

type Props = {
    border?: boolean;
};

const ListItemRegularLoader: React.FC<Props> = ({ border }) => {
    return (
        <ListItemWrapper border={border}>
            <Stack sx={{ p: 1 }}>
                <Skeleton variant="rectangular" width={120} height={120} sx={{ borderRadius: 1 }} />
            </Stack>
            <Stack sx={{ p: 1, flexGrow: 1 }}>
                <Stack sx={{ mr: 6 }}>
                    <Typography
                        variant="h4"
                        sx={{ lineHeight: "32px", fontSize: 18, color: "inherit" }}
                        className="heading"
                        gutterBottom
                    >
                        <Skeleton />
                    </Typography>
                    {Array.from(Array(4).keys()).map((key) => (
                        <Skeleton key={key} height={14} width="80%" />
                    ))}
                </Stack>
                <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center", mt: 0.5 }}>
                    <div />
                    <div>
                        <Skeleton variant="rectangular" width={140} height={35} />
                    </div>
                </Stack>
            </Stack>
        </ListItemWrapper>
    );
};

export default ListItemRegularLoader;
