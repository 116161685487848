// Copyright (C) 2023 TANNER AG

import { Provider } from "urql";
import { Routes, Route, useLocation } from "react-router-dom";
import { Suspense, lazy, useEffect } from "react";

import { AuthProvider } from "../hooks/useauth";
import { routes, dsgRoutes } from "../lib/utils";
import AppBar from "./appbar/AppBar";
import Bootstrap from "./Bootstrap";
import BuscastView from "../views/buscast/BuscastView";
import CustomerView from "../views/customer/CustomerView";
import DownloadsView from "../views/downloads/DownloadsView";
import DSGAppBar from "./appbar/dsg/AppBar";
import EMobilityView from "../views/emobility/EMobilityView";
import Footer from "./footer";
import HomeView from "../views/home/HomeView";
import NewsRouter from "../views/news/NewsRouter";
import NewsUnsubscribeView from "../views/news/NewsUnsubscribeView";
import NotFoundPageView from "../views/NotFoundPageView";
import PageRouter from "../views/page/PageRouter";
import PasswordForgottenView from "../views/reset-password/PasswordForgottenView";
import ProductOverview from "../views/products/overview/ProductOverview";
import ProductsView from "../views/products/ProductsView";
import ProfileEditView from "../views/profile/ProfileEditView";
import ProjectsView from "../views/projects/PojectsView";
import RegisterApproveStatusView from "../views/register/RegisterApproveStatusView";
import RegisterView from "../views/register/RegisterView";
import ResetPasswordView from "../views/reset-password/ResetPasswordView";
import SideButtons from "./sidebuttons/SideButtons";
import Spinner from "./Spinner";
import ThanksForConfirmView from "../views/register/ThanksForConfirmView";
import ThanksForRegisterView from "../views/register/ThanksForRegisterView";
import ToolsView from "../views/tools/ToolsView";
import TransportView from "../views/transport/TransportView";
import useClient from "../hooks/useclient";
import SsoLoginView from "../views/login/SsoLoginView";
import { ChatBox } from "./chatbox/ChatBox";
import ChatboxProvider from "./chatbox/useChatBox";

const TcoView = lazy(() => import("../views/tco/TcoView"));
const SearchView = lazy(() => import("../views/search/SearchView"));

const DSGRouter: React.FC = () => {
    return (
        <Routes>
            <Route path={`${dsgRoutes.root}/*`} element={<ProductOverview />} />
            <Route path={`${dsgRoutes.products}/*`} element={<ProductsView />} />
            <Route path={`${dsgRoutes.projects}/*`} element={<ProjectsView />} />
            <Route path={`${dsgRoutes.tools}/*`} element={<ToolsView />} />
        </Routes>
    );
};

const MainRouter: React.FC = () => {
    return (
        <Routes>
            <Route path={routes.home} element={<HomeView />} />
            <Route path={`${routes.page}/:pageId/*`} element={<PageRouter />} />
            <Route path={`${routes.buscasts}/*`} element={<BuscastView />} />
            <Route path={`${routes.eMobility}/*`} element={<EMobilityView />} />
            <Route path={`${routes.transportSolutions}/*`} element={<TransportView />} />
            <Route
                path={routes.tco}
                element={
                    <Suspense fallback={<Spinner />}>
                        <TcoView />
                    </Suspense>
                }
            />
            <Route
                path={routes.search}
                element={
                    <Suspense fallback={<Spinner />}>
                        <SearchView />
                    </Suspense>
                }
            />
            <Route path={`${routes.newsFlash}/*`} element={<NewsRouter />} />
            <Route path={routes.downloads} element={<DownloadsView />} />
            <Route path={routes.aiAssistant} element={<ChatBox />} />
            <Route path={routes.profile} element={<ProfileEditView />} />
            <Route path="*" element={<NotFoundPageView />} />
        </Routes>
    );
};

const AuthRouter: React.FC = () => {
    return (
        <Routes>
            <Route
                path={`${routes.home}*`}
                element={
                    <>
                        <AppBar />
                        <SideButtons />
                        <MainRouter />
                    </>
                }
            />
            <Route
                path={`${routes.dsg.root}/*`}
                element={
                    <>
                        <DSGAppBar />
                        <DSGRouter />
                    </>
                }
            />
        </Routes>
    );
};

const AppRouter: React.FC = () => {
    const { client } = useClient();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Routes>
            <Route path={`${routes.customers}/:id/*`} element={<CustomerView />} />
            <Route path={routes.passwordForgotten} element={<PasswordForgottenView />} />
            <Route path={routes.samlSso} element={<SsoLoginView />} />
            <Route path={routes.newsFlashUnsubscribe} element={<NewsUnsubscribeView />} />
            <Route path={routes.resetPassword} element={<ResetPasswordView />} />
            <Route path={routes.register} element={<RegisterView />} />
            <Route path={routes.thanksForRegister} element={<ThanksForRegisterView />} />
            <Route path={routes.thanksForConfirming} element={<ThanksForConfirmView />} />
            <Route path={routes.registerSuccess} element={<RegisterApproveStatusView />} />
            <Route path={routes.registerDisabled} element={<RegisterApproveStatusView />} />
            <Route
                path="/*"
                element={
                    <>
                        <Provider value={client}>
                            <AuthProvider>
                                <ChatboxProvider>
                                <AuthRouter />
                                <Footer />
                                <Bootstrap />
                                </ChatboxProvider>
                            </AuthProvider>
                        </Provider>
                    </>
                }
            />
        </Routes>
    );
};

export default AppRouter;
