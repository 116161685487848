// Copyright (C) 2023 TANNER AG

import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { routes } from "../../lib/utils";
import RichText from "../content/RichText";
import useAsset from "../../hooks/useasset";
import useFallbackImage from "../../hooks/useFallbackImage";

type Props = {
    title: string;
    slug: string;
    thumbnail?: string;
    description?: string;
};

const NewsWidgetItem: React.FC<Props> = ({ title, slug, thumbnail, description }) => {
    const navigate = useNavigate();
    const { createImageUrl } = useAsset();
    const { fallbackImage } = useFallbackImage();

    return (
        <Box onClick={() => navigate(`${routes.newsFlash}/${slug}`)} sx={{ cursor: "pointer" }}>
            <Grid container>
                <Grid item xs={4}>
                    <Box
                        component="img"
                        alt="News thumbnail"
                        src={thumbnail ? createImageUrl(thumbnail) : fallbackImage}
                        sx={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={8} paddingLeft={2.5}>
                    <Typography variant="h5">{title}</Typography>
                    <RichText
                        html={description}
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "3",
                            WebkitBoxOrient: "vertical"
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default NewsWidgetItem;
