// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import { Project } from "../../../../graphql";
import { CloseIcon, ShowIcon } from "../../../../components/icons/MANIcons";
import Button from "@mui/material/Button";
import Dialog from "../../../../components/dialog/Dialog";
import useTranslation from "../../../../hooks/usetranslation";
import ProjectCustomerPreviewDetails from "./ProjectCustomerPreviewDetails";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

type Props = {
    project?: Pick<Project, "id" | "name" | "uuid" | "customerPassword">;
};

const ProjectCustomerPreview: React.FC<Props> = ({ project }) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Button variant="contained" color="secondary" startIcon={<ShowIcon />} onClick={handleOpen}>
                {t("customer_preview")}
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="lg">
                <DialogContent sx={{ pt: 0 }}>
                    {project?.uuid && project?.customerPassword && (
                        <ProjectCustomerPreviewDetails uuid={project?.uuid} password={project?.customerPassword} />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} startIcon={<CloseIcon />} variant="outlined">
                        {t("close_preview")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ProjectCustomerPreview;
