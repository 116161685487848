// Copyright (C) 2022 TANNER AG

import React from "react";
import Typography from "@mui/material/Typography";
import { Download } from "../../../../graphql";
import Stack from "@mui/material/Stack";
import Section from "../../../../components/Section";
import ProjectDownloadListItem from "./ProjectDownloadListItem";
import useTranslation from "../../../../hooks/usetranslation";

type Props = {
    projectId?: string;
    downloads: { visible?: boolean; item: Pick<Download, "id" | "name" | "size" | "mime"> }[];
};

const ProjectDownloadList: React.FC<Props> = ({ projectId = "", downloads }) => {
    const { t } = useTranslation();

    if (!downloads.length) {
        return null;
    }

    return (
        <Section sx={{ mb: 5 }}>
            <Typography variant="h3">{t("additional_downloads")}</Typography>
            <Stack spacing={2} sx={{ mt: 2 }}>
                {downloads.map((download) => (
                    <ProjectDownloadListItem
                        key={download.item.id}
                        download={download.item}
                        visible={download.visible}
                        projectId={projectId}
                    />
                ))}
            </Stack>
        </Section>
    );
};

export default ProjectDownloadList;
