// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";

import { SearchIcon } from "../../components/icons/MANIcons";
import FormInput from "../../components/inputs/FormInput";
import useTranslation from "../../hooks/usetranslation";

type Props = {
    setSearchParam: (searchText: string) => void;
    initialSearchText?: string;
};

const SearchBar: React.FC<Props> = ({ setSearchParam, initialSearchText }) => {
    const { t } = useTranslation();
    const [searchText, setSearchText] = useState(initialSearchText ?? "");

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setSearchParam(searchText);
    };

    return (
        <Box component="form" onSubmit={handleSubmit} width={{ sx: "100%", md: "40%" }}>
            <Stack direction="row" alignItems="center" spacing={1}>
                <FormInput
                    id="searchText"
                    placeholder={`${t("search_files")}...`}
                    InputProps={{
                        value: searchText,
                        onChange: (event) => setSearchText(event.currentTarget.value),
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
                <Button type="submit" variant="contained" color="secondary" onClick={() => {}}>
                    {t("search")}
                </Button>
            </Stack>
        </Box>
    );
};

export default SearchBar;
