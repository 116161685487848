// Copyright (C) 2023 TANNER AG

import {useNavigate} from "react-router-dom";
import {useState} from "react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import {allFields} from "../profile/fields";
import {isValidEmail, isValidPassword} from "../../lib/validations";
import {routes} from "../../lib/utils";
import AppToolbar from "../../components/apptoolbar/AppToolbar";
import BaseAppBar from "../../components/appbar/BaseAppBar";
import Footer from "../../components/footer";
import HTTPClient from "../../lib/HTTPClient";
import RegisterFirstStep from "../profile/ProfileFirstStep";
import RegisterSecondStep from "../profile/ProfileSecondStep";
import Spinner from "../../components/Spinner";
import TermsOfUseDialog from "./TermsOfUseDialog";
import useLocale from "../../hooks/uselocale";
import useTranslation from "../../hooks/usetranslation";
import DataProtectionDialog from "./DataProtectionDialog";

enum Step {
    First,
    Second
}

export type FormFieldsObj = { [key: string]: { value: any; error?: string } };

const RegisterView: React.FC = () => {
    const {t} = useTranslation();
    const {locale} = useLocale();
    const navigate = useNavigate();
    const [step, setStep] = useState<Step>(Step.First);
    const [isOpenTermsOfUseDialog, setOpenTermsOfUseDialog] = useState(false);

    const [isOpenDataProtectionDialog, setOpenDataProtectionDialog] = useState(false);
    const [formFields, setFormFields] = useState<FormFieldsObj>({});
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [isTermsOfUseAccepted, setTermsOfUseAccepted] = useState({value: false, error: false});

    const goBack = () => navigate(-1);

    const goNextStep = () => setStep((prevStep) => prevStep + 1);
    const goPreviousStep = () => setStep((prevStep) => prevStep - 1);

    const updateFormField = (name: string, value: any) => {
        setFormFields((prevForm) => ({...prevForm, [name]: {value, error: undefined}}));
    };

    const setFormFieldError = (name: string, error: string) => {
        setFormFields((prevForm) => ({...prevForm, [name]: {...prevForm[name], error}}));
    };

    const handleGoNextStep = () => {
        const allValues: { [key: string]: string } = {};
        let isError = false;
        allFields.forEach((field) => {
            allValues[field.name] = formFields[field.name]?.value ?? field.defaultValue;
            if (field.required && !allValues[field.name]) {
                isError = true;
                setFormFieldError(field.name, t("required"));
            }
        });

        if (!isValidEmail(allValues.email)) {
            isError = true;
            setFormFieldError("email", t("invalid_email"));
        }
        if (!isValidPassword(allValues.password, allValues.confirmPassword)) {
            isError = true;
            setFormFieldError("password", t("invalid_password"));
            setFormFieldError("confirmPassword", t("invalid_password"));
        }
        if (allValues.comment.length > 500) {
            isError = true;
            setFormFieldError("comment", t("invalid_comment"));
        }

        if (isError) {
            return window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        }

        if (!isTermsOfUseAccepted.value) {
            return setTermsOfUseAccepted({value: false, error: true});
        }

        if (step === Step.Second) {
            setLoading(true);
            HTTPClient.POST("/user/register", allValues)
                .then(() => {
                    navigate(routes.thanksForRegister);
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            goNextStep();
        }
    };

    const renderStep = () => {
        switch (step) {
            case Step.First:
                return <RegisterFirstStep formFields={formFields} updateFormField={updateFormField}/>;
            case Step.Second:
                return <RegisterSecondStep formFields={formFields} updateFormField={updateFormField}/>;
        }
    };

    const renderTermsOfUse = () => (
        <Typography
            display="inline"
            onClick={(event) => {
                event.preventDefault();
                setOpenTermsOfUseDialog(true);
            }}
            sx={{textDecoration: "underline"}}
        >
            {t("terms_of_use")}
        </Typography>
    );
    const renderDataProtection = () => (
        <Typography
            display="inline"
            onClick={(event) => {
                event.preventDefault();
                setOpenDataProtectionDialog(true);
            }}
            sx={{textDecoration: "underline"}}
        >
            {t("data_protection_title")}
        </Typography>
    );

    if (isLoading) return <Spinner/>;

    return (
        <>
            <BaseAppBar toolbar={<AppToolbar isUserAuthenticated={false}/>}/>
            <Container disableGutters sx={{marginBottom: 4}}>
                <Box marginTop={4} marginBottom={4} marginX={2}>
                    {step === Step.First ? (
                        <Typography fontSize={14}>{t("register_intro")}</Typography>
                    ) : (
                        <Typography variant="h4">{t("user_information")}</Typography>
                    )}
                </Box>

                <Grid container spacing={2} padding={2} sx={{backgroundColor: "white"}}>
                    {renderStep()}

                    <Grid item xs={12}>
                        <Typography fontSize={14}>{t("have_difficulties")}</Typography>
                        <Typography fontSize={14}>
                            {t("please_contact")}{" "}
                            <a href="mailto:mybus@man.eu" style={{color: "inherit"}}>
                                mybus@man.eu
                            </a>
                        </Typography>

                        <Typography variant="subtitle2" marginTop={2}>
                            {t("information_on_copyright")}
                        </Typography>
                        <Typography fontSize={14}>{t("information_on_copyright_content")}</Typography>

                        <Typography fontSize={14} marginTop={2}>
                            {t("password_hint")}
                            <br />
                            {t("news_flash_description")}
                        </Typography>

                        <Typography variant="subtitle2" marginTop={2}>
                            {t("note")}
                        </Typography>
                        <Typography fontSize={14}>{t("note_content_1")}</Typography>
                        <Typography fontSize={14} marginTop={1}>
                            {t("note_content_2")}
                        </Typography>

                        <Typography fontSize={14} marginTop={2}>
                            © {new Date().getFullYear()} MAN Truck & Bus SE, Munich
                        </Typography>
                    </Grid>

                    {step === Step.First && (
                        <>
                            <Grid item xs={0} md={6}/>
                            <Grid item xs={12} md={6}>
                                <FormControl error={isTermsOfUseAccepted.error}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isTermsOfUseAccepted.value}
                                                onChange={(_, checked) =>
                                                    setTermsOfUseAccepted({value: checked, error: false})
                                                }
                                            />
                                        }
                                        label={
                                            locale === "de" ? (
                                                <>
                                                    {"Bitte unsere "}
                                                    {renderTermsOfUse()} & {renderDataProtection()}
                                                    {" bestätigen"}
                                                </>
                                            ) : (
                                                <>
                                                    {"Please confirm our "}
                                                    {renderTermsOfUse()} & {renderDataProtection()}
                                                </>
                                            )
                                        }
                                    />
                                    {isTermsOfUseAccepted.error && <FormHelperText>{t("required")}</FormHelperText>}
                                </FormControl>
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12} md={6}>
                        <Button variant="contained" fullWidth onClick={step === Step.First ? goBack : goPreviousStep}>
                            {t("back")}
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button variant="contained" fullWidth onClick={handleGoNextStep}>
                            {t(step === Step.Second ? "done" : "next")}
                        </Button>
                    </Grid>
                    {isError && (
                        <Grid item xs={12}>
                            <Alert severity="error">{t("error")}</Alert>
                        </Grid>
                    )}
                </Grid>
            </Container>
            <Footer/>
            <TermsOfUseDialog isOpen={isOpenTermsOfUseDialog} onClose={() => setOpenTermsOfUseDialog(false)}/>
            <DataProtectionDialog isOpen={isOpenDataProtectionDialog}
                                  onClose={() => setOpenDataProtectionDialog(false)}/>
        </>
    );
};

export default RegisterView;
