// Copyright (C) 2022 TANNER AG

import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import useTranslation from "../../../hooks/usetranslation";
import Stack from "@mui/material/Stack";
import ListItemRegularLoader from "../../../components/listitem/regular/ListItemRegularLoader";
import useLocale from "../../../hooks/uselocale";
import { useToolsQuery } from "../../../graphql";
import ToolsListItem from "./ToolsListItem";
import ToolDetails from "../details/ToolDetails";
import useTags from "../../../hooks/usetags";
import TagList from "../../../components/TagList";
import useParam from "../../../hooks/useparam";

const ToolsOverview = () => {
    const [id, setId] = useParam("id");
    const { t } = useTranslation();
    const { locale } = useLocale();
    const [{ data, fetching }] = useToolsQuery({ variables: { locale } });
    const { tags, activeTags, toggleTag } = useTags(data?.tools);

    return (
        <>
            <Container sx={{ py: 4 }}>
                <Typography variant="h2" sx={{ mb: 4 }}>
                    {t("my_tools")}
                </Typography>
                <TagList tags={tags} activeTags={activeTags} toggleTag={toggleTag} />
                <Stack spacing={2}>
                    {!fetching &&
                        data?.tools
                            ?.filter(
                                (tool) => !activeTags.length || activeTags.every((tag) => tool.keywords.includes(tag))
                            )
                            ?.map((tool) => (
                                <ToolsListItem
                                    key={tool.id}
                                    id={tool.id}
                                    name={tool.name}
                                    intro={tool.intro}
                                    thumbnail={tool.image}
                                    keywords={tool.keywords}
                                    url={tool.url}
                                    onClick={() => setId(tool.id)}
                                />
                            ))}
                    {fetching && Array.from(Array(4).keys()).map((key) => <ListItemRegularLoader key={key} />)}
                </Stack>
            </Container>
            <ToolDetails activeId={id} onClose={() => setId()} />
        </>
    );
};

export default ToolsOverview;
