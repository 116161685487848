// Copyright (C) 2022 TANNER AG

import useParam from "./useparam";
import useStore from "./usestore";
import { useEffect } from "react";

const useProductParam = () => {
    const [id, setId] = useParam("id");
    const setProductId = useStore((state) => state.setProduct);

    useEffect(() => {
        return () => setProductId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (id) {
            setProductId(id);
            setId(undefined, false, true);
        }
    }, [id, setProductId, setId]);
};

export default useProductParam;
