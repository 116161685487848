// Copyright (C) 2023 TANNER AG

import { useAssetsQuery } from "../../../graphql";
import useLocale from "../../../hooks/uselocale";
import useParam from "../../../hooks/useparam";
import DownloadsWidget from "../../../components/downloadswidget/DownloadsWidget";

const DOWNLOADS_LIMIT = 9;

const HomeDownloads: React.FC = () => {
    const { locale } = useLocale();
    const [searchParam] = useParam("q", "");
    const [{ data, fetching }] = useAssetsQuery({
        variables: {
            locale,
            limit: DOWNLOADS_LIMIT,
            search: searchParam
        }
    });
    const assets = data?.assets.assets ?? [];
    const total = data?.assets.total ?? 0;

    return <DownloadsWidget searchQuery={searchParam} assets={assets} total={total} loading={fetching} />;
};

export default HomeDownloads;
