// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import Dialog from "../../dialog/Dialog";
import { CloseIcon, LocationIcon } from "../../icons/MANIcons";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import useLocale from "../../../hooks/uselocale";
import DialogHead from "../../dialog/DialogHead";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import FormSelect from "../../inputs/FormSelect";
import useTranslation from "../../../hooks/usetranslation";
import useActiveProject from "../../../hooks/useactiveproject";
import { useProjectOptionsQuery } from "../../../graphql";
import FocusCountries from "./FocusCountries";

type Props = {
    countries?: string[];
};

const CountrySwitch: React.FC<Props> = ({ countries = [] }) => {
    const { country, setCountry, regionNames, locale } = useLocale();
    const [open, setOpen] = useState(false);
    const [{ data }] = useProjectOptionsQuery({ variables: { locale }, pause: Boolean(!open || countries?.length) });
    const { t } = useTranslation();
    const { id: projectId, data: projectData } = useActiveProject();

    const handleClose = () => setOpen(false);

    const handleChange = (value: string) => {
        setCountry(value);
        handleClose();
    };

    return (
        <>
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    px: 1,
                    py: 0.25,
                    border: "1px solid",
                    borderColor: "grey.100",
                    alignItems: "center",
                    borderRadius: 1
                }}
            >
                <LocationIcon sx={{ color: "text.secondary", fontSize: 16 }} />
                <Typography color="textSecondary" variant="body2">
                    {projectId
                        ? `${regionNames.of(projectData?.tenderCountry ?? country)} (${t("project")})`
                        : regionNames.of(country)}
                </Typography>
                {!projectId && (
                    <Link onClick={() => setOpen(true)} variant="body2">
                        {t("change")}
                    </Link>
                )}
            </Stack>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <DialogHead title={t("country_selection")} onClose={handleClose} />
                    <FocusCountries
                        focusCountries={data?.countries?.focusCountries}
                        selectedCountry={country}
                        onClick={handleChange}
                    />
                    <FormSelect
                        id="country"
                        label={t("more_countries")}
                        options={
                            countries?.length
                                ? countries?.map((country) => ({
                                      label: regionNames.of(country) ?? country,
                                      value: country
                                  }))
                                : data?.countries?.countries ?? []
                        }
                        SelectProps={{ value: country, onChange: (e) => handleChange(e.currentTarget.value) }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} startIcon={<CloseIcon />} variant="outlined">
                        {t("close")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CountrySwitch;
