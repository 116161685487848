// Copyright (C) 2023 TANNER AG

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import useTranslation from "../hooks/usetranslation";

export default function NotFoundPageView() {
    const { t } = useTranslation();

    return (
        <Container>
            <Typography variant="h2" textAlign="center" marginTop={16}>
                {t("page_not_found")}
            </Typography>
        </Container>
    );
}
