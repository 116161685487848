// Copyright (C) 2022 TANNER AG

import React from "react";
import { Project } from "../../../graphql";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import ProjectStatus from "../../../components/ProjectStatus";
import GroupAttr from "../../../components/GroupAttr";
import useDatetime from "../../../hooks/usedatetime";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import ProjectContextActions from "./ProjectContextActions";
import useStore from "../../../hooks/usestore";
import shallow from "zustand/shallow";
import useBreakpoint from "../../../hooks/usebreakpoint";
import ProjectCustomerSite from "./customersite/ProjectCustomerSite";
import useTranslation from "../../../hooks/usetranslation";
import ProjectCustomerPreview from "./customersite/ProjectCustomerPreview";

type Props = {
    project?: Pick<Project, "id" | "name" | "status" | "created" | "modified" | "uuid" | "customerPassword">;
};

const ProjectToolbar: React.FC<Props> = ({ project }) => {
    const { down } = useBreakpoint("md");
    const [showDetails, setShowDetails] = useStore((state) => [state.projectDetails, state.setProjectDetails], shallow);
    const { fDate } = useDatetime();
    const { t } = useTranslation();

    if (down) {
        return (
            <Stack spacing={2} my={2}>
                <Stack direction="row">
                    <ProjectCustomerPreview project={project} />
                    <Stack direction="row" spacing={1} sx={{ ml: "auto" }}>
                        <ProjectCustomerSite project={project} />
                        {project?.id && <ProjectContextActions projectId={project.id} />}
                    </Stack>
                </Stack>
                <Breadcrumbs separator={<Box sx={{ width: 6, height: 6, bgcolor: "grey.100", borderRadius: "50%" }} />}>
                    <ProjectStatus status={project?.status} />
                    <Link variant="body2" onClick={() => setShowDetails(!showDetails)}>
                        {showDetails ? t("hide_project_details") : t("show_project_details")}
                    </Link>
                </Breadcrumbs>
            </Stack>
        );
    }

    return (
        <Stack
            direction="row"
            sx={{
                alignItems: "center",
                minHeight: { md: 72 }
            }}
        >
            <Breadcrumbs separator={<Box sx={{ width: 6, height: 6, bgcolor: "grey.100", borderRadius: "50%" }} />}>
                <ProjectStatus status={project?.status} />
                <GroupAttr label={t("created")} value={fDate(project?.created)} minWidth="auto" />
                <GroupAttr label={t("last_updated")} value={fDate(project?.modified)} minWidth="auto" />
                <Link variant="body2" onClick={() => setShowDetails(!showDetails)}>
                    {showDetails ? t("hide_project_details") : t("show_project_details")}
                </Link>
            </Breadcrumbs>
            <Stack sx={{ ml: "auto" }} spacing={1.5} direction="row">
                <ProjectCustomerSite project={project} />
                <ProjectCustomerPreview project={project} />
                {project?.id && <ProjectContextActions projectId={project.id} />}
            </Stack>
        </Stack>
    );
};

export default ProjectToolbar;
