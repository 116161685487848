// Copyright (C) 2022 TANNER AG

import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import ProjectFilterInput from "./ProjectFilterInput";
import ProjectFilterStatus from "./ProjectFilterStatus";

const ProjectToolbar: React.FC = () => {
    return (
        <Toolbar disableGutters sx={{ mb: 4 }}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4} lg={4}>
                    <ProjectFilterInput />
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <ProjectFilterStatus />
                </Grid>
                <Grid item xs={0} md={2} lg={4} sx={{ display: { xs: "none", md: "block" } }} />
            </Grid>
        </Toolbar>
    );
};

export default ProjectToolbar;
