// Copyright (C) 2022 TANNER AG

import { useState } from "react";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import { boldFontFamily } from "../../../theme";
import { ClearIcon, CaretDownIcon } from "../../../components/icons/MANIcons";
import { FilterValue } from "../../../graphql";
import useTranslation from "../../../hooks/usetranslation";

type Props = {
    activeFilters: string[];
    id: string;
    name: string;
    options: FilterValue[];
    setActiveFilters: (filters: string[]) => void;
    submitFilters: () => void;
};

const MultiSelectFilter: React.FC<Props> = ({ activeFilters, id, name, options, setActiveFilters, submitFilters }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        submitFilters();
    };

    const handleSelect = (value: string) => {
        if (activeFilters.includes(value)) setActiveFilters(activeFilters.filter((filter) => filter !== value));
        else setActiveFilters([...activeFilters, value]);
    };

    const clearFilters: React.FormEventHandler<HTMLLIElement> = (event) => {
        event.preventDefault();
        setActiveFilters([]);
    };

    return (
        <>
            <Button
                aria-describedby={id}
                onClick={handleOpen}
                endIcon={<CaretDownIcon sx={{ color: "#738497", width: 14, height: 7 }} />}
            >
                <Typography fontFamily={boldFontFamily} color="#4E6174">
                    {name}
                </Typography>
                {!!activeFilters?.length && (
                    <Badge
                        color="secondary"
                        badgeContent={activeFilters?.length}
                        sx={{ marginLeft: 2, marginRight: 1 }}
                    />
                )}
            </Button>
            <Popover
                id={id}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
            >
                {options.map(({ value, name }, index) => (
                    <MenuItem
                        key={index}
                        value={value}
                        divider={options.length - 1 === index}
                        sx={{ paddingLeft: "5px" }}
                        onClick={() => handleSelect(value)}
                    >
                        <Checkbox checked={activeFilters.includes(value)} disableRipple />
                        <ListItemText primary={name} color="text.primary" />
                    </MenuItem>
                ))}
                <MenuItem onClick={clearFilters} sx={{ color: "secondary.main", fontSize: 14 }}>
                    <ClearIcon sx={{ width: 16, marginLeft: "6px", marginRight: "12px" }} />
                    <ListItemText primary={t("clear_filters")} />
                </MenuItem>
            </Popover>
        </>
    );
};

export default MultiSelectFilter;
