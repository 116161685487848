// Copyright (C) 2022 TANNER AG

declare global {
    interface Window {
        deferredPrompt?: Event & { prompt(): void };
        et_eC_Wrapper?(params: { et_et?: string }): void;
    }
}

export const runtimeConfig = {
    basePath: process.env.PUBLIC_URL
};

export const dsgRoutes = {
    root: "",
    products: "/p",
    projects: "/projects",
    tools: "/tools"
};

export const routes = {
    home: "/",
    dsg: {
        root: "/sellingguide",
        products: `/sellingguide${dsgRoutes.products}`,
        projects: `/sellingguide${dsgRoutes.projects}`,
        tools: `/sellingguide${dsgRoutes.tools}`
    },
    tco: "/tco",
    customers: "/c",
    vehicles: "/vehicles",
    buscasts: "/buscasts",
    eMobility: "/e-mobility",
    transportSolutions: "/transport-solutions",
    search: "/search",
    newsFlash: "/newsflash",
    newsFlashUnsubscribe: "/newsflash/unsubscribe",
    downloads: "/downloads",
    aiAssistant: "/ai-assistant",
    page: "/page",
    passwordForgotten: "/password-forgotten",
    samlSso: "/auth/samlsso",
    resetPassword: "/reset-password",
    register: "/register",
    thanksForRegister: "/thanks-for-registration",
    thanksForConfirming: "/thanks-for-confirming",
    registerSuccess: "/registration-success",
    registerDisabled: "/registration-disabled",
    profile: "/profile"
};

export const accessToken = {
    TOKEN_KEY: "access-token",
    get: () => localStorage.getItem(accessToken.TOKEN_KEY),
    set: (token: string) => localStorage.setItem(accessToken.TOKEN_KEY, token),
    clear: () => {
        localStorage.removeItem(accessToken.TOKEN_KEY);
    }
};

export const scrollbarSmall = {
    "&::-webkit-scrollbar": {
        width: 6,
        height: 6
    },

    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        borderRadius: 4
    },

    "&::-webkit-scrollbar-track": {
        backgroundColor: "rgba(0, 0, 0, 0.06)",
        borderRadius: 4
    }
};

export const languageOptions = [
    { value: "en", label: "English" },
    { value: "de", label: "Deutsch" },
    { value: "fr", label: "French", message:"French translation of the page is not available. English is displayed as fallback" },
    { value: "es", label: "Spanish", message:"Spanish translation of the page is not available. English is displayed as fallback." },
    { value: "it", label: "Italian", message: "Italian translation of the page is not available. English is displayed as fallback." }
];

export const formatBytes = (bytes = 0, decimals = 2, label = true) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + (label ? " " + sizes[i] : "");
};

export const getYearOptions = (amount = 6): number[] =>
    Array.from(Array(amount).keys()).map((key) => {
        const date = new Date();
        date.setFullYear(date.getFullYear() + key);
        return date.getFullYear();
    });

export const copyToClipboard = async (data: string) => {
    if (!navigator?.clipboard?.writeText) {
        return;
    }

    try {
        await navigator.clipboard.writeText(data);
    } catch (err) {
        console.log(err);
    }
};

export const compare =
    <T extends {}>(prop: keyof T, order?: "asc" | "desc") =>
    (a: T, b: T): number => {
        if (a[prop] > b[prop]) {
            return order === "desc" ? -1 : 1;
        }
        if (a[prop] < b[prop]) {
            return order === "desc" ? 1 : -1;
        }
        return 0;
    };

export const zeroPad = (num: string | number, places = 2) => String(num).padStart(places, "0");

export const formatNo = (no: (number | string | null | undefined)[]) => no.filter((n) => n).join(".");

export const formatTag = (tag: string) => `#${tag.replace(" ", "")}`;
