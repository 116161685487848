// Copyright (C) 2022 TANNER AG

import React from "react";
import { Project } from "../../../graphql";
import { AddCircleIcon, LinkExternIcon } from "../../../components/icons/MANIcons";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useNavigate, Link } from "react-router-dom";
import useStore from "../../../hooks/usestore";
import { routes } from "../../../lib/utils";
import ProjectUpload from "../../../components/projectupload/ProjectUpload";
import ProjectAddCalc from "./addcalc/ProjectAddCalc";
import useTranslation from "../../../hooks/usetranslation";

type Props = {
    project?: Pick<Project, "id" | "name" | "status" | "created" | "modified">;
};

const ProjectActions: React.FC<Props> = ({ project }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const setProduct = useStore((state) => state.setProject);

    const handleAddProducts = () => {
        if (!project) {
            return;
        }

        setProduct({ id: project.id, name: project.name });
        navigate(routes.dsg.products);
    };

    if (!project) {
        return null;
    }

    return (
        <Stack direction="row" spacing={2} sx={{ flexDirection: { xs: "column", md: "row" } }}>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<AddCircleIcon />}
                onClick={handleAddProducts}
                sx={{ mb: 1 }}
            >
                {t("add_products")}
            </Button>
            <Stack direction="row">
                <ProjectUpload projectId={project.id} />
                <ProjectAddCalc projectId={project.id} />
                <Button
                    startIcon={<LinkExternIcon color="secondary" />}
                    component={Link}
                    to={`${routes.dsg.tools}?id=771`}
                >
                    eSort tool
                </Button>
            </Stack>
        </Stack>
    );
};

export default ProjectActions;
