// Copyright (C) 2022 TANNER AG

import React, { useEffect } from "react";
import useProductCategories from "../../hooks/useproductcategories";
import { useNavigate } from "react-router-dom";
import { routes } from "../../lib/utils";
import Spinner from "../../components/Spinner";

const ProductsRedirect = () => {
    const { silos } = useProductCategories();
    const navigate = useNavigate();

    useEffect(() => {
        const firstCategory = silos?.[0]?.categories?.[0];
        if (!firstCategory) {
            return;
        }
        navigate(`${routes.dsg.products}/${firstCategory?.slug || firstCategory?.id}`, { replace: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [silos]);

    return <Spinner />;
};

export default ProductsRedirect;
