// Copyright (C) 2022 TANNER AG

import { useProductCategoryQuery } from "../graphql";
import useLocale from "./uselocale";
import { useParams } from "react-router-dom";

const useProductCategory = () => {
    const { categoryId } = useParams();
    const { locale } = useLocale();
    const key = categoryId?.toString() ?? "";
    const [{ data, fetching, error }] = useProductCategoryQuery({ variables: { key, locale }, pause: !key });

    return { productCategory: data?.productCategory, fetching, error };
};

export default useProductCategory;
