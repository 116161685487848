// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";

import { useNavQuery } from "../../../graphql";
import AppMenuItem from "./AppMenuItem";
import useLocale from "../../../hooks/uselocale";

const AppMenu: React.FC = () => {
    const { locale } = useLocale();
    const [{ data, fetching }] = useNavQuery({ variables: { locale } });
    const firstMenuItemRef = React.useRef<HTMLDivElement | null>(null);

    const navigationItems = data?.nav ?? [];

    return (
        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1.5, lg: 3 }} marginTop={1}>
            {fetching ? (
                "..."
            ) : (
                <>
                    <div ref={firstMenuItemRef}>
                        <AppMenuItem
                            key={navigationItems[0].id}
                            navItem={navigationItems[0]}
                            anchorRef={firstMenuItemRef}
                        />
                    </div>
                    {navigationItems.slice(1).map((navItem) => (
                        <AppMenuItem key={navItem.id} navItem={navItem} anchorRef={firstMenuItemRef} />
                    ))}
                </>
            )}
        </Stack>
    );
};

export default AppMenu;
