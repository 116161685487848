// Copyright (C) 2022 TANNER AG

import React from "react";
import { SxProps } from "@mui/system";
import Box from "@mui/material/Box";

import useHTMLParser from "../../hooks/usehtmlparser";

type Props = {
    html?: string;
    sx?: SxProps;
};

const RichText: React.FC<Props> = ({ html, sx }) => {
    const { parseHTML } = useHTMLParser();
    if (!html) {
        return null;
    }

    return (
        <Box sx={sx} className="richtext">
            {parseHTML(html)}
        </Box>
    );
};

export default RichText;
