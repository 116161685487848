// Copyright (C) 2022 TANNER AG

import { useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { boldFontFamily } from "../../theme";
import { Filter, SortMethod, useAssetsQuery } from "../../graphql";
import AssetItem from "../../components/asset/AssetItem";
import Filters, { FilterObj } from "./filters/Filters";
import NavBar from "../../components/navbar/NavBar";
import SearchBar from "./SearchBar";
import SortSelect from "./SortSelect";
import Spinner from "../../components/Spinner";
import useLocale from "../../hooks/uselocale";
import useTranslation from "../../hooks/usetranslation";
import useParam from "../../hooks/useparam";

const ASSETS_LIMIT_PER_PAGE = 20;

const getSelectedFiltersInitialValue = (filters: Filter[]) =>
    filters.map((filter) => ({
        key: filter.key,
        values: filter.values.map((filterValue) => filterValue.value)
    }));

type Props = {
    isTab?: boolean;
    activeFilters?: Filter[];
};

const DownloadsView: React.FC<Props> = ({ isTab = false, activeFilters = [] }) => {
    const { t } = useTranslation();
    const { locale } = useLocale();
    const [page, setPage] = useParam("page", "1");
    const [searchParam, setSearchParam] = useParam("q", "");
    const [sortParam, setSortParam] = useState(SortMethod.Latest);
    const [selectedFilters, setSelectedFilters] = useState<{ key: string; values: string[] }[]>(
        getSelectedFiltersInitialValue(activeFilters)
    );
    const [{ data, fetching }] = useAssetsQuery({
        variables: {
            locale,
            limit: ASSETS_LIMIT_PER_PAGE,
            page: Number(page) - 1,
            filters: selectedFilters,
            search: searchParam,
            sort: sortParam === SortMethod.Relevance ? SortMethod.Latest : sortParam
        }
    });

    const totalAssets = data?.assets.total ?? 0;

    const handleSubmitFilters = (filters: FilterObj) => {
        setPage("1");
        setSelectedFilters(
            Object.keys(filters).map((filterKey) => ({
                key: filterKey,
                values: filters[filterKey]
            }))
        );
    };

    const handleSetSearchParam = (searchParam: string) => {
        setSearchParam(searchParam);
        if (searchParam) setSortParam(SortMethod.Relevance);
        else setSortParam(SortMethod.Latest);
    };

    return (
        <>
            {!isTab && <NavBar links={[{ label: t("downloads") }]} />}
            <Container sx={{ mt: 4, mb: 8 }}>
                {!isTab && <Typography variant="h2">{t("downloads")}</Typography>}
                <Box sx={{ display: "flex", justifyContent: "center", mt: isTab ? 0 : 3, mb: 4 }}>
                    <SearchBar setSearchParam={handleSetSearchParam} initialSearchText={searchParam} />
                </Box>

                <Filters
                    filters={data?.assets.filters || []}
                    activeFilters={activeFilters}
                    submitFilters={handleSubmitFilters}
                />

                <Divider sx={{ my: 3 }} />

                <Box display="flex" justifyContent="flex-end">
                    <SortSelect sortParam={sortParam} setSortParam={setSortParam} />
                </Box>

                {fetching ? (
                    <Spinner />
                ) : totalAssets > 0 ? (
                    <Stack direction="row" flexWrap="wrap" marginY={3}>
                        {(data?.assets.assets || []).map((asset) => (
                            <AssetItem
                                key={asset.id}
                                id={asset.id}
                                title={asset.title}
                                type={asset.type}
                                selectedFilter={selectedFilters.find((filter) => filter.key === "languages")?.values}
                            />
                        ))}
                    </Stack>
                ) : (
                    <Typography fontFamily={boldFontFamily} color="textSecondary" marginTop={3}>
                        {t("total_results", { total: totalAssets })}
                    </Typography>
                )}

                {totalAssets > ASSETS_LIMIT_PER_PAGE && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Pagination
                            color="secondary"
                            page={Number(page)}
                            count={Math.ceil(totalAssets / ASSETS_LIMIT_PER_PAGE)}
                            onChange={(_, page) => setPage(String(page))}
                            renderItem={(item) => <PaginationItem {...item} />}
                        />
                    </Box>
                )}
            </Container>
        </>
    );
};

export default DownloadsView;
