// Copyright (C) 2022 TANNER AG

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { ClearIcon } from "../../../components/icons/MANIcons";

type Props = {
    name: string;
    clearFilter: () => void;
};

const ActiveFilter: React.FC<Props> = ({ name, clearFilter }) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            sx={{
                marginTop: 0.5,
                marginRight: 0.5,
                border: "1px solid #ABB6C3",
                borderRadius: "4px",
                height: 40,
                padding: "4px 12px",
                color: "#8F8F8F"
            }}
        >
            <Typography display="inline" color="text.primary" marginRight={1}>
                {name}
            </Typography>
            <ClearIcon fontSize="small" onClick={clearFilter} sx={{ cursor: "pointer" }} />
        </Box>
    );
};

export default ActiveFilter;
