// Copyright (C) 2022 TANNER AG

import React, { ChangeEvent, useEffect, useState } from "react";
import { Project } from "../../../../graphql";
import { CopyIcon, MailIcon } from "../../../../components/icons/MANIcons";
import Button from "@mui/material/Button";
import DialogHead from "../../../../components/dialog/DialogHead";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormInput from "../../../../components/inputs/FormInput";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ProjectCustomerSiteFormPassword from "./ProjectCustomerSiteFormPassword";
import { copyToClipboard, routes } from "../../../../lib/utils";
import Box from "@mui/material/Box";
import useTranslation from "../../../../hooks/usetranslation";

type Props = {
    onClose(): void;
    project?: Pick<Project, "id" | "name" | "uuid" | "customerPassword">;
};

const ProjectCustomerSiteForm: React.FC<Props> = ({ project, onClose }) => {
    const [clipboardText, setClipboardText] = useState("");

    const customerSiteUrl = `${window.location.origin}${routes.customers}/${project?.uuid}`;
    const shareMailLink = `mailto:?subject=Invite to ${project?.name}&body=Credentials:%0D%0AUrl: ${customerSiteUrl}%0D%0APassword: ${project?.customerPassword}`;

    useEffect(() => {
        setClipboardText(`${customerSiteUrl}\n${project?.customerPassword}`);
    }, [customerSiteUrl, project?.customerPassword]);
    const { t } = useTranslation();
    return (
        <>
            <DialogContent sx={{ pb: 0 }}>
                <DialogHead title={t("share_with_customer")} subTitle={project?.name} onClose={onClose} />
                <Stack spacing={2}>
                    <FormInput
                        id="url"
                        label={t("link_customer_website")}
                        helperText={t("following_link")}
                        InputProps={{
                            disabled: true,
                            defaultValue: customerSiteUrl,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        color="secondary"
                                        edge="end"
                                        onClick={() => copyToClipboard(customerSiteUrl)}
                                    >
                                        <CopyIcon fontSize="small" />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <ProjectCustomerSiteFormPassword
                        projectId={project?.id ?? ""}
                        customerPassword={project?.customerPassword ?? ""}
                    />
                </Stack>
                <Box
                    component="textarea"
                    value={clipboardText}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setClipboardText(e.currentTarget.value)}
                    sx={{ display: "none" }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => copyToClipboard(clipboardText)}
                    startIcon={<CopyIcon />}
                    variant="contained"
                    color="secondary"
                >
                    {t("copy_clipboard")}
                </Button>
                <Button startIcon={<MailIcon color="secondary" />} component="a" href={shareMailLink}>
                    {t("share_by_mail")}
                </Button>
            </DialogActions>
        </>
    );
};

export default ProjectCustomerSiteForm;
