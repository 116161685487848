// Copyright (C) 2022 TANNER AG

import React from "react";
import { Route, Routes } from "react-router-dom";
import ToolsOverview from "./overview/ToolsOverview";

const ToolsView = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<ToolsOverview />} />
            </Routes>
        </>
    );
};

export default ToolsView;
