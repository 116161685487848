// Copyright (C) 2022 TANNER AG

import { useEffect, useState } from "react";
import { CustomerProject as ICustomerProject } from "../views/customer/types";
import useLocale from "./uselocale";

const useCustomerProject = (id: string, password: string) => {
    const [fetching, setFetching] = useState(false);
    const [data, setData] = useState<ICustomerProject>();
    const [error, setError] = useState("");
    const { locale: storeLocale } = useLocale();

    let locale = storeLocale;

    if (storeLocale === "de") {
        locale = "de_DE";
    }

    const fetchProject = async () => {
        setFetching(true);
        setError("");

        if (fetching) {
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVICE_URL ?? ""}/api/customers/projects/${id}`, {
                headers: { Authorization: window.btoa(password), "X-Backend-Language": locale }
            });
            if (!response.ok) {
                setError(await response.text());
                return;
            }

            setData(await response.json());
        } catch (err: any) {
            setError(err);
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        if (!id || !password) {
            return;
        }

        fetchProject();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, password, locale]);

    return {
        fetching,
        error,
        project: data
    };
};

export default useCustomerProject;
