// Copyright (C) 2022 TANNER AG

import React from "react";
import { ContentTopic, ContentTypeGroup } from "../../../graphql";
import { formatNo, zeroPad } from "../../../lib/utils";
import Stack from "@mui/material/Stack";
import ContentSectionTocItem from "../../../components/content/section/ContentSectionTocItem";
import Typography from "@mui/material/Typography";
import ContentTopicLink from "../../../components/content/ContentTopicLink";
import useContentTopic from "../../../hooks/usecontenttopic";

type Props = {
    title?: string;
    path?: string;
    topics?: Pick<ContentTopic, "id" | "title" | "no" | "key">[];
    activeTopicKey?: string;
    activeChapterId?: string;
    group: ContentTypeGroup;
};

const ContentSectionToc: React.FC<Props> = ({
    title,
    group,
    topics,
    path = "",
    activeTopicKey = "",
    activeChapterId
}) => {
    const { topic } = useContentTopic(group, [activeTopicKey]);
    return (
        <Stack spacing={1}>
            <Typography variant="h5" gutterBottom color="textSecondary">
                {title}
            </Typography>
            {topics?.map((subTopic) => {
                return (
                    <ContentSectionTocItem
                        key={subTopic.id}
                        title={subTopic.title}
                        to={`${path}/${subTopic.key}`}
                        no={subTopic.no ? zeroPad(subTopic.no) : undefined}
                    >
                        {subTopic?.key === activeTopicKey && (
                            <Stack spacing={0.5} sx={{ px: 2, pb: 1.5 }}>
                                {topic?.topics?.map((contentTopic) => (
                                    <ContentTopicLink
                                        key={contentTopic.id}
                                        id={contentTopic.id}
                                        to={`${path}/${subTopic.key}/${contentTopic.key}`}
                                        title={contentTopic.title}
                                        no={formatNo([subTopic.no, contentTopic.no])}
                                        active={activeChapterId === contentTopic.id}
                                    />
                                ))}
                            </Stack>
                        )}
                    </ContentSectionTocItem>
                );
            })}
        </Stack>
    );
};

export default ContentSectionToc;
