// Copyright (C) 2023 TANNER AG

import { FC } from "react";
import Box from "@mui/material/Box";

type Props = {
    url?: string;
    type?: string;
};

const MediaPlayer: FC<Props> = ({ url, type }) => {
    if (!url) {
        return null;
    }

    return (
        <Box sx={{ mt: 3 }}>
            {type === "audio" ? (
                <Box component="audio" sx={{ maxWidth: 1, width: 450 }} controlsList="nodownload" controls>
                    <source src={url} />
                </Box>
            ) : (
                <Box component="video" sx={{ maxWidth: 1, borderRadius: 1 }} controlsList="nodownload" controls>
                    <source src={url} />
                </Box>
            )}
        </Box>
    );
};

export default MediaPlayer;
