// Copyright (C) 2022 TANNER AG

import React from "react";
import { Download, UpdateMode, useUpdateProjectFileMutation } from "../../../../graphql";
import ListItemDownload from "../../../../components/listitem/ListItemDownload";
import { DeleteIcon, DownloadIcon, HideIcon, ShowIcon } from "../../../../components/icons/MANIcons";
import Button from "@mui/material/Button";
import useTranslation from "../../../../hooks/usetranslation";
import useAsset from "../../../../hooks/useasset";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

type Props = {
    projectId: string;
    download: Pick<Download, "id" | "name" | "size" | "mime" | "modified">;
    visible?: boolean;
};

const ProjectDownloadListItem: React.FC<Props> = ({ projectId, download, visible }) => {
    const [{ fetching }, updateFile] = useUpdateProjectFileMutation();
    const { createDownloadUrl } = useAsset();
    const { t } = useTranslation();

    const handleFileVisibility = () =>
        updateFile({
            where: { id: projectId },
            data: { fileId: download.id, visible: !visible, mode: UpdateMode.Update }
        });

    const handleFileDelete = () =>
        updateFile({ where: { id: projectId }, data: { fileId: download.id, mode: UpdateMode.Remove } });

    return (
        <ListItemDownload
            id={download.id}
            name={download.name}
            size={download.size}
            mime={download.mime}
            modified={download.modified}
            inactive={!visible}
            action={
                <>
                    <Button
                        startIcon={<DownloadIcon color="secondary" />}
                        component="a"
                        href={createDownloadUrl(download.id)}
                        download
                    >
                        {t("download_file")}
                    </Button>
                    <Tooltip title={t(visible ? "item_enabled_info" : "item_disabled_info")}>
                        <IconButton size="small" color="primary" onClick={handleFileVisibility} disabled={fetching}>
                            {visible ? (
                                <ShowIcon fontSize="small" color="secondary" />
                            ) : (
                                <HideIcon fontSize="small" color="primary" />
                            )}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t("item_remove_info")}>
                        <IconButton size="small" color="primary" onClick={handleFileDelete} disabled={fetching}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </>
            }
        />
    );
};

export default ProjectDownloadListItem;
