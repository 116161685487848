// Copyright (C) 2022 TANNER AG

import React from "react";
import { Route, Routes } from "react-router-dom";
import TransportOverview from "./overview/TransportOverview";
import TransportTopic from "./topic/TransportTopic";
import TransportChapter from "./chapter/TransportChapter";

const TransportView = () => {
    return (
        <Routes>
            <Route path="/" element={<TransportOverview />} />
            <Route path="/:topicKey" element={<TransportTopic />} />
            <Route path="/:topicKey/:subTopicKey" element={<TransportChapter />} />
        </Routes>
    );
};

export default TransportView;
