// Copyright (C) 2022 TANNER AG

import React, { useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import { CustomerProduct } from "../types";
import Stack from "@mui/material/Stack";
import ListItemRegular from "../../../components/listitem/regular/ListItemRegular";
import Section from "../../../components/Section";
import CustomerProductDetails from "../CustomerProductDetails";

type Props = {
    products?: CustomerProduct[];
};

const CustomerProjectProducts: React.FC<Props> = ({ products }) => {
    const [activeProduct, setActiveProduct] = useState<CustomerProduct>();

    const categories = useMemo(() => {
        if (!products?.length) {
            return [];
        }

        const groups: { id: number; name: string; products: CustomerProduct[] }[] = [];
        products.forEach((product) => {
            const group = groups.find((group) => group.id === product?.category?.id);
            if (group) {
                group.products.push(product);
            } else {
                groups.push({
                    id: product.category?.id ?? 0,
                    name: product.category?.name ?? "",
                    products: [product]
                });
            }
        });
        return groups;
    }, [products]);

    return (
        <>
            {categories.map((category) => (
                <Section key={category.id} sx={{ mb: 6 }}>
                    <Typography variant="h3" sx={{ mb: 2 }}>
                        {category.name}
                    </Typography>
                    <Stack spacing={2}>
                        {category.products.map((product) => (
                            <ListItemRegular
                                key={product.id}
                                title={product.name}
                                description={product.intro}
                                image={product.image}
                                border
                                onClick={() => setActiveProduct(product)}
                            />
                        ))}
                    </Stack>
                </Section>
            ))}
            <CustomerProductDetails product={activeProduct} onClose={() => setActiveProduct(undefined)} />
        </>
    );
};

export default CustomerProjectProducts;
