// Copyright (C) 2022 TANNER AG

import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

type Props = {
    children?: React.ReactNode;
};

const Banner: React.FC<Props> = ({ children }) => {
    return (
        <Box sx={{ backgroundColor: "grey.800", color: "common.white", py: 4 }}>
            <Container>{children}</Container>
        </Box>
    );
};

export default Banner;
