// Copyright (C) 2022 TANNER AG

import { Client } from "urql";
import { defaultClient, offlineClient } from "../lib/urql";
import { useEffect, useState } from "react";
import useOnline from "./useonline";

const useClient = () => {
    const [client, setClient] = useState<Client>(defaultClient);
    const isOnline = useOnline();

    useEffect(() => {
        if (isOnline) {
            setClient(defaultClient);
            console.debug("Online client is used.");
        } else {
            setClient(offlineClient);
            console.debug("Offline client is used.");
        }
    }, [isOnline]);

    return { client };
};

export default useClient;
