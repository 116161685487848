// Copyright (C) 2022 TANNER AG

import React from "react";
import { AddCircleIcon, MinusCircleIcon } from "../icons/MANIcons";
import useTranslation from "../../hooks/usetranslation";
import useActiveProject from "../../hooks/useactiveproject";
import { UpdateMode, useUpdateProjectProductMutation } from "../../graphql";
import ListItemAction from "../listitem/ListItemAction";

type Props = {
    productId: string;
    added?: boolean;
};

const ProductListItemUpdate: React.FC<Props> = ({ productId, added }) => {
    const { t } = useTranslation();
    const { id } = useActiveProject();
    const [{ fetching }, updateProduct] = useUpdateProjectProductMutation();

    const handleProductUpdate = () =>
        updateProduct({ where: { id }, data: { productId, mode: added ? UpdateMode.Remove : UpdateMode.Update } });

    if (!id) {
        return null;
    }

    return (
        <ListItemAction
            onClick={handleProductUpdate}
            startIcon={added ? <MinusCircleIcon color="inherit" /> : <AddCircleIcon color="secondary" />}
            disabled={fetching}
        >
            <span className="action-text">{t(added ? "remove_from_project" : "add_to_project")}</span>
        </ListItemAction>
    );
};

export default ProductListItemUpdate;
