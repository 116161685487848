// Copyright (C) 2022 TANNER AG

import create from "zustand";
import { persist } from "zustand/middleware";
import { Project } from "../graphql";
import initialNewsCounter from "./initialNewsCounter.json";

type RuntimeStore = {
    locale?: string;
    country?: string;
    openProduct: string;
    activeProject?: Pick<Project, "id" | "name">;
    projectDetails: boolean;
    seenNews: string[];
    seenNewsCounter: number;
    changeRequest: boolean;
    setLocale(locale: string): void;
    setCountry(country: string): void;
    setProduct(product?: string): void;
    setProject(project?: Pick<Project, "id" | "name">): void;
    setProjectDetails(projectDetails: boolean): void;
    setSeenNews(seenNewsId: string): void;
    setSeenNewsCounter(seenNews: number): void;
    setChangeRequest(changeRequest: boolean): void;
};

const useStore = create<RuntimeStore>()(
    persist(
        (set) => ({
            openProduct: "",
            projectDetails: false,
            seenNews: initialNewsCounter,
            seenNewsCounter: initialNewsCounter.length,
            changeRequest: false,
            setLocale: (locale) => set(() => ({ locale })),
            setCountry: (country) => set(() => ({ country })),
            setProduct: (product = "") => set(() => ({ openProduct: product })),
            setProject: (project) => set(() => ({ activeProject: project })),
            setProjectDetails: (projectDetails) => set(() => ({ projectDetails })),
            setSeenNews: (seenNewsId) => set((prevState) => ({ seenNews: [...prevState.seenNews, seenNewsId] })),
            setSeenNewsCounter: (seenNewsCounter) => set(() => ({ seenNewsCounter })),
            setChangeRequest: (changeRequest) => set(() => ({ changeRequest }))
        }),
        {
            name: "dsg-storage",
            partialize: (state) => ({
                locale: state.locale??"de",
                activeProject: state.activeProject,
                seenNews: state.seenNews,
                seenNewsCounter: state.seenNewsCounter
            })
        }
    )
);

export default useStore;
