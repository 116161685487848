// Copyright (C) 2022 TANNER AG

import useLocale from "./uselocale";
import { useProjectsQuery } from "../graphql";
import useParam from "./useparam";
import { useDeferredValue, useMemo } from "react";

const useProjects = () => {
    const [query] = useParam("q");
    const deferredQuery = useDeferredValue(query.toLowerCase());

    const [status] = useParam("status");
    const deferredStatus = useDeferredValue(status);

    const { locale } = useLocale();
    const [{ data, fetching, error }] = useProjectsQuery({ variables: { locale }, requestPolicy: "network-only" });
    const projects = useMemo(() => {
        return (
            data?.projects?.projects?.filter(
                (project) =>
                    (!deferredQuery ||
                        project.name?.toLowerCase().includes(deferredQuery) ||
                        project.gpa?.includes(deferredQuery)) &&
                    (!deferredStatus || project?.status === deferredStatus)
            ) ?? []
        );
    }, [deferredQuery, deferredStatus, data?.projects?.projects]);

    return {
        projects,
        total: data?.projects?.total ?? 0,
        fetching,
        error
    };
};

export default useProjects;
