// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import useTranslation from "../../../hooks/usetranslation";
import { useProductExpertsQuery, useProductQuery } from "../../../graphql";
import DialogContent from "@mui/material/DialogContent";
import Section from "../../../components/Section";
import Typography from "@mui/material/Typography";
import ListItemSmall from "../../../components/listitem/ListItemSmall";
import { ArrowRightIcon } from "../../../components/icons/MANIcons";
import Experts from "./experts/Experts";
import useLocale from "../../../hooks/uselocale";

type Props = {
    productId: string;
    onClose(): void;
};

const ProductAdditional: React.FC<Props> = ({ productId, onClose }) => {
    const { t } = useTranslation();
    const { locale } = useLocale();
    const [{ data }] = useProductQuery({ variables: { key: productId, locale }, pause: !productId });
    const [{ data: expertsData }] = useProductExpertsQuery({ variables: { key: productId }, pause: !productId });

    // TODO check if experts or recommended products exists
    if (!expertsData?.productExperts?.length) {
        return null;
    }

    return (
        <DialogContent sx={{ bgcolor: "grey.50" }}>
            <Experts productName={data?.product?.name} experts={expertsData?.productExperts ?? []} />
            <Section sx={{ display: "none" }}>
                <Typography variant="h3" gutterBottom>
                    {t("recommended_products")}
                </Typography>
                <Stack>
                    {[1, 2].map((_, index) => (
                        <ListItemSmall
                            key={index}
                            action={
                                <Button startIcon={<ArrowRightIcon color="secondary" />}>{t("show_details")}</Button>
                            }
                        />
                    ))}
                </Stack>
            </Section>
            <Stack direction="row" spacing={1} sx={{ mt: 3 }}>
                <Button onClick={onClose} variant="outlined">
                    {t("back_to_overview")}
                </Button>
            </Stack>
        </DialogContent>
    );
};

export default ProductAdditional;
