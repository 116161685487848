// Copyright (C) 2022 TANNER AG

import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const ListItemAction = styled(Button)<ButtonProps & { component?: any; to?: string }>(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        paddingLeft: 11,
        paddingRight: 11,
        minWidth: "auto",
        "& .MuiButton-startIcon": {
            marginLeft: 0,
            marginRight: 0
        },
        "& .action-text": {
            display: "none"
        }
    }
}));

export default ListItemAction;
