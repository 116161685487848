// Copyright (C) 2023 TANNER AG

export default class HTTPClient {
    private static _generateURL(endpoint: string) {
        return `${process.env.REACT_APP_SERVICE_URL ?? ""}${endpoint}`;
    }

    static async GET(endpoint: string) {
        const url = HTTPClient._generateURL(endpoint);

        const response = await fetch(url);
        if (!response.ok) throw new Error("Response is not OK!");

        return response;
    }

    static async POST(endpoint: string, body: any) {
        const url = HTTPClient._generateURL(endpoint);

        const response = await fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)
        });
        if (!response.ok) throw new Error(JSON.stringify(response.status))
        return response;
    }
}
