// Copyright (C) 2022 TANNER AG

import React from "react";
import useAsset from "../hooks/useasset";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

type Props = {
    src?: string;
    children?: React.ReactNode;
};

const HeroBanner: React.FC<Props> = ({ src, children }) => {
    const { createImageUrl } = useAsset();
    return (
        <Root style={{ backgroundImage: `url(${createImageUrl(src)})` }}>
            <Box sx={{ maxWidth: 600 }}>{children}</Box>
        </Root>
    );
};

export const Root = styled(Paper)(({ theme }) => ({
    height: 500,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    textAlign: "center",
    borderRadius: 4,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    color: theme.palette.common.white,
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4)
}));

export default HeroBanner;
