// Copyright (C) 2022 TANNER AG

import React from "react";
import BaseAppBar from "../../components/appbar/BaseAppBar";
import AppToolbar from "../../components/apptoolbar/AppToolbar";

type Props = {
    children?: React.ReactNode;
};

const CustomerAppBar: React.FC<Props> = ({ children }) => {
    return (
        <BaseAppBar disableBorder disableTitle toolbar={<AppToolbar isUserAuthenticated={false} />}>
            {children}
        </BaseAppBar>
    );
};

export default CustomerAppBar;
