// Copyright (C) 2022 TANNER AG

import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

type Props = {
    no?: string | number;
    content?: string;
};

const ContentHeading: React.FC<Props> = ({ content, no }) => {
    if (!content) {
        return null;
    }

    return (
        <Stack direction="row" sx={{ mb: 1 }}>
            {no && (
                <Typography variant="h3" color="secondary" sx={{ minWidth: 65 }}>
                    {no}
                </Typography>
            )}
            <Typography variant="h3">{content}</Typography>
        </Stack>
    );
};

export default ContentHeading;
