import { useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { BouncingDotLoader } from "./BouncingDotLoader";
import {User, useAssetsQuery, useLinkResolverQuery, useUserQuery } from "../../graphql";
import useTranslation from "../../hooks/usetranslation";
import useParam from "../../hooks/useparam";
import useLocale from "../../hooks/uselocale";
import { Button } from "@mui/material";
import DownloadDetailsDialog from "../asset/details/DownloadDetailsDialog";

type MessageType = {
    answer: string;
    endtime: string;
    message: string;
    messageid: string;
    permission: {
        country: string,
        groupColor: string,
        groups: Array<string>,
    };
    references: Array<{ content: string, file: string }>;
    starttime: string;
    status: string;
}

type SingleMessageProps = {
    message: MessageType;
    sender: string;
    isLoading: boolean;
};

export const SingleMessage = (props: SingleMessageProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [showDownloadModal, setShowModal] = useState(false);
    const [{ data: userData }] = useUserQuery();
    const { username } = userData?.user as User;
    const { message: {
        answer, message, status, references
    } } = props;
    const { t } = useTranslation();
    const file = references[0]?.file.split('/').pop() || "" as string;
    const filename = file.replace('.xml', '');
    const [{ data: linkResolverData }] = useLinkResolverQuery({
        variables: { documentId: filename, user: username }
    });
    const { locale } = useLocale();
    const [searchParam2] = useParam("q", linkResolverData?.resolvedLink?.title || "");
    const [{ data }] = useAssetsQuery({
        variables: {
            locale,
            limit: 1,
            search: searchParam2
        }
    });
    const downloadCheck = linkResolverData?.resolvedLink?.url?.split("/")[1] === "assets" ?? false;
    const parseUrl = (urlpath: string | undefined) => {
        const pathname = window.location.href.replace("/ai-assistant", "");
        if (urlpath)
            return pathname + urlpath;
    }

    const onDownloadClickHandler = () => {
        setShowModal(true);

    }
    const asset = data?.assets.assets[0];
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return (
        <>
            <Grid container ref={ref}
                sx={{
                    display: "flex",
                    gridGap: "30%",
                    marginBottom: "20px"
                }}
            >
                <Grid item xs={4} sx={{
                    backgroundColor: "#d2d9e2", borderRadius: "15px", height: "fit-content",
                    padding: "10px"
                }}>
                    <Typography>
                        {message}
                    </Typography>
                </Grid>
                <Grid item xs={4} sx={{
                    backgroundColor: status === "completed" ? "#4092bf" : "white",
                    borderRadius: "15px",
                    height: "fit-content",
                    padding: "10px",
                    marginTop: "50px"
                }}>
                    {(status === "completed" && !downloadCheck) || (downloadCheck && asset) ? (
                        <>
                            {answer !== "" &&
                                <Typography
                                    variant="body2"
                                    sx={{ pr: 2 }}
                                    color="white"
                                    component="div"
                                    dangerouslySetInnerHTML={{ __html: answer }}
                                    gutterBottom
                                />}
                            <Typography
                                sx={{ pr: 2, fontWeight: 500, fontSize: "16px" }}
                                color="black"
                                component="div"
                                gutterBottom
                            >
                                {(linkResolverData && linkResolverData.resolvedLink?.success) ? (
                                    <>
                                        {!downloadCheck ? (
                                            <>
                                                {t("information_source")}: <a href={parseUrl(linkResolverData?.resolvedLink?.url)} rel="noreferrer" target="_blank">{linkResolverData.resolvedLink?.title}</a>
                                            </>
                                        ) : (
                                            <>
                                                {t("information_source")}: <Button onClick={onDownloadClickHandler}>{linkResolverData.resolvedLink?.title}</Button>
                                            </>
                                        )}
                                    </>
                                ) : "Message"}

                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{ pr: 2 }}
                                color="white"
                                component="div"
                                gutterBottom
                            >
                                {linkResolverData && !linkResolverData.resolvedLink?.success && linkResolverData?.resolvedLink?.msg}
                            </Typography>
                        </>
                    ) : (
                        <BouncingDotLoader />
                    )
                    }
                    {showDownloadModal && (
                        <DownloadDetailsDialog
                            id={asset!.id}
                            isOpen={showDownloadModal}
                            onClose={() => setShowModal(false)}
                        />
                    )}
                </Grid>

            </Grid>
        </>
    );
};
