// Copyright (C) 2022 TANNER AG

import { useMemo, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { compare } from "../../../lib/utils";
import { VehicleTopic, VehicleTopics } from "../../../graphql";
import PagePdf from "../pdf/PagePdf";
import PageThemeItem from "./PageThemeItem";
import useTranslation from "../../../hooks/usetranslation";
import useVehiclePdf from "../../../hooks/usevehiclepdf";

type FilterTopic = Pick<VehicleTopic, "id" | "key" | "title" | "totalUsage" | "type" | "relatedTopics">;

const filterItems = (
    items: { benefits: FilterTopic[]; topics: FilterTopic[]; components: FilterTopic[] },
    activeItem?: FilterTopic
) => {
    if (activeItem?.type === "component") {
        const topics = items.topics.filter((topic) => activeItem.relatedTopics?.includes(Number(topic.id)));
        const topicIds = topics.map((topic) => topic.id);

        return {
            topics,
            benefits: items.benefits.filter((item) =>
                item.relatedTopics?.some((relatedItem) => topicIds.includes(String(relatedItem)))
            ),
            components: [activeItem]
        };
    }

    if (activeItem?.type === "benefit") {
        const topics = items.topics.filter((topic) => activeItem.relatedTopics?.includes(Number(topic.id)));
        const topicIds = topics.map((topic) => topic.id);

        return {
            topics,
            components: items.components.filter((item) =>
                item.relatedTopics?.some((relatedItem) => topicIds.includes(String(relatedItem)))
            ),
            benefits: [activeItem]
        };
    }

    if (activeItem?.type === "topic") {
        return {
            components: items.components.filter((item) => item.relatedTopics?.includes(Number(activeItem.id))),
            benefits: items.benefits.filter((item) => item.relatedTopics?.includes(Number(activeItem.id))),
            topics: [activeItem]
        };
    }

    return items;
};

type Props = {
    topics?: VehicleTopics;
    isPDFMode: boolean;
};

const PageFeatures: React.FC<Props> = ({ topics: vehicleTopics, isPDFMode }) => {
    const { t } = useTranslation();
    const [activeItem, setActiveItem] = useState<FilterTopic>();
    const { items, itemIds, toggleItem } = useVehiclePdf();

    const silos = useMemo(() => {
        const { topics, benefits, components } = filterItems(
            {
                benefits: vehicleTopics?.benefits ?? [],
                topics: vehicleTopics?.topics ?? [],
                components: vehicleTopics?.components ?? []
            },
            activeItem
        );

        const silos = [];
        if (benefits.length)
            silos.push({
                label: t("benefits"),
                items: benefits
            });
        if (topics.length)
            silos.push({
                label: t("topics"),
                items: topics
            });
        if (components.length)
            silos.push({
                label: t("components"),
                items: components
            });

        return silos;
    }, [vehicleTopics, activeItem, t]);

    return (
        <>
            <Container sx={{ my: 4 }}>
                <Grid container spacing={8}>
                    {silos.map(({ label, items }, index) => (
                        <Grid key={index} item xs={12} md={4}>
                            <Typography variant="h3" sx={{ mb: 2 }}>
                                {label}
                            </Typography>
                            <Stack spacing={1}>
                                {[...items].sort(compare("title")).map((item) => (
                                    <PageThemeItem
                                        key={item.id}
                                        id={item.id}
                                        to={`../${item.type}/${item.key}`}
                                        name={item.title}
                                        filter={item.totalUsage === 1}
                                        pdf={isPDFMode}
                                        onFilterClick={() => {
                                            if (isPDFMode) {
                                                toggleItem(item);
                                            } else {
                                                setActiveItem((currentItem) =>
                                                    currentItem?.id === item.id ? undefined : item
                                                );
                                            }
                                        }}
                                        active={isPDFMode ? itemIds.includes(item.id) : activeItem?.id === item.id}
                                    />
                                ))}
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <PagePdf items={items} />
        </>
    );
};

export default PageFeatures;
