// Copyright (C) 2022 TANNER AG

import React from "react";
import FormSelect from "../../../components/inputs/FormSelect";
import useLocale from "../../../hooks/uselocale";
import { useStatusQuery } from "../../../graphql";
import useParam from "../../../hooks/useparam";
import useTranslation from "../../../hooks/usetranslation";

const ProjectFilterStatus: React.FC = () => {
    const [value, setValue] = useParam("status");
    const { locale } = useLocale();
    const [{ data }] = useStatusQuery({ variables: { locale } });
    const { t } = useTranslation();
    return (
        <FormSelect
            id="status"
            label={t("status")}
            options={[{ label: t("all_status"), value: "" }, ...(data?.status ?? [])]}
            SelectProps={{ value: value || "", onChange: (event) => setValue(event.currentTarget.value) }}
        />
    );
};

export default ProjectFilterStatus;
