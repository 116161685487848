// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import ListItemRegular from "../../../components/listitem/regular/ListItemRegular";
import ListItemRegularLoader from "../../../components/listitem/regular/ListItemRegularLoader";
import useProductCategory from "../../../hooks/useproductcategory";

const ProductCategoryDetails: React.FC = () => {
    const { productCategory, fetching } = useProductCategory();

    return (
        <>
            <Stack spacing={2}>
                {!fetching &&
                    productCategory?.groups?.map((productGroup) => (
                        <ListItemRegular
                            key={productGroup.id}
                            title={productGroup.name}
                            description={productGroup.descriptionInternal}
                            image={productGroup.image}
                            to={productGroup.slug || productGroup.id}
                        />
                    ))}
                {fetching && Array.from(Array(4).keys()).map((key) => <ListItemRegularLoader key={key} />)}
            </Stack>
        </>
    );
};

export default ProductCategoryDetails;
