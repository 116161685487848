// Copyright (C) 2022 TANNER AG

import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ErrorAlertMore from "./ErrorAlertMore";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/material";

type Props = {
    title?: string;
    message?: string;
    cause?: string;
    sx?: SxProps;
};

const ErrorAlert: React.FC<Props> = ({ title = "", message = "", cause = "", sx }) => {
    return (
        <Alert
            severity="error"
            sx={{ width: "100%", "& .MuiAlert-message": { flexGrow: 1, overflow: "hidden" }, ...sx }}
        >
            {title && <AlertTitle sx={{ m: 0 }}>{title}</AlertTitle>}
            {message && <Typography component="div">{message}</Typography>}
            {cause && <ErrorAlertMore message={cause} />}
        </Alert>
    );
};

export default ErrorAlert;
