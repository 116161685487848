// Copyright (C) 2022 TANNER AG

import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import MuiAppBar from "@mui/material/AppBar";
import NavBarControls from "./NavBarControls";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { ChevronRightIcon } from "../icons/MANIcons";

type Props = {
    links?: { label?: string; to?: string }[];
    children?: React.ReactNode;
};

const NavBar: React.FC<Props> = ({ links, children }) => {
    return (
        <MuiAppBar
            position="sticky"
            elevation={0}
            sx={{
                backgroundColor: "background.paper",
                color: "text.secondary",
                borderBottom: "1px solid",
                borderColor: "grey.50"
            }}
        >
            <Container>
                <Toolbar disableGutters variant="dense">
                    <NavBarControls />
                    <Breadcrumbs
                        sx={{ ml: 4, "& li > a": { display: "block" }, mr: "auto" }}
                        separator={<ChevronRightIcon sx={{ fontSize: 12 }} />}
                    >
                        <Link variant="body2" color="textPrimary" component={RouterLink} to="/">
                            Home
                        </Link>
                        {links?.map((link, index) => {
                            if (!link.to) {
                                return (
                                    <Typography key={index} variant="body2" color="textSecondary">
                                        {link.label || "..."}
                                    </Typography>
                                );
                            }

                            return (
                                <Link
                                    key={index}
                                    variant="body2"
                                    color="textPrimary"
                                    component={RouterLink}
                                    to={link.to}
                                >
                                    {link.label || "..."}
                                </Link>
                            );
                        })}
                    </Breadcrumbs>
                    {children}
                </Toolbar>
            </Container>
        </MuiAppBar>
    );
};

export default NavBar;
