// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import { SearchIcon } from "../../components/icons/MANIcons";
import SearchInputFloating from "./input/SearchInputFloating";
import useTranslation from "../../hooks/usetranslation";
import useOnline from "../../hooks/useonline";

const SearchToolbarItem = () => {
    const { t } = useTranslation();
    const isOnline = useOnline();
    const [open, setOpen] = useState(false);

    if (!isOnline) {
        return null;
    }

    return (
        <>
            <IconButton size="small" color="primary" onClick={() => setOpen(true)} title={t("search")}>
                <SearchIcon />
            </IconButton>
            {open && (
                <AppBar position="fixed" sx={{ bgcolor: "secondary.main", top: 76, zIndex: 1200 }}>
                    <SearchInputFloating onClose={() => setOpen(false)} />
                </AppBar>
            )}
        </>
    );
};

export default SearchToolbarItem;
