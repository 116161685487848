// Copyright (C) 2022-2023 TANNER AG

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Asset } from "../../../graphql";
import { ASSET_TYPE_AUDIO, ASSET_TYPE_VIDEO } from "../AssetItem";
import { DownloadIcon } from "../../icons/MANIcons";
import audioAssetPreview from "../audio_asset_thumbnail.png";
import DownloadDetailsInfoTable from "./DownloadDetailsInfoTable";
import MediaPlayer from "../../mediaplayer/MediaPlayer";
import useAsset from "../../../hooks/useasset";
import useFallbackImage from "../../../hooks/useFallbackImage";
import useTranslation from "../../../hooks/usetranslation";

const ASSET_PREVIEW_HEIGHT = 600;

type Props = {
    asset: Asset;
    locale: string;
    setLocale: (locale: string) => void;
};

const DownloadDetailsContent: React.FC<Props> = ({ asset, locale, setLocale }) => {
    const { t } = useTranslation();
    const { createAssetDownloadUrl, createAssetPreviewUrl } = useAsset();
    const { displayFallbackImage } = useFallbackImage();

    return (
        <Grid container spacing={3} height={ASSET_PREVIEW_HEIGHT + 32}>
            <Grid item xs={12} md={8}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        backgroundColor: "#EAEDF3"
                    }}
                >
                    {asset.type === ASSET_TYPE_VIDEO ? (
                        <MediaPlayer url={createAssetDownloadUrl(asset.id, locale)} type={asset.type} />
                    ) : (
                        <Box
                            component="img"
                            src={
                                asset.type === ASSET_TYPE_AUDIO
                                    ? audioAssetPreview
                                    : createAssetPreviewUrl(asset.id, ASSET_PREVIEW_HEIGHT, locale)
                            }
                            onError={displayFallbackImage}
                            alt={`Asset ${asset.title}`}
                            sx={{
                                maxWidth: "100%"
                            }}
                        />
                    )}
                </Box>
            </Grid>
            <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                    <Box sx={{ paddingX: 2, paddingY: 1, border: "1px solid #CBD3DC" }}>
                        <FormControl>
                            <FormLabel
                                id="languages-radio-buttons-group-label"
                                sx={{ fontSize: 20, textTransform: "uppercase", marginBottom: 1 }}
                            >
                                {t("languages")}
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="languages-radio-buttons-group-label"
                                name="languages-radio-buttons-group"
                                value={locale}
                                onChange={(_: unknown, newValue: string) => setLocale(newValue)}
                            >
                                {(asset?.languages || []).map((language, index) => (
                                    <FormControlLabel
                                        key={language}
                                        value={language}
                                        label={asset?.languageNames ? asset?.languageNames[index] : language}
                                        control={<Radio color="secondary" />}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box sx={{ paddingX: 2, paddingY: 1, border: "1px solid #CBD3DC" }}>
                        <Typography id="tableTitle" variant="h4" color="#303C49" textTransform="uppercase">
                            {t("info")}
                        </Typography>
                        <DownloadDetailsInfoTable asset={asset} />
                        <Button
                            variant="contained"
                            color="secondary"
                            download
                            href={createAssetDownloadUrl(asset.id, locale)}
                            startIcon={<DownloadIcon />}
                        >
                            {t("download")}
                        </Button>
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default DownloadDetailsContent;
