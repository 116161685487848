// Copyright (C) 2024 TANNER AG

import {Link, useSearchParams} from "react-router-dom";
import useTranslation from "../../hooks/usetranslation";
import React, {useEffect, useState} from "react";
import HTTPClient from "../../lib/HTTPClient";
import Spinner from "../../components/Spinner";
import BaseAppBar from "../../components/appbar/BaseAppBar";
import AppToolbar from "../../components/apptoolbar/AppToolbar";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import {Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/lab/LoadingButton";
import {routes} from "../../lib/utils";
import Box from "@mui/material/Box";

const NewsUnsubscribeView: React.FC = () => {
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [token] = useState(searchParams.get("token"));
    useEffect(() => {
        setLoading(true);
        HTTPClient.POST("/user/unsubscribe/validate", {token})
            .then((response) => {
                return response.text();
            })
            .then((username: string) => {
                if (!username) throw new Error("Sorry, we don't have your address in our database.");
            })
            .catch((error) => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        HTTPClient.POST("/user/unsubscribe", {token})
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                if (!result.success) {
                    setError(true);
                }
                setSuccess(result.success);
            })
            .catch((error) => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    if (isLoading) return <Spinner/>;
    return (
        <>
            <BaseAppBar toolbar={<AppToolbar isUserAuthenticated={false}/>}/>
            {isError ? (
                <Alert severity="error" sx={{width: {sx: "100%", md: "50%"}, mx: "auto", mt: 4}}>
                    {t("error")}
                </Alert>
            ) : (
                <Container sx={{width: "60%"}}>
                    <Typography variant="h2" textAlign="center" marginTop={16}>
                        <p>{t("unsubscribe_prompt")}</p>
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        {!!isSuccess && (
                            <Box mt={1}>
                                <Alert severity="info">{t("unsubscribe_success")}</Alert>
                            </Box>
                        )}
                        <Stack direction="row" sx={{mt: 4, textAlign: "left"}} spacing={1}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                sx={{minWidth: 140}}
                                disabled={isSuccess}
                                fullWidth
                            >
                                {t("unsubscribe")}
                            </Button>
                            <Button component={Link} to={routes.home}
                                    sx={{minWidth: 140, backgroundColor: "#e0e0e0"}}
                                    fullWidth>
                                {isSuccess ? t("return_to_startpage") : t("cancel")}
                            </Button>
                        </Stack>
                    </form>
                </Container>
            )}
            <Typography variant="caption" sx={{position: "absolute", bottom: 0, left: 0, my: 1, mx: 2}}>
                © MAN {new Date().getFullYear()}
            </Typography>
        </>
    );
}

export default NewsUnsubscribeView;