// Copyright (C) 2022 TANNER AG

import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { routes } from "../../lib/utils";
import { useNewsQuery } from "../../graphql";
import NewsItem from "./NewsItem";
import Spinner from "../Spinner";
import useLocale from "../../hooks/uselocale";
import useTranslation from "../../hooks/usetranslation";

const LATEST_NEWS_LIMIT = 3;

const LatestNews: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { locale } = useLocale();
    const [{ data, fetching }] = useNewsQuery({ variables: { locale, page: 0, limit: LATEST_NEWS_LIMIT, query: "" } });
    const news = data?.news?.news || [];

    return (
        <>
            <Typography variant="h3" marginTop={4} marginBottom={3}>
                {t("latest_news")}
            </Typography>
            {fetching ? (
                <Spinner />
            ) : (
                <Grid container spacing={3} marginBottom={3}>
                    {news.map((news) => (
                        <Grid key={news.id} item xs={12} md={4}>
                            <NewsItem key={news.id} news={news} />
                        </Grid>
                    ))}
                </Grid>
            )}
            <Button variant="outlined" onClick={() => navigate(routes.newsFlash)}>
                {t("view_all_news")}
            </Button>
        </>
    );
};

export default LatestNews;
