// Copyright (C) 2022 TANNER AG

import useStore from "./usestore";
import shallow from "zustand/shallow";
import useLocale from "./uselocale";
import { useProjectQuery } from "../graphql";
import { useMemo } from "react";

const useActiveProject = () => {
    const activeProject = useStore((state) => state.activeProject, shallow);
    const { locale } = useLocale();
    const [{ data, fetching, error }] = useProjectQuery({
        variables: { id: activeProject?.id ?? "", locale }
    });
    const addedProducts = useMemo(() => {
        return data?.project?.products?.map((product) => product.item.id) ?? [];
    }, [data?.project?.products]);

    return {
        id: activeProject?.id ?? "",
        name: activeProject?.name ?? "",
        data: data?.project,
        addedProducts,
        fetching,
        error
    };
};

export default useActiveProject;
