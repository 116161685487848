// Copyright (C) 2022 TANNER AG

import React from "react";
import Paper from "@mui/material/Paper";
import { CustomerContact } from "../types";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { ChatIcon, MailIcon, PhoneIcon } from "../../../components/icons/MANIcons";
import useTranslation from "../../../hooks/usetranslation";
import useAnchorHandler from "../../../hooks/useanchorhandler";
import MenuPopover from "../../../components/MenuPopover";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

type Props = {
    contact?: CustomerContact;
    subject?: string;
};

const CustomerProjectContact: React.FC<Props> = ({ contact, subject }) => {
    const { t } = useTranslation();
    const { anchorEl, onClose, onClick } = useAnchorHandler();

    if (!contact) {
        return null;
    }

    return (
        <Stack
            spacing={2}
            component={Paper}
            sx={{
                border: "1px solid",
                borderColor: "grey.100",
                p: 1.5,
                alignItems: "center",
                mt: 8,
                position: "sticky",
                top: 32
            }}
        >
            <Typography variant="subtitle2" color="textSecondary" textTransform="uppercase">
                {t("personal_contact")}
            </Typography>
            <Avatar sx={{ width: 64, height: 64 }}>PF</Avatar>
            <Stack sx={{ alignItems: "center" }} spacing={0.5}>
                <Typography variant="h5">
                    {contact.firstname} {contact.lastname}
                </Typography>
                {contact.phone && (
                    <Typography variant="body2" color="textSecondary">
                        {t("tel")} {contact.phone}
                    </Typography>
                )}
                {contact.email && (
                    <Link variant="body2" component="a" href={`mailto:${contact.email}`}>
                        {contact.email}
                    </Link>
                )}
            </Stack>
            <Button
                onClick={onClick}
                startIcon={<ChatIcon />}
                variant="contained"
                color="secondary"
                sx={{ minWidth: 160 }}
            >
                {t("contact_us")}
            </Button>
            <MenuPopover anchorEl={anchorEl} onClose={onClose} maxWidth={160}>
                <MenuList component="div">
                    {contact?.email && (
                        <MenuItem component="a" href={`mailto:${contact.email}?subject=${subject}`}>
                            <ListItemIcon>
                                <MailIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={{ variant: "body2" }}>{t("email")}</ListItemText>
                        </MenuItem>
                    )}
                    {contact?.phone && (
                        <MenuItem component="a" href={`tel:${contact.phone}`}>
                            <ListItemIcon>
                                <PhoneIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={{ variant: "body2" }}>{t("phone")}</ListItemText>
                        </MenuItem>
                    )}
                </MenuList>
            </MenuPopover>
        </Stack>
    );
};

export default CustomerProjectContact;
