// Copyright (C) 2023 TANNER AG

import { FC, useMemo, useState } from "react";
import { Grid, styled } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { LubStatus } from "../../../graphql";
import { LubTableSearchBar } from "./LubTableSearchBar";

type Props = {
    content?: LubStatus;
};

const columns: GridColDef[] = [
    { field: "id", headerName: "eCON ID", width: 70 },
    { field: "Kunde", headerName: "Kunde", flex: 1, cellClassName: "break-word" },
    { field: "Typ", headerName: "Typ", width: 70, cellClassName: "break-word" },
    {
        field: "Besitzer",
        headerName: "Besitzer"
    },
    { field: "Status", headerName: "Status", width: 60 },
    { field: "Bewerter", headerName: "Bewerter" },
    { field: "Teamleiter", headerName: "Teamleiter" },
    { field: "Eingang", headerName: "Eingang", width: 100 },
    { field: "Land", headerName: "Land", width: 50 }
];

const mapDataToObject = (data: string[]) => {
    return {
        id: data[0],
        Kunde: data[1],
        Typ: data[2],
        Besitzer: data[3],
        Status: data[4],
        Bewerter: data[5],
        Teamleiter: data[6],
        Eingang: data[7],
        Land: data[8]
    };
};

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-columnHeader": {
        backgroundColor: theme.palette.background.paper,
        ...theme.typography.subtitle2
    },
    "& .MuiDataGrid-columnHeader--sorted": {
        backgroundColor: theme.palette.grey[200]
    },
    "& .MuiDataGrid-iconButtonContainer": {
        display: "none"
    },
    ".break-word": {
        wordBreak: "break-word"
    }
}));

const LubTable: FC<Props> = ({ content }) => {
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("");
    const [land, setLand] = useState("");

    const [headers, rows] = useMemo(() => {
        if (!content) {
            return [null, null];
        }
        return [content.headers, content.data];
    }, [content]);

    const filteredRows = useMemo(() => {
        if (!rows) {
            return [];
        }

        return rows
            .filter((row) => {
                return (
                    row.join("|").toLowerCase().includes(search.toLowerCase()) &&
                    row[4].toLowerCase().includes(status.toLowerCase()) &&
                    row[8].toLowerCase().includes(land.toLowerCase())
                );
            })
            .map((row) => mapDataToObject(row));
    }, [rows, search, status, land]);

    if (!headers || !rows) {
        return null;
    }

    return (
        <Grid container>
            <Grid item xs={12} md={12} mb={1}>
                <LubTableSearchBar
                    search={search}
                    status={status}
                    land={land}
                    setSearch={setSearch}
                    setStatus={setStatus}
                    setLand={setLand}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <StyledDataGrid
                    rows={filteredRows}
                    columns={columns}
                    autoHeight
                    disableColumnMenu
                    sx={{ borderStyle: "none" }}
                    getRowHeight={() => "auto"}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 15
                            }
                        }
                    }}
                    pageSizeOptions={[5, 10, 15, 25, 50, 100]}
                />
            </Grid>
        </Grid>
    );
};

export default LubTable;
