// Copyright (C) 2022 TANNER AG

import React, { useState } from "react";
import Button from "@mui/material/Button";
import { CalculatorIcon } from "../../../../components/icons/MANIcons";
import Dialog from "../../../../components/dialog/Dialog";
import ProjectAddCalcForm from "./ProjectAddCalcForm";
import useTranslation from "../../../../hooks/usetranslation";

type Props = {
    projectId: string;
};

const ProjectAddCalc: React.FC<Props> = ({ projectId }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);

    return (
        <>
            <Button onClick={() => setOpen(true)} startIcon={<CalculatorIcon color="secondary" />}>
                {t("add_toc_calc")}
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <ProjectAddCalcForm projectId={projectId} onClose={handleClose} />
            </Dialog>
        </>
    );
};

export default ProjectAddCalc;
