// Copyright (C) 2022 TANNER AG

import React from "react";
import Button from "@mui/material/Button";
import { CommentIcon } from "./icons/MANIcons";
import { SxProps } from "@mui/system";
import useTranslation from "../hooks/usetranslation";
import useBreakpoint from "../hooks/usebreakpoint";
import IconButton from "@mui/material/IconButton";

type Props = {
    subject?: string;
    sx?: SxProps;
};

const ChangeRequest: React.FC<Props> = ({ subject, sx }) => {
    const { t } = useTranslation();
    const { down } = useBreakpoint("lg");
    const href = `mailto:mybus@man.eu?subject=Feedback for "${subject}"`;

    if (down) {
        return (
            <IconButton component="a" href={href} size="small" title={t("feedback")} edge="end" sx={sx}>
                <CommentIcon fontSize="small" color="secondary" />
            </IconButton>
        );
    }

    return (
        <Button component="a" href={href} startIcon={<CommentIcon color="secondary" />} sx={sx}>
            {t("feedback")}
        </Button>
    );
};

export default ChangeRequest;
