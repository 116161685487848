// Copyright (C) 2022 TANNER AG

import { useUserQuery } from "../graphql";
import useLocale from "./uselocale";

const useAsset = () => {
    const supportedLocales = ["en", "de_DE"];
    const { locale: globalLocale } = useLocale();
    const [{ data }] = useUserQuery();

    const loggedInUserEmail = data?.user?.email ?? "";

    const createImageUrl = (path = "") => {
        if (!path) {
            return "";
        }
        // MANPST-708 Images not displyed correctly in customer browser
        // if (path.startsWith("https://")) {
        //     return path;
        // }

        return `${process.env.REACT_APP_SERVICE_URL ?? ""}/image?path=${path}`;
    };

    const createDownloadUrl = (id?: string | number) =>
        `${process.env.REACT_APP_SERVICE_URL ?? ""}/assets/${id}/download?user=${loggedInUserEmail}`;

    const createAssetDownloadUrl = (id: string, locale = globalLocale) =>
        `${process.env.REACT_APP_SERVICE_URL ?? ""}/assets/${id}/download?locale=${locale}&user=${loggedInUserEmail}`;

    const createAssetPreviewUrl = (id: string, height?: number, locale = globalLocale) => {
        const assetLocale = supportedLocales.find((supportedLocale) => supportedLocale.includes(locale)) ?? "en";
        return (
            `${
                process.env.REACT_APP_SERVICE_URL ?? ""
            }/assets/${id}/preview?locale=${assetLocale}&user=${loggedInUserEmail}` +
            (height ? `&height=${height}` : "")
        );
    };

    return { createImageUrl, createDownloadUrl, createAssetDownloadUrl, createAssetPreviewUrl };
};

export default useAsset;
