// Copyright (C) 2023 TANNER AG

import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import Dialog from "../../components/dialog/Dialog";
import DialogHead from "../../components/dialog/DialogHead";
import useTranslation from "../../hooks/usetranslation";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import React from "react";

type Props = {
    onClose: () => void;
    isOpen: boolean;
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subTitle: {
            fontSize: 21,
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        mainContent: {
            fontSize: 15,
        },
        mainContent_subtitle: {

            fontWeight: "bold",
        },
        divider: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        }
    })
);

const DataProtectionDialog: React.FC<Props> = ({onClose, isOpen}) => {
    const {t} = useTranslation();
    const classes = useStyles();


    return (
        <Dialog onClose={onClose} open={isOpen}>
            <DialogTitle>
                <DialogHead title={t("data_protection_title")} onClose={onClose}/>
            </DialogTitle>
            <DialogContent>
                <Typography className={classes.subTitle} variant={"h3"}
                            gutterBottom>{t("data_protection_general_information")}</Typography>
                <Typography className={classes.mainContent_subtitle}
                            gutterBottom>{t("data_protection_general_information_introduction")}</Typography>
                <Typography className={classes.mainContent}
                            gutterBottom>{t("data_protection_general_information_introduction_1")}</Typography>
                <Typography className={classes.mainContent}
                            gutterBottom>{t("data_protection_general_information_introduction_2")}</Typography>
                <Typography className={classes.mainContent}
                            gutterBottom>{t("data_protection_general_information_introduction_3")}</Typography>
                <Typography className={classes.mainContent_subtitle}
                            gutterBottom>{t("data_protection_general_information_responsible_party")}</Typography>
                <Typography className={classes.mainContent}
                            gutterBottom>{t("data_protection_general_information_responsible_party_1")}</Typography>
                <Typography className={classes.mainContent}
                            gutterBottom>{t("data_protection_general_information_responsible_party_2")}</Typography>
                <Typography className={classes.subTitle} variant={"h3"}
                            gutterBottom>{t("data_protection_information_about_this_website")}</Typography>
                <Typography className={classes.mainContent}
                            gutterBottom>{t("data_protection_information_about_this_website_1")}</Typography>
                <Typography className={classes.mainContent}
                            gutterBottom>{t("data_protection_information_about_this_website_2")}</Typography>
                <Typography className={classes.mainContent}
                            gutterBottom>{t("data_protection_information_about_this_website_3")}</Typography>
                <Typography className={classes.mainContent}
                            gutterBottom>{t("data_protection_information_about_this_website_4")}</Typography>
                <Typography className={classes.subTitle} variant={"h3"}
                            gutterBottom>{t("data_protection_collection_gi")}</Typography>
                <Typography className={classes.mainContent}
                            gutterBottom>{t("data_protection_collection_gi_1")}</Typography>
                <Typography className={classes.mainContent}
                            gutterBottom>{t("data_protection_collection_gi_2")}</Typography>
                <Typography className={classes.subTitle} variant={"h3"}
                            gutterBottom>{t("data_protection_collection_personal_data")}</Typography>
                <Typography className={classes.mainContent_subtitle}
                            gutterBottom>{t("data_protection_collection_personal_data_subhead_1")}</Typography>
                <Typography className={classes.mainContent}
                            gutterBottom>{t("data_protection_collection_personal_data_subheader_1_content_1")}</Typography>
                <Typography className={classes.mainContent_subtitle}
                            gutterBottom>{t("data_protection_collection_personal_data_subhead_2")}</Typography>
                <Typography className={classes.mainContent}
                            gutterBottom>{t("data_protection_collection_personal_data_subheader_2_content_1")}</Typography>
                <Typography className={classes.mainContent_subtitle}
                            gutterBottom>{t("data_protection_collection_personal_data_subhead_3")}</Typography>
                <Typography className={classes.mainContent}
                            gutterBottom>{t("data_protection_collection_personal_data_subheader_3_content_1")}</Typography>
                <Typography className={classes.mainContent_subtitle}
                            gutterBottom>{t("data_protection_collection_personal_data_subhead_4")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subheader_4_content_1")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_4_content_2")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_4_content_3")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_4_content_4")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_4_content_5")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_4_content_6")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_4_content_7")}</Typography><Typography
                className={classes.mainContent}
                gutterBottom>{t("data_protection_collection_personal_data_subhead_4_content_8")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_4_content_9")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_4_content_10")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_4_content_11")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_4_content_12")}</Typography>
                <Typography className={classes.mainContent_subtitle}
                            gutterBottom>{t("data_protection_collection_personal_data_subhead_5")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subheader_5_content_1")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_5_content_2")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_5_content_3")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_5_content_4")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_5_content_5")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_5_content_6")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_5_content_7")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_5_content_8")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_5_content_9")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_5_content_10")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_5_content_11")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_5_content_12")}</Typography>
                <Typography
                    className={classes.mainContent_subtitle}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_6")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subheader_6_content_1")}</Typography>
                <Typography
                    className={classes.mainContent_subtitle}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_7")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subheader_7_content_1")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subheader_7_content_2")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subheader_7_content_3")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subheader_7_content_4")}</Typography>
                <Typography
                    className={classes.mainContent_subtitle}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_8")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subheader_8_content_1")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subheader_8_content_2")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subheader_8_content_3")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subheader_8_content_4")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subheader_8_content_5")}</Typography>
                <Typography
                    className={classes.mainContent_subtitle}
                    gutterBottom>{t("data_protection_collection_personal_data_subhead_9")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subheader_9_content_1")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subheader_9_content_2")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subheader_9_content_3")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subheader_9_content_4")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subheader_9_content_5")}</Typography>
                <Typography
                    className={classes.mainContent_subtitle}
                    gutterBottom>{t("data_protection_collection_personal_data_subheader_10")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subheader_10_content_1")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_collection_personal_data_subheader_10_content_2")}</Typography>
                <Typography className={classes.subTitle} variant={"h3"}
                            gutterBottom>{t("data_protection_limitation_of_use")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_limitation_of_use_content_1")}</Typography>
                <Typography className={classes.subTitle} variant={"h3"}
                            gutterBottom>{t("data_protection_legal_basis")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_legal_basis_content_1")}</Typography>
                <Typography className={classes.subTitle} variant={"h3"}
                            gutterBottom>{t("data_protection_transfer_of_personal_data")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_transfer_of_personal_data_content_1")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_transfer_of_personal_data_content_2")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_transfer_of_personal_data_content_3")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_transfer_of_personal_data_content_4")}</Typography>
                <Typography className={classes.subTitle} variant={"h3"}
                            gutterBottom>{t("data_protection_data_storage_and_deletion")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_data_storage_and_deletion_content_1")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_data_storage_and_deletion_content_2")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_data_storage_and_deletion_content_3")}</Typography>
                <Typography className={classes.subTitle} variant={"h3"}
                            gutterBottom>{t("data_protection_your_rights")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_your_rights_content_1")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_your_rights_content_2")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_your_rights_content_3")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_your_rights_content_4")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_your_rights_content_5")}</Typography>
                <Typography className={classes.subTitle} variant={"h3"}
                            gutterBottom>{t("data_protection_security")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_security_content_1")}</Typography>
                <Typography className={classes.subTitle} variant={"h3"}
                            gutterBottom>{t("data_protection_links_to_other_websites")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_links_to_other_websites_content_1")}</Typography>
                <Typography className={classes.subTitle} variant={"h3"}
                            gutterBottom>{t("data_protection_reservation")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_reservation_content_1")}</Typography>
                <Typography
                    className={classes.mainContent}
                    gutterBottom>{t("data_protection_timestamp")}</Typography>
            </DialogContent>
        </Dialog>
    );
};

export default DataProtectionDialog;
