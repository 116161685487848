// Copyright (C) 2022 TANNER AG

import Box from "@mui/material/Box";
import clsx from "clsx";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {boldFontFamily} from "../theme";
import Disclaimer from "./Disclaimer";
import useLocale from "../hooks/uselocale";
import useTranslation from "../hooks/usetranslation";
import {styled} from "@mui/material/styles";
import Imprint from "./Imprint";
import DataProtectionFooter from "./DataProtectionFooter";

const Footer: React.FC = () => {
    const {t} = useTranslation();
    const {locale, locales, setLocale, languageNames} = useLocale();

    return (
        <Box component="footer" sx={{backgroundColor: "background.paper", mt: "auto"}}>
            <Container sx={{py: 4}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Stack spacing={2} direction="row">
                            {locales.map((curLocale) => (
                                <Typography
                                    key={curLocale}
                                    variant="body2"
                                    onClick={() => setLocale(curLocale)}
                                    className={clsx({active: locale === curLocale})}
                                    sx={{cursor: "pointer", "&.active": {fontFamily: boldFontFamily}}}
                                >
                                    {languageNames.of(curLocale) ?? curLocale}
                                </Typography>
                            ))}
                        </Stack>
                    </Grid>
                    <ItemGrid item xs={12} sm={6} md={2}>
                        <Typography
                            display="block"
                            variant="body2"
                            component="a"
                            href="mailto:mybus@man.eu"
                            sx={{color: "text.primary", textDecoration: "none"}}
                        >
                            {t("feedback_requests")}
                        </Typography>
                    </ItemGrid>
                    <ItemGrid item xs={12} sm={6} md={2}>
                        <Disclaimer/>
                    </ItemGrid>
                    <ItemGrid item xs={12} sm={6} md={2}>
                        <Imprint/>
                    </ItemGrid>
                    <ItemGrid item xs={12} sm={6} md={2}>
                        <DataProtectionFooter/>
                    </ItemGrid>

                </Grid>
                <Typography sx={{mt: 3}}>© MAN {new Date().getFullYear()}</Typography>
            </Container>
        </Box>
    );
};

export const ItemGrid = styled(Grid)(() => ({
    display: "flex",
    justifyContent: "center",
}));

export default Footer;
