// Copyright (C) 2022 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import ListItemDownload from "../../../components/listitem/ListItemDownload";
import { useProductGroupQuery } from "../../../graphql";
import ListItemLink from "../../../components/listitem/ListItemLink";
import BulletList from "../../../components/BulletList";
import Section from "../../../components/Section";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import useLocale from "../../../hooks/uselocale";
import Skeleton from "@mui/material/Skeleton";
import { DownloadIcon } from "../../../components/icons/MANIcons";
import Button from "@mui/material/Button";
import useAsset from "../../../hooks/useasset";
import useTranslation from "../../../hooks/usetranslation";
import RichText from "../../../components/content/RichText";
import ChangeRequest from "../../../components/ChangeRequest";
import CountrySwitch from "../../../components/apptoolbar/countryswitch/CountrySwitch";
import RelatedContent from "../../../components/relatedcontent/RelatedContent";
import ProductGroupProducts from "./ProductGroupProducts";
import useProductParam from "../../../hooks/useproductparam";

const ProductGroup: React.FC = () => {
    useProductParam();
    const { groupId } = useParams();
    const { t } = useTranslation();
    const { locale } = useLocale();
    const { createDownloadUrl } = useAsset();
    const key = groupId?.toString() ?? "";
    const [{ data, fetching }] = useProductGroupQuery({ variables: { key, locale } });
    const productGroup = data?.productGroup;

    return (
        <>
            <Box component={Paper} sx={{ px: 3, py: 2, mb: 4 }}>
                <Stack direction="row" sx={{ alignItems: "flex-start" }}>
                    <Typography variant="h2" gutterBottom sx={{ mr: 1 }}>
                        {fetching ? <Skeleton /> : productGroup?.name}
                    </Typography>
                    <ChangeRequest sx={{ ml: "auto" }} subject={productGroup?.name} />
                </Stack>
                <Section>
                    <RichText html={productGroup?.descriptionInternal} sx={{ my: 2 }} />
                    <BulletList title={t("man_wow")} items={productGroup?.wowBullets} />
                </Section>
                <Section>
                    <Stack direction="row" sx={{ mb: 1, alignItems: "center" }}>
                        <Typography variant="h3" sx={{ mr: "auto" }}>
                            {t("products")}
                        </Typography>
                        <CountrySwitch />
                    </Stack>
                    <ProductGroupProducts fetching={fetching} products={productGroup?.products} />
                </Section>
                {Boolean(productGroup?.downloads?.length) && (
                    <Section>
                        <Typography variant="h4" textTransform="uppercase" gutterBottom>
                            {t("downloads")}
                        </Typography>
                        <Stack>
                            {productGroup?.downloads.map((download) => (
                                <ListItemDownload
                                    key={download.id}
                                    id={download.id}
                                    name={download.name}
                                    mime={download.mime}
                                    size={download.size}
                                    border
                                    action={
                                        <Button
                                            startIcon={<DownloadIcon color="secondary" />}
                                            component="a"
                                            href={createDownloadUrl(download.id)}
                                            download
                                        >
                                            {t("download_file")}
                                        </Button>
                                    }
                                />
                            ))}
                        </Stack>
                    </Section>
                )}
                {Boolean(productGroup?.information?.length) && (
                    <Section>
                        <Typography variant="h4" textTransform="uppercase" gutterBottom>
                            {t("further_information")}
                        </Typography>
                        <List disablePadding>
                            {productGroup?.information.map((info, idx) => (
                                <ListItemLink key={idx} href={info.href}>
                                    {info.title}
                                </ListItemLink>
                            ))}
                        </List>
                    </Section>
                )}
            </Box>
            {Boolean(productGroup?.related?.length) && (
                <>
                    <Typography variant="h3" sx={{ mb: 2 }}>
                        {t("related_content")}
                    </Typography>
                    <Grid container spacing={3}>
                        {productGroup?.related.map((related, idx) => (
                            <Grid key={related.id} item xs={12} sm={6} md={4}>
                                <RelatedContent related={related} />
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
        </>
    );
};

export default ProductGroup;
