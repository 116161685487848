// Copyright (C) 2023 TANNER AG

import { ChangeEvent, FC } from "react";
import { Grid, InputAdornment } from "@mui/material";
import FormInput from "../../../components/inputs/FormInput";
import { SearchIcon } from "../../../components/icons/MANIcons";

type Props = {
    search: string;
    status: string;
    land: string;
    setSearch: (value: string) => void;
    setStatus: (value: string) => void;
    setLand: (value: string) => void;
};

export const LubTableSearchBar: FC<Props> = ({ search, status, land, setSearch, setStatus, setLand }) => {
    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleStatus = (event: ChangeEvent<HTMLInputElement>) => {
        setStatus(event.target.value);
    };

    const handleLand = (event: ChangeEvent<HTMLInputElement>) => {
        setLand(event.target.value);
    };

    return (
        <Grid container direction="row">
            <Grid item xs={3} md={4} paddingRight={2}>
                <FormInput
                    id="search"
                    placeholder={"Search"}
                    label="Search"
                    InputProps={{
                        value: search,
                        onChange: handleSearch,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
            <Grid item xs={3} md={4} paddingX={1}>
                <FormInput
                    id="status"
                    placeholder={"Status"}
                    label="Status"
                    InputProps={{
                        value: status,
                        onChange: handleStatus,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
            <Grid item xs={3} md={4} paddingLeft={2}>
                <FormInput
                    id="land"
                    placeholder={"Land"}
                    label="Land"
                    InputProps={{
                        value: land,
                        onChange: handleLand,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
        </Grid>
    );
};
