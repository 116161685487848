// Copyright (C) 2023 TANNER AG

import { Link } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";

import { routes } from "../../lib/utils";
import { News } from "../../graphql";
import HomeNewsItem from "./NewsWidgetItem";
import Spinner from "../Spinner";
import useTranslation from "../../hooks/usetranslation";
import { boldFontFamily } from "../../theme";

type Props = {
    searchQuery?: string;
    news: News[];
    total: number;
    loading: boolean;
};

const NewsWidget: React.FC<Props> = ({ searchQuery, news, total, loading }) => {
    const { t } = useTranslation();

    const searchParams = new URLSearchParams();
    if (searchQuery) searchParams.set("q", searchQuery);
    const newLocation = {
        pathname: routes.newsFlash,
        search: searchParams.toString()
    };

    return (
        <>
            <Stack>
                <Link to={newLocation} style={{ textDecoration: "none" }}>
                    <Typography variant="h4" color="black" textTransform="uppercase">
                        {t("news")}
                    </Typography>
                </Link>
                {searchQuery && !loading && (
                    <Typography sx={{ fontFamily: boldFontFamily, fontSize: 16 }} color="textSecondary">
                        {t("total_results", { total: total ?? 0 })}
                    </Typography>
                )}
            </Stack>

            {loading ? (
                <Spinner />
            ) : (
                <Stack spacing={1.5} marginTop={3}>
                    {news.map((news) => (
                        <HomeNewsItem
                            key={news.id}
                            slug={news.slug}
                            title={news.title}
                            thumbnail={news.thumbnail}
                            description={news.description}
                        />
                    ))}
                </Stack>
            )}
            {searchQuery && total > news.length && (
                <Box display="flex" justifyContent="end">
                    <Link to={newLocation} style={{ textDecoration: "none" }}>
                        <Typography variant="subtitle2" color="black" textTransform="uppercase">
                            {t("more")}
                        </Typography>
                    </Link>
                </Box>
            )}
        </>
    );
};

export default NewsWidget;
