// Copyright (C) 2023 TANNER AG

import { Link } from "react-router-dom";
import { Stack, Grid, Typography, Box } from "@mui/material";

import { routes } from "../../lib/utils";
import { Asset } from "../../graphql";
import Spinner from "../Spinner";
import AssetItem from "../asset/AssetItem";
import useTranslation from "../../hooks/usetranslation";
import { boldFontFamily } from "../../theme";

type Props = {
    searchQuery?: string;
    assets: Asset[];
    total: number;
    loading: boolean;
};

const ASSET_SIZE = 110;

const DownloadsWidget: React.FC<Props> = ({ searchQuery, assets, total, loading }) => {
    const { t } = useTranslation();

    const searchParams = new URLSearchParams();
    if (searchQuery) searchParams.set("q", searchQuery);
    const newLocation = {
        pathname: routes.downloads,
        search: searchParams.toString()
    };

    const showTitle = Boolean(searchQuery);

    return (
        <>
            <Stack>
                <Link to={newLocation} style={{ textDecoration: "none" }}>
                    <Typography variant="h4" color="black" textTransform="uppercase">
                        {t("downloads")}
                    </Typography>
                </Link>
                {searchQuery && !loading && (
                    <Typography sx={{ fontFamily: boldFontFamily, fontSize: 16 }} color="textSecondary">
                        {t("total_results", { total: total ?? 0 })}
                    </Typography>
                )}
            </Stack>

            {loading ? (
                <Spinner />
            ) : (
                <div className="downloadContent">
                    <Grid container paddingTop={2} spacing={2}>
                        {assets.map((asset) => (
                            <Grid key={asset.id} item xs={4}>
                                <AssetItem
                                    id={asset.id}
                                    type={asset.type}
                                    title={asset.title}
                                    assetHeight={ASSET_SIZE}
                                    showTitle={showTitle}
                                    showAdditionalIcons={false}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            )}
            {searchQuery && total > assets.length && (
                <Box display="flex" justifyContent="end">
                    <Link to={newLocation} style={{ textDecoration: "none" }}>
                        <Typography variant="subtitle2" color="black" textTransform="uppercase">
                            {t("more")}
                        </Typography>
                    </Link>
                </Box>
            )}
        </>
    );
};

export default DownloadsWidget;
