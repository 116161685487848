import React, { createContext, useState } from "react";


export const ChatboxContext = createContext<any>({
    isShow:false,
    setIsShow:()=>{}
});

type ChatBoxProps ={
    children: React.ReactNode;
}

const ChatboxProvider:React.FC<ChatBoxProps> = ({children}) =>{
    const [isShowChatBox, setIsShowChatBox] = useState(false);
    return <ChatboxContext.Provider value={{
        isShow: isShowChatBox,
        setIsShow: setIsShowChatBox
    }}>{children}</ChatboxContext.Provider>
}

export default ChatboxProvider;