// Copyright (C) 2022 TANNER AG

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Asset } from "../../../graphql";
import AssetItem from "../../../components/asset/AssetItem";
import useTranslation from "../../../hooks/usetranslation";

type Props = {
    title?: string;
    assets: Asset[];
    assetHeight?: number;
};

const DownloadsSection: React.FC<Props> = ({ title, assets, assetHeight }) => {
    const { t } = useTranslation();

    return (
        <Grid container justifyContent="center" paddingX={2}>
            <Grid item xs={12} md={10}>
                <Typography variant="h3">{title ?? t("downloads")}</Typography>
                <Stack direction="row" flexWrap="wrap" marginTop={4}>
                    {assets.map((asset) => (
                        <AssetItem
                            key={asset.id}
                            id={asset.id}
                            title={asset.title}
                            type={asset.type}
                            assetHeight={assetHeight}
                        />
                    ))}
                </Stack>
            </Grid>
        </Grid>
    );
};

export default DownloadsSection;
