// Copyright (C) 2022 TANNER AG

import React from "react";
import { CheckIcon } from "../../../../components/icons/MANIcons";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import HeroIcon from "../../../../components/icons/HeroIcons";
import { Link, useMatch } from "react-router-dom";
import { routes } from "../../../../lib/utils";

type Props = {
    id: string;
    icon: string;
    name: string;
    selected?: boolean;
    onClick?(): void;
};

const ProductSidebarMobileItem: React.FC<Props> = ({ id, icon, name, selected, onClick }) => {
    const match = useMatch(`${routes.dsg.products}/*`);
    return (
        <MenuItem key={id} selected={selected} component={Link} to={`${match?.pathnameBase}/${id}`} onClick={onClick}>
            <ListItemIcon>
                <HeroIcon color="primary" iconName={icon} />
            </ListItemIcon>
            <ListItemText>{name}</ListItemText>
            {selected && <CheckIcon />}
        </MenuItem>
    );
};

export default ProductSidebarMobileItem;
