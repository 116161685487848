// Copyright (C) 2022 TANNER AG

import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import RichText from "./RichText";
import Stack from "@mui/material/Stack";

type Props = {
    title: string;
    subTitle?: string;
    no?: number | string;
    theme?: string;
    content?: string;
    additional?: React.ReactNode;
    children?: React.ReactNode;
};

const ContentIntro: React.FC<Props> = ({ title, no, subTitle, additional, theme, content, children }) => {
    return (
        <Box sx={{ bgcolor: "background.paper" }}>
            <Container sx={{ pt: 3, pb: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        {theme && (
                            <Typography variant="subtitle2" textTransform="uppercase" color="secondary">
                                {theme}
                            </Typography>
                        )}
                        <Stack direction="row">
                            {no && (
                                <Typography variant="h2" color="secondary" sx={{ mb: 2, minWidth: 70 }}>
                                    {no}
                                </Typography>
                            )}
                            <Typography variant="h2" sx={{ mb: 2 }}>
                                {title}
                            </Typography>
                        </Stack>
                        {subTitle && (
                            <Typography variant="h3" sx={{ mb: 1 }}>
                                {subTitle}
                            </Typography>
                        )}
                        <RichText html={content} />
                        {additional}
                    </Grid>
                    {children && (
                        <Grid item xs={12} md={4}>
                            {children}
                        </Grid>
                    )}
                </Grid>
            </Container>
        </Box>
    );
};

export default ContentIntro;
