// Copyright (C) 2022 TANNER AG

import React, { FormEvent, useEffect, useState } from "react";
import { CheckCircleIcon, EditIcon } from "../../../../components/icons/MANIcons";
import FormInput from "../../../../components/inputs/FormInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Button from "@mui/lab/LoadingButton";
import { useUpdateProjectMutation } from "../../../../graphql";
import useTranslation from "../../../../hooks/usetranslation";

type Props = {
    projectId: string;
    customerPassword: string;
};

const ProjectCustomerSiteFormPassword: React.FC<Props> = ({ projectId, customerPassword }) => {
    const [edit, setEdit] = useState(false);
    const [passwordInput, setPasswordInput] = useState("");
    const [{ fetching }, updateProject] = useUpdateProjectMutation();

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        await updateProject({ where: { id: projectId }, data: { customerPassword: passwordInput } });
        setEdit(false);
    };

    const handleCancel = () => {
        setPasswordInput(customerPassword);
        setEdit(false);
    };

    useEffect(() => {
        setPasswordInput(customerPassword);
    }, [customerPassword]);
    const { t } = useTranslation();
    return (
        <Stack spacing={2} component="form" onSubmit={handleSubmit}>
            <FormInput
                id="password"
                label={t("password")}
                helperText={t("protectet_by_pass")}
                InputProps={{
                    disabled: !edit,
                    value: passwordInput,
                    onChange: (event) => setPasswordInput(event.currentTarget.value),
                    inputProps: { minLength: 8, maxLength: 18 },
                    endAdornment: (
                        <InputAdornment position="end">
                            {!edit && (
                                <IconButton color="secondary" edge="end" onClick={() => setEdit(true)}>
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            )}
                        </InputAdornment>
                    )
                }}
            />
            {edit && (
                <Stack direction="row">
                    <Button
                        startIcon={<CheckCircleIcon color="secondary" />}
                        variant="outlined"
                        type="submit"
                        loading={fetching}
                    >
                        {t("save_password")}
                    </Button>
                    <Button onClick={handleCancel}>{t("cancel")}</Button>
                </Stack>
            )}
        </Stack>
    );
};

export default ProjectCustomerSiteFormPassword;
