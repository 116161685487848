// Copyright (C) 2022 TANNER AG

import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import shallow from "zustand/shallow";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { boldFontFamily } from "../../../theme";
import { useNewsQuery } from "../../../graphql";
import ListItemRegularLoader from "../../../components/listitem/regular/ListItemRegularLoader";
import NewsListItem from "./NewsListItem";
import useLocale from "../../../hooks/uselocale";
import useSeenNewsCounter from "../../../hooks/useSeenNewsCounter";
import useStore from "../../../hooks/usestore";
import useTranslation from "../../../hooks/usetranslation";

type Props = {
    selectedCategoryIds: string[];
    searchParam: string | null;
};

const NEWS_LIMIT_PER_PAGE = 10;

const NewsList: React.FC<Props> = ({ selectedCategoryIds, searchParam }) => {
    const { t } = useTranslation();
    const { locale: storeLocale } = useLocale();
    const [page, setPage] = useState(1);
    const [seenNews] = useStore((state) => [state.seenNews], shallow);
    const { setSeenAllNews } = useSeenNewsCounter();

    let locale = storeLocale;

    if (storeLocale === "de") {
        locale = "de_DE";
    }

    const [{ data, fetching }] = useNewsQuery({
        variables: {
            locale,
            categories: selectedCategoryIds,
            limit: NEWS_LIMIT_PER_PAGE,
            page: page - 1,
            query: searchParam || ""
        }
    });

    useEffect(() => {
        setPage(1);
    }, [selectedCategoryIds, searchParam]);

    useEffect(() => {
        setSeenAllNews();
    }, [setSeenAllNews]);

    const news = data?.news;

    return (
        <Stack spacing={2}>
            {fetching ? (
                Array(4)
                    .fill(0)
                    .map((_, index) => <ListItemRegularLoader key={index} />)
            ) : (
                <Stack spacing={1}>
                    <Typography color="textSecondary" sx={{ fontFamily: boldFontFamily }}>
                        {t("total_posts", { total: news?.total ?? 0 })}
                    </Typography>
                    <Stack spacing={4}>
                        {(news?.news ?? []).map((news, index) => (
                            <NewsListItem
                                key={news.id}
                                title={news.title}
                                description={news.description}
                                thumbnail={news.thumbnail}
                                publication={news.publication}
                                author={news.author}
                                slug={news.slug}
                                categories={news.categories}
                                isNew={!seenNews.includes(news.id)}
                            />
                        ))}
                    </Stack>
                    {!!news && news.total > NEWS_LIMIT_PER_PAGE && (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <Pagination
                                color="secondary"
                                page={page}
                                count={Math.ceil(news?.total / NEWS_LIMIT_PER_PAGE)}
                                onChange={(_, page) => setPage(page)}
                                renderItem={(item) => <PaginationItem {...item} />}
                            />
                        </Box>
                    )}
                </Stack>
            )}
        </Stack>
    );
};

export default NewsList;
