// Copyright (C) 2023 TANNER AG

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import AppToolbar from "../../components/apptoolbar/AppToolbar";
import BaseAppBar from "../../components/appbar/BaseAppBar";
import useTranslation from "../../hooks/usetranslation";

const ThanksForRegisterView: React.FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <BaseAppBar toolbar={<AppToolbar isUserAuthenticated={false} />} />
            <Container>
                <Typography variant="h2" textAlign="center" marginTop={16}>
                    {t("thanks_interest")}
                </Typography>
                <Typography variant="h3" textAlign="center" marginTop={2}>
                    {t("check_email_confirm")}
                </Typography>
                <Typography variant="h3" textAlign="center" whiteSpace="pre-line" marginTop={2}>
                    {t("best_regards")}
                </Typography>
            </Container>
        </>
    );
};

export default ThanksForRegisterView;
