// Copyright (C) 2022-2023 TANNER AG

import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import Dialog from "../../../components/dialog/Dialog";
import DialogHead from "../../../components/dialog/DialogHead";
import RichText from "../../../components/content/RichText";
import { CloseIcon } from "../../../components/icons/MANIcons";
import MediaPlayer from "../../../components/mediaplayer/MediaPlayer";
import useLocale from "../../../hooks/uselocale";
import useTranslation from "../../../hooks/usetranslation";
import { usePodcastQuery } from "../../../graphql";

type Props = {
    activeId?: string;
    onClose(): void;
};

const BuscastDetails: React.FC<Props> = ({ activeId, onClose }) => {
    const { locale } = useLocale();
    const [{ data }] = usePodcastQuery({ variables: { id: activeId ?? "", locale }, pause: !activeId });
    const podcast = data?.podcast;
    const { t } = useTranslation();
    return (
        <Dialog open={Boolean(activeId)} onClose={onClose} maxWidth="md" scroll="body">
            <DialogContent>
                <DialogHead title={podcast?.title ?? ""} subTitle={t("bus_cast")} onClose={onClose} />
                <RichText html={podcast?.longDescription} />
                <MediaPlayer url={data?.podcast?.mediaUrl} type={data?.podcast?.mediaType} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} startIcon={<CloseIcon />} variant="outlined">
                    {t("close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BuscastDetails;
