import React, {FormEvent, useEffect, useRef, useState} from "react";
import {Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {DeleteIcon, SendMessageIcon} from "../icons/MANIcons";
import {useChatService} from "../../hooks/useChatService";
import {SingleMessage} from "./SingleMessage";
import useTranslation from "../../hooks/usetranslation";

export const ChatWindow = () => {
    const {sendChatmessage, messageHistoryContent, deleteChatHistory, isLoading} = useChatService() as any;
    const [messageStr, setMessageStr] = useState<string>("");
    const chatViewRef = useRef<HTMLDivElement>(null);
    const {t} = useTranslation();
   
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [data, _] = useState({
        sender: {
            id: 1,
            username: "Kai"
        },
        receiver: {
            id: 2,
            username: "Snowball"
        },
        messages: []
    });

    // const { setIsOpenChatBox } = useContext(ChatBoxContext);

    const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessageStr(e.target.value);
    };

    const onSubmit = (e?: FormEvent) => {
        if (!messageStr) {
            return;
        }
        if (e) {
            e.preventDefault();
        }
        sendChatmessage(messageStr);
        setMessageStr("");
    };

    const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            onSubmit(e);
        }
    };
    const welcomeMessageComp = () => (
        <>
            <Typography>{t("welcome_message")}</Typography>
            <Typography>{t("welcome_message_secondary")}</Typography>
        </>
    );
    useEffect(() => {
        if (chatViewRef.current) {
            chatViewRef.current.scrollTop = chatViewRef.current.scrollHeight;
            // add event listener if dom is changed
            const observer = new MutationObserver(() => {
                if (chatViewRef.current) {
                    chatViewRef.current.scrollTop = chatViewRef.current.scrollHeight + 100;
                }
            });
            observer.observe(chatViewRef.current, {childList: true});
            return () => {
                observer.disconnect();
            };
        }
        return () => {
        };
    }, [chatViewRef, data.messages]);
    return (
        <div className="appContainer">
            <header>
                <div className="header">
                {t("aiAssistant")}
                    <div>
                        <IconButton onClick={deleteChatHistory} sx={{ml: "auto"}}>
                            <DeleteIcon sx={{fontSize: 16}}/>
                        </IconButton>
                    </div>
                </div>
            </header>

            <div className="messages" ref={chatViewRef}>
                {messageHistoryContent.length === 0 && welcomeMessageComp()}

                <div className="messagesContent">
                    {messageHistoryContent.map((message: any, index: any) => (
                        <SingleMessage key={`message=${index}`} message={message} sender={message.message}
                                       isLoading={isLoading}/>
                    ))}
                </div>

            </div>
            <div className="input_msg">
                <form className="input">
                    <input
                        onInput={onInput}
                        onKeyDown={onEnter}
                        disabled={isLoading}
                        value={messageStr}
                        className="msgInput"
                        autoComplete="off"
                        name="msg"
                        type="text"
                        placeholder="Enter your message..."
                    />
                    <IconButton className="buttonSendMessage" onClick={onSubmit} sx={{ml: "auto"}}>
                        <SendMessageIcon sx={{fontSize: 16}}/>
                    </IconButton>
                </form>
            </div>
        </div>
    );
};
