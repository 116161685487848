// Copyright (C) 2022 TANNER AG

import { ContentTypeGroup, useContentTopicQuery } from "../graphql";
import useLocale from "./uselocale";

const useContentTopic = (group: ContentTypeGroup, keys: string[]) => {
    const { locale } = useLocale();
    const [{ data, fetching, error }] = useContentTopicQuery({
        variables: { group, keys, locale }
    });

    return { topic: data?.contentTopic, fetching, error };
};

export default useContentTopic;
