// Copyright (C) 2023 TANNER AG

import { useState } from "react";

import FormInput from "../../components/inputs/FormInput";
import FormLayout from "./FormLayout";
import HTTPClient from "../../lib/HTTPClient";
import Spinner from "../../components/Spinner";
import useTranslation from "../../hooks/usetranslation";
import { useNavigate } from "react-router-dom";

const PasswordForgottenView: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setLoading] = useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const email = formData.get("email") as string;

        setLoading(true);
        setTimeout(
            () =>
                HTTPClient.POST("/user/pwreset", { username: email })
                    .then(() => {
                        setSuccessMessage(t("check_email"));
                    })
                    .catch(() => {
                        setErrorMessage(t("error"));
                    })
                    .finally(() => {
                        setLoading(false);
                    }),
            5 * 1000
        );
    };

    const handleReturn = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        navigate("/");
    };

    if (isLoading) return <Spinner />;

    if (successMessage)
        return (
            <FormLayout
                title={t("password_forgotten")}
                onSubmit={handleReturn}
                successMessage={successMessage}
                errorMessage={errorMessage}
                buttonLabel={t("return_to_startpage")}
            ></FormLayout>
        );

    return (
        <FormLayout
            title={t("password_forgotten")}
            subtitle={t("enter_email_to_create_pass")}
            onSubmit={handleSubmit}
            successMessage={successMessage}
            errorMessage={errorMessage}
        >
            <FormInput id="email" label={t("email")} InputProps={{ type: "email" }} required />
        </FormLayout>
    );
};

export default PasswordForgottenView;
