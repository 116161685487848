// Copyright (C) 2023 TANNER AG

import shallow from "zustand/shallow";

import { useNewsInfoQuery } from "../graphql";
import useStore from "./usestore";

const useSeenNewsCounter = () => {
    const [seenNewsCounter, setSeenNewsCounter] = useStore(
        (state) => [state.seenNewsCounter, state.setSeenNewsCounter],
        shallow
    );
    const [{ data }] = useNewsInfoQuery();
    const totalNews = data?.news?.total || 0;
    const diff = totalNews - seenNewsCounter;
    const newNews = diff > 0 ? diff : 0;

    const setSeenAllNews = () => {
        if (totalNews) setSeenNewsCounter(totalNews);
    };

    return { newNews, setSeenAllNews };
};

export default useSeenNewsCounter;
