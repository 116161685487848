// Copyright (C) 2022 TANNER AG

import {
    ContentGroupDocument,
    ContentGroupQuery,
    ContentTopicDocument,
    ContentTopicQuery,
    ContentTypeGroup
} from "../../graphql";
import useLocale from "../uselocale";
import useOfflineQuery from "./useofflinequery";

const useOfflineContentTopic = (group: ContentTypeGroup) => {
    const { locale } = useLocale();
    const query = useOfflineQuery();

    const queryContentTopic = async (keys: string[] = []) => {
        const { data } = await query<ContentTopicQuery>(ContentTopicDocument, { group, keys, locale });
        await Promise.all(data?.contentTopic?.topics?.map((topic) => queryContentTopic([...keys, topic.key])) ?? []);
    };

    return async () => {
        const { data } = await query<ContentGroupQuery>(ContentGroupDocument, { group, locale });
        await Promise.all(data?.contentGroup?.topics?.map((topic) => queryContentTopic([topic.key])) ?? []);
    };
};

export default useOfflineContentTopic;
