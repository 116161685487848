// Copyright (C) 2023 TANNER AG

import { FC, Fragment } from "react";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import { NewsContentBlock } from "../../../graphql";
import ContentRichText from "../../../components/content/ContentRichtText";
import DownloadsSection from "../../news/downloads/DownloadsSection";
import LatestNews from "../../../components/latest-news/LatestNews";
import HotspotImage from "../../../components/content/hotspot/HotspotImage";
import LubTable from "./LubTable";

type Props = {
    contentBlocks?: NewsContentBlock[];
};

const SubPage: FC<Props> = ({ contentBlocks = [] }) => {
    return (
        <>
            <Grid container justifyContent="center" marginTop={2} paddingX={2}>
                {contentBlocks.map((contentBlock, index) => (
                    <Fragment key={index}>
                        {contentBlock.content && (
                            <Grid item xs={12} md={8} marginTop={4} sx={{ color: "#161616" }}>
                                <ContentRichText content={contentBlock.content} />
                            </Grid>
                        )}
                        {contentBlock.lubStatusHtml && (
                            <Grid item xs={12} md={8} marginTop={4} marginBottom={2} sx={{ color: "#161616" }}>
                                <LubTable content={contentBlock.lubStatusHtml} />
                            </Grid>
                        )}
                        {!!contentBlock.hotspots.length && (
                            <Grid item xs={12} md={8} marginTop={4} marginBottom={2} sx={{ color: "#161616" }}>
                                <HotspotImage src={contentBlock?.image} hotspots={contentBlock?.hotspots} />
                            </Grid>
                        )}
                        {!!contentBlock.assets.length && (
                            <Grid item xs={12} md={10} marginTop={4}>
                                <DownloadsSection
                                    title={contentBlock.title}
                                    assets={contentBlock.assets}
                                    assetHeight={140}
                                />
                            </Grid>
                        )}
                    </Fragment>
                ))}

                <Grid item xs={12} marginTop={2} marginBottom={4}>
                    <Divider />
                    <LatestNews />
                </Grid>
            </Grid>
        </>
    );
};

export default SubPage;
