// Copyright (C) 2023 TANNER AG

import { useNavigate } from "react-router-dom";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { boldFontFamily } from "../../theme";
import { AIChatIcon, FileDownloadIcon, NewsLetterIcon } from "../icons/MANIcons";
import { routes } from "../../lib/utils";
import useSeenNewsCounter from "../../hooks/useSeenNewsCounter";
import useTranslation from "../../hooks/usetranslation";

const BUTTON_ICON_WIDTH = 64;
const SideButtons: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { newNews } = useSeenNewsCounter();
    // console.log(window.location.hostname);
    const isStage = window.location.hostname !== "mybus.man.eu";

    const buttons = [
        {
            id: 0,
            labelTranslationKey: "newsflash",
            Icon: NewsLetterIcon,
            badgeContent: newNews,
            navigateTo: routes.newsFlash,
        },
        {
            id: 1,
            labelTranslationKey: "all_downloads",
            Icon: FileDownloadIcon,
            badgeContent: 0,
            navigateTo: routes.downloads,
        },
        {
            id: 2,
            labelTranslationKey: "ai_assistant",
            Icon: AIChatIcon,
            badgeContent: 0,
            navigateTo: routes.aiAssistant,
            isStageStatus: isStage
        }
    ];

    return (
        
        <Box
            position="fixed"
            left={`calc(100% - ${BUTTON_ICON_WIDTH}px)`}
            top="50%"
            bottom="50%"
            zIndex={1}
            sx={{ transform: "translateY(-50%)" }}
        >
            <Stack spacing={1}>
                {buttons.map(({ id, navigateTo, badgeContent, Icon, labelTranslationKey, isStageStatus }) => (
                    <Fab
                        key={id}
                        variant="extended"
                        color="secondary"
                        onClick={() => navigate(navigateTo)}
                        sx={{
                            height: 64,
                            visibility: (isStageStatus === false) ? "hidden" : "visible",
                            justifyContent: "flex-start",
                            transition: "transform 1s",
                            "&:hover": { transform: `translateX(-100%) translateX(${BUTTON_ICON_WIDTH}px)` }
                        }}
                    >
                        <Badge
                            badgeContent={badgeContent}
                            color="primary"
                            sx={{
                                ".MuiBadge-standard": {
                                    fontFamily: boldFontFamily,
                                    color: "#E40045",
                                    backgroundColor: "white"
                                }
                            }}
                        >
                            <Icon fontSize="large" />
                        </Badge>

                        <Typography variant="h5" textTransform="uppercase" ml={2} align="right">
                            {t(labelTranslationKey)}
                        </Typography>
                    </Fab>
                ))}
            </Stack>
        </Box>
        
    );
};

export default SideButtons;
