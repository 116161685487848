// Copyright (C) 2022 TANNER AG

import React from "react";
import Typography from "@mui/material/Typography";
import { CustomerDownloads } from "../types";
import Stack from "@mui/material/Stack";
import Section from "../../../components/Section";
import ListItemDownload from "../../../components/listitem/ListItemDownload";
import Button from "@mui/material/Button";
import { DownloadIcon } from "../../../components/icons/MANIcons";
import useAsset from "../../../hooks/useasset";
import useTranslation from "../../../hooks/usetranslation";

type Props = {
    downloads?: CustomerDownloads[];
};

const CustomerProjectDownloads: React.FC<Props> = ({ downloads }) => {
    const { t } = useTranslation();
    const { createDownloadUrl } = useAsset();

    if (!downloads?.length) {
        return null;
    }

    return (
        <Section sx={{ mb: 4 }}>
            <Typography variant="h3" sx={{ mb: 2 }}>
                {t("additional_downloads")}
            </Typography>
            <Stack spacing={2}>
                {downloads?.map((download) => (
                    <ListItemDownload
                        key={download.id}
                        id={String(download.id)}
                        name={download.name}
                        mime={download.mime}
                        size={download.size}
                        border
                        action={
                            <Button
                                startIcon={<DownloadIcon color="secondary" />}
                                component="a"
                                href={createDownloadUrl(download.id)}
                                download
                            >
                                {t("download_file")}
                            </Button>
                        }
                    />
                ))}
            </Stack>
        </Section>
    );
};

export default CustomerProjectDownloads;
