// Copyright (C) 2022 TANNER AG

import React from "react";
import FormInput from "../inputs/FormInput";
import FormSelect from "../inputs/FormSelect";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import { BusIcon } from "../icons/MANIcons";
import Stack from "@mui/material/Stack";
import FormSwitch from "../inputs/FormSwitch";
import { useFormik } from "formik";
import { Project, ProjectDataInput, useProjectOptionsQuery } from "../../graphql";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Divider from "@mui/material/Divider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import useLocale from "../../hooks/uselocale";
import FormDate from "../inputs/FormDate";
import { getYearOptions } from "../../lib/utils";
import useTranslation from "../../hooks/usetranslation";

type FormFields = {
    name: string;
    gpa: string;
    tenderCountry: string;
    status: string;
    deliveryYear: number;
    vehicleAmount: number;
    lub: string;
    orderValue: number;
    proposal: boolean;
    publication: string;
    deadline: string;
};

type Props = {
    head?: React.ReactNode;
    initialValues?: Pick<
        Project,
        | "name"
        | "gpa"
        | "tenderCountry"
        | "status"
        | "vehicleAmount"
        | "deliveryYear"
        | "lub"
        | "orderValue"
        | "proposal"
        | "deadline"
        | "publication"
    >;
    onSubmit?(data: ProjectDataInput): void;
    error?: React.ReactNode;
    children?: React.ReactNode;
};

const ProjectForm: React.FC<Props> = ({ initialValues, onSubmit, head, error, children }) => {
    const { locale, dateLocale, country } = useLocale();
    const [{ data }] = useProjectOptionsQuery({ variables: { locale } });
    const { values, handleChange, handleSubmit, setFieldValue } = useFormik<FormFields>({
        initialValues: {
            name: "",
            gpa: "",
            tenderCountry: country,
            status: "open",
            deliveryYear: 0,
            vehicleAmount: 0,
            lub: "",
            orderValue: 0,
            proposal: false,
            publication: "",
            deadline: "",
            ...initialValues
        },
        onSubmit: (values) => {
            onSubmit?.({
                name: values.name,
                gpa: values.gpa,
                tenderCountry: values.tenderCountry,
                status: values.status,
                deliveryYear: values.deliveryYear,
                vehicleAmount: values.vehicleAmount,
                lub: values.lub,
                orderValue: values.orderValue,
                proposal: values.proposal,
                publication: values.publication,
                deadline: values.deadline
            });
        }
    });
    const { t } = useTranslation();
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
            <Stack component="form" onSubmit={handleSubmit} sx={{ overflow: "hidden" }}>
                <DialogContent sx={{ pb: 0 }}>
                    {head}
                    <Grid container spacing={3}>
                        <Grid xs={12} item>
                            <Typography variant="h5" color="textSecondary" textTransform="uppercase">
                                Project details
                            </Typography>
                        </Grid>
                        <Grid xs={12} item>
                            <FormInput
                                id="name"
                                label={t("project_title")}
                                helperText={t("client_name")}
                                required
                                InputProps={{ onChange: handleChange, value: values.name, autoFocus: true }}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <FormSelect
                                id="tenderCountry"
                                label={t("country")}
                                helperText={t("tender_delivery_country")}
                                required
                                options={data?.countries?.countries ?? []}
                                SelectProps={{ value: values.tenderCountry, onChange: handleChange }}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <FormSelect
                                id="status"
                                label={t("status")}
                                required
                                options={data?.status ?? []}
                                SelectProps={{ value: values.status, onChange: handleChange }}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <FormInput
                                id="gpa"
                                label={t("gpa_number")}
                                InputProps={{ onChange: handleChange, value: values.gpa }}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <Typography sx={{ mt: 2 }} variant="h5" color="textSecondary" textTransform="uppercase">
                                {t("order_details_opt")}
                            </Typography>
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <FormInput
                                id="vehicleAmount"
                                label={t("number_vehicles")}
                                InputProps={{
                                    onChange: (event) =>
                                        setFieldValue("vehicleAmount", Number(event.currentTarget.value)),
                                    value: values.vehicleAmount || "",
                                    type: "number",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <BusIcon color="primary" />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <FormInput
                                id="deliveryYear"
                                label={t("year_of_deliver")}
                                InputProps={{
                                    onChange: (event) =>
                                        setFieldValue("deliveryYear", Number(event.currentTarget.value)),
                                    value: values.deliveryYear || "",
                                    type: "number",
                                    inputProps: {
                                        min: new Date().getFullYear(),
                                        step: 1,
                                        list: "deliveryYearOptions"
                                    }
                                }}
                            />
                            <datalist id="deliveryYearOptions">
                                {getYearOptions(6).map((year) => (
                                    <option key={year} value={year} />
                                ))}
                            </datalist>
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <FormInput
                                id="lub"
                                label={t("lub_numbers")}
                                InputProps={{ onChange: handleChange, value: values.lub }}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <FormInput
                                id="orderValue"
                                label={t("order_value_mio")}
                                InputProps={{
                                    onChange: (event) => setFieldValue("orderValue", Number(event.currentTarget.value)),
                                    value: values.orderValue || "",
                                    type: "number"
                                }}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <FormSwitch
                                id="proposal"
                                label={t("call_proposals")}
                                checked={values.proposal}
                                onChange={handleChange}
                            />
                        </Grid>
                        {values.proposal && (
                            <>
                                <Grid xs={12} sm={6} item>
                                    <FormDate
                                        id="publication"
                                        label={t("call_proposals_ex")}
                                        value={values.publication}
                                        onChange={(value) => setFieldValue("publication", value)}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} item>
                                    <FormDate
                                        id="deadline"
                                        label={t("call_proposals_deadline")}
                                        value={values.deadline}
                                        onChange={(value) => setFieldValue("deadline", value)}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Divider sx={{ mt: 4 }} />
                    {error}
                </DialogContent>
                <DialogActions>{children}</DialogActions>
            </Stack>
        </LocalizationProvider>
    );
};

export default ProjectForm;
