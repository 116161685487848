// Copyright (C) 2022 TANNER AG

import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import MuiTab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

type Tab = {
    id: string;
    title?: string;
};

type Props = {
    tabs: Tab[];
    activeTabId?: string;
};

const PageTabs: React.FC<Props> = ({ tabs, activeTabId }) => {
    const navigate = useNavigate();

    const handleChangeTab = (_: unknown, tabId: string) => {
        navigate(`../${tabId}`);
    };

    return (
        <Box sx={{ backgroundColor: "white" }}>
            <Container>
                <Tabs variant="scrollable" value={activeTabId ?? false} onChange={handleChangeTab}>
                    {tabs.map((tab) => (
                        <MuiTab key={tab.id} label={tab.title} value={tab.id} />
                    ))}
                </Tabs>
            </Container>
        </Box>
    );
};

export default PageTabs;
