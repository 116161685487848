// Copyright (C) 2022 TANNER AG

import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { BusIcon } from "./icons/MANIcons";
import Box from "@mui/material/Box";

const Spinner: React.FC = () => {
    return (
        <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "50vh" }}>
            <Box sx={{ position: "relative" }}>
                <CircularProgress color="secondary" size={60} />
                <BusIcon
                    sx={{
                        fontSize: 32,
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "primary.main"
                    }}
                />
            </Box>
        </Container>
    );
};

export default Spinner;
