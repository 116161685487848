// Copyright (C) 2022 TANNER AG

import React from "react";
import { useParams } from "react-router-dom";
import CustomerLobby from "./CustomerLobby";
import CustomerProject from "./project/CustomerProject";
import GlobalStyles from "@mui/material/GlobalStyles";

const CustomerView: React.FC = () => {
    const { id } = useParams();
    const uuid = String(id);

    return (
        <>
            <GlobalStyles styles={{ body: { backgroundColor: "#fff" } }} />
            <CustomerLobby>
                <CustomerProject id={uuid} />
            </CustomerLobby>
        </>
    );
};

export default CustomerView;
