// Copyright (C) 2022 TANNER AG

import { useMemo, useState } from "react";

const useTags = (items?: { keywords?: string[] }[]) => {
    const [activeTags, setActiveTags] = useState<string[]>([]);
    const tags = useMemo(() => {
        const tags: string[] = [];
        items?.forEach((item) => tags.push(...(item.keywords ?? [])));
        // @ts-ignore
        return [...new Set(tags)] as string[];
    }, [items]);

    const toggleTag = (tag: string) => {
        const newTags = [...activeTags];
        const index = newTags.indexOf(tag);
        if (index === -1) {
            newTags.push(tag);
        } else {
            newTags.splice(index, 1);
        }
        setActiveTags(newTags);
    };

    return { tags, activeTags, toggleTag, setActiveTags };
};

export default useTags;
