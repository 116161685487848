// Copyright (C) 2022 TANNER AG

import React from "react";
import useActiveProject from "../../../../hooks/useactiveproject";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ProjectSidebarProduct from "./ProductSidebarProduct";
import useTranslation from "../../../../hooks/usetranslation";

const ProjectSidebarProducts: React.FC = () => {
    const { data, addedProducts } = useActiveProject();
    const { t } = useTranslation();
    if (!addedProducts.length) {
        return (
            <>
                <Typography variant="h5" color="textSecondary" gutterBottom>
                    {t("add_select_products")}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {t("add_to_cart")}
                </Typography>
            </>
        );
    }

    return (
        <Stack spacing={1}>
            <Typography variant="h5" gutterBottom>
                {addedProducts.length} {t("lower_products")}
            </Typography>
            {data?.products?.map((product) => (
                <ProjectSidebarProduct
                    key={product.item.id}
                    productId={product.item.id}
                    name={product.item.name}
                    categoryName={product.item.category?.name}
                    image={product.item.image}
                />
            ))}
        </Stack>
    );
};

export default ProjectSidebarProducts;
