// Copyright (C) 2022 TANNER AG

import React from "react";
import { Routes, Route } from "react-router-dom";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import ProductGroup from "../group/ProductGroup";
import ProductCategoryDetails from "./ProductCategoryDetails";
import Sidebar from "../sidebar/Sidebar";
import ProductSidebarMobile from "../sidebar/productsidebar/ProductSidebarMobile";

const ProductCategory: React.FC = () => {
    return (
        <Container sx={{ py: 4 }}>
            <Stack direction="row" spacing={3}>
                <Sidebar />
                <Box sx={{ flexGrow: 1 }}>
                    <ProductSidebarMobile />
                    <Routes>
                        <Route path="/" element={<ProductCategoryDetails />} />
                        <Route path=":groupId" element={<ProductGroup />} />
                    </Routes>
                </Box>
            </Stack>
        </Container>
    );
};

export default ProductCategory;
