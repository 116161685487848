// Copyright (C) 2022 TANNER AG

import React from "react";
import { Product } from "../../../../graphql";
import Stack from "@mui/material/Stack";
import ProjectProductListItem from "./ProjectProductListItem";
import useProductCategories from "../../../../hooks/useproductcategories";
import Typography from "@mui/material/Typography";
import Section from "../../../../components/Section";

type Props = {
    projectId?: string;
    products: { visible?: boolean; item: Pick<Product, "id" | "name" | "image" | "intro" | "details" | "category"> }[];
};

const ProjectProductList: React.FC<Props> = ({ projectId = "", products }) => {
    const { categories } = useProductCategories();

    return (
        <>
            {categories.map((category) => {
                const categoryProducts = products.filter((product) => product.item.category?.id === category.id);
                if (!categoryProducts.length) {
                    return null;
                }

                return (
                    <Section key={category.id} sx={{ mb: 5 }}>
                        <Typography variant="h3">{category.name}</Typography>
                        <Stack spacing={2} sx={{ mt: 2 }}>
                            {categoryProducts.map((product) => (
                                <ProjectProductListItem
                                    projectId={projectId}
                                    key={product.item.id}
                                    product={product.item}
                                    visible={product.visible}
                                />
                            ))}
                        </Stack>
                    </Section>
                );
            })}
        </>
    );
};

export default ProjectProductList;
