// Copyright (C) 2022 TANNER AG

import React from "react";
import { CombinedError } from "urql";
import ErrorAlert from "./ErrorAlert";
import { SxProps } from "@mui/material";

type Props = {
    error?: CombinedError | null;
    sx?: SxProps;
};

const GqlError: React.FC<Props> = ({ error, sx }) => {
    if (!error) {
        return null;
    }

    const { message } = parseError(error);

    return <ErrorAlert message={message} sx={sx} />;
};

const parseError = (error: CombinedError): Error => {
    if (error.graphQLErrors.length) {
        return {
            name: error.graphQLErrors[0].name,
            message: error.graphQLErrors[0].message,
            stack: error.graphQLErrors[0].stack,
        };
    }

    if (error.networkError) {
        return { name: error.networkError.name, message: error.networkError.message, stack: error.networkError.stack };
    }

    return {
        name: "Unexpected error",
        message: "",
    };
};

export default GqlError;
