// Copyright (C) 2022 TANNER AG

import { ContentTypeGroup, useContentGroupQuery } from "../graphql";
import useLocale from "./uselocale";

const useContentGroup = (group: ContentTypeGroup) => {
    const { locale } = useLocale();
    const [{ data, fetching, error }] = useContentGroupQuery({ variables: { group, locale } });

    return { group: data?.contentGroup, fetching, error };
};

export default useContentGroup;
