// Copyright (C) 2022 TANNER AG

import React, { FormEvent, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { CheckCircleIcon } from "../../../../components/icons/MANIcons";
import { Project, useUpdateProjectMutation } from "../../../../graphql";
import FormInput from "../../../../components/inputs/FormInput";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import useTranslation from "../../../../hooks/usetranslation";

type Props = {
    project?: Pick<Project, "id" | "customerIntro">;
    onClose(): void;
};

const ProjectIntroEdit: React.FC<Props> = ({ project, onClose }) => {
    const [value, setValue] = useState("");
    const [{ fetching }, updateProject] = useUpdateProjectMutation();

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        await updateProject({ where: { id: project?.id ?? "" }, data: { customerIntro: value } });
        onClose();
    };

    const handleRemove = async () => {
        await updateProject({ where: { id: project?.id ?? "" }, data: { customerIntro: "" } });
        onClose();
    };

    useEffect(() => {
        setValue(project?.customerIntro ?? "");
    }, [project?.customerIntro]);
    const { t } = useTranslation();
    return (
        <Stack spacing={2} component="form" onSubmit={handleSubmit}>
            <FormInput
                id="customerIntro"
                label=""
                InputProps={{
                    value,
                    onChange: (event) => setValue(event.currentTarget.value),
                    multiline: true,
                    minRows: 3
                }}
            />
            <Stack direction="row" sx={{ alignItems: "center" }}>
                <LoadingButton
                    loading={fetching}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    startIcon={<CheckCircleIcon />}
                >
                    {t("save_changes")}
                </LoadingButton>
                <Button onClick={onClose} disabled={fetching}>
                    {t("cancel")}
                </Button>
                {project?.customerIntro && (
                    <Link sx={{ ml: "auto" }} onClick={handleRemove}>
                        {t("remove_introduction")}
                    </Link>
                )}
            </Stack>
        </Stack>
    );
};

export default ProjectIntroEdit;
