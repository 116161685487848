// Copyright (C) 2022 TANNER AG

import React, { FormEvent, useState } from "react";
import Stack from "@mui/material/Stack";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import { useDropzone } from "react-dropzone";
import { DeleteIcon, FileUploadIcon, FileUploadOutlineIcon } from "../icons/MANIcons";
import Button from "@mui/material/Button";
import ListItemDownload from "../listitem/ListItemDownload";
import IconButton from "@mui/material/IconButton";

type Props = {
    head?: React.ReactNode;
    children?: React.ReactNode;
    onSubmit?(file: File): void;
};

const ProjectUploadForm: React.FC<Props> = ({ head, onSubmit, children }) => {
    const [file, setFile] = useState<File>();
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        multiple: false,
        onDrop: (acceptedFiles) => {
            if (!acceptedFiles.length) {
                return;
            }

            setFile(acceptedFiles[0]);
        }
    });

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        file && onSubmit?.(file);
    };

    return (
        <Stack component="form" sx={{ overflow: "hidden" }} onSubmit={handleSubmit}>
            <DialogContent sx={{ pb: 0 }}>
                {head}
                {file && (
                    <Stack>
                        <ListItemDownload
                            id=""
                            name={file.name}
                            modified={new Date(file.lastModified).toISOString()}
                            size={file.size}
                            mime={file.name?.split(".").pop()}
                            border
                            action={
                                <IconButton onClick={() => setFile(undefined)}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            }
                        />
                    </Stack>
                )}
                {!file && (
                    <Dropzone {...getRootProps()}>
                        <FileUploadOutlineIcon fontSize="large" sx={{ mb: 2, color: "grey.500" }} />
                        <Button startIcon={<FileUploadIcon />} variant="contained" color="secondary" sx={{ mb: 1.5 }}>
                            Select a file...
                        </Button>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <p>Drop the files here ...</p>
                        ) : (
                            <Typography variant="body2">or drag a file to this field to upload it.</Typography>
                        )}
                    </Dropzone>
                )}
            </DialogContent>
            <DialogActions>{children}</DialogActions>
        </Stack>
    );
};

const Dropzone = styled("div")(({ theme }) => ({
    position: "relative",
    width: "100%",
    minHeight: 140,
    border: "1px dashed",
    borderColor: theme.palette.grey["500"],
    backgroundColor: theme.palette.grey["100"],
    borderRadius: 8,
    boxSizing: "border-box",
    cursor: "pointer",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2)
}));

export default ProjectUploadForm;
