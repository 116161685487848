// Copyright (C) 2022 TANNER AG

import React from "react";
import Button from "@mui/material/Button";
import { ChevronDownIcon, DataIcon } from "../icons/MANIcons";
import useAnchorHandler from "../../hooks/useanchorhandler";
import MenuPopover from "../MenuPopover";
import FormLabel from "@mui/material/FormLabel";
import ProjectCreation from "../projectform/ProjectCreation";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ProjectSelectList from "./ProjectSelectList";
import useStore from "../../hooks/usestore";
import Typography from "@mui/material/Typography";
import useTranslation from "../../hooks/usetranslation";

type Props = {
    withLabel?: boolean;
};

const ProjectSelect: React.FC<Props> = ({ withLabel }) => {
    const { onClick, onClose, anchorEl } = useAnchorHandler();
    const activeProject = useStore((state) => state.activeProject);
    const { t } = useTranslation();
    return (
        <>
            {withLabel && <FormLabel sx={{ display: "block" }}>{t("project_title")}</FormLabel>}
            <Button
                variant="outlined"
                fullWidth
                startIcon={<DataIcon />}
                endIcon={<ChevronDownIcon />}
                sx={{
                    justifyContent: "flex-start",
                    "& > .MuiButton-endIcon": { marginLeft: "auto" }
                }}
                onClick={onClick}
            >
                <Typography component="span" noWrap variant="body2">
                    {Boolean(activeProject) ? activeProject?.name : t("select_create_project")}
                </Typography>
            </Button>
            <MenuPopover
                anchorEl={anchorEl}
                onClose={onClose}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                maxWidth={288}
                disablePortal
            >
                <ProjectSelectList open={Boolean(anchorEl)} onSelect={onClose} />
                <Divider />
                <Box sx={{ p: 1.5 }}>
                    <ProjectCreation label={t("create_new_project")} />
                </Box>
            </MenuPopover>
        </>
    );
};

export default ProjectSelect;
