// Copyright (C) 2022 TANNER AG

import React, { useState, SyntheticEvent } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { PlusIcon, MinusIcon } from "../../icons/MANIcons";
import Typography from "@mui/material/Typography";
import Hotspot from "./Hotspot";
import { Hotspot as GqlHotspot } from "../../../graphql";
import useAsset from "../../../hooks/useasset";
import RichText from "../RichText";
import { boldFontFamily } from "../../../theme";

type Props = {
    src?: string;
    hotspots?: GqlHotspot[];
};

const HotspotImage: React.FC<Props> = ({ src, hotspots }) => {
    const { createImageUrl } = useAsset();
    const [expanded, setExpanded] = useState<number | false>(false);
    const [size, setSize] = useState({ width: 0, height: 0 });

    const handleChange = (index: number) => () => {
        setExpanded((expanded) => (expanded === index ? false : index));
    };

    const handleImageLoad = (event: SyntheticEvent<HTMLImageElement>) => {
        setSize({
            width: event.currentTarget.naturalWidth,
            height: event.currentTarget.naturalHeight
        });
    };

    if (!src) {
        return null;
    }

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
                <Box sx={{ position: "relative", display: "flex" }}>
                    <Box
                        component="img"
                        src={createImageUrl(src)}
                        sx={{ maxWidth: 1, borderRadius: 1, boxShadow: "0 0 4px 0 #d6d8db" }}
                        onLoad={handleImageLoad}
                    />
                    <Box sx={{ position: "absolute", overflow: "hidden", top: 0, width: 1, height: 1 }}>
                        {Boolean(size.width > 0) &&
                            hotspots?.map((hotspot, index) => (
                                <Hotspot
                                    key={index}
                                    imageSize={size}
                                    hotspot={hotspot}
                                    active={expanded === index}
                                    onClick={handleChange(index)}
                                />
                            ))}
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={4}>
                {hotspots?.map((hotspot, index) => {
                    const isExpanded = expanded === index;
                    return (
                        <Accordion
                            key={index}
                            expanded={isExpanded}
                            onChange={handleChange(index)}
                            sx={{ "&.Mui-expanded": { my: 0, "&::before": { opacity: 1 } }, bgcolor: "transparent" }}
                        >
                            <AccordionSummary
                                sx={{
                                    px: 0,
                                    flexDirection: "row-reverse",
                                    "& .MuiAccordionSummary-expandIconWrapper": { mr: 2 }
                                }}
                                expandIcon={
                                    isExpanded ? (
                                        <MinusIcon color="secondary" sx={{ fontSize: 18 }} />
                                    ) : (
                                        <PlusIcon color="secondary" sx={{ fontSize: 18 }} />
                                    )
                                }
                            >
                                <Typography variant="subtitle1" sx={{ fontFamily: boldFontFamily, lineHeight: 1.2 }}>
                                    {hotspot.title}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ px: 0 }}>
                                <RichText html={hotspot.content} />
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Grid>
        </Grid>
    );
};

export default HotspotImage;
