// Copyright (C) 2022 TANNER AG

import React, {FC, useEffect} from "react";
import useLocale from "../../hooks/uselocale";
import FormSelect from "../inputs/FormSelect";
import {languageOptions} from "../../lib/utils";
import Box from "@mui/material/Box";
import useStore from "../../hooks/usestore";
import shallow from "zustand/shallow";
import {useUpdatePreferredLanguageMutation} from "../../graphql";
import useAuth from "../../hooks/useauth";

interface Props{
    label?: string;
    isUserAuthenticated?: boolean;

}
const LocaleDropdown:FC<Props> = ({label, isUserAuthenticated}) => {
    const [locale] = useStore((state) => [state.locale], shallow);
    const { setLocale } = useLocale()
   const {refetch} =useAuth();
    const [{ fetching }, updatePreferredLanguage] = useUpdatePreferredLanguageMutation();

    useEffect(() => {
        if(isUserAuthenticated && !fetching){
                    refetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(isUserAuthenticated && !fetching)]);
    return (
        <>
            <Box right={16}>
                <FormSelect
                    id="country"
                    label={label}
                    options={languageOptions}
                    SelectProps={{
                        value: locale??'de',
                        onChange: (event) => {
                            setLocale(event.target.value as string);
                            isUserAuthenticated && updatePreferredLanguage({ preferredLanguage: event.target.value });
                        }
                    }}
                />
            </Box>
        </>
    );
};

export default LocaleDropdown;
