// Copyright (C) 2022 TANNER AG

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import React from "react";
import Stack from "@mui/material/Stack";

import { PDFIcon } from "../../../components/icons/MANIcons";

type Props = {
    enabled?: boolean;
    enable(): void;
    disable(): void;
};

const PageToolbarPdf: React.FC<Props> = ({ enabled, enable, disable }) => {
    if (!enabled) {
        return (
            <IconButton onClick={enable} edge="end">
                <PDFIcon color="primary" />
            </IconButton>
        );
    }

    return (
        <Stack direction="row" spacing={1} sx={{ alignItems: "center", mr: { xs: 0, md: 21 } }}>
            <Button variant="outlined" onClick={disable}>
                Beenden
            </Button>
            <Button variant="contained" color="secondary" onClick={() => window.print()}>
                Generate PDF
            </Button>
        </Stack>
    );
};

export default PageToolbarPdf;
