// Copyright (C) 2022 TANNER AG

import React from "react";
import useLocale from "../../../hooks/uselocale";
import { useToolQuery } from "../../../graphql";
import DialogContent from "@mui/material/DialogContent";
import DialogHead from "../../../components/dialog/DialogHead";
import RichText from "../../../components/content/RichText";
import Button from "@mui/material/Button";
import { DownloadIcon, LinkExternIcon } from "../../../components/icons/MANIcons";
import useTranslation from "../../../hooks/usetranslation";
import Section from "../../../components/Section";
import BulletList from "../../../components/BulletList";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemLink from "../../../components/listitem/ListItemLink";
import Experts from "../../products/product/experts/Experts";
import Stack from "@mui/material/Stack";
import ListItemDownload from "../../../components/listitem/ListItemDownload";
import useAsset from "../../../hooks/useasset";
import ToolsItemsTags from "../ToolsItemTags";

type Props = {
    id: string;
    onClose?(): void;
};

const ToolDetailsContent: React.FC<Props> = ({ id, onClose }) => {
    const { locale } = useLocale();
    const { createDownloadUrl } = useAsset();
    const [{ data }] = useToolQuery({ variables: { id, locale }, pause: !id });
    const { t } = useTranslation();
    const tool = data?.tool;

    return (
        <>
            <DialogContent>
                <DialogHead title={tool?.name ?? ""} subTitle={t("my_tools")} onClose={onClose} />
                {Boolean(tool?.keywords?.length) && (
                    <Stack sx={{ mb: 2 }}>
                        <ToolsItemsTags tags={tool?.keywords} />
                    </Stack>
                )}
                <Section>
                    <RichText html={tool?.description} />
                </Section>
                {tool?.details && (
                    <Section>
                        <BulletList title={t("details")} items={tool?.details} />
                    </Section>
                )}
                {Boolean(tool?.downloads?.length) && (
                    <Section>
                        <Typography variant="h4" textTransform="uppercase" gutterBottom>
                            {t("downloads")}
                        </Typography>
                        <Stack>
                            {tool?.downloads.map((download) => (
                                <ListItemDownload
                                    key={download.id}
                                    id={download.id}
                                    name={download.name}
                                    mime={download.mime}
                                    size={download.size}
                                    border
                                    action={
                                        <Button
                                            startIcon={<DownloadIcon color="secondary" />}
                                            component="a"
                                            href={createDownloadUrl(download.id)}
                                            download
                                        >
                                            {t("download_file")}
                                        </Button>
                                    }
                                />
                            ))}
                        </Stack>
                    </Section>
                )}
                {Boolean(tool?.information?.length) && (
                    <Section>
                        <Typography variant="h4" textTransform="uppercase">
                            {t("further_information")}
                        </Typography>
                        <List disablePadding>
                            {tool?.information.map((info, idx) => (
                                <ListItemLink key={idx} href={info.href}>
                                    {info.title}
                                </ListItemLink>
                            ))}
                        </List>
                    </Section>
                )}
                {tool?.url && (
                    <Button
                        onClick={onClose}
                        startIcon={<LinkExternIcon color="secondary" />}
                        component="a"
                        href={tool?.url}
                        target="_blank"
                    >
                        {t("open_tool")}
                    </Button>
                )}
            </DialogContent>
            {Boolean(tool?.experts?.length) && (
                <DialogContent sx={{ bgcolor: "grey.50" }}>
                    <Experts productName={tool?.name} experts={tool?.experts ?? []} />
                    <Stack direction="row" spacing={1} sx={{ mt: 3 }}>
                        <Button onClick={onClose} variant="outlined">
                            {t("back_to_overview")}
                        </Button>
                    </Stack>
                </DialogContent>
            )}
        </>
    );
};

export default ToolDetailsContent;
