// Copyright (C) 2022 TANNER AG

import React from "react";
import { useDeleteProjectMutation } from "../../../graphql";
import IconButton from "@mui/material/IconButton";
import { DeleteIcon, DotsHorizontalIcon } from "../../../components/icons/MANIcons";
import MenuPopover from "../../../components/MenuPopover";
import useAnchorHandler from "../../../hooks/useanchorhandler";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../lib/utils";
import useTranslation from "../../../hooks/usetranslation";
import useStore from "../../../hooks/usestore";

type Props = {
    projectId: string;
};

const ProjectContextActions: React.FC<Props> = ({ projectId }) => {
    const { anchorEl, onClick, onClose } = useAnchorHandler();
    const [activeProject, setProject] = useStore((state) => [state.activeProject, state.setProject]);
    const navigate = useNavigate();
    const [{ fetching }, deleteProject] = useDeleteProjectMutation();
    const { t } = useTranslation();

    const handleDelete = async () => {
        await deleteProject({ where: { id: projectId } });
        if (activeProject?.id === projectId) {
            setProject();
        }
        navigate(routes.dsg.projects);
    };

    return (
        <>
            <IconButton onClick={onClick} edge="end">
                <DotsHorizontalIcon />
            </IconButton>
            <MenuPopover anchorEl={anchorEl} onClose={onClose} maxWidth={200}>
                <MenuList component="div">
                    <MenuItem onClick={handleDelete} disabled={fetching}>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{ variant: "body2" }}>{t("delete_project")}</ListItemText>
                    </MenuItem>
                </MenuList>
            </MenuPopover>
        </>
    );
};

export default ProjectContextActions;
